<template>
    <div class="cookie" v-show="cookieShow" data-timer="30">
        <div class="cookie__text">Мы&nbsp;используем файлы cookie.
            Продолжая пользоваться сайтом, вы&nbsp;соглашаетесь с&nbsp;использованием файлов cookie.</div>

        <ButtonComponent
            modify="button_theme_white button_theme_thin cookie__button"
            text="OK"
            @click="_close" />
    </div>
</template>

<script>
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import Cookies from 'js-cookie';

const DAY_MILLISECONDS = 24 * 60 * 60 * 1000;
const SHOW_DELAY = 1500;
const COOKIE_NAME = 'alert-cookie-accepted';

export default {
    name: 'CookieComponent',

    components: {
        ButtonComponent
    },

    data() {
        return {
            cookieShow: false,
            timer     : 0,
            date      : null
        };
    },

    mounted() {
        this.init();
    },

    methods: {
        /**
         * Метод инициализирует модуль.
         */
        init() {
            this.timer = this.$el.dataset['timer'] || false;
            this.date = new Date().getTime();

            this._check();
        },

        /**
         * Метод проверяет наличие куки.
         * @private
         */
        _check() {
            // Дата, когда была записана кука
            const cookiesTime = Number(Cookies.get(COOKIE_NAME));
            // Сколько прошло времени
            const spentTime = this.date - cookiesTime;
            // Если стоит таймер
            const timerData = this.timer ? DAY_MILLISECONDS * Number(this.timer) : 0;
            // Когда истекает запись
            const dateOver = cookiesTime + timerData;

            if (!cookiesTime) {
                this._show();
            } else if ((spentTime > dateOver) && this.timer) {
                Cookies.remove(COOKIE_NAME);
                this._show();
            }
        },

        /**
         * Метод показывает уведомление.
         * @private
         */
        _show() {
            setTimeout(() => {
                this.cookieShow = true;
            }, SHOW_DELAY);
        },

        /**
         * Метод закрывает уведомление и устанавливает куку.
         * @private
         */
        _close() {
            Cookies.set(COOKIE_NAME, `${this.date}`);

            this.cookieShow = false;
        }
    }
};
</script>

<style lang="scss">
@import './CookieComponent';
</style>
