<template>
    <div class="compare-slider__wrapper">
        <swiper
            class="compare-slider"
            :slides-per-view="slidesPerView"
            :loop="false"
            :initialSlide="activeSlide"
            :allowTouchMove="false"
            :breakpoints="breakpoints"
            @swiper="initSwiper"
            @slideChange="_onSlideChange"
        >
            <swiper-slide
                v-for="(slide, index) in slides"
                :key="slide.id"
                class="compare-slider__slide"
            >
                <CompareActionsComponent
                    :flatID="slide.id"
                    :slideID="slide.guid || 0"
                    :startFavoriteState="slide.inFavorite"
                    :activeSection="activeSection"
                    @removeItem="_removeItem(slide.id)"
                    @processing="_processingHandler" />
                <div :class="['compare-slider__slide-image', {'is-disabled': slide.disabled}]">
                    <img :src="slide.image" :alt="slide.room">

                    <div v-if="slide.disabled" class="compare-slider__slide-disabled">Объект продан</div>
                </div>
                <div v-if="!isMobile" class="compare-slider__title">
                    {{ _formatNum(index + 1) }} / {{ _formatNum(slides.length) }}
                </div>
            </swiper-slide>
        </swiper>
        <div
            v-if="isMobile || (isTablet && slides.length > 2) || (isDesktop && slides.length > 3)"
            class="compare-slider__navigation">
            <ButtonComponent
                modify="button_theme_circle-small compare-slider__navigation-button compare-slider__navigation-prev"
                icon="ArrowIcon"
                :fill="buttonFill"
                @click="_goToSlide('prev')" />

            <div class="compare-slider__navigation-counter" v-if="isMobile">
                {{ _formatNum(activeSlide + 1) }} / {{ _formatNum(slides.length) }}
            </div>

            <ButtonComponent
                modify="button_theme_circle-small compare-slider__navigation-button compare-slider__navigation-next"
                icon="ArrowIcon"
                :fill="buttonFill"
                @click="_goToSlide('next')" />
        </div>
    </div>
</template>

<script>
import 'swiper/swiper-bundle.css';
import {Swiper, SwiperSlide} from 'swiper-vue2';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import CompareActionsComponent from '../CompareActionsComponent/CompareActionsComponent';
import CompareDataController from '@/dataControllers/compare/index';
import {mapGetters} from 'vuex';

const compareDataController = new CompareDataController();

export default {
    name: 'CompareSwiperComponent',

    components: {
        ButtonComponent,
        CompareActionsComponent,
        Swiper,
        SwiperSlide
    },

    props: {
        slides: {
            default: null,
            type   : Array
        },
        mobileSlider: {
            default: false,
            type   : Boolean
        },
        buttonFill: {
            default: '#8b96a3',
            type   : String
        },
        activeSection: {
            default: 'buyers',
            type   : String
        }
    },

    data() {
        return {
            activeSlide  : this.slides.length > 1 && this.mobileSlider ? 1 : 0,
            swiper       : null,
            slidesPerView: '1',
            breakpoints  : {}
        };
    },

    computed: {
        ...mapGetters({
            isMobile : 'GET_IS_MOBILE',
            isTablet : 'GET_IS_TABLET',
            isDesktop: 'GET_IS_DESKTOP'
        })
    },

    watch: {
        slides() {
            setTimeout(() => {
                this.swiper.update();
            }, 3);
        }
    },

    created() {
        this._getBreakpoints();
    },

    methods: {
        _getBreakpoints() {
            this.breakpoints = {
                320: {
                    slidesPerView: 1,
                    spaceBetween : 0
                },
                670: {
                    slidesPerView: 2,
                    spaceBetween : 56
                },
                960: {
                    slidesPerView: 3,
                    spaceBetween : 56
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween : 72
                }
            };
        },

        initSwiper(swiper) {
            this.swiper = swiper;
        },

        _onSlideChange() {
            if (!this.swiper) {
                return;
            }

            this.activeSlide = this.swiper.activeIndex;

            this.$emit('slideChanged', this.activeSlide);
        },

        _formatNum(num) {
            return String(num).padStart(2, '0');
        },

        _removeItem(id) {
            this.$emit('removeItem', id);
        },

        _goToSlide(direction) {
            if (this.isMobile) {
                const slideNum = compareDataController.calcSlideNum(direction, this.mobileSlider);

                this.swiper.slideTo(slideNum);
            } else {
                direction === 'next' ?
                    this.swiper.slideNext() :
                    this.swiper.slidePrev();
            }
        },

        _processingHandler(state) {
            this.$emit('processing', state);
        }
    }
};
</script>

<style lang="scss">
@import './CompareSwiperComponent';
</style>
