<template>
    <div :class="['universal-block', modify]">
        <template v-if="type === 'files'">
            <LinkComponent
                v-for="link in content"
                :modify="componentBlockModify"
                :key="link.title"
                :href="link.url ? link.url : '#'"
                :text="link.title"
                :type="link.button ? 'button' : 'link'"
                :download="link.button ? null : 'download'" />
        </template>

        <template v-else-if="type === 'links'">
            <LinkComponent
                v-for="link in content"
                type="button"
                role="button"
                :modify="componentBlockModify"
                :key="link.id"
                :href="link.href"
                :text="link.text" />
        </template>

        <template v-else-if="type === 'details'">
            <DetailsComponent
                v-for="detail in content"
                :key="detail.id"
                :title="detail.title"
                :text="detail.text" />
        </template>

        <template v-else-if="type === 'list'">
            <div :class="['universal-block__list', componentTitleModify]">{{ title }}</div>
            <div
                v-for="item in content"
                :key="item"
                :class="componentBlockModify"
                v-html="item"
                ></div>
        </template>

        <template v-else-if="type === 'text'">
            <h5
                v-if="content?.title"
                v-html="content.title"
                class="universal-block__text-title"></h5>
            <div :class="['universal-block__text', componentBlockModify]"
                v-html="content.text"></div>
        </template>

        <template v-else-if="type === 'modals-list'">
            <ButtonComponent
                :text="title"
                modify="button_theme_gray owner-steps__step-links-item"
                @click="_modalButtonHandler(content)" />
        </template>

    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import DetailsComponent from '@/components/DetailsComponent/DetailsComponent';
import LinkComponent from '@/components/LinkComponent/LinkComponent';

export default {
    name: 'UniversalBlockComponent',

    components: {
        ButtonComponent,
        DetailsComponent,
        LinkComponent
    },

    props: {
        modify: {
            default: null,
            type   : String
        },
        componentTitleModify: {
            default: null,
            type   : String
        },
        componentBlockModify: {
            default: null,
            type   : String
        },
        type: {
            default: null,
            type   : String
        },
        title: {
            default: null,
            type   : String
        },
        content: {
            default: () => {
                return {};
            },
            type: [Object, Array]
        }
    },

    methods: {
        _modalButtonHandler(content) {
            this.$root.$emit('showPopup', 'owners', content);
        }
    }
};
</script>
