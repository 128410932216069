<template>
    <div class="compare-empty">
        <div class="compare-empty__main">
            <h4 class="compare-empty__main-title">Здесь ничего нет</h4>
            <div class="compare-empty__main-text">
                <span>Нажмите на</span>
                <CompareIcon fill="#62725b" />
                <span>чтобы добавить объект недвижимости в&nbsp;сравнение</span>
            </div>

            <router-link class="button compare-empty__main-button" to="/content/favorites">В избранное</router-link>
        </div>
    </div>
</template>

<script>
import CompareIcon from '../../../icons/CompareIcon';

export default {
    name: 'CompareEmptyComponent',

    components: {
        CompareIcon
    }
};
</script>

<style lang="scss">
@import './CompareEmptyComponent';
</style>
