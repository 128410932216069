<template>
    <CommonCard
        v-if="activeTab === 'all' || card.estateType === activeTab"
        :content="card"
        :cardOptions="cardOptions"
        :isBooking="false"
        modify="objects-card"
        @showOwners="_showOwners"
    />
</template>

<script>
import CommonCard from '../../CommonCard/CommonCard';
import ObjectsDataController from '../../../dataControllers/objects';

const ObjectsController = new ObjectsDataController();

export default {
    name: 'ObjectsCard',

    components: {
        CommonCard
    },

    props: {
        card: {
            default: () => {
                return {};
            },
            type: Object
        },
        activeTab: {
            default: 'all',
            type   : String
        }
    },

    computed: {
        cardOptions() {
            return ObjectsController.getCardOptions(this.card);
        }
    },

    methods: {
        _showOwners() {
            const data = {
                owners: this.card?.owners || [],
                name  : this.card?.name,
                price : this.card?.price,
                title : 'Список дольщиков'
            };

            this.$emit('showOwners', data);
        }
    }
};
</script>
