export interface ICallbackRequest {
    data: {
        content?: unknown;
    };
}

export interface ICallback {
    success(req: ICallbackRequest): void;
    error(err: number): void;
    complete?(): void;
}

export enum ERestMethod {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

type ArrayLengthMutationKeys = 'splice' | 'push' | 'pop' | 'shift' | 'unshift';
export type FixedLengthArray<T, L extends number, TObj = [T, ...T[]]> =
    Pick<TObj, Exclude<keyof TObj, ArrayLengthMutationKeys>>
    & {
        [I: number ]: T;
        readonly length: L;
        [Symbol.iterator](): IterableIterator<T>;
    }

export interface IImageSize {
    width: number;
    height: number;
}

export interface IDynamicImport {
    default: any;
}

export interface IItemObject {
    validation: {
        type: string;
        length: string;
        msg: string;
    };
    value: string;
    error: boolean;
}

export interface IResponseGetCodeData {
    success: boolean;
    // resend_timeout: number;
    data: {
        resend_timeout: number;
    };
    messages: any[];
}

export interface ICodeResult {
    success: boolean;
    errorText: string;
    resendTimer?: string | null;
}

export interface IDataStore {
    getData(inputName): IItemObject;
    getPhone(): string;
}

export interface IResponseCheckCodeData {
    success: boolean;
    // resend_timeout: number;
    data: {
        auth: boolean;
    };
    messages: any[];
}

export enum EValidationType {
    NUMLENGTH = 'numberLength',
    LETTERS = 'letters',
    EMAIL = 'email',
    PHONE = 'phone',
    CONTRACT = 'contract',
    BIRTHDAY = 'birthday',
    NUMBER = 'number',
    PSERIES = 'passportSeries',
    BETWEEN = 'betweenValue'
}
