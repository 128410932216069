<template>
    <div
        v-if="banner || content"
        class="management-info">
        <div class="management-info__header">
            <div v-if="banner?.image" class="management-info__header-image">
                <img :src="banner?.image" :alt="banner.title">
            </div>

            <div v-if="banner?.title || banner?.text" class="management-info__header-title">
                <h3 v-if="banner?.title" v-html="banner.title"></h3>
                <p v-if="banner?.text" v-html="banner.text"></p>
            </div>

            <div
                v-if="banner?.components?.length && banner.components[0].content?.length"
                class="management-info__header-advantages">
                <div
                    v-for="advantage in banner.components[0].content"
                    :key="advantage.id"
                    class="management-info__header-advantage">
                    <h4 v-if="advantage.title">{{ advantage.title }}</h4>
                    <p v-if="advantage.text">{{ advantage.text }}</p>
                </div>
            </div>
        </div>

        <template v-if="content?.length">
            <div
                v-for="item in content"
                :key="item.id"
                class="management-info__content">
                <div
                    v-if="item.text"
                    class="management-info__content-text"
                    v-html="item.text"></div>

                <template v-if="item.components?.length">
                    <UniversalBlockComponent
                        v-for="component in item.components"
                        :key="component.id"
                        :type="component.type"
                        :content="component.content"
                        modify="management-info__content-button"
                        />
                </template>

                <LinkComponent
                    v-if="content?.link?.href && content?.link?.text"
                    :href="content.link?.href"
                    :text="content?.link?.text"
                    type="button"
                    modify="management-info__content-button" />
            </div>
        </template>
    </div>
</template>

<script>
import LinkComponent from '@/components/LinkComponent/LinkComponent';
import ManagementDataController from '@/dataControllers/management';
import UniversalBlockComponent from '@/components/UniversalBlockComponent/UniversalBlockComponent.vue';

const ManagementController = new ManagementDataController();

export default {
    name: 'ManagementInfoPage',

    components: {
        LinkComponent,
        UniversalBlockComponent
    },

    data() {
        return {
            content     : null,
            banner      : null,
            isProcessing: true
        };
    },

    mounted() {
        this.isProcessing = true;
        this.$emit('processing', this.isProcessing);

        ManagementController.getInfo()
            .then((response) => {
                if (response.success) {
                    this.content = response.text;
                    this.banner = response.banner?.[0];
                } else {
                    this.$emit('errorShow', true, {
                        statusCode: response.errorData?.status,
                        url       : response.errorData?.url
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                this.$emit('errorShow', true, {
                    statusCode: 500,
                    url       : 'Неизвестная ошибка на странице Информация об УК'
                });
            })
            .then(() => {
                // В axios работает как finally при использовании fetch
                this.isProcessing = false;
                this.$emit('processing', this.isProcessing);
            });
    },

    beforeRouteLeave(to, from, next) {
        this.$emit('errorShow', false);

        next();
    }
};
</script>

<style lang="scss">
@import './ManagementInfoPage';
</style>
