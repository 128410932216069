<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11Z" fill="#F0F3F7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9999 7.08331C11.5522 7.08331 11.9999 7.53103 11.9999 8.08331V10H13.9166C14.4689 10 14.9166 10.4477 14.9166 11C14.9166 11.5523 14.4689 12 13.9166 12H11.9999V13.9166C11.9999 14.4689 11.5522 14.9166 10.9999 14.9166C10.4476 14.9166 9.9999 14.4689 9.9999 13.9166V12H8.08325C7.53097 12 7.08325 11.5523 7.08325 11C7.08325 10.4477 7.53097 10 8.08325 10H9.9999V8.08331C9.9999 7.53103 10.4476 7.08331 10.9999 7.08331Z" fill="#8B96A3"/>
        <rect width="22" height="22" rx="11" fill="#F0F3F7"/>
        <path d="M11 8.08333V13.9167M8.08334 11H13.9167" stroke="#8B96A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'PlusIcon'
};
</script>
