<template>
    <a
        v-if="href"
        :href="href"
        :class="[type, modify]"
        :[download]="download"
        target="_blank">
        <span v-if="leftIcon" class="link__icon link__icon_theme_left">
            <component :is="leftIcon" :fill="fill" />
        </span>
        <span v-if="icon" class="link__icon">
            <component :is="icon" :fill="fill" />
        </span>
        <span v-if="text" class="link__text">
            {{ text }}
        </span>
        <span v-if="rightIcon" class="link__icon link__icon_theme_right">
            <component :is="rightIcon" :fill="fill" />
        </span>
    </a>
</template>

<script>
import ArrowIcon from '@/icons/ArrowIcon';
import CompareIcon from '@/icons/CompareIcon';
import EmailIcon from '@/icons/EmailIcon';
import ExternalIcon from '@/icons/ExternalIcon';
import FavoritesIcon from '@/icons/FavoritesIcon';
import PhoneIcon from '@/icons/PhoneIcon';

export default {
    name: 'LinkComponent',

    components: {
        ArrowIcon,
        CompareIcon,
        EmailIcon,
        ExternalIcon,
        FavoritesIcon,
        PhoneIcon
    },

    props: {
        type: {
            default: 'link',
            type   : String
        },
        href: {
            default: null,
            type   : String
        },
        modify: {
            default: null,
            type   : String
        },
        text: {
            default: null,
            type   : String
        },
        icon: {
            default: null,
            type   : String
        },
        leftIcon: {
            default: null,
            type   : String
        },
        rightIcon: {
            default: null,
            type   : String
        },
        fill: {
            default: null,
            type   : String
        },
        download: {
            default: null,
            type   : String
        }
    }
};
</script>

<style lang="scss">
@import './LinkComponent';
</style>
