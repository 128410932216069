<template>
    <div class="actions-card" @click="_actionClickHandler">
        <div class="actions-card__images">
            <img :src="`/webicons/actions/actions-0${computedNum}.svg`" :alt="content.title" />
        </div>
        <div class="actions-card__deadline">{{ content.deadline }}</div>
        <h5 class="actions-card__title">{{ content.title }}</h5>
        <p class="actions-card__text">Подробнее</p>
    </div>
</template>

<script>
export default {
    name: 'ActionsCardComponent',

    props: {
        content: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    computed: {
        computedNum() {
            return Math.floor(Math.random() * 2) + 1;
        }
    },

    methods: {
        _actionClickHandler() {
            this.$emit('showAction', this.content.id);
        }
    }
};
</script>

<style lang="scss">
@import './ActionsCardComponent';
</style>
