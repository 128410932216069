<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 2C5.80228 2 6.25 2.44772 6.25 3V12.5858L7.54289 11.2929C7.93342 10.9024 8.56658 10.9024 8.95711 11.2929C9.34763 11.6834 9.34763 12.3166 8.95711 12.7071L5.95711 15.7071C5.56658 16.0976 4.93342 16.0976 4.54289 15.7071L1.54289 12.7071C1.15237 12.3166 1.15237 11.6834 1.54289 11.2929C1.93342 10.9024 2.56658 10.9024 2.95711 11.2929L4.25 12.5858V3C4.25 2.44772 4.69772 2 5.25 2ZM12.0429 2.29289C12.4334 1.90237 13.0666 1.90237 13.4571 2.29289L16.4571 5.29289C16.8476 5.68342 16.8476 6.31658 16.4571 6.70711C16.0666 7.09763 15.4334 7.09763 15.0429 6.70711L13.75 5.41421V15C13.75 15.5523 13.3023 16 12.75 16C12.1977 16 11.75 15.5523 11.75 15V5.41421L10.4571 6.70711C10.0666 7.09763 9.43342 7.09763 9.04289 6.70711C8.65237 6.31658 8.65237 5.68342 9.04289 5.29289L12.0429 2.29289Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'SortIcon',

    props: {
        fill: {
            default: '#455569',
            type   : String
        }
    }
};
</script>
