<template>
    <div v-if="content" class="management-contacts">
        <div class="management-contacts__buttons">
            <ButtonComponent
                modify="management-contacts__buttons-button j-anchor"
                type="button"
                text="Онлайн-приемная"
                @click="_onlineHandler" />

            <ButtonComponent
                modify="button_theme_gray management-contacts__buttons-button"
                text="Стать партнером"
                @click="_popupHandler" />
        </div>

        <ManagementContactsContact
            :content="content" />
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import ManagementContactsContact from '@/sections/Management/ManagementContactsContact/ManagementContactsContact';
import ManagementDataController from '@/dataControllers/management';

const ManagementController = new ManagementDataController();

export default {
    name: 'ManagementContactsPage',

    components: {
        ButtonComponent,
        ManagementContactsContact
    },

    data() {
        return {
            content     : null,
            popupShow   : false,
            isProcessing: true
        };
    },

    mounted() {
        this.isProcessing = true;
        this.$emit('processing', this.isProcessing);

        ManagementController.getContacts()
            .then((response) => {
                if (response.success) {
                    this.content = response;
                } else {
                    this.$emit('errorShow', true, {
                        statusCode: response.errorData?.status,
                        url       : response.errorData?.url
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                this.$emit('errorShow', true, {
                    statusCode: 500,
                    url       : 'Неизвестная ошибка на странице Контакты в разделе УК'
                });
            })
            .then(() => {
                // В axios работает как finally при использовании fetch
                this.isProcessing = false;
                this.$emit('processing', this.isProcessing);
            });
    },

    beforeRouteLeave(to, from, next) {
        this.$emit('errorShow', false);

        next();
    },

    methods: {
        _popupHandler() {
            this.$root.$emit('showPopup', 'partner');
        },

        _onlineHandler() {
            this.$root.$emit('onlineClicked');
        }
    }
};
</script>

<style lang="scss">
@import './ManagementContactsPage';
</style>
