<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.6665 4.66663C3.6665 4.11435 4.11422 3.66663 4.6665 3.66663H11.3332C11.8855 3.66663 12.3332 4.11435 12.3332 4.66663V11.3333C12.3332 11.8856 11.8855 12.3333 11.3332 12.3333C10.7809 12.3333 10.3332 11.8856 10.3332 11.3333V7.08084L5.37361 12.0404C4.98308 12.4309 4.34992 12.4309 3.9594 12.0404C3.56887 11.6499 3.56887 11.0167 3.9594 10.6262L8.91898 5.66663H4.6665C4.11422 5.66663 3.6665 5.21891 3.6665 4.66663Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'ExternalIcon',

    props: {
        fill: {
            default: '#ffffff',
            type   : String
        }
    }
};
</script>
