import ManagementContactsPage from '../../pages/Management/ManagementContactsPage/ManagementContactsPage.vue';
import ManagementInfoPage from '../../pages/Management/ManagementInfoPage/ManagementInfoPage.vue';
import ManagementNewsPage from '../../pages/Management/ManagementNewsPage/ManagementNewsPage.vue';
import ManagementPaymentsPage from '../../pages/Management/ManagementPaymentsPage/ManagementPaymentsPage.vue';
import ManagementServicesPage from '../../pages/Management/ManagementServicesPage/ManagementServicesPage.vue';

const managementChildrens = [{
    path     : 'info',
    name     : 'info',
    component: ManagementInfoPage,
    meta     : {
        name: 'Информация'
    }
}, {
    path     : 'services',
    name     : 'services',
    component: ManagementServicesPage,
    meta     : {
        name: 'Заказать услуги'
    },
    props: {
        type: 'main'
    }
}, {
    path     : 'partner-services',
    name     : 'partner-services',
    component: ManagementServicesPage,
    meta     : {
        name: 'Услуги партнеров'
    },
    props: {
        type: 'partners'
    }
}, {
    path     : 'payments',
    name     : 'payments',
    component: ManagementPaymentsPage,
    meta     : {
        name: 'Способы оплаты'
    }
}, {
    path     : 'news',
    name     : 'news',
    component: ManagementNewsPage,
    meta     : {
        name: 'Новости'
    }
}, {
    path     : 'contacts',
    name     : 'contacts',
    component: ManagementContactsPage,
    meta     : {
        name: 'Контакты'
    }
}];

export default managementChildrens;
