<template>
    <div :class="['order-conditionals', modify]">
            <h5 class="order-conditionals__title">{{ title }}</h5>
            <div class="order-conditionals__content order-universal__block">
                <div
                    v-if="content.bookingTime"
                    class="order-conditionals__item order-universal__prop-item order-conditionals__booking">
                    <div class="order-conditionals__name order-universal__prop-name">Срок бронирования</div>
                    <div
                        class="order-conditionals__value order-universal__prop-value"
                        v-html="content.bookingTime"></div>
                </div>
                <div
                    v-if="content.developer"
                    class="order-conditionals__item order-universal__prop-item order-conditionals__developer">
                    <div class="order-conditionals__name order-universal__prop-name">Застройщик</div>
                    <div class="order-conditionals__value order-universal__prop-value">
                        {{ content.developer }}
                    </div>
                </div>
                <div
                    v-if="content.price"
                    class="order-conditionals__item order-universal__prop-item order-conditionals__price">
                    <div class="order-conditionals__name order-universal__prop-name">Сумма сделки</div>
                    <div class="order-conditionals__value order-universal__prop-value">
                        {{ _convertToDigit(content.price) }} ₽
                    </div>
                </div>
                <div
                    v-if="content.pricePerMeter"
                    class="order-conditionals__item order-universal__prop-item order-conditionals__meter">
                    <div class="order-conditionals__name order-universal__prop-name">Стоимость за м²</div>
                    <div class="order-conditionals__value order-universal__prop-value">
                        {{ _convertToDigit(content.pricePerMeter) }} ₽/м²
                    </div>
                </div>
                <div
                    v-if="content.paymentMethod"
                    class="order-conditionals__item order-universal__prop-item order-conditionals__payment">
                    <div class="order-conditionals__name order-universal__prop-name">Способ оплаты</div>
                    <div class="order-conditionals__value order-universal__prop-value">
                        {{ content.paymentMethod }}
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import {Utils} from '@/scripts/utils';

export default {
    name: 'OrderConditionalsComponent',

    props: {
        content: {
            default: () => {
                return {};
            },
            type: Object
        },
        title: {
            default: 'Условия сделки',
            type   : String
        },
        modify: {
            default: '',
            type   : String
        }
    },

    methods: {
        _convertToDigit(num) {
            return Utils.convertToDigit(num);
        }
    }
};
</script>

<style lang="scss">
@import './OrderConditionalsComponent';
</style>
