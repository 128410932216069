<template>
    <section class="owner-keys__notify">
        <div class="owner-keys__notify-icon">
            <InfoIcon fill="#62725b" />
        </div>
        <div class="owner-keys__notify-text">
            <h5>Уважаемый/ая {{ user?.name }},</h5>
            <p>ознакомиться с&nbsp;подробной информацией по&nbsp;приемке объектов недвижимости
                можно <a href="/owner/about">здесь</a></p>
        </div>
    </section>
</template>

<script>
import InfoIcon from '@/icons/InfoIcon';
import {mapGetters} from 'vuex';

export default {
    name: 'OwnerKeysNotifySection',

    components: {
        InfoIcon
    },

    computed: {
        ...mapGetters({
            user: 'GET_USER'
        })
    }
};
</script>

<style lang="scss">
@import './OwnerKeysNotifySection';
</style>
