<template>
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 6V4.5C8.5 3.11929 7.38071 2 6 2C4.61929 2 3.5 3.11929 3.5 4.5V6M4.4 11H7.6C8.44008 11 8.86012 11 9.18099 10.8365C9.46323 10.6927 9.6927 10.4632 9.83651 10.181C10 9.86012 10 9.44008 10 8.6V8.4C10 7.55992 10 7.13988 9.83651 6.81901C9.6927 6.53677 9.46323 6.3073 9.18099 6.16349C8.86012 6 8.44008 6 7.6 6H4.4C3.55992 6 3.13988 6 2.81901 6.16349C2.53677 6.3073 2.3073 6.53677 2.16349 6.81901C2 7.13988 2 7.55992 2 8.4V8.6C2 9.44008 2 9.86012 2.16349 10.181C2.3073 10.4632 2.53677 10.6927 2.81901 10.8365C3.13988 11 3.55992 11 4.4 11Z" :stroke="fill" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'LockIcon',

    props: {
        fill: {
            default: '#62725b',
            type   : String
        }
    }
};
</script>
