<template>
    <RouterView
        :key="$route.meta.type"
        :globalError="globalError" />
</template>

<script>
import {EBreakpoint, RESIZE_EVENTS} from './scripts/constants';
import {mapActions, mapGetters} from 'vuex';
import {Utils} from './scripts/utils';

export default {
    name: 'App',

    computed: {
        ...mapGetters({
            globalError: 'GET_GLOBAL_ERROR'
        })
    },

    data() {
        return {
            windowWidth: Utils.getWindowWidth()
        };
    },

    watch: {
        windowWidth() {
            this.setIsMobile(this.windowWidth < EBreakpoint.TABLET_PORTRAIT);
            this.setIsTablet(this.windowWidth < EBreakpoint.DESKTOP);
            // -0.1 для того чтобы срабатывало сразу от 1280
            this.setIsDesktop(this.windowWidth > (EBreakpoint.DESKTOP - 0.1));
        }
    },

    created() {
        this.getSettings();
        this.getProjectsList();
        this.getProjectsMain();

        this.setIsMobile(this.windowWidth < EBreakpoint.TABLET_PORTRAIT);
        this.setIsTablet(this.windowWidth < EBreakpoint.DESKTOP);
        // -0.1 для того чтобы срабатывало сразу от 1280
        this.setIsDesktop(this.windowWidth > (EBreakpoint.DESKTOP - 0.1));
    },

    mounted() {
        this._bindEvents();
    },

    methods: {
        // eslint-disable-next-line
        ...mapActions(['getProjectsList', 'getProjectsMain', 'getSettings', 'getSidebar', 'getFavorites', 'setIsMobile', 'setIsTablet', 'setIsDesktop']),

        _bindEvents() {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

            RESIZE_EVENTS.forEach((event) => {
                window.addEventListener(event, this._onResize.bind(this));
            });
        },

        _unbindEvents() {
            RESIZE_EVENTS.forEach((event) => {
                window.removeEventListener(event, this._onResize, false);
            });
        },

        _onResize() {
            this.windowWidth = Utils.getWindowWidth();

            document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        }
    }
};
</script>

<style lang="scss">
@import './styles/base';
</style>
