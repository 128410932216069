import type {
    IFavoritesData,
    IFavoritesDataStore,
    IFavoritesResult
} from './types';

let favoritesDataInstance: IFavoritesData | null = null;

export class FavoritesData implements IFavoritesData {
    public favoritesDataStore: IFavoritesDataStore;

    constructor() {
        if (!favoritesDataInstance) {
            // eslint-disable-next-line
            favoritesDataInstance = this;
        }

        this.favoritesDataStore = {
            premises : [],
            more     : [],
            sortList : [],
            noResults: false,
            projects : []
        };

        return favoritesDataInstance;
    }

    /**
     * Записываем в стор значение выполнения запроса на получение избранного
     * @param {object} response - объект с данными результата запроса
     * @param {string} type - тип запроса
     */
    public setRequestResponse(response: IFavoritesResult, type: string): void {
        // Если тип запроса - показать еще, то добавляем к текущему массиву
        // новый, если все другие - перезаписываем массив
        this.favoritesDataStore.premises = type === 'more' ?
            this.favoritesDataStore.premises.concat(response.premises) :
            response.premises;

        this.favoritesDataStore.more = response.more;
        this.favoritesDataStore.sortList = response.sort.options;
        this.favoritesDataStore.projects = response.projects.options;
        this.favoritesDataStore.noResults = response.noResults;
    }

    /**
     * Возвращает данные стора
     * @returns {object} - данные стора
     */
    public getStoreData(): IFavoritesDataStore {
        return this.favoritesDataStore;
    }
}
