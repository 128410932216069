<template>
    <div class="popup popup-inspect">
        <div
            class="popup__overlay"
            @click="_closeClick"
            ></div>
        <div class="popup__content popup-inspect__content">
            <transition name="fade">
                <PreloaderComponent
                    v-if="isFormProgress"
                    modify="preloader_theme_opacity" />
            </transition>
            <div class="popup__content-top">
                <button
                    class="button button_theme_thin button_theme_transparent popup__content-close"
                    @click="_closeClick">
                    <span class="button__icon">
                        <CloseIcon />
                    </span>
                </button>
            </div>
            <div
                :class="[
                    'popup__content-body',
                    'popup-inspect__content-body',
                    {'is-fixed': formResult && Object.keys(formResult)?.length}
                ]">
                <h3 class="popup-inspect__content-title">Запись на&nbsp;осмотр объекта</h3>

                <div class="popup-inspect__steps">
                    <span>Шаг {{ nowStep }} из 3</span>
                </div>
                <p class="popup-inspect__step-title">{{ stepTitle[nowStep - 1] }}</p>

                <form action="" class="popup-inspect__form">
                    <div :key="1" v-if="nowStep === 1" class="popup-inspect__step">
                        <SelectComponent
                            modify="popup-inspect__step-contract"
                            name="contractNumber"
                            :list="contracts"
                            label="Выберите объект из списка"
                            @clickRadio="_clickRadioHandler" />
                    </div>
                    <div :key="2" v-if="nowStep === 2" class="popup-inspect__step">
                        <CheckboxComponent
                            name="viewType"
                            type="radio"
                            :icon=true
                            :text="TYPE_PERSONAL"
                            :value="TYPE_PERSONAL"
                            :checked="getChecked('viewType', TYPE_PERSONAL)"
                            :data-store-instance="UserFormStore"
                            @checkboxChanged="_setChecked(TYPE_PERSONAL)"
                        />

                        <CheckboxComponent
                            name="viewType"
                            type="radio"
                            :text="TYPE_WITHOUT"
                            :value="TYPE_WITHOUT"
                            :checked="getChecked('viewType', TYPE_WITHOUT)"
                            :data-store-instance="UserFormStore"
                            @checkboxChanged="_setChecked(TYPE_WITHOUT)"
                        />
                    </div>
                    <div :key="3" v-show="nowStep === 3" class="popup-inspect__step popup-inspect__step-third">
                        <DatePicker
                            ref="calendar"
                            v-model="myDate"
                            :available-dates="date"
                            @dayclick="_onDayClick"
                            @popoverDidShow="_movePopover"
                            class="popup-inspect__date" >
                            <template v-slot="{ inputValue, togglePopover }">
                                <button type="button"
                                    :class="[
                                        'button',
                                        'button_theme_gray',
                                        'popup-inspect__date-selector',
                                        {'is-selected': dateSelected}
                                    ]"
                                    @click="_changeDate($event, togglePopover)">
                                    <span class="button__text">Дата</span>
                                    <span v-if="dateSelected" class="button__date">{{ inputValue }}</span>
                                    <div class="button__icon">
                                        <img src="/webicons/owner/calendar.svg" alt="Выберите дату" />
                                    </div>
                                </button>
                            </template>
                        </DatePicker>

                        <div ref="timeList" class="popup-inspect__time">
                            <button type="button"
                                :class="[
                                    'button',
                                    'button_theme_gray',
                                    'popup-inspect__time-selector',
                                    {'is-selected': timeSelected},
                                    {'is-open': showTimeList}]"
                                :disabled="!dateSelected"
                                @click="showTimeList = !showTimeList">
                                <span class="button__text">Время</span>
                                <span v-if="timeSelected" class="button__time">{{ timeValue }}</span>
                                <div class="button__icon">
                                        <img src="/webicons/owner/clock.svg" alt="Выберите время" />
                                    </div>
                            </button>
                            <div
                                v-if="availableTimes?.length && showTimeList"
                                class="popup-inspect__time-list">
                                <button
                                    v-for="time in availableTimes"
                                    :key="time"
                                    :class="[
                                        'button',
                                        'button_theme_white',
                                        'button_theme_thin',
                                        'popup-inspect__time-item',
                                        {'is-selected': time === timeValue}]"
                                    :text="time"
                                    @click="_setClickedTime(time)" >
                                    <span class="button__text">{{ time }}</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="popup-inspect__form-buttons">
                        <ButtonComponent text="Назад" :disabled="nowStep === 1" @click="_clickHandler('prev')" />
                        <ButtonComponent
                            v-if="nowStep < 3"
                            :disabled="isDisabled || isFormProgress"
                            text="Далее"
                            @click="_clickHandler('next')" />
                        <ButtonComponent
                            v-else
                            text="Записаться"
                            :disabled="!dateSelected || !timeSelected"
                            @click="_orderComplete()" />
                    </div>
                </form>

                <FormResultComponent
                    v-if="formResult && Object.keys(formResult)?.length"
                    :success="formResult.success"
                    :title="formResult.title"
                    :text="formResult.text"
                    :requestNote="formResult.requestNote"
                    @closeResult="formResult = {}"
                    @closePopup="_closeClick" />
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import CheckboxComponent from '@/components/CheckboxComponent/CheckboxComponent';
import CloseIcon from '@/icons/CloseIcon.vue';
import {DatePicker} from 'v-calendar';
import FormDataController from '@/dataControllers/form/index';
import FormResultComponent from '@/components/FormResultComponent/FormResultComponent';
import PreloaderComponent from '@/components/PreloaderComponent/PreloaderComponent';
import SelectComponent from '@/components/SelectComponent/SelectComponent';

const FormController = new FormDataController();

export default {
    name: 'PopupMultiStepsComponent',

    components: {
        ButtonComponent,
        CheckboxComponent,
        CloseIcon,
        DatePicker,
        FormResultComponent,
        PreloaderComponent,
        SelectComponent
    },

    props: {
        title: {
            default: 'Запись на осмотр объекта',
            type   : String
        },
        contracts: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    data() {
        return {
            myDate          : [],
            date            : [],
            firstDate       : null,
            nowStep         : 1,
            stepTitle       : ['Введите номер договора', 'Выберите способ осмотра', 'Выберите дату и время'],
            dateSelected    : false,
            timeSelected    : false,
            showTimeList    : false,
            timeValue       : null,
            isDisabled      : true,
            availableDates  : null,
            availableTimes  : [],
            TYPE_PERSONAL   : 'Очный',
            TYPE_DISTANCE   : 'Дистанционный',
            TYPE_WITHOUT    : 'Без осмотра',
            selectedViewType: 'Очный',
            formResult      : null,
            isFormProgress  : false,
            currentTimesList: {}
        };
    },

    computed: {
        UserFormStore() {
            return FormController.formDataStore;
        },

        getValue() {
            return (field) => {
                return FormController.formDataStore?.dataStore?.items?.[field]?.value;
            };
        },

        getChecked() {
            return (name, value) => {
                return FormController.formDataStore?.dataStore?.items?.[name]?.value === value;
            };
        }
    },

    watch: {
        showTimeList() {
            this.$nextTick(() => {
                this.showTimeList ?
                    document.addEventListener('click', this._onClickOutsideTimeList) :
                    document.removeEventListener('click', this._onClickOutsideTimeList);
            });
        }
    },

    mounted() {
        if (FormController?.formDataStore?.dataStore) {
            FormController.formDataStore.dataStore.items['viewType'] = {value: this.TYPE_PERSONAL};
        }
    },

    destroyed() {
        FormController.clearDataStore();
    },

    methods: {
        /**
         * Обработка клика по кнопке закрытия попапа
         */
        _closeClick() {
            this.$emit('closePopup');
        },

        /**
         * Обработка клика по кнопке Далее или назад
         * @param {string} direction - направление: Далее или Назад
         */
        _clickHandler(direction) {
            direction === 'prev' ? this.nowStep-- : this._nextStep();
        },

        /**
         * Обработка переключения типа осмотра помещения
         * @param {string} value - выбранное значения
         */
        _setChecked(value) {
            this.selectedViewType = value;
        },

        _clickRadioHandler(contract) {
            if (!contract) {
                return;
            }

            this.UserFormStore.setData('contractNumber', {value: contract.text});

            this.isDisabled = false;
        },

        /**
         * Обработка перехода вперед, включает в себя запрос на свободные даты и
         * времена
         */
        _nextStep() {
            if (this.nowStep === 1) {
                this.nowStep++;
            } else {
                this.isFormProgress = true;

                FormController.getKeysSlot({
                    contractNumber: this.getValue('contractNumber') || 0,
                    viewType      : this.selectedViewType
                })
                    .then((response) => {
                        if (response.success) {
                            this.availableDates = response.availableDates;

                            response?.availableDates.forEach((date, index) => {
                                const preparedDate = date.date.split('.').reverse();

                                this.date.push(new Date(Number(preparedDate[0]),
                                    Number(preparedDate[1]) - 1, Number(preparedDate[2])));

                                // Если первая дата - то записываем ее в переменную
                                // firstDate для того чтобы сдвинуть календарь на
                                // нужный вид
                                if (index === 0) {
                                    this.firstDate = new Date(Number(preparedDate[0]),
                                        Number(preparedDate[1]) - 1, Number(preparedDate[2]));
                                }
                            });

                            this.nowStep++;
                        } else {
                            this.formResult = response;
                        }
                    })
                    .then(() => {
                        this.isFormProgress = false;
                    });
            }
        },

        /**
         * Обработка клика по кнопке выбора даты
         * @param {object} day - объект с данными выбранного дня
         */
        _onDayClick(day) {
            this.dateSelected = true;

            const preparedDate = day.id.split('-').reverse()
                .join('.');

            const dateTimeData = this.availableDates.find((item) => {
                return item.date === preparedDate;
            });

            this._preparedTimes(dateTimeData?.time);

            this.UserFormStore.setData('inspectDate', {value: preparedDate});

            this.timeValue = null;
            this.timeSelected = false;
        },

        _preparedTimes(timesList) {
            this.availableTimes = [];

            if (!timesList || !Object.keys(timesList)?.length) {
                return;
            }

            this.currentTimesList = timesList;

            Object.keys(timesList).forEach((time) => {
                this.availableTimes.push(time);
            });
        },

        /**
         * Вызывает раскрытие календаря
         * @param {Event} event - событие
         * @param {Function} togglePopover - коллбек вызова открытия календаря
         */
        _changeDate(event, togglePopover) {
            togglePopover({ref: event.target});
        },

        /**
         * Если выбранные даты за пределами текущего месяца - перелистываем
         * календарь
         */
        async _movePopover() {
            const calendar = this.$refs.calendar;

            await calendar.move(this.firstDate);
        },

        /**
         * Сохраняем выбранное время
         * @param {string} time - выбранное время
         */
        _setClickedTime(time) {
            this.timeSelected = true;
            this.timeValue = time;
            this.showTimeList = false;

            this.UserFormStore.setData('inspectTime', {value: time});
            this.UserFormStore.setData('manager', {value: this.currentTimesList[time] || null});
        },

        /**
         * Отправляем форму
         */
        _orderComplete() {
            this.isFormProgress = true;

            FormController.sendViewsRequest()
                .then((response) => {
                    this.formResult = response;

                    FormController.clearDataStore();

                    this.isFormProgress = false;
                });
        },

        _onClickOutsideTimeList(event) {
            if (!this.$refs?.timeList?.contains(event.target)) {
                this.showTimeList = false;
            }
        }
    }
};
</script>

<style lang="scss">
@import './PopupMultiStepsComponent';
</style>
