import type {AxiosError, AxiosResponse} from 'axios';
import type {
    IActionContentResponse,
    IActionContentResult,
    IActionsDataController,
    IActionsRequestOptions,
    IActionsResponse,
    IActionsResult
} from './types';
import ActionsData from './actionsData';
import axios from 'axios';
import {ERequests} from '../../requests';
import {Utils} from '../../scripts/utils';

const ActionsDataStore = new ActionsData();

export class ActionsDataController implements IActionsDataController {
    /**
     * Получаем акции
     * @param {object} options - опции запроса
     * @returns {object} - результат запроса
     */
    public async getActions(options: IActionsRequestOptions): Promise<IActionsResult> {
        const data = {
            type : options.type,
            page : options.page,
            limit: 12
        };

        const result: IActionsResult = {
            success  : true,
            list     : [],
            noResults: false,
            more     : null
        };

        await axios.post(ERequests.actions, data)
            .then((response: AxiosResponse<IActionsResponse>) => {
                // Записываем данные в хранилище
                ActionsDataStore.setRequestResponse(response.data, data.type);

                // Получаем данные из хранилища
                // Такая схема работы используется для конкатенации подгружаемых акций
                const storeData = ActionsDataStore.getStoreData();

                result.list = storeData.list;
                result.noResults = storeData.noResults;
                result.more = storeData.more;
            })
            .catch((error: AxiosError) => {
                console.error(error);
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }

    public async getActionContent(id: string | number): Promise<IActionContentResult> {
        const result = {
            date        : '',
            title       : '',
            text        : '',
            showProjects: true
        };

        await axios.post(ERequests.actionItem)
            .then((response: AxiosResponse<IActionContentResponse>) => {
                result.date = response.data.data.date;
                result.title = response.data.data.title;
                result.text = response.data.data.text;
                result.showProjects = response.data.data.showProjects;
            });

        return result;
    }
}
