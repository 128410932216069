import type {
    IActionsData,
    IActionsResponse,
    IDataStore
} from './types';

let actionsDataInstance: IActionsData | null = null;

export default class ActionsData implements IActionsData {
    public dataStore: IDataStore;

    constructor() {
        if (!actionsDataInstance) {
            // eslint-disable-next-line
            actionsDataInstance = this;
        }

        this.dataStore = {
            list     : [],
            more     : {},
            noResults: false,
            action   : {}
        };

        return actionsDataInstance;
    }

    /**
     * Записываем в стор значение выполнения запроса на получение избранного
     * @param {object} response - объект с данными результата запроса
     * @param {string} type - тип запроса
     */
    public setRequestResponse(response: IActionsResponse, type: string): void {
        // Если тип запроса - показать еще, то добавляем к текущему массиву
        // новый, если все другие - перезаписываем массив
        this.dataStore.list = type === 'more' ?
            this.dataStore.list.concat(response.data.list) :
            response.data.list;

        this.dataStore.more = response.data.more;
        this.dataStore.noResults = response.data.noResults;
    }

    /**
     * Возвращает данные стора
     * @returns {object} - данные стора
     */
    public getStoreData(): IDataStore {
        return this.dataStore;
    }
}
