import agencyChildren from './childrens/agency';
import Auth from '../pages/AuthPage/AuthPage.vue';
import buyerChildren from './childrens/buyer';
import ContentPage from '../pages/ContentPage/ContentPage.vue';
import EmptyPage from '../pages/EmptyPage/EmptyPage.vue';
import managementChildrens from './childrens/management';
import OrderPage from '../pages/OrderPage/OrderPage.vue';
import ownerChildren from './childrens/owners';
import PrivacyPage from '../pages/PrivacyPage/PrivacyPage.vue';
import ProfilePage from '../pages/ProfilePage/ProfilePage.vue';
import Router from 'vue-router';

import Vue from 'vue';

Vue.use(Router);

export const defaultSection = 'buyer';

export default new Router({
    mode  : 'history',
    base  : '/',
    routes: [{
        path     : '/auth',
        name     : 'auth',
        component: Auth
    }, {
        path     : '/content',
        name     : 'content',
        component: ContentPage,
        props    : {
            section: defaultSection
        },
        redirect: {
            name: 'favorites'
        },
        meta: {
            requiresAuth: true,
            haveSidebar : true
        },
        children: buyerChildren
    }, {
        path     : '/owner',
        name     : 'owner-content',
        component: ContentPage,
        props    : {
            section: 'owner'
        },
        redirect: {
            name: 'about'
        },
        meta: {
            requiresAuth: true,
            haveSidebar : true
        },
        children: ownerChildren
    }, {
        path     : '/agency',
        name     : 'agency-content',
        component: ContentPage,
        props    : {
            section: 'agency'
        },
        redirect: {
            name: 'trade-in'
        },
        meta: {
            requiresAuth: true,
            haveSidebar : true
        },
        children: agencyChildren
    }, {
        path     : '/management',
        name     : 'Управляющая компания',
        component: ContentPage,
        props    : {
            section: 'management'
        },
        redirect: {
            name: 'info'
        },
        meta: {
            requiresAuth: true,
            haveSidebar : true
        },
        children: managementChildrens
    }, {
        path     : '/order/:id',
        name     : 'order',
        component: OrderPage
    }, {
        path     : '/profile',
        name     : 'profile',
        component: ProfilePage,
        meta     : {
            requiresAuth: true
        },
        props: {
            section: 'profile'
        }
    }, {
        path     : '/privacy',
        name     : 'privacy',
        component: PrivacyPage,
        meta     : {
            requiresAuth: true
        },
        props: {
            section: 'privacy'
        }
    }, {
        path     : '*',
        component: EmptyPage
    }]
});
