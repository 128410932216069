import type {IAuthData, IDataStore, IItemObject} from './types';
import {Utils} from '../../scripts/utils';

let authDataInstance: IAuthData | null = null;

export class AuthData implements IAuthData {
    public dataStore: IDataStore;

    constructor() {
        const that = this;

        if (!authDataInstance) {
            authDataInstance = that;
        }

        this.dataStore = {
            item    : {},
            phoneNum: null,
            timer   : 10
        };

        this.initTimer();

        return authDataInstance;
    }

    public initTimer(time: number = 200): void {
        this.setTimerData(time);
    }

    public setData(itemName: string, itemState: IItemObject): object {
        if (!this.dataStore[itemName]) {
            this.dataStore[itemName] = {
                validation: {
                    type  : null,
                    length: null,
                    msg   : null
                },
                value: null,
                error: false
            };
        }

        this.dataStore[itemName] = itemState;

        this.validation(itemName);

        return this;
    }

    public getData(itemName: string): boolean | IItemObject {
        if (!this.dataStore[itemName]) {
            return false;
        }

        return this.dataStore[itemName];
    }

    public validation(itemName: string): boolean {
        const item = this.dataStore[itemName];

        if (!item?.validation?.type) {
            return;
        }

        if (item.validation.type === 'numberLength') {
            item.value = item?.value?.replace(/[^0-9]/g, '');
        }

        item.error = Utils.validation(item?.validation?.type, item?.value, item?.validation?.length);
    }

    public setPhone(value: string): void {
        this.dataStore.phoneNum = value;
    }

    public setTimerData(time: number = 200): void {
        this.dataStore.timer = time;
    }

    public getTimerData(): number {
        return this.dataStore.timer;
    }
}
