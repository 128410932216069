<template>
    <div :class="['order-object', modify]">
        <h5 class="order-object__title">{{ title }}</h5>

        <div class="order-object__content order-universal__block">
            <div v-if="objectData.image?.length" class="order-object__image">
                <img :src="objectData.image" :alt="objectData.name">
            </div>

            <div class="order-object__content-descr">
                <p v-if="objectData.project" class="order-object__project">{{objectData.project}}</p>
                <h3 v-if="objectData.name" class="order-object__name">{{objectData.name}}</h3>

                <div
                    v-if="objectData.specs && Object.keys(objectData.specs).length"
                    class="order-object__specs"
                    >
                    <div
                        v-for="spec in objectData.specs"
                        :key="spec.id"
                        :class="[
                            'order-object__spec',
                            'order-universal__prop-item',
                            `order-object__spec_theme_${spec.slug}`
                            ]"
                        >
                        <div class="order-universal__prop-name order-object__spec-name">{{ spec.name }}</div>
                        <div class="order-universal__prop-value order-object__spec-value">{{ spec.text }}</div>
                    </div>
                </div>

                <div v-if="objectData.tags?.length" class="order-object__tags">
                    <div
                        v-for="tag in objectData.tags"
                        :key="tag"
                        class="order-object__tags-item"
                        >{{ tag }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OrderObjectCard',

    props: {
        objectData: {
            default: () => {
                return {};
            },
            type: Object
        },
        title: {
            default: 'Характеристики помещения',
            type   : String
        },
        modify: {
            default: '',
            type   : String
        }
    }
};
</script>

<style lang="scss">
@import './OrderObjectCard';
</style>
