<template>
    <div class="popup popup-constructor">
        <div
            class="popup__overlay"
            @click="_closeClick"
            ></div>
        <div class="popup__content popup-constructor__content">
            <transition name="fade">
                <PreloaderComponent v-if="isProcessing" />
            </transition>
            <div class="popup__content-top">
                <ButtonComponent
                    modify="button_theme_thin button_theme_transparent popup__content-close"
                    icon="CloseIcon"
                    fill="#8b96a3"
                    @click="_closeClick" />
            </div>
            <div class="popup__content-body popup-constructor__content-body">
                <h3 class="popup-constructor__content-title"
                    v-html="popupData?.title ? popupData.title : 'Обратный звонок'"></h3>
                <div class="popup-constructor__content-descr"
                    v-if="popupData?.description"
                    v-html="popupData?.description"></div>
                <form action="" class="popup-constructor__form">
                    <template v-if="popupData?.fields">
                        <div
                            v-for="field in popupData.fields"
                            :key="field.id"
                            class="popup-constructor__form-item">
                            <InputComponent
                                v-if="field.type !== 'select'"
                                :type="field.type"
                                :name="field.name"
                                :label="field.title"
                                :mask="field.mask"
                                :required="field.required"
                                :value="user?.[field.type] ? user[field.type] : null"
                                :validation="field.validation"
                                :data-store-instance="UserFormStore"
                                :parent-error="parentError"
                                @inputChanged="_inputChanged" />

                            <SelectComponent
                                v-else
                                :list="field.variants"
                                :label="field.title"
                                modify="popup-meeting__content-select"
                                @clickRadio="clickRadioHandler($event, field)" />
                        </div>
                    </template>

                    <ButtonComponent
                        modify="button_theme_big"
                        :text="popupData?.buttonText"
                        :disabled="isDisabled"
                        @click="_sendForm" />
                </form>
                <FormResultComponent
                    v-if="Object.keys(formResult)?.length && formSended"
                    :success="formResult.success"
                    :title="formResult.title"
                    :service="formResult?.service ? formResult.service : null"
                    :text="formResult.text"
                    @closeResult="formResult = {}"
                    @closePopup="_closeClick" />
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import FormDataController from '@/dataControllers/form/index';
import FormResultComponent from '@/components/FormResultComponent/FormResultComponent';
import InputComponent from '@/components/InputComponent/InputComponent';
import {mapGetters} from 'vuex';
import PreloaderComponent from '@/components/PreloaderComponent/PreloaderComponent';
import SelectComponent from '@/components/SelectComponent/SelectComponent';

const FormController = new FormDataController();

export default {
    name: 'PopupConstructorComponent',

    components: {
        ButtonComponent,
        FormResultComponent,
        InputComponent,
        PreloaderComponent,
        SelectComponent
    },

    props: {
        popupData: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    data() {
        return {
            parentError : null,
            isDisabled  : true,
            formResult  : {},
            formSended  : false,
            isProcessing: false
        };
    },

    computed: {
        ...mapGetters({
            user: 'GET_USER'
        }),

        UserFormStore() {
            return FormController.formDataStore;
        }
    },

    mounted() {
        this.popupData?.fields?.forEach((field) => {
            this.UserFormStore.setData(field.name, this._prepareField(field));
        });
    },

    methods: {
        _closeClick() {
            this.$emit('closePopup');

            FormController.clearDataStore();
            this.formSended = false;
        },

        /**
         * Заполняем поля данными
         * @param {object} field - элемент с данными инпута
         * @return {object} - объект с данными поля для записи в хранилище
         */
        _prepareField(field) {
            let value = null;

            if (this.user?.[field.type]) {
                value = this.user?.[field.type];
            } else if (field.type === 'hidden' && field.code) {
                value = this.popupData[field.code];
            }

            return {
                value,
                validation: field.validation ? field.validation : null,
                required  : field.required,
                error     : false
            };
        },

        /**
         * проверяем поля на возможность отправки формы
         */
        _checkFields() {
            const store = this.UserFormStore.dataStore?.items;

            if (store && !Object.keys(store)?.length) {
                return;
            }

            this.isDisabled = false;

            Object.keys(store)?.forEach((key) => {
                if (this.isDisabled) {
                    return;
                }

                // Дизейблим кнопку - если требуемые поля не валидны или пустые
                this.isDisabled = (store[key]['required'] && store[key]['error']) ||
                    (store[key]['required'] && !store[key]['value']?.length);
            });
        },

        _inputChanged() {
            this.parentError = null;

            this._checkFields();
        },

        clickRadioHandler(item, field) {
            if (!item) {
                return;
            }

            const preparedField = this._prepareField(field);

            preparedField.value = item.text;

            this.UserFormStore.setData(field.name, preparedField);

            this._checkFields();
        },

        _sendForm() {
            this.isProcessing = true;

            FormController.sendForm(this.popupData?.id)
                .then((response) => {
                    this.formResult = response;

                    this.formResult.service = this.popupData?.service ?
                        this.popupData?.service :
                        null;

                    this.formSended = true;
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    this.isProcessing = false;
                });
        }
    }
};
</script>

<style lang="scss">
@import './PopupConstructorComponent';
</style>
