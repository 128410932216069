<template>
    <div :class="['favorites-card', {'favorites-card_theme_booked': card.isBooked}]">
        <div class="favorites-card__head">
            <div v-if="card.project" class="favorites-card__head-project">{{ card.project }}</div>
            <div v-if="card.queue" class="favorites-card__head-item">{{ card.queue }} оч.</div>
            <div v-if="card.corpus" class="favorites-card__head-item">{{ card.corpus }} корп.</div>
        </div>

        <div class="favorites-card__content">
            <div class="favorites-card__top">
                <div
                    v-if="card.discount && card.discount.text"
                    class="favorites-card__discount favorites-card__top-tag">
                    <span>{{ card.discount.text }}</span>
                </div>
                <div v-if="offersTag && offersTag.label" class="favorites-card__top-tag">
                    <span
                        @click="_clickTags('offersTagShow')"
                        @mouseenter="_hoverTags('offersTagShow', true)"
                        @mouseleave="_hoverTags('offersTagShow', false)">
                        {{ offersTag.label }}
                    </span>

                    <ul v-if="offersTag.list?.length && offersTagShow" class="favorites-card__top-tags">
                        <li v-for="(tag, index) in offersTag.list" :key="`${tag.text}-${index}`">
                            <span>{{ tag.text }}</span>
                        </li>
                    </ul>
                </div>
                <div class="favorites-card__top-manage">
                    <ButtonComponent
                        modify="button_theme_flat"
                        icon="CompareIcon"
                        :fill="compareState ? '#62725b' : '#8b96a3'"
                        @click="_switchCompare" />

                    <ButtonComponent
                        modify="button_theme_flat favorites-card__top-favorite"
                        icon="FavoritesFilledIcon"
                        fill="#62725b"
                        @click="_favoriteRemove" />
                </div>
            </div>

            <div class="favorites-card__image">
                <img :src="card.image ? card.image : card.plan" :alt="card.room">
                <span v-if="card.isBooked" class="favorites-card__image-lock">
                    <FlatLockIcon fill="#455569" />
                </span>
            </div>

            <h5 v-if="card.price" class="favorites-card__price">{{ card.price }} ₽</h5>

            <div v-if="card.mortgage" class="favorites-card__mortgage">
                В ипотеку {{ card.mortgage }} ₽/мес.
            </div>

            <div class="favorites-card__description">
                <div v-if="card.room" class="favorites-card__description-item">{{ card.room }}</div>
                <div v-if="card.area" class="favorites-card__description-item">{{ card.area }} м²</div>
                <div v-if="card.floor" class="favorites-card__description-item">{{ card.floor }} этаж</div>
                <div v-if="card.num" class="favorites-card__description-item">№{{ card.num }}</div>
                <div
                    v-if="card.deadline"
                    class="favorites-card__description-item favorites-card__description-deadline">
                    {{ card.deadline }}
                </div>
            </div>

            <div v-if="descriptionsTag && descriptionsTag?.display?.length" class="favorites-card__tags">
                <div
                    v-for="tag in descriptionsTag?.display"
                    :key="tag"
                    class="favorites-card__tags-item"
                >{{ tag }}</div>

                <template v-if="descriptionsTag?.list?.length">
                    <div
                        v-if="descriptionsTag?.list?.length"
                        class="favorites-card__tags-item favorites-card__tags-additional"
                        @click="_clickTags('contentTagShow')"
                        @mouseenter="_hoverTags('contentTagShow', true)"
                        @mouseleave="_hoverTags('contentTagShow', false)" >
                        +{{ descriptionsTag?.list?.length }}
                    </div>

                    <ul v-if="contentTagShow" class="favorites-card__tags-list">
                        <li
                            class="favorites-card__tags-item"
                            v-for="tag in descriptionsTag.list"
                            :key="tag">
                            {{ tag }}
                        </li>
                    </ul>
                </template>
            </div>
        </div>

        <div class="favorites-card__buttons">
            <LinkComponent
                type="button"
                modify="button_theme_gray"
                :href="card.href"
                text="Подробнее" />

            <ButtonComponent
                :disabled="card.isBooked"
                :text="bookingText"
                @click="_clickHandler(card.guid)" />
        </div>

    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import FavoritesDataController from '@/dataControllers/favorites/index';
import FlatLockIcon from '@/icons/FlatLockIcon';
import LinkComponent from '@/components/LinkComponent/LinkComponent.vue';
import {RESIZE_EVENTS} from '@/scripts/constants';

const FavoritesController = new FavoritesDataController();

export default {
    name: 'FavoritesCard',

    props: ['card', 'activeTab', 'activeSection'],

    components: {
        ButtonComponent,
        FlatLockIcon,
        LinkComponent
    },

    computed: {
        ...mapGetters({
            isDesktop: 'GET_IS_DESKTOP'
        }),

        bookingText() {
            let result = '';

            if (this.card?.isBooked) {
                result = this.activeTab === 'flats' ? 'Квартира продана' : 'Забронировано';
            } else {
                result = this.activeTab === 'flats' ? 'Забронировать' : 'Оставить заявку';
            }

            return result;
        }
    },

    data() {
        return {
            descriptionsTag: [],
            offersTag      : '',
            offersTagShow  : false,
            contentTagShow : false,
            compareState   : false
        };
    },

    mounted() {
        if (this.card?.offersTags) {
            this.offersTag = FavoritesController.calcOffersTags(this.card.offersTags);
        }

        this._calcDescriptionTags();

        RESIZE_EVENTS.forEach((event) => {
            window.addEventListener(event, this._calcDescriptionTags.bind(this));
        });

        this.compareState = this.card.inCompare;
    },

    methods: {
        ...mapActions(['updateSidebar']),

        _calcDescriptionTags() {
            if (this.card?.tags) {
                this.descriptionsTag = FavoritesController.calcDescriptionsTags(this.card?.tags, this.$el.clientWidth);
            }
        },

        _clickTags(tagElement) {
            if (!tagElement) {
                return;
            }

            this[tagElement] = !this[tagElement];
        },

        _hoverTags(tagElement, state = true) {
            if (!tagElement || !this.isDesktop) {
                return;
            }

            this[tagElement] = state;
        },

        /**
         * Удаление из избранного
         */
        _favoriteRemove() {
            this.$emit('removeFavorite', this.card.id);
        },

        /**
         * Добавление/удаление помещения в сравнение
         */
        _switchCompare() {
            const method = this.compareState ? 'remove' : 'add';

            this.$emit('processing', true);

            FavoritesController.switchCompareState(this.card.id, method)
                .then((response) => {
                    this.compareState = method === 'add' ? response : !response;
                    this.$emit('processing', false);
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    const params = new URLSearchParams([['section', this.activeSection]]);

                    this.updateSidebar(params);
                });
        },

        /**
         * Обработка клика по кнопке бронирования
         * @param {string} guid - guid помещения
         */
        _clickHandler(guid) {
            if (this.activeTab === 'flats') {
                this.$router.push(`/order/${guid}`);
            } else {
                this.$root.$emit('showPopup', 'commerce', guid);
            }
        }
    }
};
</script>

<style lang="scss">
@import './FavoritesCard';
</style>
