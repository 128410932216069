<template>
    <div
        :class="[
            'mortgage-filters',
            {'mortgage-filters_theme_status': filters?.status?.list?.length > 1}
            ]">
        <SelectComponent
            v-if="filters?.projects"
            modify="mortgage-filters__item mortgage-filters__select"
            label="ЖК №1"
            :active="activeProject"
            :title="filters.projects?.label"
            :list="filters.projects?.list"
            @clickRadio="clickRadioHandler" />

        <SelectComponent
            v-if="filters?.status?.list?.length > 1"
            modify="mortgage-filters__item mortgage-filters__select"
            :label="filters.status?.list[0].text"
            :active="activeStatus"
            :title="filters.status?.label"
            :list="filters.status?.list"
            @clickRadio="clickStatusHandler" />

        <div class="mortgage-filters__item mortgage-filters__slider">
            <RangeSlider
                v-if="filters?.priceSlider"
                :slider="filters.priceSlider"
                :sliderType="filters.priceSlider.type"
                @change="sliderHandler" />
        </div>

        <div class="mortgage-filters__item mortgage-filters__slider">
            <RangeSlider
                v-if="filters?.paymentSlider"
                ref="paymentSlider"
                :percent="percent"
                :slider="filters.paymentSlider"
                :sliderType="filters.paymentSlider.type"
                @change="sliderHandler" />
            <div class="mortgage-filters__item-percent">
                {{ percent }}%
            </div>
        </div>

        <div class="mortgage-filters__item mortgage-filters__slider">
            <RangeSlider
                v-if="filters?.termSlider"
                :slider="filters.termSlider"
                :sliderType="filters.termSlider.type"
                @change="sliderHandler" />
        </div>
    </div>
</template>

<script>
import MortgageCalculator from '@/dataControllers/mortgage';
import RangeSlider from '@/components/RangeSlider/RangeSlider';
import SelectComponent from '@/components/SelectComponent/SelectComponent';

const MortgageController = new MortgageCalculator();

export default {
    name: 'MortgageSlidersComponent',

    components: {
        RangeSlider,
        SelectComponent
    },

    data() {
        return {
            paymentChange: false
        };
    },

    props: {
        filters: {
            default: () => {
                return {};
            },
            type: Object
        },
        activeProject: {
            default: null,
            type   : String
        },
        activeStatus: {
            default: null,
            type   : Number
        },
        percent: {
            default: 10,
            type   : Number
        },
        payment: {
            default: 0
        }
    },

    computed: {
        MortgageDataStore() {
            return MortgageController.mortgageDataStore;
        }
    },

    methods: {
        sliderHandler(slider) {
            if (slider.category === 'first-pay') {
                const store = this.MortgageDataStore.dataStore;
                const value =
                    Number(store.price) * (Number(store.percentMax) / 100);

                if (this.percent >= 90 && slider.value > value) {
                    this.$refs.paymentSlider?.$children?.[0]?.setValue(parseInt(value));
                }
            }

            this.$emit('sliderUpdated', slider);
        },

        clickRadioHandler(item) {
            this.$emit('projectUpdated', item);
        },

        clickStatusHandler(item) {
            this.$emit('statusUpdated', item);
        }
    }
};
</script>

<style lang="scss">
@import './MortgageFiltersComponent';
</style>
