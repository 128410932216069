<template>
    <div :class="['owner-keys__result', `owner-keys__result_theme_${resultData.status}`]">
        <div class="owner-keys__result-title">
            <div class="owner-keys__result-icon">
                <component :is="getIcon"></component>
            </div>
            <div class="owner-keys__result-text">
                <h4>{{resultData.title}}</h4>
                <p v-if="resultData.titleDesc">{{ resultData.titleDesc }}</p>
            </div>
        </div>
        <div v-if="resultData.desc" class="owner-keys__result-desc">
            {{ resultData.desc }}
        </div>

        <div class="owner-keys__result-props">
            <div v-if="resultData.number" class="owner-keys__result-prop">
                <span>№ ПИБ</span>
                <span>{{ resultData.number }}</span>
            </div>

            <div v-if="resultData.date" class="owner-keys__result-prop">
                <span>Дата и время</span>
                <span>{{ resultData.date }}</span>
            </div>

            <div v-if="resultData.address" class="owner-keys__result-prop">
                <span>Адрес осмотра</span>
                {{ resultData.address }}
            </div>
        </div>
    </div>
</template>

<script>
import CheckboxCircleIcon from '@/icons/ChecboxCircleIcon';
import DotsIcon from '@/icons/DotsIcon';
import HouseIcon from '@/icons/HouseIcon';

export default {
    name: 'OwnerKeysResultComponent',

    components: {
        CheckboxCircleIcon,
        DotsIcon,
        HouseIcon
    },

    props: {
        resultData: Object
    },

    computed: {
        getIcon() {
            switch (this.resultData.status) {
                case 'fixing_problems':
                    return 'DotsIcon';
                case 'accepted':
                    return 'HouseIcon';
                case 'inspection':
                case 're_inspection':
                default:
                    return 'CheckboxCircleIcon';
            }
        }
    }
};
</script>

<style lang="scss">
@import './OwnerKeysResultComponent';
</style>
