<template>
    <div
            v-if="list?.length"
            :class="[
            'select-list',
            {'is-active': isActive}
        ]">
            <div v-if="isMobile" class="select-list__overlay"></div>
            <div class="select-list__content">
                <div v-if="isMobile" class="select-list__title">
                    <span class="select-list__title-text">{{ label }}</span>
                    <span class="select-list__title-close" @click="_closeList">
                        <CloseIcon />
                    </span>
                </div>
                <ul class="select-list__list">
                    <li
                        v-for="item in list"
                        :class="[
                            'select-list__list-item',
                            {'is-active': activeItem?.id === item?.id}
                        ]"
                        :key="item?.id"
                        @click="_clickRadio(item?.id)"
                    >
                        <div v-if="isMobile" class="select-list__list-radio">
                            <CheckboxIcon />
                        </div>
                        {{ item.text }}
                    </li>
                </ul>
            </div>
        </div>
</template>

<script>
import CheckboxIcon from '@/icons/CheckboxIcon';
import CloseIcon from '@/icons/CloseIcon';
import {mapGetters} from 'vuex';

export default {
    name: 'FiltersSelectListComponent',

    components: {
        CheckboxIcon,
        CloseIcon
    },

    props: {
        list: {
            default() {
                return [];
            },
            type: Array
        },
        isActive: {
            default: false,
            type   : Boolean
        },
        label: {
            default: 'Выберите опцию',
            type   : String
        },
        activeItem: {
            default() {
                return {};
            },
            type: Object
        }
    },

    computed: {
        ...mapGetters({
            isMobile: 'GET_IS_MOBILE'
        })
    },

    methods: {
        _closeList() {
            this.$emit('closeList');
        },

        _clickRadio(id) {
            this.$emit('clickRadio', id);
        }
    }
};
</script>

<style lang="scss">
@import './FiltersSelectListComponent';
</style>
