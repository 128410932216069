var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"select",class:['select',
        {'select_theme_title': _vm.title},
        {'is-active': _vm.isActive},
        {'is-process': _vm.isProcess},
        {'is-selected': _vm.activeItem},
        _vm.modify]},[(_vm.title)?_c('div',{staticClass:"select__label"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_vm._v(" "),_c('ButtonComponent',{ref:"choose-project",attrs:{"rightIcon":"ArrowDownIcon","fill":"#8b96a3","modify":"button_theme_gray","text":_vm.activeItem ? _vm.activeItem.text : _vm.label},on:{"click":_vm._switchSelect}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.list?.length && _vm.isActive)?_c('div',{class:[
            'select__list',
            {'is-active': _vm.isActive}
        ]},[(_vm.isMobile)?_c('div',{staticClass:"select__list-overlay"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"select__list-content"},[(_vm.isMobile)?_c('div',{staticClass:"select__list-title"},[_c('span',{staticClass:"select__list-title-text"},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('span',{staticClass:"select__list-title-close",on:{"click":_vm._switchSelect}},[_c('CloseIcon')],1)]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"select__list-list"},_vm._l((_vm.list),function(item){return _c('li',{key:item?.id,class:[
                            'select__list-item',
                            {'is-active': _vm.activeItem?.id === item?.id}
                        ],on:{"click":function($event){return _vm._clickRadio(item?.id)}}},[(_vm.isMobile)?_c('div',{staticClass:"select__list-radio"},[_c('CheckboxIcon')],1):_vm._e(),_vm._v("\n                        "+_vm._s(item.text)+"\n                    ")])}),0)])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }