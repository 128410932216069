<template>
    <div
        v-if="compareData?.success"
        :class="[
            'compare',
            {'is-empty': activeTab && !compareData[activeTab.slug].length}
        ]">
        <div class="compare__wrapper">
            <div v-if="compareData?.flats?.length || compareData?.commerce?.length" class="compare__tabs">
                <TabsComponent
                    modify="tabs_theme_compare"
                    :tabsData="tabsData"
                    :activeTab="activeTab"
                    @changeTab="_changeActiveTab" />
            </div>

            <div v-if="compareData?.flats?.length || compareData?.commerce?.length" class="compare__manage">
                <div
                    :class="[
                        'compare-difference',
                        {'is-disabled': activeTab && compareData[activeTab.slug].length < 2}
                    ]"
                    @click="_getDifference"
                >
                    <div
                        :class="['compare-difference__switcher', {'is-active': showDifference}]"
                    >
                        <div class="compare-difference__switcher-point"></div>
                    </div>
                    <button
                        class="button button_theme_thin button_theme_flat compare-difference__switcher-button"
                        :disabled="activeTab && compareData[activeTab.slug].length < 2">
                        <span class="button__text">Только различия</span>
                    </button>
                </div>
                <button
                    class="button button_theme_thin button_theme_flat compare-button__clear"
                    @click="clearShow = true"
                >
                    <TrashIcon v-if="isMobile" />
                    <span class="button__text" v-if="!isMobile">Очистить список</span>
                </button>
            </div>
            <div class="compare__content" v-if="activeTab && compareData[activeTab.slug].length">
                <CompareComponent
                    v-if="activeTab && compareData[activeTab.slug].length"
                    :slides="compareData[activeTab.slug]"
                    :showDifference="showDifference"
                    :activeTab="activeTab.slug"
                    :activeSection="activeSection"
                    @removeItem="_removeItem"
                    @processing="_processingHandler" />
            </div>

            <CompareEmptyComponent v-else />
        </div>


        <transition name="fade">
            <ClearComponent
                v-if="clearShow"
                title="Очистить сравнение"
                description="Вы уверены, что хотите полностью очистить список сравнения?"
                @closeClearPopup="clearShow = false"
                @makeClear="_clearCompare"
            />
        </transition>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ClearComponent from '../../components/ClearComponent/ClearComponent';
import CompareComponent from '../../components/Compare/CompareComponent/CompareComponent';
import CompareDataController from '../../dataControllers/compare/index';
import CompareEmptyComponent from '../../components/Compare/CompareEmptyComponent/CompareEmptyComponent';
import TabsComponent from '../../components/TabsComponent/TabsComponent';
import TrashIcon from '../../icons/TrashIcon';

const CompareController = new CompareDataController();

export default {
    name: 'ComparePage',

    components: {
        ClearComponent,
        CompareComponent,
        CompareEmptyComponent,
        TabsComponent,
        TrashIcon
    },

    data() {
        return {
            activeTab: {
                id   : 0,
                slug : 'flats',
                name : 'Квартиры',
                count: 5
            },
            tabsData      : [],
            showDifference: false,
            clearShow     : false,
            compareData   : null,
            activeSection : null
        };
    },

    computed: {
        ...mapGetters({
            isMobile: 'GET_IS_MOBILE'
        })
    },

    watch: {
        compareData() {
            this._makeTabs();
        }
    },

    created() {
        this._getRequest('init');

        this.activeSection = this.$route.matched[0]?.props?.['default']?.section ?
            this.$route.matched[0]?.props?.['default']?.section :
            null;
    },

    destroyed() {
        CompareController.clearData();
    },

    methods: {
        ...mapActions(['updateSidebar']),

        /**
         * Универсальный обработчик запроса к апи избранного
         * @param {string} type - тип запроса - удаление / очистка / инит ...
         * @param {string} id - идентификатор объекта для удаления
         */
        _getRequest(type, id = null) {
            this.isProcessing = true;
            this._processingHandler(this.isProcessing);

            CompareController.getCompare({
                type,
                id,
                object: this.activeTab?.slug || null
            })
                .then((response) => {
                    if (response.success) {
                        this.compareData = response;
                        this.isEmpty = this.compareData.noResults;
                    } else {
                        this.$emit('errorShow', true, {
                            statusCode: response.errorData?.status,
                            url       : response.errorData?.url
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.$emit('errorShow', true, {
                        statusCode: 500,
                        url       : 'Неизвестная ошибка на странице Контакты в разделе УК'
                    });
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this.isProcessing = false;
                    this._processingHandler(this.isProcessing);

                    if (type === 'remove') {
                        const params = new URLSearchParams([['section', this.activeSection]]);

                        this.updateSidebar(params);

                        if (this.compareData && this.compareData[this.activeTab.slug]?.length) {
                            this.showDifference = false;
                        }
                    }
                });
        },

        /**
         * Собираем табы с возможными типами помещений на основе ответа бека
         */
        _makeTabs() {
            this.tabsData = [];

            Object.keys(this.compareData).forEach((itemKey) => {
                if (itemKey === 'descriptions' || itemKey === 'success') {
                    return;
                }

                this.tabsData.push({
                    id   : this.tabsData?.length,
                    slug : itemKey,
                    name : itemKey === 'flats' ? 'Квартиры' : 'Коммерческие помещения',
                    count: this.compareData[itemKey]?.length
                });
            });
        },

        /**
         * Переключаем табы
         * @param {string} id - ид нового активного таба
         */
        _changeActiveTab(id) {
            this.activeTab = this.tabsData[id];
            CompareController.setActiveType(this.activeTab.slug);
        },

        /**
         * Очищаем список сравнения
         */
        _clearCompare() {
            this._getRequest('clear');
            this.clearShow = false;
        },

        /**
         * Показываем только различия
         */
        _getDifference() {
            if (this.activeTab && this.compareData[this.activeTab.slug].length >= 2) {
                this.showDifference = !this.showDifference;
            }
        },

        _removeItem(id) {
            this._getRequest('remove', id);
        },

        _processingHandler(state) {
            this.$emit('processing', state);
        }
    }
};
</script>

<style lang="scss">
@import './ComparePage';
</style>
