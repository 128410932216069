<template>
    <div v-if="advantages || contacts" class="estate-sell">
        <EstateSellAdvantagesSection
            v-if="advantages"
            :content=advantages />

        <HelpBannerComponent
            v-if="banner"
            modify="estate-sell__help is-full-image"
            :title="banner?.title"
            :text="banner?.text"
            :image="banner?.image" />

        <EstateSellContactsSection
            v-if="contacts"
            :title="contacts?.title"
            :text="contacts?.text"
            :content="contacts?.components?.[0]?.content" />
    </div>
</template>

<script>
import AgencyDataController from '../../../dataControllers/agency';
import EstateSellAdvantagesSection from
    '@/sections/Agency/EstateSell/EstateSellAdvantagesSection/EstateSellAdvantagesSection';
import EstateSellContactsSection from
    '@/sections/Agency/EstateSell/EstateSellContactsSection/EstateSellContactsSection';
import HelpBannerComponent from '@/components/HelpBannerComponent/HelpBannerComponent';

const AgencyController = new AgencyDataController();

export default {
    name: 'EstateSell',

    components: {
        EstateSellAdvantagesSection,
        EstateSellContactsSection,
        HelpBannerComponent
    },

    data() {
        return {
            content     : null,
            advantages  : null,
            banner      : null,
            contacts    : null,
            isProcessing: true
        };
    },

    mounted() {
        this.getContent();
    },

    methods: {
        /**
         * Обработчик получения данных от бека
         */
        getContent() {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            AgencyController.getEstateSell()
                .then((response) => {
                    if (response.success) {
                        this.advantages = response.advantages;
                        this.banner = response.banner;
                        this.contacts = response.contacts;
                    } else {
                        this.$emit('errorShow', true, {
                            statusCode: response.errorData?.status,
                            url       : response.errorData?.url
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.$emit('errorShow', true, {
                        statusCode: 500,
                        url       : 'Неизвестная ошибка на странице Дополнительные услуги'
                    });
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                });
        }
    }
};
</script>

<style lang="scss">
@import './EstateSellPage';
</style>
