<template>
    <div :class="['help-choose', modify]">
        <div class="help-choose__image">
            <img :src="image === null ? '/images/help-choose/help-choose.png' : image" :alt="title" />
        </div>
        <div class="help-choose__banner">
            <h4 class="help-choose__banner-title" v-html="title"></h4>
            <div class="help-choose__banner-text" v-html="text"></div>

            <ButtonComponent modify="help-choose__banner-button" text="Заказать звонок" @click="_clickHandler"/>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '../ButtonComponent/ButtonComponent';

export default {
    name: 'HelpBanner',

    components: {
        ButtonComponent
    },

    props: {
        modify: {
            default: null,
            type   : String
        },
        title: {
            default: 'Есть сложности с&nbsp;выбором?',
            type   : String
        },
        text: {
            // eslint-disable-next-line
            default: 'Наш менеджер перезвонит вам в&nbsp;течение 20&nbsp;минут и&nbsp;проконсультирует по&nbsp;всем доступным вариантам.',
            type   : String
        },
        image: {
            default: null,
            type   : String
        }
    },

    methods: {
        _clickHandler() {
            this.$root.$emit('showPopup', 'callback');
        }
    }
};
</script>

<style lang="scss">
@import './HelpBannerComponent';
</style>
