export interface IRangeSliderOptions {
    type: string | null;
    min: number;
    max: number;
    from: number | null;
    to: number | null;
    step: number | null;
    min_interval: number | null;
    digit: boolean;
    disable: boolean;
    validate: string | undefined;
    hide_from_to: boolean;
    hide_min_max: boolean;
    prettify_enabled: boolean;
    onStart(data: IRangeSliderData): void;
    onChange(data: IRangeSliderData): void;
    onFinish(data: IRangeSliderData): void;
    onUpdate(data: IRangeSliderData): void;
}

export interface IRangeSliderData {
    from: number;
    from_percent: number;
    from_pretty: string;
    input: HTMLInputElement;
    max: number;
    min: number;
    rangeSlider?: IRangeSlider;
    slider: HTMLElement;
    to: number;
    to_percent: number;
    to_pretty: string;
}

export interface IIonRangeSliderInstance {
    old_from: number;
    update(options?: Partial<IIonRSUpdateOptions>): void;
    destroy(): void;
    init(): void;
    reset(): void;
}

export interface IIonRSUpdateOptions {
    from: string | number | undefined | null;
    to: string | number | undefined | null;
    max: string | number | undefined | null;
    min: string | number | undefined | null;
}

export interface IRangeSlider {
    input: HTMLInputElement;
    wrapper: HTMLElement;
    slider: HTMLElement;
    oldFrom: number;
    ionRangeSliderInstance?: IIonRangeSliderInstance;
    options: IRangeSliderOptions;
    init(): void;
}

export interface IProgram {
    active: boolean;
    id: number;
    name: string;
    title: string;
    plus: string;
    rate: number;
    payment: number;
    term: string;
    mortgageTerm?: number;
    mortgageTermMin?: number;
    paymentMin?: number;
    paymentMax?: number;
    additionalCost?: number | string;
}

export interface IPrograms {
    id: number;
    programs: IProgram[];
    title: string;
    color?: string;
}

export interface IFilterSlider {
    type?: string;
    category?: string;
    label?: string;
    value?: number;
    min?: number;
    max?: number;
    percentMin?: number;
    percentMax?: number;
}

export interface IDataStore {
    creditAmount: number | string;
    creditPercent: number | string;
    percentMin: number;
    percentMax: number;
    types: object[];
    programs: IPrograms[];
    sliders: object;
    filters: object;
    resultList: IPrograms[];
}

export interface IFirstPaymentObject {
    oldFrom: number;
    value: number;
}

export interface IAdditionalCost {
    value: number;
    pretty: string | number;
}

export interface IMortgageData {
    dataStore: IDataStore;
    isExist(data: unknown): boolean;
    getAccessStorage(key: string): number;
    removeStorage(key: string): void;
    calc(action: string, a: number, b: number): number;
    calcString(action: string, a: number, b: number): string;
    decodePostfix(num: number): string;
    saveToStorage(key: string, value: number): void;
    savePrograms(value: IPrograms[]): void;
    saveProgram(index: number, value: IPrograms): void;
    saveFilter(name: string, value: object): void;
    saveTypes(value: object[]): void;
    getPrograms(): object[];
    getFilter(name: string): IFilterSlider;
    setResultItem(program: IPrograms): void;
    getResultList(): IPrograms[];
    clearResultList(): void;
    calcCredit(percentMin: number, percentMax: number): {amount: string, percent: string};
    checkInputData(inputElement: HTMLInputElement, data: IRangeSliderData, regexp: RegExp): void;
    onKeyDownEvent(event: KeyboardEvent,
        data: any,
        obj: IMortgageCalculator,
        input: HTMLInputElement,
        regexp: RegExp): void;
    preventNaNSymbolsEnter(event: KeyboardEvent): void;
    getQueryData(): Record<string, string> | false;
    _checkMaxPayment(firstPay: number): number;
}

export interface IMortgageCalculator {
    sliderHandled(key: string, value: number | string): void;
    update(): void;
}

export enum EStartValues {
    PRICE = 'price',
    FIRST_PAY = 'first-pay',
    TERM = 'term'
}

export enum EFilters {
    PRICE = 'priceSlider',
    PAYMENT = 'paymentSlider',
    TERM = 'termSlider',
    PROJECTS = 'projects',
    STATUS = 'status'
}
