import type {
    IDataStore,
    IManagementData,
    IManagementResult
} from './type';

let managementDataInstance: IManagementData | null = null;

export default class ManagementData {
    public dataStore: IDataStore;

    constructor() {
        const that = this;

        if (!managementDataInstance) {
            managementDataInstance = that;
        }

        this.dataStore = {
            list     : [],
            more     : {},
            noResults: false,
            news     : {}
        };

        return managementDataInstance;
    }

    /**
     * Записываем в стор значение выполнения запроса на получение избранного
     * @param {object} response - объект с данными результата запроса
     * @param {string} type - тип запроса
     */
    public setRequestResponse(response: IManagementResult, type: string): void {
        // Если тип запроса - показать еще, то добавляем к текущему массиву
        // новый, если все другие - перезаписываем массив
        if (type === 'content') {
            this.dataStore.news.date = response.date ? response.date : '';
            this.dataStore.news.title = response.title ? response.title : '';
            this.dataStore.news.text = response.text ? response.text : '';
        } else {
            this.dataStore.list = type === 'more' ?
                this.dataStore.list.concat(response.list) :
                response.list;

            this.dataStore.more = response.more;
            this.dataStore.noResults = response.noResults;
        }
    }

    /**
     * Возвращает данные стора
     * @returns {object} - данные стора
     */
    public getStoreData(): IDataStore {
        return this.dataStore;
    }
}
