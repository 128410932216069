var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list?.length)?_c('div',{class:[
        'select-list',
        {'is-active': _vm.isActive}
    ]},[(_vm.isMobile)?_c('div',{staticClass:"select-list__overlay"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"select-list__content"},[(_vm.isMobile)?_c('div',{staticClass:"select-list__title"},[_c('span',{staticClass:"select-list__title-text"},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('span',{staticClass:"select-list__title-close",on:{"click":_vm._closeList}},[_c('CloseIcon')],1)]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"select-list__list"},_vm._l((_vm.list),function(item){return _c('li',{key:item?.id,class:[
                        'select-list__list-item',
                        {'is-active': _vm.activeItem?.id === item?.id}
                    ],on:{"click":function($event){return _vm._clickRadio(item?.id)}}},[(_vm.isMobile)?_c('div',{staticClass:"select-list__list-radio"},[_c('CheckboxIcon')],1):_vm._e(),_vm._v("\n                    "+_vm._s(item.text)+"\n                ")])}),0)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }