<template>
    <div :class="['file-upload', {'is-disabled': filesCount === maxFilesCount}]">
        <input
            type="file"
            :multiple="filesCount === 0"
            name="passport-upload"
            :disabled="filesCount === maxFilesCount"
            @change="filesChange($event.target.files)"
            accept="all"
            class="file-upload__input-file"
            />
        <div class="file-upload__icon">
            <img src="/webicons/upload/upload.svg" alt="Загрузка файлов">
        </div>
        <p class="file-upload__text">
            Перетащите файлы в эту область, <br />или <span>выберите их</span>
        </p>
        <p class="file-upload__text-size">
            Допустимые форматы — jpeg, jpg, png, pdf, doc, docx размером не более {{ maxSize }}&nbsp;Мб
        </p>
    </div>
</template>

<script>
export default {
    name: 'FileUploadComponent',

    props: {
        filesCount: {
            default: 0,
            type   : Number
        }
    },

    data() {
        return {
            uploadedFiles  : [],
            errorFiles     : [],
            uploadError    : null,
            currentStatus  : null,
            uploadFieldName: 'photos',
            maxSize        : 8,
            maxFilesCount  : 2,
            // Допустимые типы файлов
            // eslint-disable-next-line
            grantedTypes   : ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpg', 'image/jpeg', 'image/png']
        };
    },

    mounted() {
        this.reset();
    },

    methods: {
        reset() {
            // reset form to initial state
            this.uploadedFiles = [];
            this.errorFiles = [];
        },

        filesChange(fileList) {
            if (!fileList.length) {
                return;
            }

            this.reset();

            [...Array(fileList.length).keys()].forEach((key) => {
                if (this._validation(fileList[key], 'all')) {
                    this.uploadedFiles.push(fileList[key]);
                } else {
                    this.errorFiles.push(fileList[key]);
                }
            });

            this.$emit('uploadedFiles', this.uploadedFiles, this.errorFiles);
        },

        _validation(file, type) {
            let valid = false;

            if (this._checkTypeFile(file.type, type)) {
                valid = this._checkFileSize(file.size);
            }

            return valid;
        },

        /**
         * Проверяем допустимость типа загружаемого файла
         * @param {string} fileType - Тип загружаемого файла
         * @param {string} typeAccess - допустимый тип загружаемого файла
         * @returns {boolean} - результат проверки
         */
        _checkTypeFile(fileType, typeAccess) {
            let isExist = [];
            let typesArray = [];

            typesArray = typeAccess === 'all' ? this.grantedTypes : this._getUniqueType(typeAccess);

            isExist = typesArray.filter((type) => {
                return type === fileType;
            });

            return isExist.length;
        },

        _getUniqueType(type) {
            switch (type) {
                case 'xls':
                case 'xlsx':
                    // eslint-disable-next-line
                    return ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
                case 'doc':
                    // eslint-disable-next-line
                    return ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
                case 'img':
                    return ['image/jpg', 'image/jpeg', 'image/png'];
                case 'pdf':
                    return ['application/pdf'];
                default:
                    return [];
            }
        },

        /**
         * Проверяем размер загружаемого файла
         * @param {number} size - размер файла в байтах
         * @returns {boolean} - результат проверки
         */
        _checkFileSize(size) {
            return this.maxSize * 1024 * 1024 > Number(size);
        }
    }
};
</script>

<style lang="scss">
@import './FileUploadComponent';
</style>
