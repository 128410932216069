<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.49335 8.8551L5.26177 12.1124C5.5931 12.1124 5.7366 11.9701 5.90868 11.7991L7.4621 10.3146L10.6809 12.6718C11.2713 13.0008 11.6872 12.8276 11.8464 12.1287L13.9593 2.22844C14.1466 1.35577 13.6443 1.01394 13.0691 1.22803L0.649937 5.98277C-0.197646 6.31177 -0.184813 6.78427 0.505853 6.99835L3.68093 7.98594L11.056 3.37119C11.4031 3.14136 11.7187 3.26852 11.4591 3.49836L5.49335 8.8551Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'TelegramIcon',

    props: {
        fill: {
            default: '#455569',
            type   : String
        }
    }
};
</script>
