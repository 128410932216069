<template>
    <div class="management-contacts__contact">
        <div
            v-for="item in content?.list"
            :key="item.id"
            class="management-contacts__contact-item">
            <h5
                v-if="item?.title"
                v-html="item.title"
                class="management-contacts__contact-title"></h5>
            <div
                v-if="item?.schedule?.length"
                class="management-contacts__contact-line management-contacts__contact-schedule">
                <span class="title">Прием граждан:</span>
                <div
                    v-for="workTime in item.schedule"
                    :key="workTime.day"
                    class="management-contacts__contact-schedule-item">
                    <p
                        v-if="workTime.work"
                        v-html="`${workTime.day ? workTime.day : '' } ${workTime.work}`"></p>
                    <p v-html="workTime.lunch ? `перерыв ${workTime.lunch}` : 'Без перерыва'"></p>
                </div>
            </div>

            <div class="management-contacts__contact-line">
                <template v-if="item?.phones?.length">
                    <div
                        v-for="phone in item.phones"
                        :key="phone.id"
                        class="management-contacts__contact-phone">
                        <span class="title" v-if="phone.name">{{ phone.name }}:</span>
                        <a :href="`tel:${phone.phone }`">{{ phone.phone }}</a>
                    </div>
                </template>

                <p v-if="item?.fax" class="management-contacts__contact-fax">Факс: {{ item.fax }}</p>

                <div v-if="item?.email" class="management-contacts__contact-email">
                    <span class="title">Email:</span>
                    <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                </div>
            </div>


            <div v-if="item?.address" class="management-contacts__contact-line management-contacts__contact-address">
                <span class="title">Адрес:</span>
                <p>{{ item.address }}</p>
            </div>
        </div>

        <div ref="online" v-if="content?.socials?.length" class="management-contacts__contact-socials">
            <h5 class="management-contacts__contact-title">Онлайн-приемная</h5>
            <a
                v-for="social in content.socials"
                :key="social.id"
                :href="social.href"
                target="_blank">
                <img :src="`/webicons/socials/${social.type}.svg`" alt="Ссылка на соцсеть">
                {{ social.title }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ManagementContactsContact',

    props: {
        content: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    mounted() {
        this.$root.$on('onlineClicked', this._scrollTo);
    },

    methods: {
        _scrollTo() {
            this.$refs?.online?.scrollIntoView({behavior: 'smooth'});
        }
    }
};
</script>

<style lang="scss">
@import './ManagementContactsContact';
</style>
