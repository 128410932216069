<template>
    <div class="evaluate-result">
        <div v-if="!error" class="evaluate-result__info">
            <h5 class="evaluate-result__info-room">{{ room }}</h5>
            <div class="evaluate-result__info-specs">
                <p class="evaluate-result__info-spec">{{ square }} м²</p>
                <p class="evaluate-result__info-spec">{{ queue }} очередь</p>
                <p class="evaluate-result__info-spec">{{ floor }} этаж</p>
            </div>
        </div>

        <div v-if="!error" class="evaluate-result__price">
            <p>Результат оценки</p>
            <h5>{{ _convertToDigit(price) }} ₽</h5>
        </div>

        <div :class="['evaluate-result__text', {'evaluate-result__text_theme_error': error}]">
            <p v-if="!error">Результат оценки не является итоговым и точным,
                для полноценной и максимально репрезентативной оценки вашей квартиры
                <a href='#' @click.prevent="_clickHandler">обращайтесь</a> к нашим менеджерам.</p>

            <h5 v-else>{{ error }}</h5>
        </div>
    </div>
</template>

<script>
import {Utils} from '@/scripts/utils';

export default {
    name: 'EvaluateResultComponent',

    props: {
        room: {
            default: '-',
            type   : [String, Number]
        },
        queue: {
            default: '-',
            type   : [String, Number]
        },
        square: {
            default: '-',
            type   : [String, Number]
        },
        floor: {
            default: '-',
            type   : [String, Number]
        },
        price: {
            default: '-',
            type   : [String, Number]
        },
        error: {
            default: null,
            type   : String
        }
    },

    methods: {
        _convertToDigit(num) {
            return Utils.convertToDigit(num);
        },

        _clickHandler() {
            this.$root.$emit('showPopup', 'callback');
        }
    }
};
</script>

<style lang="scss">
@import './EvaluateResultComponent';
</style>
