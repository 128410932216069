<template>
    <div class="compare__content-wrapper">
        <div class="compare__content-sliders">
            <CompareSwiperComponent
                :buttonFill="buttonFill"
                :slides="slides"
                :activeSection="activeSection"
                @removeItem="_removeItem"
                @slideChanged="_slideChange"
                @processing="_processingHandler" />
            <CompareSwiperComponent
                v-if="isMobile && slides.length > 1"
                :slides="slides"
                :mobileSlider="true"
                :activeSection="activeSection"
                @removeItem="_removeItem"
                @slideChanged="_slideMobileChange"
                @processing="_processingHandler" />
        </div>
        <div class="compare__content-specs" v-if="specs?.common">
            <CompareSpecsComponent
                v-for="spec in specs.common"
                :key="spec.name"
                :spec="spec"
                :showDifference="showDifference" />
        </div>
        <div class="compare__content-specs" v-if="specs?.feature && specs?.feature.length">
            <h5 class="compare-spec__title">Особенности</h5>
            <CompareSpecsComponent
                v-for="feature in specs.feature"
                :key="feature.name"
                :spec="feature"
                :showDifference="showDifference" />
        </div>
        <div class="compare__content-specs" v-if="specs?.square && specs?.square.length">
            <h5 class="compare-spec__title">Площадь</h5>
            <CompareSpecsComponent
                v-for="square in specs.square"
                :key="square.name"
                :spec="square"
                :showDifference="showDifference" />
        </div>
        <div class="compare__content-order" v-if="orderLinks?.length">
            <div class="compare-order__button-wrapper" v-for="link in orderLinks" :key="link.id">
                <LinkComponent
                    v-if="activeTab === 'flats' && !link.disabled"
                    type="button"
                    modify="button_theme_thin compare-order__button-link"
                    :href="`/order/${link.href}`"
                    text="Забронировать" />
<!--                <LinkComponent-->
<!--                    v-if="activeTab === 'flats' && !link.disabled"-->
<!--                    type="button"-->
<!--                    modify="button_theme_thin compare-order__button-link"-->
<!--                    :href="`/order/${link.href}`"-->
<!--                    text="Забронировать" />-->
                <ButtonComponent
                    v-else-if="activeTab === 'commerce' || link.disabled"
                    :disabled="link.disabled"
                    modify="button_theme_thin compare-order__button-link"
                    :text="link.disabled ? 'Объект продан' : 'Оставить заявку'"
                    @click="_commerceOrderClick(link.href)" />
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import CompareDataController from '../../../dataControllers/compare/index';
import CompareSpecsComponent from '../CompareSpecsComponent/CompareSpecsComponent';
import CompareSwiperComponent from '../CompareSwiperComponent/CompareSwiperComponent.vue';
import LinkComponent from '@/components/LinkComponent/LinkComponent';
import {mapGetters} from 'vuex';

const compareDataController = new CompareDataController();

export default {
    name: 'CompareComponent',

    components: {
        ButtonComponent,
        CompareSpecsComponent,
        CompareSwiperComponent,
        LinkComponent
    },

    props: ['slides', 'showDifference', 'activeTab', 'activeSection'],

    data() {
        return {
            activeSlides     : [0],
            activeMobileSlide: this.slides.length > 1 ? 1 : null,
            specs            : {},
            empty            : false,
            orderLinks       : []
        };
    },

    computed: {
        ...mapGetters({
            isMobile : 'GET_IS_MOBILE',
            isDesktop: 'GET_IS_DESKTOP'
        }),

        buttonFill() {
            return this.isMobile ? '#8b96a3' : '#ffffff';
        }
    },

    watch: {
        slides() {
            this._calcActiveSlides();
            this._calcSpecs();
        }
    },

    created() {
        if (this.slides.length) {
            compareDataController.setSlidesLength(this.slides.length);
            window.addEventListener('resize', this._resizeListener);
        } else {
            this.empty = true;
        }
    },

    mounted() {
        if (!this.empty) {
            this._calcActiveSlides();
            this._calcSpecs();
        }
    },

    destroyed() {
        window.removeEventListener('resize', this._resizeListener);
    },

    methods: {
        /**
         * Слушатель на изменения размеров окна
         */
        _resizeListener() {
            this._calcActiveSlides(this.activeSlides[0]);

            this._calcSpecs();
        },

        /**
         * Расчет активных слайдов
         */
        _calcActiveSlides() {
            this.activeSlides = compareDataController.calcActiveSlides();
        },

        /**
         * Прокрутка основного слайдера
         * @param {number} item - номер нового активного слайда
         */
        _slideChange(item) {
            this.activeSlides = compareDataController.calcActiveSlides(item);

            this._calcSpecs();
        },

        /**
         * Прокрутка второго слайдера в мобильной версии
         * @param {number} item - номер нового активного слайда
         */
        _slideMobileChange(item) {
            this.activeMobileSlide = item;

            compareDataController.setCompareData(this.activeSlides, this.activeMobileSlide);

            this._calcSpecs();
        },

        /**
         * Получаем характеристики активных помещений (слайдов)
         */
        _calcSpecs() {
            // Если мы в мобильной версии - нам надо два слайдера, иначе
            // передаем данные для одного
            const slidesArray = this.isMobile ?
                [this.activeSlides[0], this.activeMobileSlide] :
                this.activeSlides;

            this.specs = compareDataController.getSpecs(this.slides, slidesArray);
            this.orderLinks = compareDataController.getLinks(this.slides, slidesArray);
        },

        _removeItem(id) {
            this.$emit('removeItem', id);
        },

        /**
         * Обработка клика по кнопке бронирования коммерции
         * @param {string} link - guid помещения
         */
        _commerceOrderClick(link) {
            this.$root.$emit('showPopup', 'commerce', link);
        },

        _processingHandler(state) {
            this.$emit('processing', state);
        }
    }
};
</script>

<style lang="scss">
@import './CompareComponent';
</style>
