<template>
    <div class="mobile-menu">
        <div class="mobile-menu__overlay"></div>
        <div class="mobile-menu__wrapper">
            <div class="mobile-menu__top">
                <div class="mobile-menu__head">
                    <ButtonComponent
                        modify="button_theme_flat mobile-menu__head-back"
                        icon="ArrowBack"
                        fill="#8b96a3"
                        @click="_closeMenu" />

                    <div class="mobile-menu__head-title">Меню</div>

                    <!-- <button class="button button_theme_flat mobile-menu__head-notification">
                        <NotificationIcon fill="#8B96A3" />
                        <span class="button__notification">3</span>
                    </button> -->
                </div>

                <div class="mobile-menu__user">
                    <UserComponent
                        @closeMenu="_closeMenu" />
                </div>
            </div>

            <ul v-if="menuData?.length" class="mobile-menu__menu">
                <li
                    v-for="item in menuData"
                    :key="item.href"
                    class="mobile-menu__menu-item"
                >
                    <router-link
                        :to="item.href"
                        :key="item.href"
                        class="mobile-menu__menu-link"
                        @click.native="_closeMenu">
                        {{ item.text }}
                        <ArrowRightIcon fill="#8b96a3"/>
                    </router-link>
                </li>
            </ul>

            <div class="mobile-menu__meetings">
                <ButtonComponent
                    v-if="!meetings?.date"
                    modify="button_theme_flat mobile-menu__meetings-item"
                    rightIcon="PlusIcon"
                    text="Назначить встречу"
                    @click="meetingPopup('meeting')" />

                <router-link
                    v-else-if="meetings?.date"
                    to="/content/meetings"
                    class="mobile-menu__meetings-item">
                    <div class="link__text">
                        <div class="link__text">Встреча с менеджером</div>
                        <div class="link__subtext">
                            <p>{{meetings.date}}</p>
                            <p>{{meetings.time}}</p>
                        </div>
                    </div>
                    <div class="link__icon">
                        <ArrowRightIcon fill="#8b96a3"/>
                    </div>
                </router-link>

                <ButtonComponent
                    v-if="!tour?.date"
                    modify="button_theme_flat mobile-menu__meetings-item"
                    rightIcon="PlusIcon"
                    text="Записаться на экскурсию"
                    @click="meetingPopup('tour')" />

                <router-link
                    v-else-if="tour"
                    to="/content/excursion"
                    key="/content/excursion"
                    class="mobile-menu__meetings-item"
                    @click.native="_closeMenu">
                    <div class="link__text">
                        <div class="link__title">Экскурсия по {{ tour.project }}</div>
                        <div class="link__subtext">
                            <p>{{tour.date}}</p>
                            <p>{{tour.time}}</p>
                        </div>
                    </div>
                    <div class="link__icon">
                        <ArrowRightIcon fill="#8b96a3"/>
                    </div>
                </router-link>
            </div>

            <div class="mobile-menu__projects">
                <div class="mobile-menu__projects-title">Проекты Кельник-Студиос</div>
                <ul class="mobile-menu__projects-list">
                    <li
                        v-for="project in projects"
                        :key="project.href"
                        class="mobile-menu__projects-item"
                    >
                        <a :href="project.href" target="_blank">
                            <div class="mobile-menu__projects-img">
                                <img :src="project.image" :alt="project.name">
                            </div>

                            <div class="mobile-menu__projects-description">
                                <h3>{{ project.name }}</h3>
                                <p>{{ project.descr }}</p>
                            </div>

                            <ButtonComponent
                                modify="button_theme_thin mobile-menu__projects-button"
                                rightIcon="ExternalIcon"
                                fill="#ffffff"
                                text="Сайт проекта" />
                        </a>
                    </li>
                </ul>

                <LinkComponent
                    type="button"
                    modify="button_theme_gray mobile-menu__projects-corporate"
                    href="/"
                    text="Корпоративный сайт"
                    rightIcon="ExternalIcon"
                    fill="#8b96a3" />
            </div>

            <div class="mobile-menu__footer">
                <div class="mobile-menu__footer-text">{{computedCopyright}}</div>
                <div class="mobile-menu__footer-logo">
                    <img src="/webicons/logo/kelnik-logo.svg" alt="Kelnik">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ArrowRightIcon from '@/icons/ArrowRightIcon';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent.vue';
import LinkComponent from '@/components/LinkComponent/LinkComponent.vue';
// import NotificationIcon from '@/icons/NotificationIcon';
import UserComponent from '@/components/UserComponent/UserComponent';

export default {
    name: 'MobileMenuComponent',

    components: {
        ArrowRightIcon,
        ButtonComponent,
        LinkComponent,
        // NotificationIcon, после раскомментирования поправить стили
        UserComponent
    },

    computed: {
        ...mapGetters({
            menuData: 'GET_MENU',
            projects: 'GET_PROJECTS_LIST',
            meetings: 'GET_MEETING',
            tour    : 'GET_TOUR',
            settings: 'GET_SETTINGS'
        }),

        computedCopyright() {
            return this.settings?.copyright ?
                this.settings.copyright :
                '© 2023, ООО "Кельник-Студиос"';
        }
    },

    methods: {
        ...mapActions(['setMobileMenuState']),

        meetingPopup(type) {
            this.$root.$emit('showPopup', 'meeting', type);
            this._closeMenu();
        },

        _closeMenu() {
            this.setMobileMenuState();
        }
    }
};
</script>

<style lang="scss">
@import './MobileMenuComponent';
</style>
