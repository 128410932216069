import type {
    IAgencyData,
    IAgencyDataStore,
    IAgencyResult
} from './types';

let agencyDataInstance: IAgencyData | null = null;

export class AgencyData implements IAgencyData {
    public agencyDataStore: IAgencyDataStore;

    constructor() {
        if (!agencyDataInstance) {
            // eslint-disable-next-line
            agencyDataInstance = this;
        }

        this.agencyDataStore = {
            premises : [],
            more     : [],
            sortList : [],
            noResults: false,
            projects : [],
            complexes: []
        };

        return agencyDataInstance;
    }

    /**
     * Записываем в стор значение выполнения запроса на получение избранного
     * @param {object} response - объект с данными результата запроса
     * @param {string} type - тип запроса
     */
    public setRequestResponse(response: IAgencyResult, type: string): void {
        // Если тип запроса - показать еще, то добавляем к текущему массиву
        // новый, если все другие - перезаписываем массив
        this.agencyDataStore.premises = type === 'more' ?
            this.agencyDataStore.premises.concat(response.premises) :
            response.premises;

        this.agencyDataStore.more = response.more;
        this.agencyDataStore.sortList = response.sort.options;
        this.agencyDataStore.projects = response.projects.options;
        this.agencyDataStore.noResults = response.noResults;
        this.agencyDataStore.complexes = response.complexes;
    }

    /**
     * Возвращает данные стора
     * @returns {object} - данные стора
     */
    public getStoreData(): IAgencyDataStore {
        return this.agencyDataStore;
    }
}
