<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.39823 1.75C11.4529 1.75 12.8334 3.70563 12.8334 5.53C12.8334 9.22469 7.10379 12.25 7.00008 12.25C6.89638 12.25 1.16675 9.22469 1.16675 5.53C1.16675 3.70563 2.5473 1.75 4.60193 1.75C5.78156 1.75 6.55286 2.34719 7.00008 2.87219C7.4473 2.34719 8.2186 1.75 9.39823 1.75Z" :fill="fill"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.60193 2.75C3.25623 2.75 2.16675 4.08702 2.16675 5.53C2.16675 6.19167 2.4241 6.88098 2.89409 7.58163C3.36328 8.28109 4.00871 8.93753 4.68897 9.51086C5.36548 10.081 6.04953 10.5465 6.57101 10.8692C6.73325 10.9695 6.87807 11.055 7.00008 11.1246C7.12209 11.055 7.26691 10.9695 7.42915 10.8692C7.95062 10.5465 8.63467 10.081 9.31118 9.51086C9.99145 8.93753 10.6369 8.28109 11.1061 7.58163C11.576 6.88098 11.8334 6.19167 11.8334 5.53C11.8334 4.087 10.744 2.75 9.39823 2.75C8.59956 2.75 8.08416 3.14168 7.76132 3.52065C7.57133 3.7437 7.29307 3.87219 7.00008 3.87219C6.70708 3.87219 6.42883 3.7437 6.23883 3.52065C5.916 3.14168 5.40059 2.75 4.60193 2.75ZM0.166748 5.53C0.166748 3.32424 1.83837 0.75 4.60193 0.75C5.62194 0.75 6.41408 1.09291 7.00008 1.51784C7.58608 1.09291 8.37822 0.75 9.39823 0.75C12.1618 0.75 13.8334 3.32426 13.8334 5.53C13.8334 6.71567 13.3745 7.79004 12.767 8.69577C12.1586 9.60268 11.3652 10.3953 10.6001 11.0402C9.83122 11.6882 9.06342 12.2099 8.48142 12.57C8.18975 12.7504 7.94041 12.893 7.75557 12.9927C7.66394 13.0422 7.58327 13.0838 7.51821 13.1153C7.48702 13.1304 7.45037 13.1475 7.41352 13.163C7.39635 13.1703 7.36648 13.1825 7.33053 13.1947C7.31291 13.2007 7.28198 13.2107 7.24318 13.2203L7.24236 13.2205C7.21781 13.2266 7.12298 13.25 7.00008 13.25C6.87717 13.25 6.78235 13.2266 6.75779 13.2205L6.75697 13.2203C6.71817 13.2107 6.68723 13.2007 6.66962 13.1947C6.63367 13.1825 6.6038 13.1703 6.58663 13.163C6.54978 13.1475 6.51313 13.1304 6.48195 13.1153C6.41689 13.0838 6.33621 13.0422 6.24459 12.9927C6.05975 12.893 5.81041 12.7504 5.51874 12.57C4.93674 12.2099 4.16894 11.6882 3.40008 11.0402C2.63497 10.3953 1.84151 9.60268 1.23316 8.69577C0.625602 7.79004 0.166748 6.71568 0.166748 5.53Z" fill="#E40220"/>
    </svg>
</template>

<script>
export default {
    name: 'HeartIcon',

    props: {
        fill: {
            default: '#E40220',
            type   : String
        }
    }
};
</script>
