<template>
    <ul :class="['mobile-bar', modify]">
        <li
            class="mobile-bar__item"
            v-for="item in data"
            :key="item.id">
            <router-link :to="item.href">
                <img :src="item.image" :alt="item.name">
                <span class="item__text">{{ item.name }}</span>
            </router-link>
        </li>

        <li class="mobile-bar__item" @click="_mobileMenuOpen">
            <img src="/webicons/mobilebar/menu.svg" alt="Меню">
            <span class="item__text">Меню</span>
        </li>
    </ul>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'MobileBarComponent',

    props: {
        modify: {
            default: '',
            type   : String
        }
    },

    data() {
        return {
            data: [{
                id   : 0,
                name : 'Брони',
                href : '/content/booking',
                image: '/webicons/mobilebar/booking.svg'
            }, {
                id   : 1,
                name : 'Мои объекты',
                href : '/content/objects',
                image: '/webicons/mobilebar/objects.svg'
            }, {
                id   : 2,
                name : 'Избранное',
                href : '/content/favorites',
                image: '/webicons/mobilebar/favorites.svg'
            }, {
                id   : 3,
                name : 'Сравнение',
                href : '/content/compare',
                image: '/webicons/mobilebar/compare.svg'
            }]
        };
    },

    methods: {
        ...mapActions(['setMobileMenuState']),

        _mobileMenuOpen() {
            this.setMobileMenuState();
        }
    }
};
</script>

<style lang="scss">
@import './MobileBarComponent';
</style>
