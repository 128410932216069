<template>
    <div class="management-services__list">
        <h4 v-if="list.title" class="management-services__list-title">
            {{ list.title }}
        </h4>

        <div class="management-services__list-content">
            <div
                v-for="service in list.list"
                :key="service.id"
                class="management-services__service">
                <div class="management-services__service-content">
                    <h5 v-if="service.name" class="management-services__service-title">{{ service.name }}</h5>
                    <div class="management-services__service-projects">
                        <p
                            v-for="project in service.projects"
                            :key="project">
                            {{ project }}
                        </p>
                    </div>

                    <div v-if="service.partner" class="management-services__service-partner">{{ service.partner }}</div>

                    <p
                        v-if="service.text"
                        v-html="service.text"
                        class="management-services__service-descr"></p>
                </div>

                <div v-if="service.price" class="management-services__service-price h5">{{ service.price }} р.</div>

                <ButtonComponent
                    modify="button_theme_thin management-services__service-order"
                    text="Заказать"
                    @click="_orderClick(service.name)" />
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';

export default {
    name: 'ManagementServicesList',

    components: {
        ButtonComponent
    },

    props: {
        activeTab: {
            default: () => {
                return {};
            },
            type: Object
        },
        list: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    methods: {
        _orderClick(name) {
            this.$root.$emit('showPopup', 'service', name);
        }
    }
};
</script>

<style lang="scss">
@import './ManagementServicesList';
</style>
