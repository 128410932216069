<template>
    <div class="popup popup-booking-steps">
        <div
            class="popup__overlay"
            @click="_closeClick"
            ></div>
        <div class="popup__content popup-booking-steps__content">
            <div class="popup__content-top">
                <ButtonComponent
                    modify="button_theme_thin button_theme_transparent popup__content-close"
                    icon="CloseIcon"
                    fill="#8b96a3"
                    @click="_closeClick" />
            </div>
            <div class="popup__content-body popup-booking-steps__content-body">
                <h3 class="popup-booking-steps__content-title">Этапы покупки</h3>
                <div v-if="steps?.length" class="popup-booking-steps__list">
                    <div
                        v-for="(step, index) in steps"
                        :key="step.id"
                        class="popup-booking-steps__item"
                    >
                        <div class="popup-booking-steps__item-num">{{ index + 1 }}</div>
                        <div class="popup-booking-steps__item-content">
                                <h5 class="popup-booking-steps__item-title" v-html="step.title"></h5>
                                <span v-if="step.notice" class="popup-booking-steps__item-note">
                                    <InfoIcon fill="#62725b" />
                                    {{ step.notice }}
                                </span>
                                <div class="popup-booking-steps__item-text" v-html="step.text"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import InfoIcon from '../../../icons/InfoIcon';

export default {
    name: 'PopupBookingStepsComponent',

    components: {
        ButtonComponent,
        InfoIcon
    },

    props: {
        steps: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    methods: {
        _closeClick() {
            this.$emit('closePopup');
        }
    }
};
</script>

<style lang="scss">
@import './PopupBookingStepsComponent';
</style>
