<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 3.75C0.5 3.19772 0.947715 2.75 1.5 2.75H16.5C17.0523 2.75 17.5 3.19772 17.5 3.75C17.5 4.30228 17.0523 4.75 16.5 4.75H1.5C0.947715 4.75 0.5 4.30228 0.5 3.75ZM0.5 9C0.5 8.44772 0.947715 8 1.5 8H16.5C17.0523 8 17.5 8.44772 17.5 9C17.5 9.55228 17.0523 10 16.5 10H1.5C0.947715 10 0.5 9.55228 0.5 9ZM0.5 14.25C0.5 13.6977 0.947715 13.25 1.5 13.25H12C12.5523 13.25 13 13.6977 13 14.25C13 14.8023 12.5523 15.25 12 15.25H1.5C0.947715 15.25 0.5 14.8023 0.5 14.25Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'MenuIcon',

    props: {
        fill: {
            default: '#ffffff',
            type   : String
        }
    }
};
</script>
