<template>
    <div :class="['popup', 'popup-text', popupData?.modify]">
        <div
            class="popup__overlay"
            @click="_closeClick"
            ></div>
        <div class="popup__content popup-text__content">
            <div class="popup__content-top">
                <ButtonComponent
                    modify="button_theme_thin button_theme_transparent popup__content-close"
                    icon="CloseIcon"
                    fill="#8b96a3"
                    @click="_closeClick" />
            </div>
            <div class="popup__content-body popup-text__content-body">
                <div v-if="popupData?.date" class="popup-text__content-date">
                    <span>Акция до </span>{{ popupData.date }}
                </div>
                <h3 v-if="popupData?.title" class="popup-text__content-title" v-html="popupData.title"></h3>
                <div v-if="popupData?.text" class="popup-text__content-text" v-html="popupData.text"></div>

                <div v-if="popupData?.showProjects && project" class="popup-text__projects">
                    <a  :href="project?.href"
                        class="popup-text__projects-item"
                        target="_blank">
                        <div class="popup-text__projects-img">
                            <img :src="project?.image" :alt="project?.name">
                        </div>
                        <ButtonComponent
                            modify="button_theme_circle-small popup-text__projects-link"
                            icon="ExternalIcon"
                            fill="#ffffff" />
                        <div class="popup-text__projects-title">Выбрать квартиру в&nbsp;{{ project?.name }}</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import {mapGetters} from 'vuex';

export default {
    name: 'PopupTextContentComponent',

    components: {
        ButtonComponent
    },

    props: {
        popupData: {
            default: () => {
                return {};
            },
            type: Object
        },
        modify: {
            default: '',
            type   : String
        }
    },

    computed: {
        ...mapGetters({
            project: 'GET_PROJECTS_MAIN'
        })
    },

    methods: {
        _closeClick() {
            this.$emit('closePopup');
        }
    }
};
</script>

<style lang="scss">
@import './PopupTextContentComponent';
</style>
