<template>
    <details v-if="title?.length && text?.length" :class="['details', modify]" :open="open">
        <summary>
            <div class="details__summary-text">{{ title }}</div>
            <div v-if="plus" class="details__summary-plus">
                <CommonMinusIcon class="details__summary-plus-minus" />
                <CommonPlusIcon class="details__summary-plus-plus" />
            </div>
            <ArrowDownIcon v-else />
        </summary>
        <div class="details__content" v-html="text"></div>
        <div v-if="date" class="details__date" v-html="date"></div>
    </details>
</template>

<script>
import ArrowDownIcon from '@/icons/ArrowDownIcon';
import CommonMinusIcon from '@/icons/CommonMinusIcon';
import CommonPlusIcon from '@/icons/CommonPlusIcon';

export default {
    name: 'DetailsComponent',

    components: {
        ArrowDownIcon,
        CommonMinusIcon,
        CommonPlusIcon
    },

    props: {
        title: {
            default: null,
            type   : String
        },
        text: {
            default: null,
            type   : String
        },
        modify: {
            default: null,
            type   : String
        },
        open: {
            default: false,
            type   : Boolean
        },
        plus: {
            default: false,
            type   : Boolean
        },
        date: {
            default: null,
            type   : String
        }
    }
};
</script>

<style lang="scss">
@import './DetailsComponent';
</style>
