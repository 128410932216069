<template>
    <div :class="['owner-banner', modify]">
        <div class="owner-banner__image">
            <img v-if="banner.image" :src="banner.image" alt="Изображение">
        </div>
        <div class="owner-banner__info">
            <h4 v-if="banner.title"  class="owner-banner__info-title">{{ banner.title }}</h4>
            <div v-if="banner.text" class="owner-banner__info-text" v-html="banner.text"></div>
            <div
                v-if="banner.components?.[0]?.content"
                v-html="banner.components[0]?.content.text"
                class="owner-banner__info-note"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OwnerBannerComponent',

    props: {
        modify: [Array, String, Object],
        banner: {
            default: () => {
                return {};
            },
            type: Object
        }
    }
};
</script>

<style lang="scss">
@import './OwnerBannerComponent';
</style>
