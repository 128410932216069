<template>
    <details
        class="additional-service__item"
        @click="_detailsClickHandler">
        <summary>
            <h5 class="additional-service__item-title" v-html="content.title"></h5>
            <div class="additional-service__item-more">
                <PlusMoreIcon modify="additional-service__item-more-plus" />
                <MinusMoreIcon modify="additional-service__item-more-minus" />
            </div>
        </summary>
        <div class="additional-service__item-content">
            <div
                v-for="item, index in content.content"
                :key="item.id"
                class="additional-service__item-step">
                <div
                    class="additional-service__item-step-name"
                    v-html="`<span>Шаг ${index + 1 }.</span> ${item.title}`"></div>
                <div
                    class="additional-service__item-step-text"
                    v-html="item.text"></div>
            </div>

            <ButtonComponent
                modify="additional-service__item-button"
                text="Заказать услугу"
                @click="_serviceClickHandler" />
        </div>
    </details>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import MinusMoreIcon from '@/icons/MinusMoreIcon';
import PlusMoreIcon from '@/icons/PlusMoreIcon';

export default {
    name: 'AdditionalServiceItem',

    components: {
        ButtonComponent,
        MinusMoreIcon,
        PlusMoreIcon
    },

    props: {
        content: {
            default: () => {
                return {};
            },
            type: Object
        },
        activeUID: {
            default: null,
            type   : Number
        }
    },

    watch: {
        activeUID() {
            if (this._uid === this.activeUID) {
                return;
            }

            this.$el.open = false;
        }
    },

    methods: {
        /**
         * Обработка клика по кнопке - Заказать услугу
         */
        _serviceClickHandler() {
            this.$root.$emit('showPopup', 'additional-service', this.content?.title);
        },

        /**
         * Обрабатываем клик, для функциональности аккордеона
         */
        _detailsClickHandler() {
            this.$emit('click', this._uid);
        }
    }
};
</script>

<style lang="scss">
@import './AdditionalServiceItemComponent';
</style>
