<template>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9997 3.3335C8.10863 3.3335 3.33301 8.10912 3.33301 14.0002C3.33301 19.8912 8.10863 24.6668 13.9997 24.6668C19.8907 24.6668 24.6663 19.8912 24.6663 14.0002C24.6663 8.10911 19.8907 3.3335 13.9997 3.3335ZM1.33301 14.0002C1.33301 7.00455 7.00406 1.3335 13.9997 1.3335C20.9953 1.3335 26.6663 7.00456 26.6663 14.0002C26.6663 20.9958 20.9953 26.6668 13.9997 26.6668C7.00407 26.6668 1.33301 20.9958 1.33301 14.0002ZM9.79259 9.79309C10.1831 9.40257 10.8163 9.40257 11.2068 9.79309L13.9997 12.586L16.7926 9.79309C17.1831 9.40257 17.8163 9.40257 18.2068 9.79309C18.5973 10.1836 18.5973 10.8168 18.2068 11.2073L15.4139 14.0002L18.2068 16.7931C18.5973 17.1836 18.5973 17.8168 18.2068 18.2073C17.8163 18.5978 17.1831 18.5978 16.7926 18.2073L13.9997 15.4144L11.2068 18.2073C10.8163 18.5978 10.1831 18.5978 9.79259 18.2073C9.40207 17.8168 9.40207 17.1836 9.79259 16.7931L12.5855 14.0002L9.79259 11.2073C9.40207 10.8168 9.40207 10.1836 9.79259 9.79309Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'DeletedBookingIcon',

    props: {
        fill: {
            default: '#62725b',
            type   : String
        }
    }
};
</script>
