<template>
    <section class="treatment-empty">
        <h4 class="treatment-empty__title">Нет активных обращений</h4>
        <p class="treatment-empty__text">Заполните форму и&nbsp;ожидайте ответа
            менеджера поддержки в&nbsp;вашем личном кабинете</p>

        <ButtonComponent
            modify="treatment-empty__button"
            text="Создать обращение"
            @click="_createHandler" />
    </section>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
export default {
    name: 'TreatmentEmptySection',

    components: {
        ButtonComponent
    },

    methods: {
        /**
         * Подскролл до формы
         */
        _createHandler() {
            this.$emit('scrollToCreate');
        }
    }
};
</script>

<style lang="scss">
@import './TreatmentEmptySection';
</style>
