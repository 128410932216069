<template>
    <section class="estate-sell__advantages">
        <div class="estate-sell__advantages-title">
            <h4 v-if="content.title" v-html="content.title"></h4>
            <p v-if="content.text" v-html="content.text"></p>
        </div>

        <ul v-if="content.components?.[0]?.content?.length" class="estate-sell__advantages-list">
            <li
                v-for="advantage in content.components[0].content"
                :key="advantage"
                class="estate-sell__advantages-list-item">
                <img src="/webicons/agency/check.svg" alt="Иконка изображающая галочку">
                {{ advantage }}
            </li>
        </ul>

        <div v-if="content.components?.[1]?.content?.length" class="estate-sell__advantages-panels">
            <div
                v-for="panel in content.components[1].content"
                :key="panel.id"
                class="estate-sell__advantages-panel">
                <img v-if="panel.icon" :src="panel.icon" :alt="panel.title">
                <p v-html="panel.title"></p>
            </div>
        </div>

    </section>
</template>

<script>
export default {
    name: 'EstateSellAdvantagesSection',

    props: {
        content: {
            default: () => {
                return {};
            },
            type: Object
        }
    }
};
</script>

<style lang="scss">
@import './EstateSellAdvantagesSection';
</style>
