<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.57391 3.5C4.34766 3.5 2.6665 5.67514 2.6665 7.9C2.6665 8.95752 3.07733 10.0248 3.77822 11.0696C4.47832 12.1133 5.43208 13.0803 6.42207 13.9146C7.4083 14.7458 8.40346 15.4229 9.16139 15.8918C9.49885 16.1006 9.78583 16.266 9.99984 16.3835C10.2139 16.266 10.5008 16.1006 10.8383 15.8918C11.5962 15.4229 12.5914 14.7458 13.5776 13.9146C14.5676 13.0803 15.5214 12.1133 16.2215 11.0696C16.9224 10.0248 17.3332 8.95752 17.3332 7.9C17.3332 5.67515 15.652 3.5 13.4258 3.5C12.1216 3.5 11.2755 4.14762 10.7611 4.75157C10.5711 4.97462 10.2929 5.10312 9.99986 5.10312C9.70686 5.10312 9.4286 4.97463 9.2386 4.75159C8.7241 4.14761 7.87814 3.5 6.57391 3.5ZM0.666504 7.9C0.666504 4.91236 2.92981 1.5 6.57391 1.5C8.07545 1.5 9.20285 2.0591 9.99984 2.70858C10.7968 2.05909 11.9243 1.5 13.4258 1.5C17.0698 1.5 19.3332 4.91235 19.3332 7.9C19.3332 9.48153 18.7209 10.9338 17.8824 12.1838C17.0432 13.4349 15.9414 14.5381 14.8665 15.4439C13.7879 16.353 12.709 17.0863 11.8906 17.5926C11.4807 17.8462 11.1318 18.0456 10.8755 18.1839C10.7482 18.2527 10.6389 18.309 10.5534 18.3503C10.512 18.3704 10.4672 18.3913 10.4244 18.4093C10.4042 18.4177 10.3719 18.4309 10.3341 18.4438C10.3156 18.45 10.2839 18.4603 10.2444 18.4701L10.2437 18.4703C10.2186 18.4764 10.1232 18.5 9.99984 18.5C9.87643 18.5 9.78103 18.4764 9.756 18.4703L9.75526 18.4701C9.71582 18.4603 9.6841 18.45 9.66556 18.4438C9.62776 18.4309 9.59546 18.4177 9.57531 18.4093C9.53252 18.3913 9.48771 18.3704 9.44631 18.3503C9.36083 18.309 9.25149 18.2527 9.12417 18.1839C8.86794 18.0456 8.51902 17.8462 8.10912 17.5926C7.29066 17.0863 6.21175 16.353 5.13317 15.4439C4.05834 14.5381 2.95654 13.4349 2.11729 12.1838C1.27883 10.9338 0.666504 9.48153 0.666504 7.9Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'FavoritesIcon',

    props: {
        fill: {
            default: '#ffffff',
            type   : String
        }
    }
};
</script>
