<template>
    <div class="additional-service__list">
        <AdditionalServiceItemComponent
            v-for="item in list?.components"
            :key="item.id"
            :content="item"
            :activeUID="activeUID"
            @click="_setActiveUID" />
    </div>
</template>

<script>
import AdditionalServiceItemComponent from '@/components/AdditionalServiceItemComponent/AdditionalServiceItemComponent';

export default {
    name: 'AdditionalServiceList',

    components: {
        AdditionalServiceItemComponent
    },

    props: {
        list: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    data() {
        return {
            activeUID: null
        };
    },

    methods: {
        /**
         * Устанавливаем ID аккордеона по которому кликнули
         * @param {number} uid - ID элемента
         */
        _setActiveUID(uid) {
            if (!uid || this.activeUID === uid) {
                return;
            }

            this.activeUID = uid;
        }
    }
};
</script>
