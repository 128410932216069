<template>
    <div
        ref="select"
        :class="['select',
            {'select_theme_title': title},
            {'is-active': isActive},
            {'is-process': isProcess},
            {'is-selected': activeItem},
            modify]">
        <div v-if="title" class="select__label">{{ title }}</div>
        <ButtonComponent
            ref="choose-project"
            rightIcon="ArrowDownIcon"
            fill="#8b96a3"
            modify="button_theme_gray"
            :text="activeItem ? activeItem.text : label"
            @click="_switchSelect" />
        <transition name="fade">
            <div
                v-if="list?.length && isActive"
                :class="[
                'select__list',
                {'is-active': isActive}
            ]">
                <div v-if="isMobile" class="select__list-overlay"></div>
                <div class="select__list-content">
                    <div v-if="isMobile" class="select__list-title">
                        <span class="select__list-title-text">{{ label }}</span>
                        <span class="select__list-title-close" @click="_switchSelect">
                            <CloseIcon />
                        </span>
                    </div>
                    <ul class="select__list-list">
                        <li
                            v-for="item in list"
                            :class="[
                                'select__list-item',
                                {'is-active': activeItem?.id === item?.id}
                            ]"
                            :key="item?.id"
                            @click="_clickRadio(item?.id)"
                        >
                            <div v-if="isMobile" class="select__list-radio">
                                <CheckboxIcon />
                            </div>
                            {{ item.text }}
                        </li>
                    </ul>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import CheckboxIcon from '@/icons/CheckboxIcon';
import CloseIcon from '@/icons/CloseIcon';
import {mapGetters} from 'vuex';
import {Utils} from '@/scripts/utils';

export default {
    name: 'SelectComponent',

    components: {
        ButtonComponent,
        CheckboxIcon,
        CloseIcon
    },

    props: {
        list: {
            default() {
                return [];
            },
            type: Array
        },
        label: {
            default: 'Выберите опцию',
            type   : String
        },
        modify: {
            default: '',
            type   : String
        },
        title: {
            default: null,
            type   : String
        },
        active: {
            default: null,
            type   : [String, Number]
        },
        clearActive: {
            default: false,
            type   : Boolean
        }
    },

    data() {
        return {
            isActive  : false,
            isProcess : false,
            activeItem: null
        };
    },

    computed: {
        ...mapGetters({
            isMobile: 'GET_IS_MOBILE'
        })
    },

    watch: {
        /**
         * Очищаем активный пункт если требуется
         */
        clearActive() {
            this.activeItem = null;
        }
    },

    created() {
        // Если передан активный таб из родителя (при ините), ищем такой элемент
        // в списке и ставим его как активный элемент
        if (this.active?.toString()) {
            this.activeItem = this.list.find((item) => {
                return item.slug === this.active;
            });
        }
    },

    mounted() {
        document.addEventListener('click', this._onClickOutside);
    },

    beforeDestroy() {
        document.removeEventListener('click', this.onClickOutside);
    },

    methods: {
        /**
         * Закрываем выпадающий список
         */
        _closeList() {
            this.isActive = false;

            // Используем для плавного скрытия списка
            this.isProcess = true;

            setTimeout(() => {
                this.isProcess = false;
            }, 800);
        },

        /**
         * Обрабатываем клик по выбранному пункту
         * @param {string | number} id - ID выбранного пункта
         */
        _clickRadio(id) {
            this._switchSelect();
            this.activeItem = this.list.find((item) => {
                return item.id === id;
            });
            this.$emit('clickRadio', this.activeItem);
        },

        /**
         * Переключение состояния выпадающего списка и эмит его состояния для
         * скрытия мобильного бара
         */
        _switchSelect() {
            if (this.$attrs?.disabled) {
                return;
            }

            this.isActive = !this.isActive;
            this.$root.$emit('selectOpened', this.isActive);

            if (!this.isActive) {
                this._closeList();
            }

            this.isActive && this.isMobile ? Utils.bodyFixed(this.$el) : Utils.bodyStatic();
        },

        _onClickOutside(event) {
            const {select} = this.$refs;

            if (this.isActive && !select?.contains(event.target)) {
                this._switchSelect();
            }
        }
    }
};
</script>

<style lang="scss">
@import './SelectComponent';
</style>
