import type {
    ICalcDescriptionsTagsResult,
    ICalcOffersTagsResult,
    IFavoritesDataController,
    IFavoritesRequestOptions,
    IFavoritesResult,
    IResponseFavoritesRequest
} from './types';
import axios from 'axios';
import type {AxiosResponse} from 'axios';
import {ERequests} from '../../requests';
import {FavoritesData} from './favoritesData';
import {Utils} from '../../scripts/utils';

const FavoritesDataStore = new FavoritesData();

export default class FavoritesDataController implements IFavoritesDataController {
    /**
     * Получаем список пунктов меню
     * @param {object} options - объект параметров запроса
     * @returns {object} - объект с данными квартир
     */
    public async getFavorites(options: IFavoritesRequestOptions): Promise<IFavoritesResult> {
        const data = {
            type   : options.type,
            id     : options.id,
            shown  : options.more,
            sort   : options.sort,
            project: options.project,
            object : options.object
        };

        const result = {
            premises : [],
            noResults: false,
            more     : null,
            sort     : null,
            projects : null
        };

        const getData = () => {
            // Получаем результат из временного стора - реализованно так,
            // чтобы использовать одну функцию на разные сценарии получения
            // записей - инициализация / показать еще / очистить
            const storeData = FavoritesDataStore.getStoreData();

            const favoritesRemovedFromCompare = JSON.parse(localStorage.getItem('favoritesRemoved') || '[]');
            const compareRemoved = JSON.parse(localStorage.getItem('compareRemoved') || '[]');

            if (favoritesRemovedFromCompare?.length) {
                result.premises = storeData.premises.filter((item: {id: string}) => {
                    return !favoritesRemovedFromCompare.includes(item.id);
                });
            } else {
                result.premises = storeData.premises;
            }

            result.premises.forEach((flat) => {
                flat.inCompare = !compareRemoved.includes(flat.id);
            });

            result.more = storeData.more;
            result.sort = storeData.sortList;
            result.noResults = storeData.noResults;
            result.projects = storeData.projects;
        };


        await axios.post(ERequests.favoritesResult, data)
            .then((response: AxiosResponse<IResponseFavoritesRequest>) => {
                switch (data.type) {
                    case 'remove':
                        const favoritesRemovedFromCompare = JSON.parse(localStorage.getItem('favoritesRemoved') || '[]');

                        if (!favoritesRemovedFromCompare.includes(data.id)) {
                            favoritesRemovedFromCompare.push(data.id);

                            response.data.data.premises = response.data.data.premises.filter((flat: {id: string}) => {
                                return !favoritesRemovedFromCompare.includes(flat.id);
                            });

                            localStorage.setItem('favoritesRemoved', JSON.stringify(favoritesRemovedFromCompare));
                        }

                        break;
                    case 'sort':
                        if (typeof data.sort === 'string') {
                            switch (data.sort) {
                                case '0':
                                    response.data.data.premises.sort((a: {price: string}, b: {price: string}) => {
                                        const priceA = a.price.replace(/\s/g, '');
                                        const priceB = b.price.replace(/\s/g, '');

                                        return Number(priceA) - Number(priceB);
                                    });

                                    break;
                                case '1':
                                    response.data.data.premises.sort((a: {price: string}, b: {price: string}) => {
                                        const priceA = a.price.replace(/\s/g, '');
                                        const priceB = b.price.replace(/\s/g, '');

                                        return Number(priceB) - Number(priceA);
                                    });

                                    break;
                                case '2':
                                    response.data.data.premises.sort((a: {area: string}, b: {area: string}) => {
                                        const areaA = a.area.replace(/\s/g, '');
                                        const areaB = b.area.replace(/\s/g, '');

                                        return Number(areaA) - Number(areaB);
                                    });

                                    break;
                                case '3':
                                    response.data.data.premises.sort((a: {area: string}, b: {area: string}) => {
                                        const areaA = a.area.replace(/\s/g, '');
                                        const areaB = b.area.replace(/\s/g, '');

                                        return Number(areaB) - Number(areaA);
                                    });

                                    break;
                            }

                            response.data.data.sort.options.forEach((sort: {id: string, selected: boolean}) => {
                                sort.selected = sort.id === data.sort;
                            });
                        }

                        break;
                    case 'filter':
                        switch (data.project) {
                            case '1':
                                response.data.data.premises = response.data.data.premises.filter((item: {project: string}) => {
                                    return item.project === 'ЖК №1';
                                });

                                break;
                            case '2':
                                response.data.data.premises = response.data.data.premises.filter((item: {project: string}) => {
                                    return item.project === 'ЖК №2';
                                });

                                break;
                            default:
                                break;
                        }

                        response.data.data.projects.options.forEach((project: {id: string, checked: boolean}) => {
                            project.checked = project.id === data.project;
                        });

                        break;
                }

                // Записываем результат во временный стор
                FavoritesDataStore.setRequestResponse(response.data.data, data.type);

                if (data.type === 'clear') {
                    localStorage.removeItem('favoritesRemoved');
                    localStorage.removeItem('compareRemoved');
                }

                getData();
            });

        return result;
    }

    /**
     * Ищем выбранный элемент
     * @param {string} filter - фильтр или сортировка в которой ищем
     * @param {string} type - тип элемента selected или checked
     * @returns {object} - выбранный элемент
     */
    public getActiveFilter(filter: string, type: string): object {
        const storeData = FavoritesDataStore.getStoreData();

        const filterItem = storeData[filter]?.find((item: object) => {
            return item[type];
        });

        return filterItem;
    }

    public calcOffersTags(tags: object[]): ICalcOffersTagsResult {
        const tagsArray = {
            label: '',
            list : []
        };

        if (tags.length === 1) {
            tagsArray.label = tags[0]['text'];
        } else {
            tagsArray.label = `${tags.length} ${Utils.pluralWord(tags.length, ['Акция', 'Акции', 'Акций'])}`;
            tagsArray.list = tags;
        }

        return tagsArray;
    }

    public calcDescriptionsTags(tags: string[], width: number): ICalcDescriptionsTagsResult {
        let tagsArray = {
            display: [],
            list   : []
        };

        if (tags.length === 1) {
            tagsArray.display.push(tags[0]);
        } else {
            tagsArray = this._calcDescriptionLength(tags, width);
        }

        return tagsArray;
    }

    public async switchCompareState(id: number, method: string): Promise<boolean> {
        // const URL = method === 'add' ? ERequests.compareAdd : ERequests.compareRemove;

        let result = false;

        const compareRemoved = JSON.parse(localStorage.getItem('compareRemoved') || '[]');

        if (method === 'add') {
            const index = compareRemoved.findIndex((compareId) => {
                return compareId === id;
            });

            if (index !== -1) {
                compareRemoved.splice(index, 1);
            }

            localStorage.setItem('compareRemoved', JSON.stringify(compareRemoved));

            result = true;
        } else {
            if (!compareRemoved.includes(id)) {
                compareRemoved.push(id);

                localStorage.setItem('compareRemoved', JSON.stringify(compareRemoved));
            }

            result = true;
        }

        // await axios.post(URL, {id})
        //     .then((response: AxiosResponse) => {
        //         result = response.data.success;
        //     })
        //     .catch(() => {
        //         result = false;
        //     });

        return result;
    }

    private _calcDescriptionLength(tags: string[], width: number): ICalcDescriptionsTagsResult {
        // Ширина одного символа
        const wordSize = 8;
        // Размер паддинга с обоих сторон
        const padSize = 36;
        // Размер левого
        const marginSize = 4;
        // Размер кнопки расскрывающий доп список преимуществ
        const addButtonSize = 36;
        // Общая сумма всех параметров
        const additionalSize = padSize + marginSize + addButtonSize;
        // Массив с результатом отображаемых
        const resultArray = {
            display: [],
            list   : []
        };

        let commonLength = 0;

        tags.forEach((tag: string, index: number) => {
            // Высчитываем общую длину строки с учетом текущего тега
            commonLength = commonLength + (tag.length * wordSize) + additionalSize;

            // Если первый тег слишком длинный, отправляем его сразу в массив
            // списка, и обнуляем общую длину, для того чтобы хоть какой то тег
            // отображался в карточке
            if (index === 0 && commonLength > width) {
                resultArray.list.push(tag);
                commonLength = 0;
            } else {
                commonLength < width ?
                    resultArray.display.push(tag) :
                    resultArray.list.push(tag);
            }
        });

        return resultArray;
    }
}
