<template>
    <form class="evaluate__form">
        <InputComponent
            name="contract"
            label="Номер договора"
            :data-store-instance="TradeInStore"
            @inputChanged="inputChanged"
        />

        <ButtonComponent
            modify="button_theme_big evaluate__form-button"
            :disabled="!isDisabled"
            text="Оценить квартиру"
            @click="_clickHandler" />
    </form>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import InputComponent from '@/components/InputComponent/InputComponent';
import TradeInDataController from '@/dataControllers/tradein/index';

const TradeInController = new TradeInDataController();

export default {
    name: 'ContractForm',

    components: {
        ButtonComponent,
        InputComponent
    },

    data() {
        return {
            inputError: true,
            isDisabled: false
        };
    },

    computed: {
        TradeInStore() {
            return TradeInController.tradeInStore;
        }
    },

    methods: {
        inputChanged(inputScope) {
            this.inputError = inputScope.error;

            this._checkFields();
        },

        /**
         * Проверяем заполнение полей
         */
        _checkFields() {
            const store = this.TradeInStore.dataStore.items;

            this.isDisabled = store?.contract?.value?.length;
        },

        _clickHandler() {
            const store = this.TradeInStore.dataStore.items;

            this.$emit('sendData', 'contract', {contract: store.contract.value});
        }
    }
};
</script>
