var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page profile"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isProcessing)?_c('PreloaderComponent',{attrs:{"modify":"preloader_theme_opacity"}}):_vm._e()],1),_vm._v(" "),_c('HeaderComponent',{attrs:{"name":"Профиль"}}),_vm._v(" "),_c('div',{staticClass:"page__wrapper profile__wrapper"},[_c('div',{staticClass:"page__sidebar profile__sidebar"},[(_vm.user?.name)?_c('h4',{staticClass:"profile__sidebar-name"},[_vm._v(_vm._s(_vm.user.name)+" "+_vm._s(_vm.user.surname))]):_vm._e(),_vm._v(" "),(_vm.user?.phone)?_c('div',{staticClass:"profile__sidebar-phone"},[_c('span',[_vm._v("Номер телефона")]),_vm._v("\n                +7"+_vm._s(_vm.user?.phone)+"\n            ")]):_vm._e(),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"button_theme_gray profile__sidebar-logout","text":"Выйти","leftIcon":"LogoutIcon","disabled":_vm.isProcessing},on:{"click":_vm._userExit}})],1),_vm._v(" "),_c('div',{class:[
                'page__content',
                'profile__content',
                {'page__content_theme_processing': _vm.isProcessing}
            ]},[_c('div',{staticClass:"profile__section"},[_c('h5',{staticClass:"profile__content-title"},[_vm._v("Персональные данные")]),_vm._v(" "),(_vm.user?.name)?_c('div',{staticClass:"profile__table"},[(_vm.user?.surname)?_c('div',{staticClass:"profile__table-item"},[_c('span',[_vm._v("Фамилия")]),_vm._v("\n                        "+_vm._s(_vm.user.surname)+"\n                    ")]):_vm._e(),_vm._v(" "),(_vm.user?.name)?_c('div',{staticClass:"profile__table-item"},[_c('span',[_vm._v("Имя")]),_vm._v("\n                        "+_vm._s(_vm.user.name)+"\n                    ")]):_vm._e(),_vm._v(" "),(_vm.user.email)?_c('div',{staticClass:"profile__table-item"},[_c('span',[_vm._v("E-mail")]),_vm._v("\n                        "+_vm._s(_vm.user.email)+"\n                    ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"profile__edit","text":"Запросить изменения"},on:{"click":_vm.editPopupShow}})],1),_vm._v(" "),_c('div',{staticClass:"profile__section"},[_c('h5',{staticClass:"profile__content-title"},[_vm._v("Управляющая компания")]),_vm._v(" "),_c('div',{staticClass:"profile__uk"},[_c('InputComponent',{ref:"account",attrs:{"name":"account","inputModify":"profile__uk-item","label":"Лицевой счет","validation":{
                            type: 'number',
                            msg: 'Введите только цифры'
                        },"value":_vm.user?.uk?.account},on:{"inputChanged":_vm._inputChangeHandler}}),_vm._v(" "),_c('InputComponent',{ref:"name",attrs:{"name":"name","inputModify":"profile__uk-item","label":"ФИО","validation":{
                            type: 'letters',
                            msg: 'Введите только буквы'
                        },"value":_vm.user?.uk?.name},on:{"inputChanged":_vm._inputChangeHandler}}),_vm._v(" "),_c('InputComponent',{ref:"address",attrs:{"name":"address","inputModify":"profile__uk-item","label":"Адрес","value":_vm.user?.uk?.address},on:{"inputChanged":_vm._inputChangeHandler}}),_vm._v(" "),_c('InputComponent',{ref:"area",attrs:{"name":"area","inputModify":"profile__uk-item profile__uk-item_theme_half","label":"Площадь","validation":{
                            type: 'square'
                        },"value":_vm.user?.uk?.area},on:{"inputChanged":_vm._inputChangeHandler}}),_vm._v(" "),_c('InputComponent',{ref:"tenants",attrs:{"name":"tenants","inputModify":"profile__uk-item profile__uk-item_theme_half","label":"Кол-во жителей","validation":{
                            type: 'number',
                            msg: 'Введите только цифры'
                        },"value":_vm.user?.uk?.tenants},on:{"inputChanged":_vm._inputChangeHandler}})],1),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"profile__uk-button","text":"Сохранить изменения"},on:{"click":_vm._saveUK}})],1)])]),_vm._v(" "),_c('FooterComponent'),_vm._v(" "),(_vm.isMobile)?_c('MobileBarComponent'):_vm._e(),_vm._v(" "),_c('PopupsSection'),_vm._v(" "),_c('transition',{attrs:{"name":"popup-question-fade"}},[(_vm.popupQuestionShow)?_c('PopupQuestionComponent',{attrs:{"title":_vm.questionTitle,"descr":_vm.questionDescr,"select":_vm.questionSelect,"textarea":_vm.questionTextarea,"callbackData":_vm.callbackData},on:{"closePopup":function($event){return _vm._popupSwitch('popupQuestionShow')}}}):_vm._e()],1),_vm._v(" "),_c('CookieComponent')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }