<template>
    <div class="owner-keys__order">
        <h4 class="owner-keys__order-title">Запись на приемку</h4>
        <p class="owner-keys__order-text">Для записи на&nbsp;приемку объекта перейдите по&nbsp;ссылке,
            которая вам будет отправлена смс-сообщением на&nbsp;номер телефона,
            указанный в&nbsp;договоре, либо запишитесь в&nbsp;этом поле.
            Укажите &#8470; договора, выберете дату и&nbsp;время из&nbsp;предложенного интервала.</p>

        <ButtonComponent
            modify="button_theme_big owner-keys__order-button"
            :text="contracts?.length ? 'Записаться' : 'Нет объектов для записи'"
            :disabled="!contracts?.length"
            @click="_clickHandler"/>

        <div class="owner-keys__order-note">
            <p>Передавать объекты недвижимости участникам долевого строительства
                застройщик может только после получения разрешения на&nbsp;ввод объекта в&nbsp;эксплуатацию.<br />
            </p>
            <div v-if="permits?.length" class="owner-keys__order-permits">
                <LinkComponent
                    v-for="permit in permits"
                    :key="permit.url"
                    :href="permit.url"
                    :text="permit.title" />
            </div>
            <p>Перезванивать в&nbsp;колл-центр не&nbsp;требуется, ваша запись будет передана менеджеру
                автоматически. Смс будет отправлена перед началом демонстрации квартир.</p>
            <p>Если дата приемки не&nbsp;подходит, и&nbsp;вы&nbsp;не&nbsp;произвели запись,
                менеджер самостоятельно свяжется с&nbsp;вами в&nbsp;течение двух недель и&nbsp;запишет
                на&nbsp;другую свободную дату. Эта дата будет позже основного заселения жителей вашего корпуса.</p>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import LinkComponent from '@/components/LinkComponent/LinkComponent';

export default {
    name: 'OwnerKeysOrderSection',

    components: {
        ButtonComponent,
        LinkComponent
    },

    props: {
        permits: {
            default: () => {
                return [];
            },
            type: Array
        },
        contracts: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    methods: {
        _clickHandler() {
            this.$emit('popupOpen');
        }
    }
};
</script>

<style lang="scss">
@import './OwnerKeysOrderSection';
</style>
