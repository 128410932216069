import 'url-search-params-polyfill';
import {EFilters, EStartValues} from './types';
import type {
    IAdditionalCost,
    IMortgageCalculator,
    IMortgageData,
    IProgram,
    IPrograms
} from './types';
import axios from 'axios';
import type {AxiosResponse} from 'axios';
import {ERequests} from '../../requests';
import MortgageData from './mortgageData';

const MortgageDataController: IMortgageData = new MortgageData();

let percentMin: number = 20;
let percentMax: number = 90;

class MortgageCalculator implements IMortgageCalculator {
    private firstPayment: number;

    public get mortgageDataStore(): IMortgageData {
        return MortgageDataController;
    }

    /**
     * Рассчитываем ежемесячный платёж по формуле:
     * @param {number} rate - ставка
     * @param {number} additionalCost - надбавка
     * @return {number} - ежемесячный платёж
     * A = K * S,
     * где A - ежемесячный аннуитетный платёж,
     * K - коэффициент аннуитета,
     * S - сумма кредита.
     * K = (i * (1 + n)^n) / (((1 + i)^n) - 1)
     * i - месячная процентная ставка по кредиту (годовая/12)
     * n - количество периодов, в течение которых выплачивается кредит
     */
    static calcMonthlyPay(rate: number, additionalCost: number): number | '—' {
        const i = rate / 12 / 100;
        const n = MortgageDataController.getAccessStorage(EStartValues.TERM) * 12;
        const kNumerator = i * Math.pow(1 + i, n);
        const kDenominator = Math.pow(1 + i, n) - 1;
        const _K = kNumerator / kDenominator;
        const _S = MortgageDataController.getAccessStorage('creditAmount') + additionalCost;
        const _A = _K * _S;

        return _A < 0 ? '—' : _A;
    }

    // eslint-disable-next-line max-len
    public async getMortgage(type: string, project: number = 1, program: number = 1, status: number = 0): Promise<void> {
        const data = {
            type,
            project,
            program,
            status
        };

        await axios.post(ERequests.mortgage, data)
            .then((response: AxiosResponse) => {
                const result = response.data.data;

                // Сохраняем начальные значения
                Object.keys(EStartValues).forEach((key: string) => {
                    const name = EStartValues[key];

                    if (result.summary[name]) {
                        MortgageDataController.saveToStorage(name, result.summary[name]);
                    }
                });

                // Сохраняем данные слайдеров
                Object.keys(EFilters).forEach((key: string) => {
                    const name = EFilters[key];

                    if (result.filters[name]) {
                        if (name === EFilters.PRICE) {
                            result.filters[name].min = Number(result.filters[name].min) === 0 ?
                                2000000 :
                                result.filters[name].min;
                        }

                        MortgageDataController.saveFilter(name, result.filters[name]);
                    }
                });

                if (result.mortgage?.length) {
                    MortgageDataController.savePrograms(result.mortgage);
                }

                if (result.type?.length) {
                    MortgageDataController.saveTypes(result.type);
                }

                if (EFilters.PAYMENT) {
                    const paymentSlider = result.filters[EFilters.PAYMENT];

                    percentMin = paymentSlider.percentMin ? Number(paymentSlider.percentMin) : 20;
                    percentMax = paymentSlider.percentMax ? Number(paymentSlider.percentMax) : 90;

                    MortgageDataController.saveToStorage('percentMin', Number(percentMin));
                    MortgageDataController.saveToStorage('percentMax', Number(percentMax));
                }

                this.updateFirstPayment(MortgageDataController.getAccessStorage(EStartValues.PRICE));
                this.updatePaymentSlider(false);
                this.update();
            });
    }

    /**
     * Реакция на событие изменение какого либо слайдера
     * @param {string} key - ключ (идентификатор) потроганного слайдера
     * @param {number | string} value - новое значение слайдера
     */
    public sliderHandled(key: string, value: number | string): void {
        const activeValue = MortgageDataController.getAccessStorage(key);
        let updatePaymentBorders: boolean = false;

        const preparedValue = key === 'first-pay' ?
            MortgageDataController._checkMaxPayment(Number(value)) :
            value;

        if (key === 'price') {
            updatePaymentBorders = true;
        }

        if (activeValue && activeValue !== Number(preparedValue)) {
            MortgageDataController.saveToStorage(key, Number(preparedValue));

            this.updateFirstPayment(MortgageDataController.getAccessStorage(EStartValues.PRICE));

            this.updatePaymentSlider(updatePaymentBorders);

            this.update();
        }
    }

    public update(): void {
        const programs = MortgageDataController.getPrograms();

        this._setPaymentOnTable();

        MortgageDataController.clearResultList();

        programs.forEach((program: IPrograms, index: number) => {
            this._findCheapestInSubsidyGroup(program, index);

            this._setResultList(program, index);
        });
    }

    /**
     * Обновляем первый платеж при изменении цены квартиры
     * @param {number} from - максимальное значение для рэйндж-слайдера первого взноса
     */
    private updateFirstPayment(from: number = 0): void {
        this.firstPayment = MortgageDataController.getAccessStorage(EStartValues.FIRST_PAY);
        // Если текущее значение первого платежа в процентном выражении
        // составляет больше 90% от новой суммы квартиры, устанавливаем 90% от
        // суммы квартиры в качестве первого платежа, иначе оставляем ту же
        // сумму первого платежа, что и была выбрана пользователем ранее.
        const value = parseInt(MortgageDataController.calcCredit(percentMin, percentMax).percent);

        if (value >= percentMax) {
            this.firstPayment = Math.ceil(from * (percentMax / 100));
        } else if (value <= percentMin) {
            this.firstPayment = Math.ceil(from * (percentMin / 100));
        }

        MortgageDataController.saveToStorage(EStartValues.FIRST_PAY, this.firstPayment);
    }

    /**
     * Обновляем значения слайдера первого платежа при каких-либо изменениях
     * @param {boolean} updateBorders - флаг указвающий на то, что надо ли
     * пересчитывать нижнюю границу слайдера или нет
     */
    private updatePaymentSlider(updateBorders: boolean): void {
        // Получаем слайдер первого платежа, меняем значения и сохраняем
        const flatPrice = MortgageDataController.getAccessStorage(EStartValues.PRICE);
        const firstPay = MortgageDataController.getAccessStorage(EStartValues.FIRST_PAY);
        const paymentSlider = MortgageDataController.getFilter(EFilters.PAYMENT);


        paymentSlider.value = firstPay;
        paymentSlider.max = Number(flatPrice);

        if (updateBorders) {
            // Пересчет требуется после изменения цены квартиры
            paymentSlider.min = Math.ceil(flatPrice * (Number(paymentSlider.percentMin) / 100));
        }

        MortgageDataController.saveFilter(EFilters.PAYMENT, paymentSlider);
    }

    /**
     * Рассчитываем надбавку к стоимости квартиры
     * @param {IProgram} item - объект программы для рассчета надбавки
     * @returns {IAdditionalCost} - объект с расчетами - сумма и текст
     */
    private _setAdditionalCost(item: IProgram): IAdditionalCost {
        const additionalCostValue: number = parseFloat(item.plus);
        const price = MortgageDataController.getAccessStorage(EStartValues.PRICE);
        let additionalCost: number = 0;
        let additionalCostPretty: string | number = '';

        if (isNaN(additionalCostValue)) {
            additionalCostPretty = additionalCostValue || 'нет удорожания';
        } else {
            additionalCost = (price * additionalCostValue) / 100;
            const fixedAdditionalCost = parseInt(additionalCost.toFixed());

            additionalCostPretty = `+ ${fixedAdditionalCost.toLocaleString('ru-Ru')} ₽`;
        }

        return {
            value : additionalCost || 0,
            pretty: additionalCostPretty
        };
    }

    /**
     * Высчитываем и устанавливаем ежемесячный платёж каждому банку
     */
    private _setPaymentOnTable(): void {
        const programs = MortgageDataController.getPrograms();

        programs.forEach((item: IPrograms, index: number) => {
            item.programs.forEach((program: IProgram) => {
                const additionalCost = this._setAdditionalCost(program);

                program.payment = this._calcPaymentForBank(program, additionalCost.value);
                program.active = this._checkActiveStatus(program);
                program.additionalCost = additionalCost.pretty;
            });

            MortgageDataController.saveProgram(index, item);
        });
    }

    /**
     * Рассчет платежа для каждого банка
     * @param {IProgram} item - объект программы для рассчета платежа
     * @param {number} additionalCost - сумма надбавки
     * @returns {number} - ежемесячный платеж по банку
     */
    private _calcPaymentForBank(item: IProgram, additionalCost: number): number {
        const rate = Number(item.rate);

        const data =
            MortgageDataController.isExist(MortgageCalculator.calcMonthlyPay(rate, additionalCost)) ?
                // @ts-ignore
                `${parseInt(MortgageCalculator.calcMonthlyPay(rate, additionalCost).toFixed())}` :
                '—';

        return Number(data);
    }

    /**
     * Скрывает группу без результатов
     * @param {IPrograms} group - объект группы субсидии
     * @param {number} index - индекс текущего типа субсидии в массиве субсидий
     * @private
     */
    private _findCheapestInSubsidyGroup(group: IPrograms, index: number): void {
        group.programs.sort((a: IProgram, b: IProgram) => {
            return a.payment - b.payment;
        });

        MortgageDataController.saveProgram(index, group);
    }

    /**
     * Формирование списка с результатом для отображения
     * @param {IPrograms} group - объект с данными по программам
     * @param {number} index - порядковый номер
     */
    private _setResultList(group: IPrograms, index: number): void {
        const resultItem = {
            id      : index,
            title   : group.title,
            programs: [],
            color   : group.color ? group.color : null
        };

        group.programs.forEach((item: IProgram) => {
            if (resultItem.programs.length || !item.active || item.payment === 0) {
                return;
            }

            resultItem.programs.push(item);
        });

        if (resultItem.programs.length) {
            MortgageDataController.setResultItem(resultItem);
        }
    }

    /**
     * Проверка подходит ли программа под условия
     * @param {IProgram} program - объект программы
     * @returns {boolean} - флаг - подходит или нет
     */
    private _checkActiveStatus(program: IProgram): boolean {
        const actualTerm = MortgageDataController.getAccessStorage(EStartValues.TERM);
        const percent = MortgageDataController.getAccessStorage('creditPercent');

        return program.payment && program.payment !== 0 && actualTerm <= program.mortgageTerm &&
            actualTerm >= program.mortgageTermMin && percent >= program.paymentMin && percent <= program.paymentMax;
    }
}

export default MortgageCalculator;
