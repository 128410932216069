<template>
    <div
        :class="['checkbox', `checkbox_type_${type}`, modify]"
        @click="_changeState">
        <input
            ref="input"
            :class="['checkbox__input', inputClass]"
            :id="`${type}-${name + value}`"
            :type="type"
            :name="name"
            :value="value"
            :checked="state"
            :disabled="disabled"
        >
        <label class="checkbox__label" :for="`${type}-${name + value }`">
            <span
                v-if="icon && type === 'checkbox'"
                :class="['checkbox__icon', `checkbox__icon_type_${type}`]"
            >
                <CheckboxIcon />
            </span>

            <span
                v-if="icon && type === 'radio'"
                :class="['checkbox__icon', `checkbox__icon_type_${type}`]"
            >
            </span>

            <span class="checkbox__text" v-html="text"></span>
            <span v-if="descr?.length" class="checkbox__descr" v-html="descr"></span>
        </label>
        <div
            v-if="validate"
            class="checkbox__error"
        >
            <div
                class="checkbox__error-text"
                data-validate-error="validate_name"></div>
        </div>
    </div>

</template>

<script>
import CheckboxIcon from '../../icons/CheckboxIcon';

export default {
    name: 'CheckboxComponent',

    components: {
        CheckboxIcon
    },

    props: {
        modify: {
            default: '',
            type   : String
        },
        inputClass: {
            default: '',
            type   : String
        },
        type: {
            default: 'checkbox',
            type   : String
        },
        name: {
            default: 'checkbox',
            type   : String
        },
        value: {
            default: '',
            type   : String
        },
        checked: {
            default: true,
            type   : Boolean
        },
        disabled: {
            default: true,
            type   : Boolean
        },
        icon: {
            default: true,
            type   : Boolean
        },
        text: {
            default: '',
            type   : String
        },
        descr: {
            default: '',
            type   : String
        },
        validate: {
            default: true,
            type   : Boolean
        },
        dataStoreInstance: {
            default: null,
            type   : Object
        }
    },

    data() {
        return {
            state: this.checked
        };
    },

    methods: {
        _changeState() {
            if (this.type === 'checkbox') {
                this.state = !this.state;
            } else if (this.type === 'radio') {
                this.$refs.input.checked =
                    this.$refs.input.checked ? this.$refs.input.checked : !this.$refs.input.checked;
                this.state = this.$refs.input.checked;
            }

            this.$emit('checkboxChanged', this.state);

            if (this.dataStoreInstance) {
                this.dataStoreInstance.setData(this.name, {
                    value: this.value
                });
            }
        }
    }
};
</script>

<style lang="scss">
@import 'CheckboxComponent';
</style>
