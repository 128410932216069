<template>
    <div v-if="newsData" class="management-news">
        <ActionsListComponent
            v-if="newsData?.list?.length"
            :list="newsData.list"
            @showAction="showActionHandler" />

        <ButtonComponent
            v-if="newsData?.more?.more"
            modify="button_theme_white actions__more"
            :text="isProcessing ? 'Загрузка...' : 'Показать ещё'"
            @click="_getRequest('more');" />
    </div>
</template>

<script>
import ActionsListComponent from '@/components/Actions/ActionsListComponent/ActionsListComponent';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import ManagementDataController from '@/dataControllers/management';

const ManagementController = new ManagementDataController();

export default {
    name: 'ManagementNewsPage',

    components: {
        ActionsListComponent,
        ButtonComponent
    },

    data() {
        return {
            isProcessing: false,
            isEmpty     : false,
            newsData    : null,
            popupData   : null
        };
    },

    mounted() {
        this._getRequest('init');
    },

    beforeRouteLeave(to, from, next) {
        this.$emit('errorShow', false);

        next();
    },

    methods: {
        _getRequest(type, id = null) {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            ManagementController.getNews({
                type,
                id,
                page: type === 'init' ? 1 : this.newsData?.more?.page + 1 || null
            })
                .then((response) => {
                    if (response.success) {
                        this.newsData = response;
                        this.isEmpty = this.newsData.noResults;
                    } else {
                        this.$emit('errorShow', true, {
                            statusCode: response.errorData?.status,
                            url       : response.errorData?.url
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.$emit('errorShow', true, {
                        statusCode: 500,
                        url       : 'Неизвестная ошибка на странице Новости в разделе УК'
                    });
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                });
        },

        _getRequestContent(id = null) {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            ManagementController.getNewsContent(id)
                .then((response) => {
                    this.popupData = response;

                    this.popupData.modify = 'popup_theme_news';

                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                    this.$root.$emit('showPopup', 'actions', this.popupData);
                });
        },

        /**
         * Открываем попап акции
         * @param {string} id - айди статьи
         */
        showActionHandler(id) {
            this.popupData = null;
            this._getRequestContent(id);
        }
    }

};
</script>

<style lang="scss">
@import './ManagementNewsPage';
</style>
