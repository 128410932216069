<template>
    <div class="auth__form">
        <a
            href="https://berezka.multi.ksdev.ru/"
            class="auth__form-logo"
        >
            <img src="/webicons/logo/logo_dark.svg" alt="ЛК" />
        </a>

        <div class="auth__form-form">
            <transition name="fade">
                <PreloaderComponent v-if="isProcessing" modify="preloader_theme_small preloader_theme_opacity" />
            </transition>
            <div
                v-if="auth"
                class="auth__form-auth"
            >
                <div class="auth__form-step" v-show="step === 0">
                    <div class="auth__form-title">Единый личный кабинет</div>
                    <div class="auth__form-caption">
                        Для входа в&nbsp;Личный кабинет укажите, пожалуйста, Ваш номер телефона.
                    </div>

                    <InputComponent
                        ref="smsphone"
                        name="smsphone"
                        label="Ваш номер телефона"
                        mask="+7 (###) ###-##-##"
                        :validation="{
                            type: 'phone',
                            msg: 'Введите корректный номер телефона'
                        }"
                        :data-store-instance="AuthStore"
                        :parent-error="parentError"
                        @inputChanged="_inputChanged"
                    />

                    <ButtonComponent
                        modify="button_theme_big"
                        :disabled="!agreementChecked || isProcessing || isInputError"
                        text="Получить SMS-код"
                        @click="_getSMS" />

                    <AgreementComponent :checked="agreementChecked" @agreementChanged="_agreementChanged" />
                </div>

                <div class="auth__form-step" v-show="step === 1">
                    <div class="auth__form-title">Единый личный кабинет</div>
                    <div class="auth__form-caption">
                        Код отправлен на&nbsp;номер {{ phoneNum }}

                        <ButtonComponent
                            text="Изменить номер"
                            modify="button_theme_flat form__change-num"
                            @click="_goToStart" />
                    </div>
                    <InputComponent
                        ref="smscode"
                        name="smscode"
                        label="Введите код из смс"
                        maxlength="6"
                        :validation="{
                            type: 'numberLength',
                            length: 6,
                            msg: 'Код должен состоять из 6 цифр'
                        }"
                        :data-store-instance="AuthStore"
                        :parent-error="parentError"
                        @inputChanged="_inputChanged"
                    />

                    <ButtonComponent
                        modify="button_theme_big"
                        text="Далее"
                        :disabled="isProcessing || isInputError"
                        @click="_checkCode" />

                    <ButtonComponent
                        modify="button_theme_big button_theme_gray button__resend-sms"
                        :disabled="resendDisabled"
                        :text="resendText"
                        @click="_resendCode"/>
                </div>
            </div>

            <div
                v-else-if="registration"
                class="auth__form-step auth__form-registration"
            >
                <div class="auth__form-title">Регистрация</div>
                <div class="auth__form-caption">Введите данные для регистрации</div>

                <InputComponent
                    ref="surname"
                    name="surname"
                    label="Фамилия"
                    type="text"
                    :validation="{
                        type: 'letters',
                        msg: 'Введите только буквы'
                    }"
                    :data-store-instance="AuthStore"
                    :parent-error="parentError"
                    @inputChanged="_inputChanged"
                />

                <InputComponent
                    ref="name"
                    name="name"
                    label="Имя"
                    type="text"
                    :validation="{
                        type: 'letters',
                        msg: 'Введите только буквы'
                    }"
                    :data-store-instance="AuthStore"
                    :parent-error="parentError"
                    @inputChanged="_inputChanged"
                />

                <InputComponent
                    name="phone"
                    label="Ваш номер телефона"
                    disabled="disabled"
                    :value="phoneNum"
                />

                <ButtonComponent
                    text="Изменить номер"
                    modify="button_theme_flat form__change-num"
                    @click="_goToStart" />

                <InputComponent
                    name="email"
                    label="Email"
                    type="text"
                    :validation="{
                        type: 'email',
                        msg: 'Введите корректный email адрес'
                    }"
                    :data-store-instance="AuthStore"
                    :parent-error="parentError"
                    @inputChanged="_inputChanged"
                />

                <ButtonComponent
                    modify="button_theme_big"
                    text="Зарегистрироваться"
                    :disabled="isProcessing || !isRegistrationOpen"
                    @click="_getRegistration" />
            </div>

            <div v-show="showError" class="auth__form-error">
                <div class="form__error-title">
                    <ErrorIcon />
                    <h3>Ошибка</h3>
                </div>
                <div class="form__error-caption">
                    <p>К сожалению, произошла техническая ошибка.
                        Попробуйте еще раз позже или перезагрузите страницу.</p>
                </div>

                <ButtonComponent
                    modify="button_theme_big button_theme_gray"
                    text="Вернуться назад"
                    @click="_closeError" />
            </div>
        </div>

        <div class="auth__form-footer">
            <ButtonComponent
                @click="clickHandler"
                modify="button_theme_gray button_theme_big"
                text="Для чего вам личный кабинет?" />

<!--            <template v-for="(project, index) in projects">-->
<!--                &lt;!&ndash; v-if хак для того чтобы не ругалось на key &ndash;&gt;-->
<!--                <a-->
<!--                    v-if="true"-->
<!--                    :key="index"-->
<!--                    :href="project.href"-->
<!--                    class="auth__form-developer"-->
<!--                >-->
<!--                    <img :src="project.logo" :alt="project.name" />-->
<!--                </a>-->
<!--            </template>-->
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import AgreementComponent from '../AgreementComponent/AgreementComponent';
import AuthDataController from '../../dataControllers/auth/index';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import ErrorIcon from '../../icons/ErrorIcon';
import InputComponent from '../InputComponent/InputComponent';
import PreloaderComponent from '../PreloaderComponent/PreloaderComponent';
import {Utils} from '@/scripts/utils';

const authDataController = new AuthDataController();

export default {
    name: 'AuthFormComponent',

    components: {
        AgreementComponent,
        ButtonComponent,
        ErrorIcon,
        InputComponent,
        PreloaderComponent
    },

    props: ['projects', 'returnPath'],

    data() {
        return {
            agreementChecked  : true,
            auth              : true,
            registration      : false,
            showError         : false,
            step              : 0,
            phoneNum          : '',
            resendDisabled    : true,
            resendText        : null,
            resendInterval    : null,
            parentError       : null,
            isProcessing      : false,
            // ставим в true, чтобы при ините формы на первом шаге нельзя было
            // сразу нажать кнопку отправки кода
            isInputError      : true,
            isRegistrationOpen: false
        };
    },

    computed: {
        ...mapGetters({
            authTimer: 'GET_AUTH_TIMER',
            isMobile : 'GET_IS_MOBILE'
        }),
        AuthStore() {
            return authDataController.authDataStore;
        }
    },

    watch: {
        /**
         * Следим за изменением шага - чтобы понимать когда запускать / обнулять
         * таймер повторной отправки кода
         */
        step() {
            if (this.auth && this.step === 1) {
                this._startTimer();
            } else {
                this._clearTimer();
            }
        }
    },

    created() {
        this.resendText = authDataController.clearTimer();
    },

    methods: {
        ...mapActions(['getMenu']),

        /**
         * Отправляем запрос на получение смс кода
         */
        async _getSMS() {
            this.isProcessing = true;
            this.parentError = null;
            this.step = 0;

            const smsResult = await authDataController.getSMS('smsphone', this.agreementChecked);

            if (smsResult.error) {
                if (smsResult.parentError?.length) {
                    this.parentError = smsResult.parentError;
                } else {
                    this.showError = true;
                }
            }

            if (this.step !== smsResult.step) {
                this.step = smsResult.step;
                this.phoneNum = smsResult.phoneNum;
            }

            this.isProcessing = false;
            this.isInputError = true;
        },

        /**
         * Проверяем смс код
         */
        async _checkCode() {
            this.isProcessing = true;
            this.parentError = null;

            const checkResult = await authDataController.checkCode('smscode');

            // Если ответ пришел без ошибки - проверяем зарегистрирован
            // пользователь или нет. В случае когда пришла ошибка проверяем есть
            // ли текст или показывать общую
            if (checkResult.success === true) {
                if (checkResult.auth) {
                    Utils.setCookie('glavstroy-lk-auth', 1);

                    if (this.returnPath) {
                        window.location.href = this.returnPath;
                    } else {
                        this.$router.push({name: 'content'}).catch(() => {
                            return true;
                        });
                    }
                } else {
                    this.auth = false;
                    this.registration = true;
                }
            } else if (checkResult.errorText.length) {
                this.parentError = checkResult.errorText;
            } else {
                this.showError = true;
            }

            this.isProcessing = false;
        },

        /**
         * Запускаем интервал таймера
         */
        _startTimer() {
            this.resendInterval = setInterval(this._setTime, 1000);
            authDataController.initTimer();
            this.resendText = authDataController.getTimerText();
        },

        /**
         * Очищаем интервал таймера
         */
        _clearTimer() {
            clearInterval(this.resendInterval, 1000);
            authDataController.clearTimer();
        },

        /**
         * Таймер обратного отсчета в кнопке повторной отправки смс
         */
        _setTime() {
            this.resendText = authDataController.getTimerText();

            if (!authDataController.getResendDisabledState()) {
                this.resendDisabled = false;
                this._clearTimer();
            }
        },

        /**
         * Повторная отправка кода
         */
        async _resendCode() {
            if (this.$refs.smscode) {
                this.$refs.smscode.inputField = '';
                this.$refs.smscode.focused = false;
            }

            if (this.AuthStore?.dataStore?.smscode) {
                this.AuthStore.dataStore.smscode.value = '';
            }

            const resentResult = await authDataController.resendCode();


            if (resentResult.error) {
                this.showError = true;
            } else {
                this.resendDisabled = true;
                this._startTimer();
            }
        },

        async _getRegistration() {
            this.isProcessing = true;
            const registrationResult = await authDataController.getRegistration(['surname', 'name', 'email']);

            if (registrationResult) {
                if (this.returnPath) {
                    window.location.href = this.returnPath;
                } else {
                    this.$router.push('/content/compare').catch(() => {
                        return true;
                    });
                }
            } else {
                this.showError = true;
            }

            this.isProcessing = false;
        },

        /**
         * Возвращаемся в начало для изменения номера
         */
        _goToStart() {
            this.AuthStore.setPhone('');

            this.phoneNum = '';

            if (this.$refs.smsphone) {
                this.$refs.smsphone.inputField = null;
                this.$refs.smsphone.focused = false;
            }

            this.auth = true;
            this.registration = false;
            this.step = authDataController.resetStep();
            this.resendText = authDataController.clearTimer();
        },

        _closeError() {
            this.showError = false;
        },

        _agreementChanged(state) {
            this.agreementChecked = state;
        },

        _inputChanged(inputScope) {
            if (this.registration) {
                this.isRegistrationOpen = authDataController.isRegistrationPossible(['name', 'surname', 'email']);
            } else {
                this.isInputError = inputScope.error;
                this.parentError = null;
            }
        },

        clickHandler() {
            this.$emit('openSlider');
        }
    }
};
</script>

<style lang="scss">
    @import 'AuthFormComponent';
</style>
