<template>
    <div
        :class="['compare-spec', {'is-hide': showDifference && noDifference}]" >
        <div v-if="spec && spec.name" class="compare-spec__name"><span>{{ spec.name }}</span></div>
        <div class="compare-spec__content">
            <div
                v-for="(value, index) in spec.values"
                :key="`${spec.name}-${index}`"
                :class="[
                    'compare-spec__content-item',
                    {'is-false': value === false || !value}
                ]"
            >
                <span v-if="value === true"><CompareTrueIcon /> Есть</span>
                <span v-else-if="value === false || !value"><CompareFalseIcon /> Нет</span>
                <span v-else>{{ value }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import CompareFalseIcon from '../../../icons/CompareFalseIcon';
import CompareTrueIcon from '../../../icons/CompareTrueIcon';
export default {
    name: 'CompareMobileSpecComponent',

    props: ['spec', 'showDifference'],

    components: {
        CompareFalseIcon,
        CompareTrueIcon
    },

    computed: {
        noDifference() {
            const difference = [...new Set(this.spec.values)];

            return difference.length === 1;
        }
    }
};
</script>

<style lang="scss">
@import './CompareSpecsComponent';
</style>
