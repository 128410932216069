<template>
    <div v-if="tabsData?.length" :class="['tabs', {'tabs_theme_button': type === 'button'}, modify]">
        <div
                v-for="item in tabsData"
                :key="item.id"
                :class="['tabs__item', {'is-active': activeTab?.slug === item?.slug}]"
            >
                <button
                    :class="[
                        'button',
                        {'button_theme_flat button_theme_thin': type === 'flat'},
                        {'button_theme_thin button_theme_tab': type === 'button'}
                    ]"
                    @click="_changeTab(item.id)"
                >
                    <span class="button__text">{{ item.name }}</span>
                    <span class="button__count" v-if="item.count">{{ item.count }}</span>
                </button>
            </div>
    </div>
</template>

<script>
export default {
    name: 'TabsComponent',

    props: {
        modify: {
            default: '',
            type   : String
        },

        tabsData: {
            default: () => {
                return [];
            },
            type: Array
        },

        activeTab: {
            default: () => {
                return {};
            },
            type: Object
        },
        type: {
            default: 'flat',
            type   : String
        }
    },

    methods: {
        _changeTab(id) {
            this.$emit('changeTab', id);
        }
    }
};
</script>

<style lang="scss">
@import './TabsComponent';
</style>
