import type {
    IBookingData,
    IBookingDataStore,
    IBookingResponseResult
} from './types';

let bookingDataInstance: IBookingData | null = null;

export class BookingData implements IBookingData {
    public bookingDataStore: IBookingDataStore;

    constructor() {
        if (!bookingDataInstance) {
            // eslint-disable-next-line
            bookingDataInstance = this;
        }

        this.bookingDataStore = {
            list       : [],
            optionsKeys: {
                address    : 'Адрес',
                deadline   : 'Срок сдачи',
                queue      : 'Очередь',
                corpus     : 'Корпус',
                floor      : 'Этаж',
                num        : 'Номер',
                type       : 'Тип',
                finishing  : 'Отделка',
                paymentType: 'Способ оплаты'
            },
            steps: []
        };

        return bookingDataInstance;
    }

    setStoreData(response: IBookingResponseResult): void {
        this.bookingDataStore.list = response.data.booking;
        this.bookingDataStore.steps = response.data.steps;
    }

    getStoreData(): IBookingDataStore {
        return this.bookingDataStore;
    }
}
