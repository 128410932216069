<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.62614 4.2929C8.01666 3.90237 8.64983 3.90237 9.04036 4.29289L14.0404 9.29289C14.2279 9.48043 14.3333 9.73478 14.3333 10C14.3333 10.2652 14.2279 10.5196 14.0404 10.7071L9.04036 15.7071C8.64983 16.0976 8.01666 16.0976 7.62614 15.7071C7.23562 15.3166 7.23562 14.6834 7.62615 14.2929L11.9191 10L7.62615 5.70711C7.23562 5.31659 7.23562 4.68342 7.62614 4.2929Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'ArrowRightIcon',

    props: {
        fill: {
            default: '#8b96a3',
            type   : String
        }
    }
};
</script>
