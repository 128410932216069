var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup popup-inspect"},[_c('div',{staticClass:"popup__overlay",on:{"click":_vm._closeClick}}),_vm._v(" "),_c('div',{staticClass:"popup__content popup-inspect__content"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isFormProgress)?_c('PreloaderComponent',{attrs:{"modify":"preloader_theme_opacity"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"popup__content-top"},[_c('button',{staticClass:"button button_theme_thin button_theme_transparent popup__content-close",on:{"click":_vm._closeClick}},[_c('span',{staticClass:"button__icon"},[_c('CloseIcon')],1)])]),_vm._v(" "),_c('div',{class:[
                'popup__content-body',
                'popup-inspect__content-body',
                {'is-fixed': _vm.formResult && Object.keys(_vm.formResult)?.length}
            ]},[_c('h3',{staticClass:"popup-inspect__content-title"},[_vm._v("Запись на осмотр объекта")]),_vm._v(" "),_c('div',{staticClass:"popup-inspect__steps"},[_c('span',[_vm._v("Шаг "+_vm._s(_vm.nowStep)+" из 3")])]),_vm._v(" "),_c('p',{staticClass:"popup-inspect__step-title"},[_vm._v(_vm._s(_vm.stepTitle[_vm.nowStep - 1]))]),_vm._v(" "),_c('form',{staticClass:"popup-inspect__form",attrs:{"action":""}},[(_vm.nowStep === 1)?_c('div',{key:1,staticClass:"popup-inspect__step"},[_c('SelectComponent',{attrs:{"modify":"popup-inspect__step-contract","name":"contractNumber","list":_vm.contracts,"label":"Выберите объект из списка"},on:{"clickRadio":_vm._clickRadioHandler}})],1):_vm._e(),_vm._v(" "),(_vm.nowStep === 2)?_c('div',{key:2,staticClass:"popup-inspect__step"},[_c('CheckboxComponent',{attrs:{"name":"viewType","type":"radio","icon":true,"text":_vm.TYPE_PERSONAL,"value":_vm.TYPE_PERSONAL,"checked":_vm.getChecked('viewType', _vm.TYPE_PERSONAL),"data-store-instance":_vm.UserFormStore},on:{"checkboxChanged":function($event){return _vm._setChecked(_vm.TYPE_PERSONAL)}}}),_vm._v(" "),_c('CheckboxComponent',{attrs:{"name":"viewType","type":"radio","text":_vm.TYPE_WITHOUT,"value":_vm.TYPE_WITHOUT,"checked":_vm.getChecked('viewType', _vm.TYPE_WITHOUT),"data-store-instance":_vm.UserFormStore},on:{"checkboxChanged":function($event){return _vm._setChecked(_vm.TYPE_WITHOUT)}}})],1):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.nowStep === 3),expression:"nowStep === 3"}],key:3,staticClass:"popup-inspect__step popup-inspect__step-third"},[_c('DatePicker',{ref:"calendar",staticClass:"popup-inspect__date",attrs:{"available-dates":_vm.date},on:{"dayclick":_vm._onDayClick,"popoverDidShow":_vm._movePopover},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, togglePopover }){return [_c('button',{class:[
                                    'button',
                                    'button_theme_gray',
                                    'popup-inspect__date-selector',
                                    {'is-selected': _vm.dateSelected}
                                ],attrs:{"type":"button"},on:{"click":function($event){return _vm._changeDate($event, togglePopover)}}},[_c('span',{staticClass:"button__text"},[_vm._v("Дата")]),_vm._v(" "),(_vm.dateSelected)?_c('span',{staticClass:"button__date"},[_vm._v(_vm._s(inputValue))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"button__icon"},[_c('img',{attrs:{"src":"/webicons/owner/calendar.svg","alt":"Выберите дату"}})])])]}}]),model:{value:(_vm.myDate),callback:function ($$v) {_vm.myDate=$$v},expression:"myDate"}}),_vm._v(" "),_c('div',{ref:"timeList",staticClass:"popup-inspect__time"},[_c('button',{class:[
                                'button',
                                'button_theme_gray',
                                'popup-inspect__time-selector',
                                {'is-selected': _vm.timeSelected},
                                {'is-open': _vm.showTimeList}],attrs:{"type":"button","disabled":!_vm.dateSelected},on:{"click":function($event){_vm.showTimeList = !_vm.showTimeList}}},[_c('span',{staticClass:"button__text"},[_vm._v("Время")]),_vm._v(" "),(_vm.timeSelected)?_c('span',{staticClass:"button__time"},[_vm._v(_vm._s(_vm.timeValue))]):_vm._e(),_vm._v(" "),_vm._m(0)]),_vm._v(" "),(_vm.availableTimes?.length && _vm.showTimeList)?_c('div',{staticClass:"popup-inspect__time-list"},_vm._l((_vm.availableTimes),function(time){return _c('button',{key:time,class:[
                                    'button',
                                    'button_theme_white',
                                    'button_theme_thin',
                                    'popup-inspect__time-item',
                                    {'is-selected': time === _vm.timeValue}],attrs:{"text":time},on:{"click":function($event){return _vm._setClickedTime(time)}}},[_c('span',{staticClass:"button__text"},[_vm._v(_vm._s(time))])])}),0):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"popup-inspect__form-buttons"},[_c('ButtonComponent',{attrs:{"text":"Назад","disabled":_vm.nowStep === 1},on:{"click":function($event){return _vm._clickHandler('prev')}}}),_vm._v(" "),(_vm.nowStep < 3)?_c('ButtonComponent',{attrs:{"disabled":_vm.isDisabled || _vm.isFormProgress,"text":"Далее"},on:{"click":function($event){return _vm._clickHandler('next')}}}):_c('ButtonComponent',{attrs:{"text":"Записаться","disabled":!_vm.dateSelected || !_vm.timeSelected},on:{"click":function($event){return _vm._orderComplete()}}})],1)]),_vm._v(" "),(_vm.formResult && Object.keys(_vm.formResult)?.length)?_c('FormResultComponent',{attrs:{"success":_vm.formResult.success,"title":_vm.formResult.title,"text":_vm.formResult.text,"requestNote":_vm.formResult.requestNote},on:{"closeResult":function($event){_vm.formResult = {}},"closePopup":_vm._closeClick}}):_vm._e()],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button__icon"},[_c('img',{attrs:{"src":"/webicons/owner/clock.svg","alt":"Выберите время"}})])
}]

export { render, staticRenderFns }