import type {AxiosError, AxiosResponse} from 'axios';
import type {
    ICompareDataController,
    ICompareRequestOptions,
    ICompareResult,
    IResponseCompareRequest,
    ISpecs
} from './types';
import axios from 'axios';
import {CompareData} from './compareData';
import {ERequests} from '../../requests';
import {Utils} from '../../scripts/utils';

const CompareDataStore = new CompareData();

export default class CompareDataController implements ICompareDataController {
    /**
     * Получаем список сравнения
     * @param {object} options - объект параметров запроса
     * @returns {object} - объект с данными квартир
     */
    public async getCompare(options: ICompareRequestOptions): Promise<ICompareResult> {
        const data = {
            type  : options.type,
            id    : options.id,
            object: options.object
        };

        const result: ICompareResult = {
            flats       : [],
            commerce    : [],
            descriptions: null,
            success     : true
        };

        const getData = () => {
            // Получаем результат из временного стора - реализованно так,
            // чтобы использовать одну функцию на разные сценарии получения
            // записей - инициализация / показать еще / очистить
            const storeData = CompareDataStore.getDataStore();

            const favoritesRemovedFromCompare = JSON.parse(localStorage.getItem('favoritesRemoved') || '[]');
            const compareRemoved = JSON.parse(localStorage.getItem('compareRemoved') || '[]');

            if (compareRemoved?.length) {
                result.flats = storeData.flats.filter((item: {id: string}) => {
                    return !compareRemoved.includes(item.id);
                });
                result.commerce = storeData.commerce.filter((item: {id: string}) => {
                    return !compareRemoved.includes(item.id);
                });
            } else {
                result.flats = storeData.flats;
                result.commerce = storeData.commerce;
            }

            result.flats.forEach((flat: {id: string, inFavorite: boolean}) => {
                flat.inFavorite = !favoritesRemovedFromCompare.includes(flat.id);
            });

            result.commerce.forEach((flat: {id: string, inFavorite: boolean}) => {
                flat.inFavorite = !favoritesRemovedFromCompare.includes(flat.id);
            });

            result.descriptions = storeData.descriptions;

            if (!storeData.activeType) {
                if (result.flats.length) {
                    this.setActiveType('flats');
                } else {
                    this.setActiveType('commerce');
                }
            }
        };

        await axios.post(ERequests.compare, data)
            .then((response: AxiosResponse<IResponseCompareRequest>) => {
                switch (data.type) {
                    case 'remove':
                        const compareRemoved = JSON.parse(localStorage.getItem('compareRemoved') || '[]');

                        if (!compareRemoved.includes(data.id)) {
                            compareRemoved.push(data.id);

                            response.data.data.flats = response.data.data.flats.filter((flat: {id: string}) => {
                                return !compareRemoved.includes(flat.id);
                            });

                            response.data.data.commerce = response.data.data.commerce.filter((flat: {id: string}) => {
                                return !compareRemoved.includes(flat.id);
                            });

                            localStorage.setItem('compareRemoved', JSON.stringify(compareRemoved));
                        }

                        break;
                }

                // Записываем результат во временный стор
                CompareDataStore.setRequestResponse(response.data);

                if (data.type === 'clear') {
                    localStorage.removeItem('favoritesRemoved');
                    localStorage.removeItem('compareRemoved');
                }

                getData();
            })
            .catch((error: AxiosError) => {
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }

    public setActiveType(type: string): void {
        if (!type) {
            return;
        }

        CompareDataStore.dataStore.activeType = type;

        const storeData = CompareDataStore.getDataStore();

        if (storeData[type]?.length) {
            this.setSlidesLength(storeData[type].length);
            this.calcActiveSlides();
        }
    }

    public clearData(): void {
        CompareDataStore.clearDataStore();
    }

    /**
     * Рассчет активных слайдов
     * @param {number} item - активный основной слайд, относительно которого
     * рассчитываем - по умолчанию = 0
     * @returns {array} - массив активных слайдов
     */
    public calcActiveSlides(item: number = 0): number[] {
        const dataStore = CompareDataStore.getDataStore();

        const startActiveSlides = [];

        startActiveSlides.push(item);

        if (dataStore[dataStore.activeType].length > item + 1) {
            startActiveSlides.push(item + 1);
        }

        if (Utils.getWindowWidth() >= 960) {
            if (dataStore[dataStore.activeType].length > item + 2) {
                startActiveSlides.push(item + 2);
            }
        }

        CompareDataStore.setDataStore(startActiveSlides, dataStore.activeMobileSlide);

        return startActiveSlides;
    }

    public setSlidesLength(length: number): void {
        CompareDataStore.setSlidesLength(length);
    }

    /**
     * Устанавливаем номер активных слайдов во временный стор
     * @param {array} activeSlide - массив с данными активных слайдов ПК
     * слайдера (он же левый слайдера мобильной версии)
     * @param {number} activeMobileSlide - номер активного слайда в мобильном слайдере (правый слайдер)
     */
    public setCompareData(activeSlide: number[], activeMobileSlide: number): void {
        CompareDataStore.setDataStore(activeSlide, activeMobileSlide);
    }

    /**
     * Формируем объект со свойствами активных помещений
     * @param {array} slides - массив с объектами помещений
     * @param {array} activeSlides - активные слайды (помещения)
     * @returns {object} - объект с характеристиками
     */
    public getSpecs(slides: object[], activeSlides: number[]): ISpecs {
        const storeData = CompareDataStore.getDataStore();

        const specsData: ISpecs = {
            common : [],
            feature: [],
            square : []
        };

        if (storeData.descriptions[storeData.activeType]['common']) {
            const commonKeys = Object.keys(storeData.descriptions[storeData.activeType]['common']);
            const commonValues = Object.values(storeData.descriptions[storeData.activeType]['common']) as string[];

            specsData.common = this._makeSpecObject(slides, activeSlides, commonKeys, commonValues);
        }

        if (storeData.descriptions[storeData.activeType]['feature']) {
            const featureKeys = Object.keys(storeData.descriptions[storeData.activeType]['feature']);
            const featureValues = Object.values(storeData.descriptions[storeData.activeType]['feature']) as string[];

            specsData.feature = this._makeSpecObject(slides, activeSlides, featureKeys, featureValues);
        }

        if (storeData.descriptions[storeData.activeType]['square']) {
            const squareKeys = Object.keys(storeData.descriptions[storeData.activeType]['square']);
            const squareValues = Object.values(storeData.descriptions[storeData.activeType]['square']) as string[];

            specsData.square = this._makeSpecObject(slides, activeSlides, squareKeys, squareValues);
        }

        return specsData;
    }

    /**
     * Формируем массив с объектаим содержащие ссылки на бронирование помещений
     * @param {array} slides - массив с объектами помещений
     * @param {array} activeSlides - активные слайды (помещения)
     * @returns {array} - массив с объектаим содержащие ссылки на бронирование
     * помещений
     */
    public getLinks(slides: object[], activeSlides: number[]): object[] {
        const orderLinks = [];

        activeSlides.forEach((slide: number) => {
            if (slides[slide]?.['guid']) {
                orderLinks.push({
                    id      : orderLinks.length,
                    href    : slides[slide]['guid'],
                    disabled: slides[slide]['isBooked']
                });
            }
        });

        return orderLinks;
    }

    /**
     * Получаем номер слайда для перехода в мобильном слайдере
     * @param {string} direction - направление пролистывания
     * @param {boolean} mobileSlider - какой слайдер крутится левый или правый
     * (правый отображается только на мобильных и имеет признак mobileSlider)
     * @returns {number} - номер слайда для показа
     */
    public calcSlideNum(direction: string, mobileSlider: boolean): number {
        const nowCompareData = CompareDataStore.getDataStore();

        let newSlideNum = 0;

        if (mobileSlider) {
            newSlideNum = this._checkSlideNum(nowCompareData.activeMobileSlide,
                nowCompareData.activeSlide[0],
                direction,
                nowCompareData.length);
        } else {
            newSlideNum = this._checkSlideNum(nowCompareData.activeSlide[0],
                nowCompareData.activeMobileSlide,
                direction,
                nowCompareData.length);
        }

        return newSlideNum;
    }

    public async switchFavorites(id: string | number, method: string): Promise<boolean> {
        // const URL = method === 'add' ? ERequests.favoritesAdd : ERequests.favoritesRemove;
        let result = false;


        const favoritesRemovedFromCompare = JSON.parse(localStorage.getItem('favoritesRemoved') || '[]');

        if (method === 'add') {
            const index = favoritesRemovedFromCompare.findIndex((favoriteId) => {
                return favoriteId === id;
            });

            if (index !== -1) {
                favoritesRemovedFromCompare.splice(index, 1);
            }

            localStorage.setItem('favoritesRemoved', JSON.stringify(favoritesRemovedFromCompare));

            result = true;
        } else {
            if (!favoritesRemovedFromCompare.includes(id)) {
                favoritesRemovedFromCompare.push(id);

                localStorage.setItem('favoritesRemoved', JSON.stringify(favoritesRemovedFromCompare));
            }

            result = true;
        }

        // await axios.post(URL, {id})
        //     .then((response: AxiosResponse) => {
        //         result = response.data.success;
        //     })
        //     .catch(() => {
        //         result = false;
        //     });

        return result;
    }

    /**
     * Высчитываем номер слайда для перехода в мобильной версии - чтобы не
     * возникло ситуации когда в двух слайдерах одинаковые помещения
     * @param {number} main - номер того слайда который крутим
     * @param {number} subMain - номер второго слайдера относительно которого
     * вычисляем
     * @param {string} direction - направление перелистывания
     * @param {number} length - количество помещений (слайдов)
     * @returns {number} - номер слайда для показа
     */
    private _checkSlideNum(main: number, subMain: number, direction: string, length: number): number {
        let slideNum = 0;

        if (direction === 'next' && main + 1 === subMain) {
            slideNum = main + 2 === length ? main : main + 2;
        } else if (direction === 'next' && main + 1 !== subMain) {
            slideNum = main + 1 === length ? main : main + 1;
        } else if (direction === 'prev' && main - 1 === subMain) {
            slideNum = main - 2 < 0 ? main : main - 2;
        } else if (direction === 'prev' && main - 1 !== subMain) {
            slideNum = main - 1 < 0 ? main : main - 1;
        }

        return slideNum;
    }

    /**
     * Подготавливаем для рендера свойства отображаемых помещений
     * @param {array} slides - массив с объектами данных слайдов
     * @param {array} activeSlides - массив активных элементов
     * @param {array} specs - массив ключей свойств
     * @param {array} values - массив значений свойств
     * @returns {array} - массив с готовыми объектами свойств
     */
    private _makeSpecObject(slides: object[], activeSlides: number[], specs: string[], values: string[]): object[] {
        if (!specs || !Array.isArray(specs) || !activeSlides || !Array.isArray(activeSlides)) {
            return [];
        }

        const resultArray = [];

        specs.forEach((spec: string, index: number) => {
            const specObject = {
                name  : '',
                values: []
            };

            specObject.name = values[index];

            activeSlides.forEach((slide: number) => {
                if (slides[slide]) {
                    specObject.values.push(slides[slide][spec]);
                }
            });

            resultArray.push(specObject);
        });

        return resultArray;
    }
}
