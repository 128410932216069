var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.compareData?.success)?_c('div',{class:[
        'compare',
        {'is-empty': _vm.activeTab && !_vm.compareData[_vm.activeTab.slug].length}
    ]},[_c('div',{staticClass:"compare__wrapper"},[(_vm.compareData?.flats?.length || _vm.compareData?.commerce?.length)?_c('div',{staticClass:"compare__tabs"},[_c('TabsComponent',{attrs:{"modify":"tabs_theme_compare","tabsData":_vm.tabsData,"activeTab":_vm.activeTab},on:{"changeTab":_vm._changeActiveTab}})],1):_vm._e(),_vm._v(" "),(_vm.compareData?.flats?.length || _vm.compareData?.commerce?.length)?_c('div',{staticClass:"compare__manage"},[_c('div',{class:[
                    'compare-difference',
                    {'is-disabled': _vm.activeTab && _vm.compareData[_vm.activeTab.slug].length < 2}
                ],on:{"click":_vm._getDifference}},[_c('div',{class:['compare-difference__switcher', {'is-active': _vm.showDifference}]},[_c('div',{staticClass:"compare-difference__switcher-point"})]),_vm._v(" "),_c('button',{staticClass:"button button_theme_thin button_theme_flat compare-difference__switcher-button",attrs:{"disabled":_vm.activeTab && _vm.compareData[_vm.activeTab.slug].length < 2}},[_c('span',{staticClass:"button__text"},[_vm._v("Только различия")])])]),_vm._v(" "),_c('button',{staticClass:"button button_theme_thin button_theme_flat compare-button__clear",on:{"click":function($event){_vm.clearShow = true}}},[(_vm.isMobile)?_c('TrashIcon'):_vm._e(),_vm._v(" "),(!_vm.isMobile)?_c('span',{staticClass:"button__text"},[_vm._v("Очистить список")]):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.activeTab && _vm.compareData[_vm.activeTab.slug].length)?_c('div',{staticClass:"compare__content"},[(_vm.activeTab && _vm.compareData[_vm.activeTab.slug].length)?_c('CompareComponent',{attrs:{"slides":_vm.compareData[_vm.activeTab.slug],"showDifference":_vm.showDifference,"activeTab":_vm.activeTab.slug,"activeSection":_vm.activeSection},on:{"removeItem":_vm._removeItem,"processing":_vm._processingHandler}}):_vm._e()],1):_c('CompareEmptyComponent')],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.clearShow)?_c('ClearComponent',{attrs:{"title":"Очистить сравнение","description":"Вы уверены, что хотите полностью очистить список сравнения?"},on:{"closeClearPopup":function($event){_vm.clearShow = false},"makeClear":_vm._clearCompare}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }