<template>
    <section v-if="Object.keys(content)?.length" class="estate-sell__contacts">
        <div class="estate-sell__contacts-title">
            <h4 v-if="title" v-html="title"></h4>
            <div v-if="text" v-html="text"></div>
        </div>

        <div class="estate-sell__contacts-content">
            <div v-if="content[0]?.title" class="estate-sell__contacts-line">
                <div class="estate-sell__contacts-icon">
                    <PhoneIcon fill="#ffffff" />
                </div>
                <div class="estate-sell__contacts-text">
                    {{ content[0]?.title }}
                    <span v-if="content[0]?.text" v-html="content[0].text"></span>
                </div>
            </div>

            <div v-if="content[1]?.title" class="estate-sell__contacts-line">
                <div class="estate-sell__contacts-icon">
                    <PinIcon fill="#ffffff" />
                </div>

                <div class="estate-sell__contacts-text">
                    {{ content[1]?.title }}
                    <span v-if="content[1]?.text" v-html="content[1].text"></span>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
import PhoneIcon from '@/icons/PhoneIcon';
import PinIcon from '@/icons/PinIcon';

export default {
    name: 'EstateSellContacts',

    components: {
        PhoneIcon,
        PinIcon
    },

    props: {
        content: {
            default: () => {
                return [];
            },
            type: Array
        },
        title: {
            default: 'Контакты',
            type   : String
        },
        text: {
            default: null,
            type   : String
        }
    }
};
</script>

<style lang="scss">
@import './EstateSellContactsSection';
</style>
