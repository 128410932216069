import type {AxiosError, AxiosResponse} from 'axios';
import type {
    IAuthData,
    ICodeResult,
    IItemObject,
    IResponseCheckCodeData,
    IResponseGetCodeData
} from './types';
import {AuthData} from './authData';
import axios from 'axios';
import {ERequests} from '../../requests';
import {Utils} from '../../scripts/utils';

const authData = new AuthData();

export default class AuthDataController {
    private step: number;
    private phoneNum: string | null;
    private resendTimer: number;
    private resendDisabled: boolean;
    private resendText: string;
    private minutes: string;
    private seconds: string;
    private error: boolean;

    constructor() {
        this.step = 0;
        this.phoneNum = null;
        this.resendTimer = 0;
        this.resendDisabled = false;
        this.resendText = '';
        this.minutes = '00';
        this.seconds = '00';
        this.error = false;
    }

    public get authDataStore(): IAuthData {
        return authData;
    }

    /**
     * Отправляем запрос на получение смс кода
     * @param {string} inputName - имя поля с введеным номером телефона
     * @param {boolean} agreementState - состояние чекбокса согласия с политикой
     * @returns {number} - возвращает номер шага
     */
    public async getSMS(inputName: string, agreementState: boolean): Promise<object> {
        const inputData = authData.getData(inputName) as IItemObject;

        this.step = 0;

        if (!inputData || !agreementState || inputData?.value?.length === 0) {
            if (!inputData) {
                authData.setData(inputName, {
                    validation: {
                        type  : '',
                        length: '',
                        msg   : ''
                    },
                    value: '',
                    error: true
                });
            }

            return {step: this.step};
        }

        authData.setPhone(inputData.value);
        this.phoneNum = inputData.value;

        const resultGetCode = await this._getCode();

        if (resultGetCode.success) {
            authData.initTimer(this.resendTimer);

            this.step++;
        } else {
            this.error = true;
        }

        return {
            step       : this.step,
            phoneNum   : this.phoneNum,
            error      : this.error,
            parentError: resultGetCode.errorText
        };
    }

    /**
     * Повторный запрос смс-кода
     * @returns {object} - результат запроса
     */
    public async resendCode(): Promise<object> {
        const resultGetCode = await this._getCode();

        resultGetCode ? authData.initTimer(this.resendTimer) : this.error = true;

        return {
            phoneNum: this.phoneNum,
            error   : this.error
        };
    }

    /**
     * Проверяем смс код
     * @param {string} inputName - имя поля
     * @returns {boolean} - возвращаем истину или ложь
     */
    public async checkCode(inputName: string): Promise<ICodeResult> {
        const inputData = authData.getData(inputName) as IItemObject;

        if (!inputData || inputData?.error || inputData?.value?.length === 0) {
            // Если есть ошибка в инпуте то оставляем ее, если нет, то
            // проверяем длину
            if (inputData) {
                inputData.error = inputData.error === true ?
                    inputData.error :
                    inputData.value?.length === 0;
            }

            return {
                success  : false,
                auth     : false,
                errorText: ''
            };
        }

        const data = {
            phone: this.phoneNum,
            code : inputData.value
        };

        let result = {
            success  : false,
            auth     : false,
            errorText: ''
        };

        await axios.post(ERequests.authCheck, data)
            .then((response: AxiosResponse<IResponseCheckCodeData>) => {
                result = {
                    success  : true,
                    auth     : response.data.data.auth,
                    errorText: ''
                };
            })
            .catch((error: AxiosError) => {
                const errorText = error.response?.data?.errors?.code || '';

                result = {
                    success: false,
                    auth   : false,
                    errorText
                };
            });

        return {
            success  : result.success,
            auth     : result.auth,
            errorText: result.errorText
        };
    }

    /**
     * Регистрация пользователя
     * @param {array} - массив идентификаторов полей формы регистрации
     * @returns {boolean} - успешную / неуспешную проверку полей
     */
    public async getRegistration([...inputNames]: string): Promise<boolean> {
        const inputData = {};

        // Получаем все переданные инпуты и проверяем на ошибки
        inputNames.forEach((input: string) => {
            const currentInput = authData.getData(input) as IItemObject;

            if (currentInput && !currentInput?.error && currentInput?.value?.length > 0) {
                inputData[input] = currentInput;
            }
        });

        if (!Object.keys(inputData).length) {
            return false;
        }

        const registerResult = await this._registerRequest(inputData);

        return registerResult;
    }

    public logout(): boolean {
        let logoutResult = true;

        // await axios.post(ERequests.logout)
        //     .then(() => {
        //         logoutResult = true;
        //
        //         this.resetStep();
        //     })
        //     .catch(() => {
        //         logoutResult = false;
        //     });

        Utils.deleteCookie('glavstroy-lk-auth');

        logoutResult = true;

        this.resetStep();

        return logoutResult;
    }

    /**
     * Сброс формы на начало
     * @returns {number} - номер первого шага
     */
    public resetStep(): number {
        this.step = 0;

        return this.step;
    }

    /**
     * Переназначение времени таймера
     */
    public initTimer(): void {
        this.resendTimer = authData.getTimerData();
        this.resendDisabled = true;
    }

    /**
     * Сбрасываем таймер
     * @returns {string} - возвращаем фразу обратного отсчета
     */
    public clearTimer(): string {
        this.resendTimer = authData.getTimerData();

        this._calcTime();

        this.resendText = `Отправить код повторно через ${this.minutes}:${this.seconds}`;

        this.resendDisabled = true;

        return this.resendText;
    }

    /**
     * получаем текст кнопки повторной отправки
     * @returns {string} - текст кнопки повторной отправки
     */
    public getTimerText(): string {
        this._setTime();

        return this.resendText;
    }

    /**
     * Получаем статус возможности повторной отправки смс кода
     * @returns {boolean} - истина или ложь
     */
    public getResendDisabledState(): boolean {
        return this.resendDisabled;
    }

    public isRegistrationPossible(inputs: string[]): boolean {
        if (!inputs.length) {
            return false;
        }

        let possible = true;

        inputs.forEach((input: string) => {
            if (!authData.dataStore[input] ||
                authData.dataStore[input]['error'] ||
                !authData.dataStore[input]['value'].length
            ) {
                possible = false;
            }
        });

        return possible;
    }

    /**
     * Вычитаем секунду из времени таймера и меняем текст для кнопки повторной
     * отправки смс кода
     */
    private _setTime(): void {
        --this.resendTimer;

        if (this.resendTimer > 0) {
            this._calcTime();
            this.resendText = `Отправить код повторно через ${this.minutes}:${this.seconds}`;
        } else {
            this.resendText = 'Отправить код повторно';
            this.resendDisabled = false;
        }
    }

    /**
     * Высчитываем секунды и минуты на основе оставшегося времени
     */
    private _calcTime(): void {
        this.seconds = this._formattingTime(this.resendTimer % 60);
        this.minutes = this._formattingTime(Math.floor(this.resendTimer / 60));
    }

    /**
     * Форматируем число секунд / минут
     * @param {number} num - число для форматирования
     * @return {string} - возвращает отформатированное число в виде строки
     */
    private _formattingTime(num: number): string {
        return String(num).length < 2 ? `0${num}` : `${num}`;
    }

    /**
     * Получаем смс код
     */
    private async _getCode(): Promise<ICodeResult> {
        const data = {
            phone: this.phoneNum.replace(/\D/g, '')
        };

        let result = {
            success  : false,
            errorText: 'Произошла ошибка'
        };

        await axios.post(ERequests.auth, data)
            .then((response: AxiosResponse<IResponseGetCodeData>) => {
                if (response.data.data.resend_timeout) {
                    this.resendTimer = response.data.data.resend_timeout;
                }

                result = {
                    success  : true,
                    errorText: ''
                };
            })
            .catch((error: AxiosError) => {
                const errorText = error.response?.data?.errors?.phone?.join('; ') || '';

                result = {
                    success: false,
                    errorText
                };
            });

        return {
            success  : result.success,
            auth     : false,
            errorText: result.errorText
        };
    }

    /**
     * Отправляем данные для регистрации на апи
     * @param {array} inputs - массив объектов с полями данных для регистрации
     * @returns {boolean} - возвращаем ответ от апи
     */
    private _registerRequest(inputs: object): boolean {
        let result = false;

        const data = {
            phone: this.phoneNum.replace(/\D/g, '')
        };

        Object.keys(inputs).forEach((input: string) => {
            data[input] = inputs[input].value;
        });

        result = true;

        // await axios.post(ERequests.register, data)
        //     .then(() => {
        //         result = true;
        //     })
        //     .catch(() => {
        //         result = false;
        //     });

        return result;
    }
}
