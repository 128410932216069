<template>
    <div class="form-result">
        <div class="form-result__content">
            <div class="form-result__content-icon">
                <img v-if="success" src="/webicons/form/success.svg" alt="Успешно">
                <ErrorIcon v-else />
            </div>
            <h3 class="form-result__content-title">
                {{ title }}
            </h3>
            <div v-if="service" class="form-result__content-service">
                <span>Вы заказали услугу</span>
                {{ service }}
            </div>
            <p
                class="form-result__content-text"
                v-html="text"></p>

            <div v-if="requestNote && Object.keys(requestNote)?.length" class="popup-result__note">
                <h5 class="popup-result__note-date">{{ requestNote.date }}</h5>
                <p v-if="requestNote.address" class="popup-result__note-address">
                    {{ requestNote.address }}
                </p>
                <p v-if="requestNote.note" class="popup-result__note-note">
                    {{ requestNote.note }}
                </p>
            </div>

            <ButtonComponent
                v-if="!success"
                modify="button_theme_big button_theme_gray"
                text="Вернуться назад"
                @click="_closeError" />

            <ButtonComponent
                v-else
                modify="button_theme_big"
                text="Хорошо"
                @click="_closePopup" />
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import ErrorIcon from '@/icons/ErrorIcon';

export default {
    name: 'FormResultComponent',

    components: {
        ButtonComponent,
        ErrorIcon
    },

    props: {
        success: {
            default: false,
            type   : Boolean
        },
        title: {
            default: null,
            type   : String
        },
        service: {
            default: null,
            type   : String
        },
        text: {
            default: null,
            type   : String
        },
        requestNote: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    methods: {
        _closeError() {
            this.$emit('closeResult');
        },

        _closePopup() {
            this.$emit('closePopup');
        }
    }
};
</script>

<style lang="scss">
@import './FormResultComponent';
</style>
