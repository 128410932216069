var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth__form"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"auth__form-form"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isProcessing)?_c('PreloaderComponent',{attrs:{"modify":"preloader_theme_small preloader_theme_opacity"}}):_vm._e()],1),_vm._v(" "),(_vm.auth)?_c('div',{staticClass:"auth__form-auth"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 0),expression:"step === 0"}],staticClass:"auth__form-step"},[_c('div',{staticClass:"auth__form-title"},[_vm._v("Единый личный кабинет")]),_vm._v(" "),_c('div',{staticClass:"auth__form-caption"},[_vm._v("\n                        Для входа в Личный кабинет укажите, пожалуйста, Ваш номер телефона.\n                    ")]),_vm._v(" "),_c('InputComponent',{ref:"smsphone",attrs:{"name":"smsphone","label":"Ваш номер телефона","mask":"+7 (###) ###-##-##","validation":{
                            type: 'phone',
                            msg: 'Введите корректный номер телефона'
                        },"data-store-instance":_vm.AuthStore,"parent-error":_vm.parentError},on:{"inputChanged":_vm._inputChanged}}),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"button_theme_big","disabled":!_vm.agreementChecked || _vm.isProcessing || _vm.isInputError,"text":"Получить SMS-код"},on:{"click":_vm._getSMS}}),_vm._v(" "),_c('AgreementComponent',{attrs:{"checked":_vm.agreementChecked},on:{"agreementChanged":_vm._agreementChanged}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 1),expression:"step === 1"}],staticClass:"auth__form-step"},[_c('div',{staticClass:"auth__form-title"},[_vm._v("Единый личный кабинет")]),_vm._v(" "),_c('div',{staticClass:"auth__form-caption"},[_vm._v("\n                        Код отправлен на номер "+_vm._s(_vm.phoneNum)+"\n\n                        "),_c('ButtonComponent',{attrs:{"text":"Изменить номер","modify":"button_theme_flat form__change-num"},on:{"click":_vm._goToStart}})],1),_vm._v(" "),_c('InputComponent',{ref:"smscode",attrs:{"name":"smscode","label":"Введите код из смс","maxlength":"6","validation":{
                            type: 'numberLength',
                            length: 6,
                            msg: 'Код должен состоять из 6 цифр'
                        },"data-store-instance":_vm.AuthStore,"parent-error":_vm.parentError},on:{"inputChanged":_vm._inputChanged}}),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"button_theme_big","text":"Далее","disabled":_vm.isProcessing || _vm.isInputError},on:{"click":_vm._checkCode}}),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"button_theme_big button_theme_gray button__resend-sms","disabled":_vm.resendDisabled,"text":_vm.resendText},on:{"click":_vm._resendCode}})],1)]):(_vm.registration)?_c('div',{staticClass:"auth__form-step auth__form-registration"},[_c('div',{staticClass:"auth__form-title"},[_vm._v("Регистрация")]),_vm._v(" "),_c('div',{staticClass:"auth__form-caption"},[_vm._v("Введите данные для регистрации")]),_vm._v(" "),_c('InputComponent',{ref:"surname",attrs:{"name":"surname","label":"Фамилия","type":"text","validation":{
                        type: 'letters',
                        msg: 'Введите только буквы'
                    },"data-store-instance":_vm.AuthStore,"parent-error":_vm.parentError},on:{"inputChanged":_vm._inputChanged}}),_vm._v(" "),_c('InputComponent',{ref:"name",attrs:{"name":"name","label":"Имя","type":"text","validation":{
                        type: 'letters',
                        msg: 'Введите только буквы'
                    },"data-store-instance":_vm.AuthStore,"parent-error":_vm.parentError},on:{"inputChanged":_vm._inputChanged}}),_vm._v(" "),_c('InputComponent',{attrs:{"name":"phone","label":"Ваш номер телефона","disabled":"disabled","value":_vm.phoneNum}}),_vm._v(" "),_c('ButtonComponent',{attrs:{"text":"Изменить номер","modify":"button_theme_flat form__change-num"},on:{"click":_vm._goToStart}}),_vm._v(" "),_c('InputComponent',{attrs:{"name":"email","label":"Email","type":"text","validation":{
                        type: 'email',
                        msg: 'Введите корректный email адрес'
                    },"data-store-instance":_vm.AuthStore,"parent-error":_vm.parentError},on:{"inputChanged":_vm._inputChanged}}),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"button_theme_big","text":"Зарегистрироваться","disabled":_vm.isProcessing || !_vm.isRegistrationOpen},on:{"click":_vm._getRegistration}})],1):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showError),expression:"showError"}],staticClass:"auth__form-error"},[_c('div',{staticClass:"form__error-title"},[_c('ErrorIcon'),_vm._v(" "),_c('h3',[_vm._v("Ошибка")])],1),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"button_theme_big button_theme_gray","text":"Вернуться назад"},on:{"click":_vm._closeError}})],1)],1),_vm._v(" "),_c('div',{staticClass:"auth__form-footer"},[_c('ButtonComponent',{attrs:{"modify":"button_theme_gray button_theme_big","text":"Для чего вам личный кабинет?"},on:{"click":_vm.clickHandler}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"auth__form-logo",attrs:{"href":"https://berezka.multi.ksdev.ru/"}},[_c('img',{attrs:{"src":"/webicons/logo/logo_dark.svg","alt":"ЛК"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__error-caption"},[_c('p',[_vm._v("К сожалению, произошла техническая ошибка.\n                        Попробуйте еще раз позже или перезагрузите страницу.")])])
}]

export { render, staticRenderFns }