<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00157 0.699997H6.99842C3.52476 0.699997 0.699997 3.52555 0.699997 7C0.699997 8.37812 1.14415 9.65545 1.89936 10.6926L1.11422 13.033L3.53578 12.2589C4.53197 12.9188 5.72031 13.3 7.00157 13.3C10.4752 13.3 13.3 10.4737 13.3 7C13.3 3.52633 10.4752 0.699997 7.00157 0.699997ZM10.6674 9.59639C10.5154 10.0256 9.91217 10.3815 9.43101 10.4855C9.10183 10.5556 8.67186 10.6115 7.22443 10.0114C5.37302 9.24437 4.18075 7.36303 4.08782 7.24097C3.99883 7.11891 3.3397 6.24478 3.3397 5.34073C3.3397 4.43668 3.79881 3.99647 3.98387 3.80747C4.13586 3.65233 4.38707 3.58146 4.62805 3.58146C4.70601 3.58146 4.7761 3.5854 4.8391 3.58855C5.02416 3.59642 5.11708 3.60745 5.23915 3.89961C5.39113 4.2658 5.76126 5.16985 5.80536 5.26277C5.85025 5.3557 5.89513 5.4817 5.83213 5.60376C5.77307 5.72976 5.7211 5.78567 5.62817 5.89277C5.53525 5.99987 5.44705 6.08177 5.35412 6.19675C5.26907 6.29676 5.173 6.40386 5.2801 6.58892C5.3872 6.77005 5.75732 7.37406 6.30227 7.85916C7.00551 8.48522 7.57566 8.68525 7.77962 8.7703C7.93161 8.8333 8.11273 8.81833 8.22377 8.70021C8.36473 8.54822 8.53877 8.29622 8.71596 8.04816C8.84196 7.87019 9.00103 7.84813 9.16798 7.91113C9.33808 7.9702 10.2382 8.41513 10.4233 8.50727C10.6083 8.6002 10.7304 8.6443 10.7753 8.72226C10.8194 8.80022 10.8194 9.16641 10.6674 9.59639Z" fill="#455569"/>
    </svg>

</template>

<script>
export default {
    name: 'WhatsappIcon',

    props: {
        fill: {
            default: '#455569',
            type   : String
        }
    }
};
</script>
