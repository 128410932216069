<template>
    <div v-if="content" class="management-services">
        <div v-if="tabs?.length && Object.keys(content)?.length" class="management-services__tabs">
            <TabsComponent
                type="button"
                :tabsData="tabs"
                :activeTab="activeTab"
                @changeTab="_changeActiveTab" />
        </div>

        <ManagementServicesList
            v-if="content"
            :list="content[activeTab['slug']]" />
    </div>
</template>

<script>
import ManagementDataController from '@/dataControllers/management';
import ManagementServicesList from '@/sections/Management/ManagementServicesList/ManagementServicesList';
import TabsComponent from '@/components/TabsComponent/TabsComponent';

const ManagementController = new ManagementDataController();

export default {
    name: 'ManagementInfoPage',

    components: {
        ManagementServicesList,
        TabsComponent
    },

    props: {
        type: {
            default: 'main',
            type   : String
        }
    },

    data() {
        return {
            content     : null,
            tabs        : [],
            activeTab   : null,
            isProcessing: true
        };
    },

    mounted() {
        this._getData();
    },

    watch: {
        type() {
            this._getData();
        }
    },

    beforeRouteLeave(to, from, next) {
        this.$emit('errorShow', false);

        next();
    },

    methods: {
        _getData() {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            ManagementController.getServices(this.type)
                .then((response) => {
                    if (response.success) {
                        this.content = response.services;
                        this.tabs = response.tabs;

                        this.activeTab = this.tabs?.length ?
                            this.tabs[0] :
                            null;
                    } else {
                        this.$emit('errorShow', true, {
                            statusCode: response.errorData?.status,
                            url       : response.errorData?.url
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.$emit('errorShow', true, {
                        statusCode: 500,
                        url       : 'Неизвестная ошибка на странице Услуг (или Услуг партнеров) в разделе УК'
                    });
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                });
        },

        _changeActiveTab(tabID) {
            this.activeTab = this.tabs.find((item) => {
                return item.id === tabID;
            });
        }
    }
};
</script>

<style lang="scss">
@import './ManagementServicesPage';
</style>
