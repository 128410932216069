<template>
    <div class="agency-commerce__list">
        <div class="agency-commerce__list-content">
            <AgencyCommerceCardComponent
                v-for="card in content.premises"
                :key="card.id"
                :cardContent="card" />
        </div>

        <ButtonComponent
            v-if="content?.more?.more"
            modify="button_theme_white agency-commerce__list-more"
            :text="content?.more?.text"
            @click="_getMoreData"
        />
    </div>
</template>

<script>
import AgencyCommerceCardComponent from '@/components/Agency/AgencyCommerceCardComponent/AgencyCommerceCardComponent';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';

export default {
    name: 'AgencyCommerceListSection',

    components: {
        AgencyCommerceCardComponent,
        ButtonComponent
    },

    props: {
        content: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    methods: {
        _getMoreData() {
            this.$emit('more');
        }
    }
};
</script>

<style lang="scss">
@import './AgencyCommerceListSection';
</style>
