<template>
    <div class="compare-actions">
        <ButtonComponent
            modify="button_theme_gray button_theme_circle-small compare-actions__button"
            icon="CloseIcon"
            fill="#8b96a3"
            @click="_removeItem" />

        <ButtonComponent
            modify="button_theme_gray button_theme_circle-small compare-actions__button"
            icon="HeartIcon"
            :fill="this.favoriteState ? '#e40220' : '#ffffff'"
            @click="_switchFavorites" />
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import CompareDataController from '@/dataControllers/compare/index';
import {mapActions} from 'vuex';

const CompareController = new CompareDataController();

export default {
    name: 'CompareActionsComponent',

    components: {
        ButtonComponent
    },

    props: {
        flatID: {
            default: null,
            type   : [Number, String]
        },
        slideID: {
            default: null,
            type   : [Number, String]
        },
        startFavoriteState: {
            default: false,
            type   : Boolean
        },
        activeSection: {
            default: 'buyers',
            type   : String
        }
    },

    data() {
        return {
            favoriteState: false
        };
    },

    mounted() {
        this.favoriteState = this.startFavoriteState;
    },

    methods: {
        ...mapActions(['updateSidebar']),

        _removeItem() {
            this.$emit('removeItem');
        },

        _switchFavorites() {
            const method = this.favoriteState ? 'remove' : 'add';

            this.$emit('processing', true);

            CompareController.switchFavorites(this.flatID, method)
                .then((response) => {
                    this.favoriteState = method === 'add' ? response : !response;
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    const params = new URLSearchParams([['section', this.activeSection]]);

                    this.updateSidebar(params);

                    this.$emit('processing', false);
                });
        }
    }
};
</script>

<style lang="scss">
@import './CompareActionsComponent';
</style>
