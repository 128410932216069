<template>
    <div :class="['input', inputModify, {'is-focus': focused}]">
            <label
                v-show="label && type !== 'hidden'"
                :for="`input-${name}`"
                class="input__label">
                    {{ label }}
            </label>

            <div :class="['input__field', modify]">
                <input
                    v-if="type !== 'textarea'"
                    :type="type"
                    :class="[
                        'input__input',
                        {'validate-input-error': (error || parentError) && inputField?.length},
                        inputClass
                    ]"
                    :id="`input_${name}`"
                    :name="name"
                    :aria-label="ariaLabel"
                    :disabled="disabled"
                    :maxlength="maxlength"
                    :value="inputField"
                    :placeholder="placeholder"
                    v-mask="mask"
                    @focus="focused = true"
                    @input="checkValidation"
                    @blur="checkValue"
                    @click="clickHandler" >

                <textarea
                    v-else
                    type="textarea"
                    :class="[
                        'input__input',
                        'input_theme_textarea',
                        {'validate-input-error': error || parentError},
                        inputClass
                    ]"
                    :id="`textarea_${name}`"
                    :name="name"
                    :aria-label="ariaLabel"
                    :disabled="disabled"
                    :maxlength="maxlength"
                    :value="inputField"
                    v-mask="mask"
                    @focus="focused = true"
                    @input="checkValidation"
                    @blur="checkValue" ></textarea>

                <div v-if="icon" class="input__icon">
                    <img :src="`./media/${icon}.svg`" alt="Иконка">
                </div>

                <div v-if="validation && validation.msg && error" class="input__error">
                    <div :class="['input__error-text', classError]">
                        {{ validation.msg }}
                    </div>
                </div>
                <div v-else-if="parentError" class="input__error">
                    <div :class="['input__error-text', classError]">
                        {{ parentError }}
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    name: 'InputComponent',

    props: {
        type: {
            default: 'text',
            type   : String
        },
        modify: {
            default: null,
            type   : String
        },
        inputModify: {
            default: null,
            type   : String
        },
        name: {
            default: 'input-default',
            type   : String
        },
        label: {
            default: null,
            type   : String
        },
        inputClass: {
            default: null,
            type   : String
        },
        ariaLabel: {
            default: 'input',
            type   : String
        },
        disabled: {
            default: null,
            type   : [String, Boolean]
        },
        icon: {
            default: null,
            type   : String
        },
        classError: {
            default: null,
            type   : String
        },
        value: {
            default: '',
            type   : [String, Number]
        },
        validation: {
            default: null,
            type   : Object
        },
        maxlength: {
            default: null,
            type   : String
        },
        placeholder: {
            default: null,
            type   : String
        },
        mask: {
            default: null,
            type   : String,
            require: false
        },
        required: {
            default: false,
            type   : Boolean
        },
        dataStoreInstance: {
            default: null,
            type   : Object,
            require: false
        },
        parentError: {
            default: null,
            type   : String,
            require: false
        }
    },

    data() {
        return {
            focused   : false,
            error     : false,
            inputField: ''
        };
    },

    mounted() {
        if (this.value &&
            (typeof this.value === 'number' || (typeof this.value === 'string' && this.value?.length > 0))) {
            this.focused = true;
            this.inputField = this.value;

            this.setDataStore(this.value);
        }
    },

    watch: {
        value() {
            this.inputField = this.value;
            this.checkValue();
        }
    },

    methods: {
        checkValue() {
            this.focused = this.inputField.toString().length;
        },

        checkValidation(event) {
            if (this?.validation?.type === 'number' || this?.validation?.type === 'betweenValue') {
                event.target.value = event.target.value?.replace(/[^0-9]/g, '');
            }

            if (this?.validation?.type === 'letters') {
                event.target.value = event.target.value?.replace(/[^a-zA-ZА-Яа-яЁё\s]+$/g, '');
            }

            if (this?.validation?.type === 'square') {
                event.target.value = event.target.value?.replace(/[^0-9,.]+$/g, '');
            }

            this.setDataStore(event.target.value);

            this.$emit('inputChanged', this, event.target.value);
        },

        setDataStore(value) {
            if (this.dataStoreInstance) {
                this.dataStoreInstance.setData(this.name, {
                    value,
                    required  : this.required ? this.required : null,
                    validation: this.validation
                });

                const fieldData = this.dataStoreInstance.getData(this.name);

                this.inputField = fieldData?.value;
                this.error = fieldData?.error;
            } else {
                this.inputField = value;
            }
        },

        clickHandler(event) {
            this.$emit('click', event);
        }
    }
};
</script>

<style lang="scss">
    @import 'InputComponent';
</style>
