<template>
    <div v-if="tabsData?.length && content" class="management-payments">
        <div class="management-payments__tabs">
            <TabsComponent
                type="button"
                :tabsData="tabsData"
                :activeTab="activeTab"
                @changeTab="_changeActiveTab" />
        </div>

        <div class="management-payments__list">
            <div v-if="activeMethod" class="management-payments__list-item">
                <h4 v-if="activeMethod.title" class="management-payments__item-title">{{ activeMethod.title }}</h4>
                <div
                    class="management-payments__item-descr"
                    v-if="activeMethod?.text"
                    v-html="activeMethod.text"></div>
                <div v-if="Object.keys(activeMethod.components)?.length" class="management-payments__item-how">
                    <UniversalBlockComponent
                        v-for="component in activeMethod.components"
                        componentBlockModify="management-payments__item-content"
                        :key="component.id"
                        :type="component.type"
                        :title="component.title"
                        :content="component.content" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ManagementDataController from '@/dataControllers/management';
import TabsComponent from '@/components/TabsComponent/TabsComponent';
import UniversalBlockComponent from '@/components/UniversalBlockComponent/UniversalBlockComponent';

const ManagementController = new ManagementDataController();

export default {
    name: 'ManagementPaymentsPage',

    components: {
        TabsComponent,
        UniversalBlockComponent
    },

    data() {
        return {
            content     : null,
            tabsData    : [],
            activeTab   : null,
            activeMethod: null,
            isProcessing: true
        };
    },

    mounted() {
        this.isProcessing = true;
        this.$emit('processing', this.isProcessing);

        ManagementController.getPaymentMethods()
            .then((response) => {
                if (response.success) {
                    this.content = response.content;
                    this.tabsData = response.tabs;

                    this.activeTab = this.tabsData?.length ?
                        this.tabsData?.[0] :
                        null;

                    this._setActiveMethod();
                } else {
                    this.$emit('errorShow', true, {
                        statusCode: response.errorData?.status,
                        url       : response.errorData?.url
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                this.$emit('errorShow', true, {
                    statusCode: 500,
                    url       : 'Неизвестная ошибка на странице Способы оплаты'
                });
            })
            .then(() => {
                // В axios работает как finally при использовании fetch
                this.isProcessing = false;
                this.$emit('processing', this.isProcessing);
            });
    },

    methods: {
        /**
         * Меняем активный таб
         * @param {string | number} tab - id таба
         */
        _changeActiveTab(tab) {
            this.activeTab = this.tabsData.find((item) => {
                return item.id === tab;
            });

            this._setActiveMethod();
        },

        /**
         * Устанавливаем данные для активного таба
         */
        _setActiveMethod() {
            if (this.activeTab) {
                this.activeMethod = this.content.find((item) => {
                    return item.id === this.activeTab.id;
                });
            } else {
                this.activeMethod = null;
            }
        }
    }
};
</script>

<style lang="scss">
@import './ManagementPaymentsPage';
</style>
