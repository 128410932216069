<template>
    <div :class="['range-slider', modify, {'is-active': isChange}]">
        <div class="range-slider__container">
            <div class="range-slider__range">
                <span class="range-slider__title">{{ slider.label }}</span>
                <span class="range-slider__value">{{ convertToDigit(slider.value) }}</span>
            </div>
        </div>

        <VueSlider
            v-model="slider.value"
            :min="parseInt(slider.min)"
            :max="parseInt(slider.max)"
            :interval="setStepRange(sliderType)"
            :silent="true"
            @drag-end="changeFilters"
            @dragging="drag"
            @change="change"
        />

        <input v-if="isChangeDone" type="hidden"
               :data-counter="true"
               :data-min="parseInt(slider.min)"
               :data-min-value="parseInt(slider.value)"
               :data-max="parseInt(slider.max)" >

        <input v-if="isChangeDone"
               type="hidden" :name="`${sliderType}[min]`" :value="parseInt(slider.value)">
        <input v-if="isChangeDone"
               type="hidden" :name="`${sliderType}[max]`" :value="parseInt(slider.max)">
    </div>
</template>

<script>
import 'vue-slider-component/theme/default.css';
import {Utils} from '@/scripts/utils';
import VueSlider from 'vue-slider-component';

export default {
    name: 'RangeSlider',

    components: {
        VueSlider
    },

    props: ['slider', 'sliderType', 'modify', 'percent'],

    data() {
        return {
            isChange    : false,
            isChangeDone: false
        };
    },

    methods: {
        setStepRange(type) {
            return type === 'price' ? 1000 : 1;
        },

        convertToDigit(price) {
            return Utils.convertToDigit(price);
        },

        change() {
            this.isChangeDone = true;
            this.$nextTick(() => {
                this.$emit('change', this.slider);
            });
        },

        changeFilters() {
            this.isChangeDone = true;

            this.$nextTick(() => {
                this.$emit('change', this.slider);
            });
        },

        drag() {
            this.$emit('change', this.slider);
        }
    }
};
</script>

<style lang="scss">
@import "RangeSlider";
</style>

