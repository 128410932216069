<template>
    <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.20711 0.792893C8.59763 1.18342 8.59763 1.81658 8.20711 2.20711L3.70711 6.70711C3.31658 7.09763 2.68342 7.09763 2.29289 6.70711L0.292893 4.70711C-0.0976311 4.31658 -0.0976311 3.68342 0.292893 3.29289C0.683417 2.90237 1.31658 2.90237 1.70711 3.29289L3 4.58579L6.79289 0.792893C7.18342 0.402369 7.81658 0.402369 8.20711 0.792893Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'CheckboxIcon',

    props: {
        fill: {
            default: '#ffffff',
            type   : String
        }
    }
};
</script>
