<template>
    <swiper
        class="auth-slider"
        :slides-per-view="1"
        :loop="slides?.length > 1"
        :autoplay="false"
        @swiper="authSwiper"
    >
        <div class="auth-slider__back" @click="clickHandler">
            <ArrowBack />
        </div>
        <swiper-slide
            v-for="(slide, index) in slides"
            :key="index"
            class="auth-slider__slide"
        >
            <div class="auth-slider__slide-image">
                <picture>
                    <source :srcset="slide.mobile_image" media="(max-width: 669px)">
                    <source :srcset="slide.image" media="(min-width: 670px)">
                    <img :src="slide.image" :alt="slide.title">
                </picture>
            </div>
            <div class="auth-slider__slide-content">
                <div v-if="slide.icon" class="auth-slider__slide-logo">
                    <img :src="slide.icon" :alt="slide.title">
                </div>
                <h2 v-if="slide.title" class="auth-slider__slide-title">{{slide.title}}</h2>
                <p v-if="slide.caption" class="auth-slider__slide-caption">{{slide.caption}}</p>
            </div>
        </swiper-slide>

        <div v-if="slides?.length > 1" class="auth-slider__navigation">
            <button
                @click="goToSlide('prev')"
                class="button button_theme_white button_theme_circle-small auth-slider__navigation-prev">
                <ArrowIcon />
            </button>
            <button
                @click="goToSlide('next')"
                class="button button_theme_white button_theme_circle-small auth-slider__navigation-next">
                <ArrowIcon />
            </button>
        </div>
    </swiper>
</template>

<script>
import 'swiper/swiper-bundle.css';
import {Swiper, SwiperCore, SwiperSlide} from 'swiper-vue2';
import ArrowBack from '../../icons/ArrowBack';
import ArrowIcon from '../../icons/ArrowIcon';
import {Autoplay} from 'swiper';

SwiperCore.use([Autoplay]);

export default {
    name: 'AuthSliderComponent',

    props: ['slides', 'sliderShow'],

    components: {
        ArrowBack,
        ArrowIcon,
        Swiper,
        SwiperSlide
    },

    data() {
        return {
            swiper: null
        };
    },

    watch: {
        sliderShow() {
            this.swiper.el.style.transform = this.sliderShow === null ?
                'translateX(-500%)' :
                'translateX(0)';
        }
    },

    methods: {
        authSwiper(swiper) {
            this.swiper = swiper;

            if (this.slides?.length < 2) {
                this.swiper.disable();
            }
        },

        goToSlide(direction) {
            if (!direction) {
                return;
            }

            direction === 'next' ?
                this.swiper.slideNext() :
                this.swiper.slidePrev();
        },

        clickHandler() {
            this.$emit('closeSlider');
        }
    }
};
</script>

<style lang="scss">
    @import 'AuthSliderComponent';
</style>
