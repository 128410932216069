var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"evaluate__form evaluate__form_theme_address"},[_c('SelectComponent',{attrs:{"list":_vm.complexes,"label":"Комплекс"},on:{"clickRadio":_vm._clickComplexRadioHandler}}),_vm._v(" "),_c('SelectComponent',{attrs:{"list":_vm.addresses,"disabled":!_vm.addresses.length,"label":"Адрес квартиры"},on:{"clickRadio":_vm._clickAddressRadioHandler}}),_vm._v(" "),_c('InputComponent',{attrs:{"name":"building","label":"Номер дома","maxlength":"4","validation":{
            type: 'number',
            msg: 'Номер должен состоять только из цифр'
        },"data-store-instance":_vm.TradeInStore},on:{"inputChanged":_vm._inputChanged}}),_vm._v(" "),_c('InputComponent',{attrs:{"name":"corpus","label":"Номер корпуса","data-store-instance":_vm.TradeInStore},on:{"inputChanged":_vm._inputChanged}}),_vm._v(" "),_c('InputComponent',{attrs:{"name":"flat","label":"Номер квартиры","validation":{
            type: 'number',
            msg: 'Номер должен состоять только из цифр'
        },"data-store-instance":_vm.TradeInStore},on:{"inputChanged":_vm._inputChanged}}),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"button_theme_big evaluate__form-button","text":"Оценить квартиру","disabled":!_vm.isDisabled},on:{"click":_vm._clickHandler}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }