<template>
    <div class="booking-deleted">
        <transition-group name="fade">
            <div v-for="(item, index) in list" :key="item" class="booking-deleted__item">
                <DeletedBookingIcon />
                <p class="booking-deleted__item-text">{{ item }}</p>
                <ButtonComponent
                    text="Закрыть"
                    modify="button_theme_flat booking-deleted__item-button"
                    @click="_clickHandler(index)" />
            </div>
        </transition-group>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import DeletedBookingIcon from '@/icons/DeletedBookingIcon';

export default {
    name: 'BookingDeletedComponent',

    components: {
        ButtonComponent,
        DeletedBookingIcon
    },

    props: {
        list: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    methods: {
        /**
         * Удаляем элемент из массива
         * @param {number} id - элемент уведомления который надо удалить
         */
        _clickHandler(id) {
            this.list.splice(id, 1);
        }
    }
};
</script>

<style lang="scss">
@import './BookingDeletedComponent';
</style>
