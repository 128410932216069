<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.45711 2.79289C9.84763 3.18342 9.84763 3.81658 9.45711 4.20711L6.66421 7L9.45711 9.79289C9.84763 10.1834 9.84763 10.8166 9.45711 11.2071C9.06658 11.5976 8.43342 11.5976 8.04289 11.2071L4.54289 7.70711C4.15237 7.31658 4.15237 6.68342 4.54289 6.29289L8.04289 2.79289C8.43342 2.40237 9.06658 2.40237 9.45711 2.79289Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'ArrowIcon',

    props: {
        fill: {
            default: '#455569',
            type   : String
        }
    }
};
</script>
