<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8333 14.1667L17 10M17 10L12.8333 5.83333M17 10H7M7 2.5H6C4.59987 2.5 3.8998 2.5 3.36502 2.77248C2.89462 3.01217 2.51217 3.39462 2.27248 3.86502C2 4.3998 2 5.09987 2 6.5V13.5C2 14.9001 2 15.6002 2.27248 16.135C2.51217 16.6054 2.89462 16.9878 3.36502 17.2275C3.8998 17.5 4.59987 17.5 6 17.5H7" stroke="#8B96A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'LogoutIcon'
};
</script>
