import type {
    IBannersResponse,
    IBookingCombinedResult,
    IBookingDataController,
    IBookingRequestOptions,
    IBookingResponseResult,
    IBookingResult
} from './types';

import axios from 'axios';
import type {AxiosResponse} from 'axios';
import {BookingData} from './bookingData';
import {ERequests} from '../../requests';

const BookingDataStore = new BookingData();

export default class BookingDataController implements IBookingDataController {
    /**
     * Отправляем запрос на апи - результат сохраняется в "привязанный" дата
     * стор (не глобальный) и передается в ту функцию , что обратилась
     * к текущей
     * @returns {object} - возвращает результат запроса
     */
    public async getBooking(): Promise<IBookingCombinedResult> {
        const result = {
            bookingData: {
                annotation   : null,
                deleted      : [],
                booking      : [],
                steps        : [],
                tabsData     : [],
                bookingStatus: []
            },
            bannersData: {
                banners: {},
                tabs   : []
            }
        };

        const promiseBooking = axios.get(ERequests.booking);
        const promiseBanners = axios.post(ERequests.banners);

        await Promise.all([promiseBooking, promiseBanners])
            .then((response: [AxiosResponse<IBookingResponseResult>, AxiosResponse<IBannersResponse>]) => {
                BookingDataStore.setStoreData(response[0].data);

                // Обработка ответа от АПИ бронирования
                const data = response[0]?.data.data;

                result.bookingData.annotation = data.annotation;

                result.bookingData.booking = data.booking;

                result.bookingData.deleted = data.deletedPremises || [];
                result.bookingData.steps = data.steps;
                result.bookingData.tabsData = data.tabsData || [];
                result.bookingData.bookingStatus = data.bookingStatus || [];

                result.bannersData.banners = response[1]?.data?.data?.banners;
                result.bannersData.tabs = response[1]?.data?.data?.tabs;
            });

        return result;
    }

    /**
     * Отправляем запрос удаления помещения на апи - результат сохраняется в
     * "привязанный" дата стор (не глобальный) и передается в ту функцию , что
     * обратилась к текущей
     * @param {object} options - объект со списком опций запроса
     * @returns {object} - возвращает результат запроса
     */
    public async deleteBooking(options: IBookingRequestOptions): Promise<IBookingResult> {
        const data = {
            dealId: options.id
        };

        const result = {
            annotation: null,
            deleted   : [],
            booking   : [],
            steps     : [],
            tabsData  : []
        };

        await axios.post(ERequests.deleteBooking, data)
            .then((response: AxiosResponse<IBookingResponseResult>) => {
                BookingDataStore.setStoreData(response.data);

                const responseData = response.data.data;

                result.annotation = responseData.annotation;

                result.booking = responseData.booking;

                result.deleted = responseData.deletedPremises || [];
                result.steps = responseData.steps;
                result.tabsData = responseData.tabsData || [];
            });

        return result;
    }

    /**
     * Формируем список опций помещения
     * @param {object} item - объект с данными помещения
     * @returns {array} - массив с объектами данных опций помещения
     */
    public getCardOptions(item: object): object[] {
        if (!item) {
            return [];
        }

        const options = [];
        const optionsKeys = BookingDataStore.getStoreData().optionsKeys;

        for (const key in optionsKeys) {
            if (Object.prototype.hasOwnProperty.call(optionsKeys, key)) {
                if (item[key]) {
                    options.push({[optionsKeys[key]]: item[key]});
                }
            }
        }

        return options;
    }

    public getBookingSteps(): object[] {
        return BookingDataStore.getStoreData().steps;
    }
}
