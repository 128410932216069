import type {AxiosError, AxiosResponse} from 'axios';
import type {
    IAdditionalServiceResponse,
    IAdditionalServiceResult,
    IAgencyRequestOptions,
    IAgencyResponse,
    IAgencyResult,
    IEstateSellResponse,
    IEstateSellResult
} from './types';
import {AgencyData} from './agencyData';
import axios from 'axios';
import {ERequests} from '../../requests';
import {Utils} from '../../scripts/utils';


const AgencyDataStore = new AgencyData();

export default class AgencyDataController {
    async getData(page: string): Promise<AxiosResponse> {
        let result = null;

        await axios.post(ERequests.agency, {page})
            .then((response: AxiosResponse) => {
                result = response;
            });

        return result;
    }

    async getCommerceData(options: IAgencyRequestOptions): Promise<IAgencyResult> {
        const data = {
            type   : options.type,
            shown  : options.more,
            sort   : options.sort,
            project: options.project
        };

        const result = {
            premises : [],
            noResults: false,
            more     : null,
            sort     : null,
            projects : null,
            complexes: null
        };

        await axios.post(ERequests.agencyCommerce, options)
            .then((response: AxiosResponse<IAgencyResponse>) => {
                // Записываем результат во временный стор
                AgencyDataStore.setRequestResponse(response.data.data, data.type);

                // Получаем результат из временного стора - реализованно так,
                // чтобы использовать одну функцию на разные сценарии получения
                // записей - инициализация / показать еще / очистить
                const storeData = AgencyDataStore.getStoreData();

                result.premises = storeData.premises;
                result.more = storeData.more;
                result.sort = storeData.sortList;
                result.noResults = storeData.noResults;
                result.projects = storeData.projects;
                result.complexes = storeData.complexes;
            });

        return result;
    }

    /**
     * Ищем выбранный элемент
     * @param {string} filter - фильтр или сортировка в которой ищем
     * @param {string} type - тип элемента selected или checked
     * @returns {object} - выбранный элемент
     */
    public getActiveFilter(filter: string, type: string): object {
        const storeData = AgencyDataStore.getStoreData();

        const filterItem = storeData[filter]?.find((item: object) => {
            return item[type];
        });

        return filterItem;
    }

    public async getAdditionalServices(): Promise<IAdditionalServiceResult> {
        const result: IAdditionalServiceResult = {
            success   : true,
            additional: []
        };

        await axios.get(ERequests.additionalService)
            .then((response: AxiosResponse<IAdditionalServiceResponse>) => {
                result.additional = response.data.data.additional;
            })
            .catch((error: AxiosError) => {
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }

    public async getEstateSell(): Promise<IEstateSellResult> {
        const result: IEstateSellResult = {
            success   : true,
            advantages: null,
            banner    : null,
            contacts  : null
        };

        await axios.get(ERequests.estateSell)
            .then((response: AxiosResponse<IEstateSellResponse>) => {
                result.advantages = response.data.data.advantages?.[0] || null;
                result.banner = response.data.data.banner?.[0] || null;
                result.contacts = response.data.data.contacts?.[0] || null;
            })
            .catch((error: AxiosError) => {
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }
}
