<template>
    <div class="favorites-page">
        <div v-if="!favoritesData?.noResults" class="favorites-page__tabs">
            <TabsComponent
                type="button"
                :tabsData="tabsData"
                :activeTab="activeTab"
                @changeTab="_changeActiveTab" />
        </div>
        <div v-if="!isEmpty" class="favorites-page__filter">
            <SortComponent
                v-if="favoritesData?.sort"
                :sortList="favoritesData.sort"
                :activeSort="activeSort"
                @sorted="_sortChanged"/>

            <ProjectSelectorComponent
                v-if="favoritesData?.projects?.length"
                projectLabel="Выберите проект"
                :projectsList="favoritesData.projects"
                :activeProject="activeProject"
                @changeValue="_clickProjectHandler" />
            <FavoritesClearComponent @clearData="_clearHandler"/>
        </div>

        <EmptyComponent
            v-if="!isEmpty && !favoritesData?.premises?.length"
            :favorites="true"
            modify="favorites-page__empty empty_indent_top-yellow"
            title="Нет подходящих квартир!"
        />

        <div v-if="!isEmpty" class="favorites-page__list">
            <FavoritesListComponent
                v-if="activeTab && favoritesData?.premises?.length"
                :listData="favoritesData"
                :activeTab="activeTab?.slug"
                :activeSection="activeSection"
                @getMoreFavorites="_getRequest('more')"
                @removeHandler="_removeClickHandler"
                @processing="_processingHandler"
            />
        </div>

        <EmptyComponent
            v-if="isEmpty"
            :favorites="true"
            modify="favorites-page__empty"
            title="Здесь ничего нет"
        />

        <div class="favorites-page__help">
            <HelpBannerComponent modify="is-full-image"
                                 image="/images/help-choose/help-choose-interior.jpg" />
        </div>
    </div>
</template>

<script>
import EmptyComponent from '@/components/EmptyComponent/EmptyComponent';
import FavoritesClearComponent from '@/components/Favorites/FavoritesClearComponent/FavoritesClearComponent';
import FavoritesDataController from '@/dataControllers/favorites';
import FavoritesListComponent from '@/components/Favorites/FavoritesListComponent/FavoritesListComponent';
import HelpBannerComponent from '@/components/HelpBannerComponent/HelpBannerComponent';
import {mapActions} from 'vuex';
import ProjectSelectorComponent from '@/components/ProjectSelectorComponent/ProjectSelectorComponent';
import SortComponent from '@/components/SortComponent/SortComponent';
import TabsComponent from '@/components/TabsComponent/TabsComponent';

const FavoritesController = new FavoritesDataController();

export default {
    name: 'FavoritesPage',

    components: {
        EmptyComponent,
        FavoritesClearComponent,
        FavoritesListComponent,
        HelpBannerComponent,
        ProjectSelectorComponent,
        SortComponent,
        TabsComponent
    },

    data() {
        return {
            favoritesData: null,
            activeSort   : this.favoritesData?.sort?.[0] || null,
            activeProject: null,
            // Должен быть равен одному из элементов в tabsData
            activeTab    : {
                id  : 0,
                slug: 'flats',
                name: 'Квартиры'
            },
            isProcessing: false,
            isEmpty     : null,
            tabsData    : [{
                id  : 0,
                slug: 'flats',
                name: 'Квартиры'
            }, {
                id  : 1,
                slug: 'commerce',
                name: 'Коммерческие помещения'
            }],
            activeSection: null
        };
    },

    mounted() {
        this._getRequest('init');

        this.activeSection = this.$route.matched[0]?.props?.['default']?.section ?
            this.$route.matched[0]?.props?.['default']?.section :
            null;
    },

    methods: {
        ...mapActions(['updateSidebar']),

        /**
         * Универсальный обработчик запроса к апи избранного
         * @param {string} type - тип запроса - удаление / очистка / инит ...
         * @param {string} id - идентификатор объекта для удаления
         */
        _getRequest(type, id = null) {
            this.isProcessing = true;
            this._processingHandler(this.isProcessing);

            FavoritesController.getFavorites({
                type,
                id,
                sort   : type === 'init' ? null : this.activeSort?.['id'] || null,
                more   : type === 'init' ? null : this.favoritesData?.more?.count || null,
                project: type === 'init' ? null : this.activeProject?.['id'] || null,
                object : this.activeTab?.slug || null
            })
                .then((response) => {
                    this.favoritesData = response;
                    this.activeProject = FavoritesController.getActiveFilter('projects', 'checked');
                    this.activeSort = FavoritesController.getActiveFilter('sortList', 'selected');
                    this.isEmpty = this.favoritesData.noResults;
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this.isProcessing = false;
                    this._processingHandler(this.isProcessing);

                    if (type === 'remove') {
                        const params = new URLSearchParams([['section', this.activeSection]]);

                        this.updateSidebar(params);
                    }
                });
        },

        /**
         * Переключаем табы
         * @param {string} id - ид нового активного таба
         */
        _changeActiveTab(id) {
            this.activeTab = this.tabsData[id];
            this._getRequest('init');
        },

        /**
         * Изменяем сортировку
         * @param {string} id - ид выбранной сортировки
         */
        _sortChanged(id) {
            this.activeSort = this.favoritesData.sort.find((sort) => {
                return sort.id === id;
            });

            this._getRequest('sort');
        },

        /**
         * Выбираем проект для фильтрации
         * @param {string} id - ид выбранного проекта
         */
        _clickProjectHandler(id) {
            if (this.favoritesData?.projects) {
                this.activeProject = this.favoritesData.projects.find((project) => {
                    return project.id === id;
                });

                this._getRequest('filter');
            }
        },

        /**
         * Отправляем запрос на очистку списка
         */
        _clearHandler() {
            this._getRequest('clear');
        },

        _removeClickHandler(id) {
            this._getRequest('remove', id);
        },

        _processingHandler(state) {
            this.$emit('processing', state);
        }
    }
};
</script>

<style lang="scss">
@import './FavoritesPage';
</style>
