<template>
    <div v-if="steps || evaluate" class="trade-in">
        <h4 class="trade-in__title" v-html="computedTitle"></h4>

        <TradeInStepsSection v-if="steps" :content="steps" />

        <EvaluateSection
            :content="evaluate"
            :addresses="addresses"
            @processing="_processingHandler"
            @errorShow="_errorHandler" />

        <HelpBannerComponent modify="trade-in__help is-full-image"
                             image="/images/help-choose/help-choose-interior.jpg" />
    </div>
</template>

<script>
import EvaluateSection from '@/sections/Agency/TradeIn/EvaluateSection/EvaluateSection';
import HelpBannerComponent from '@/components/HelpBannerComponent/HelpBannerComponent';
import TradeInDataController from '@/dataControllers/tradein';
import TradeInStepsSection from '@/sections/Agency/TradeIn/TradeInStepsSection/TradeInStepsSection';

const TradeInController = new TradeInDataController();

export default {
    name: 'TradeIn',

    components: {
        EvaluateSection,
        HelpBannerComponent,
        TradeInStepsSection
    },

    computed: {
        computedTitle() {
            return this.title ? this.title : 'Обменяй квартиру в 3 шага';
        }
    },

    data() {
        return {
            steps       : null,
            title       : null,
            evaluate    : null,
            addresses   : null,
            isProcessing: true
        };
    },

    mounted() {
        this.getContent();
    },

    watch: {
        isProcessing() {
            this.$emit('processing', this.isProcessing);
        }
    },

    methods: {
        /**
         * Обработчик получения данных от бека
         */
        getContent() {
            this._processingHandler(true);
            this.$emit('processing', this.isProcessing);

            TradeInController.getTradeInList()
                .then((response) => {
                    if (response?.success) {
                        this.title = response.content.title;
                        this.steps = response.content.steps;
                        this.evaluate = response.content.evaluate;
                        this.addresses = response.addresses;
                    } else {
                        this._errorHandler({
                            statusCode: response.errorData?.status,
                            url       : response.errorData?.url
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this._errorHandler({
                        url: 'Неизвестная ошибка на странице Trade-in'
                    });
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this._processingHandler(false);
                    this.$emit('processing', this.isProcessing);
                });
        },

        _errorHandler(errorData) {
            this.$emit('errorShow', true, errorData);
        },

        _processingHandler(state) {
            this.isProcessing = state;
        }
    }
};
</script>

<style lang="scss">
@import './TradeInPage';
</style>
