<template>
    <div class="favorites-clear">
        <ButtonComponent
            modify="favorites-clear__button"
            icon="TrashIcon"
            fill="#8b96a3"
            text="Очистить всё"
            @click="_clearSwitch" />

        <transition name="fade">
            <ClearComponent
                v-if="clearShow"
                title="Очистить избранное"
                description="Вы уверены, что хотите полностью очистить список избранного?"
                @closeClearPopup="_clearSwitch"
                @makeClear="_clearFavorites"
            />
        </transition>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import ClearComponent from '@/components/ClearComponent/ClearComponent';

export default {
    name: 'FavoritesClearComponent',

    components: {
        ButtonComponent,
        ClearComponent
    },

    data() {
        return {
            clearShow: false
        };
    },

    methods: {
        /**
         * Скрывает / показывает модальное окно
         */
        _clearSwitch() {
            this.clearShow = !this.clearShow;
        },

        /**
         * Отправляет запрос на очистку списка избранного и закрывает модальное
         * окно
         */
        _clearFavorites() {
            this.$emit('clearData');
            this._clearSwitch();
        }
    }
};
</script>

<style lang="scss">
@import './FavoritesClearComponent';
</style>
