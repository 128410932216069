<template>
    <svg :class="modify" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.99984 2.91666V11.0833M2.9165 6.99999H11.0832"
            :stroke="fill"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'PlusMoreIcon',

    props: {
        modify: {
            default: '',
            type   : String
        },
        fill: {
            default: '#455569',
            type   : String
        }
    }
};
</script>
