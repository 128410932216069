var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['input', _vm.inputModify, {'is-focus': _vm.focused}]},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.label && _vm.type !== 'hidden'),expression:"label && type !== 'hidden'"}],staticClass:"input__label",attrs:{"for":`input-${_vm.name}`}},[_vm._v("\n                "+_vm._s(_vm.label)+"\n        ")]),_vm._v(" "),_c('div',{class:['input__field', _vm.modify]},[(_vm.type !== 'textarea')?_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],class:[
                    'input__input',
                    {'validate-input-error': (_vm.error || _vm.parentError) && _vm.inputField?.length},
                    _vm.inputClass
                ],attrs:{"type":_vm.type,"id":`input_${_vm.name}`,"name":_vm.name,"aria-label":_vm.ariaLabel,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"placeholder":_vm.placeholder},domProps:{"value":_vm.inputField},on:{"focus":function($event){_vm.focused = true},"input":_vm.checkValidation,"blur":_vm.checkValue,"click":_vm.clickHandler}}):_c('textarea',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],class:[
                    'input__input',
                    'input_theme_textarea',
                    {'validate-input-error': _vm.error || _vm.parentError},
                    _vm.inputClass
                ],attrs:{"type":"textarea","id":`textarea_${_vm.name}`,"name":_vm.name,"aria-label":_vm.ariaLabel,"disabled":_vm.disabled,"maxlength":_vm.maxlength},domProps:{"value":_vm.inputField},on:{"focus":function($event){_vm.focused = true},"input":_vm.checkValidation,"blur":_vm.checkValue}}),_vm._v(" "),(_vm.icon)?_c('div',{staticClass:"input__icon"},[_c('img',{attrs:{"src":`./media/${_vm.icon}.svg`,"alt":"Иконка"}})]):_vm._e(),_vm._v(" "),(_vm.validation && _vm.validation.msg && _vm.error)?_c('div',{staticClass:"input__error"},[_c('div',{class:['input__error-text', _vm.classError]},[_vm._v("\n                    "+_vm._s(_vm.validation.msg)+"\n                ")])]):(_vm.parentError)?_c('div',{staticClass:"input__error"},[_c('div',{class:['input__error-text', _vm.classError]},[_vm._v("\n                    "+_vm._s(_vm.parentError)+"\n                ")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }