import type {
    IDataStore,
    IItemObject,
    IOrderData
} from './types';

import {Utils} from '../../scripts/utils';

let orderDataInstance: OrderData | null = null;

export class OrderData implements IOrderData {
    public dataStore: IDataStore;

    constructor() {
        if (!orderDataInstance) {
            // eslint-disable-next-line
            orderDataInstance = this;
        }

        this.dataStore = {
            inputs        : {},
            object        : null,
            catalogLink   : null,
            agreementLink : null,
            files         : [],
            paymentMethods: [{
                id    : 0,
                text  : '100% оплата',
                active: true
            }, {
                id    : 1,
                text  : 'Рассрочка',
                active: false
            }, {
                id    : 2,
                text  : 'Ипотека',
                active: false
            }, {
                id    : 3,
                text  : 'Субсидия',
                active: false
            }]
        };
    }

    /**
     * Записываем данные в хранилище
     * @param {object} objectData - записываемые данные
     */
    public setObjectData(objectData: object): void {
        this.dataStore.object = objectData;
    }

    /**
     * Записываем данные инпутов и проводим его валидацию
     * @param {string} itemName - имя инпута
     * @param {object} itemState - данные инпута
     * @returns {object} - возвращает хранилище
     */
    public setData(itemName: string, itemState: IItemObject): object {
        if (!this.dataStore.inputs[itemName]) {
            this.dataStore.inputs[itemName] = {
                validation: {
                    type  : null,
                    length: null,
                    msg   : null
                },
                value: null,
                error: false
            };
        }

        this.dataStore.inputs[itemName] = itemState;

        this.validation(itemName);

        return this;
    }

    /**
     * Получаем данные определенного инпута
     * @param {string} itemName - имя получаемого инпута
     * @returns {object} - получаемый инпут
     */
    public getData(itemName: string): boolean | IItemObject {
        if (!this.dataStore.inputs[itemName]) {
            return false;
        }

        return this.dataStore.inputs[itemName];
    }

    public setLinks(link: string, value: string): void {
        if (!this.dataStore[link]) {
            return;
        }

        this.dataStore[link] = value;
    }

    public getLinks(link: string): null | string {
        return this.dataStore[link] || null;
    }

    public initTimer(time: number = 200): void {
        console.error(342, time);
    }

    /**
     * Добавляет файлы в массив
     * @param {array} files - список файлов для добавления
     */
    public pushFile(files: File[]): void {
        for (let i = 0; i < files.length; i++) {
            const currentFile = files[i];

            const isExist = this.dataStore.files.find((storeFile: File) => {
                return storeFile.name === currentFile.name && storeFile.lastModified === currentFile.lastModified;
            });

            if (!isExist) {
                this.dataStore.files.push(currentFile);
            }
        }
    }

    /**
     * Получение списка файлов
     * @returns {FileList} - список файлов
     */
    public getFiles(): File[] {
        return this.dataStore.files;
    }

    /**
     * Удаление файлов
     * @param {string} name - имя файла
     * @param {number} index - индекс файла
     */
    public removeFile(name: string, index: number): void {
        if (this.dataStore.files[index].name === name) {
            this.dataStore.files.splice(index, 1);
        }
    }

    /**
     * Валидация инпута
     * @param {string} itemName - имя инпута для проверки валидации
     */
    public validation(itemName: string): boolean {
        const item = this.dataStore.inputs[itemName];

        if (!item?.validation?.type) {
            return;
        }

        if (item.validation.type === 'numberLength') {
            item.value = item?.value?.replace(/[^0-9]/g, '');
        }

        item.error = Utils.validation(item?.validation?.type, item?.value, item?.validation?.length,
            item?.validation?.maxLength);
    }

    public returnPaymentMethods(): object[] {
        return this.dataStore.paymentMethods;
    }
}
