<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path :class="circle" d="M18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9Z" />
        <path :class="mark" fill-rule="evenodd" clip-rule="evenodd" d="M12.3734 6.29289C12.7639 6.68341 12.7639 7.31657 12.3734 7.7071L8.70678 11.3738C8.51924 11.5613 8.26489 11.6667 7.99967 11.6667C7.73445 11.6667 7.48009 11.5613 7.29255 11.3738L5.62589 9.7071C5.23537 9.31657 5.23538 8.6834 5.62591 8.29288C6.01644 7.90237 6.6496 7.90237 7.04012 8.2929L7.99967 9.25247L10.9592 6.2929C11.3497 5.90237 11.9829 5.90237 12.3734 6.29289Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'CheckboxCircleIcon',

    props: {
        circle: {
            default: 'icon__circle',
            type   : String
        },
        mark: {
            default: 'icon__mark'
        },
        fill: {
            default: '#ffffff',
            type   : String
        }
    }
};
</script>
