<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0405 3.29289C14.431 3.68342 14.431 4.31658 14.0405 4.70711L6.70718 12.0404C6.31666 12.4309 5.6835 12.4309 5.29297 12.0404L1.95964 8.70711C1.56912 8.31659 1.56912 7.68342 1.95964 7.2929C2.35016 6.90237 2.98333 6.90237 3.37385 7.29289L6.00008 9.91909L12.6263 3.29289C13.0168 2.90237 13.65 2.90237 14.0405 3.29289Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'CompareTrueIcon',

    props: {
        fill: {
            default: '#62725b',
            type   : String
        }
    }
};
</script>
