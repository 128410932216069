<template>
    <footer class="footer">
        <div class="footer__content">
            <div class="footer__content-copy">{{ computedCopyright }}</div>
            <div class="footer__content-links">
                <ButtonComponent
                    modify="button_theme_flat button_theme_thin footer__content-link footer__content-callback"
                    text="Обратная связь"
                    @click="callbackHandler" />

                <template v-if="Object.keys(settings?.sites).length">
                    <LinkComponent
                        v-for="project in Object.keys(settings.sites)"
                        :key="project"
                        :href="settings.sites[project]?.url"
                        modify="footer__content-link"
                        :text="settings.sites[project]?.title" />
                </template>
            </div>
        </div>
        <div class="footer__logo">
            <a href="https://kelnik.ru/" target="_blank">
                <img src="/webicons/logo/kelnik-logo.svg" alt="Kelnik">
            </a>
        </div>
    </footer>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import LinkComponent from '@/components/LinkComponent/LinkComponent.vue';
import {mapGetters} from 'vuex';

export default {
    name: 'FooterComponent',

    components: {
        ButtonComponent,
        LinkComponent
    },

    computed: {
        ...mapGetters({
            settings: 'GET_SETTINGS'
        }),

        computedCopyright() {
            return this.settings?.copyright ?
                this.settings.copyright :
                '© 2023, ООО "Кельник-Студиос"';
        }
    },

    methods: {
        callbackHandler() {
            this.$root.$emit('showPopup', 'feedback');
        }
    }
};
</script>

<style lang="scss">
@import './FooterComponent';
</style>
