<template>
    <div class="page profile">
        <transition name="fade">
            <PreloaderComponent v-if="isProcessing" modify="preloader_theme_opacity" />
        </transition>
        <HeaderComponent name="Профиль" />

        <div class="page__wrapper profile__wrapper">
            <div class="page__sidebar profile__sidebar">
                <h4 v-if="user?.name" class="profile__sidebar-name">{{ user.name }} {{ user.surname }}</h4>
                <div v-if="user?.phone" class="profile__sidebar-phone">
                    <span>Номер телефона</span>
                    +7{{ user?.phone }}
                </div>
                <ButtonComponent
                    modify="button_theme_gray profile__sidebar-logout"
                    text="Выйти"
                    leftIcon="LogoutIcon"
                    :disabled="isProcessing"
                    @click="_userExit" />
            </div>

            <div
                :class="[
                    'page__content',
                    'profile__content',
                    {'page__content_theme_processing': isProcessing}
                ]">

                <div class="profile__section">
                    <h5 class="profile__content-title">Персональные данные</h5>

                    <div v-if="user?.name" class="profile__table">
                        <div class="profile__table-item" v-if="user?.surname">
                            <span>Фамилия</span>
                            {{ user.surname }}
                        </div>

                        <div class="profile__table-item" v-if="user?.name">
                            <span>Имя</span>
                            {{ user.name }}
                        </div>

                        <div v-if="user.email" class="profile__table-item">
                            <span>E-mail</span>
                            {{ user.email }}
                        </div>
                    </div>

                    <ButtonComponent
                        modify="profile__edit"
                        text="Запросить изменения"
                        @click="editPopupShow" />
                </div>

                <div class="profile__section">
                    <h5 class="profile__content-title">Управляющая компания</h5>

                    <div class="profile__uk">
                        <InputComponent
                            ref="account"
                            name="account"
                            inputModify="profile__uk-item"
                            label="Лицевой счет"
                            :validation="{
                                type: 'number',
                                msg: 'Введите только цифры'
                            }"
                            :value="user?.uk?.account"
                            @inputChanged="_inputChangeHandler" />

                        <InputComponent
                            ref="name"
                            name="name"
                            inputModify="profile__uk-item"
                            label="ФИО"
                            :validation="{
                                type: 'letters',
                                msg: 'Введите только буквы'
                            }"
                            :value="user?.uk?.name"
                            @inputChanged="_inputChangeHandler" />

                        <InputComponent
                            ref="address"
                            name="address"
                            inputModify="profile__uk-item"
                            label="Адрес"
                            :value="user?.uk?.address"
                            @inputChanged="_inputChangeHandler" />

                        <InputComponent
                            ref="area"
                            name="area"
                            inputModify="profile__uk-item profile__uk-item_theme_half"
                            label="Площадь"
                            :validation="{
                                type: 'square'
                            }"
                            :value="user?.uk?.area"
                            @inputChanged="_inputChangeHandler" />

                        <InputComponent
                            ref="tenants"
                            name="tenants"
                            inputModify="profile__uk-item profile__uk-item_theme_half"
                            label="Кол-во жителей"
                            :validation="{
                                type: 'number',
                                msg: 'Введите только цифры'
                            }"
                            :value="user?.uk?.tenants"
                            @inputChanged="_inputChangeHandler" />
                    </div>

                    <ButtonComponent
                        modify="profile__uk-button"
                        text="Сохранить изменения"
                        @click="_saveUK" />
                </div>
            </div>

        </div>


        <FooterComponent />

        <MobileBarComponent v-if="isMobile" />

        <PopupsSection />

        <!-- Переделать на нормальные формы и перенести в компонент PopupSection -->
        <transition name="popup-question-fade">
            <PopupQuestionComponent
                v-if="popupQuestionShow"
                :title="questionTitle"
                :descr="questionDescr"
                :select="questionSelect"
                :textarea="questionTextarea"
                @closePopup="_popupSwitch('popupQuestionShow')"
                :callbackData="callbackData" />
        </transition>


        <CookieComponent />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import AuthDataController from '@/dataControllers/auth/index';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import CookieComponent from '@/components/CookieComponent/CookieComponent';
import FooterComponent from '@/components/FooterComponent/FooterComponent';
import HeaderComponent from '@/components/HeaderComponent/HeaderComponent';
import InputComponent from '@/components/InputComponent/InputComponent';
import MobileBarComponent from '@/components/MobileBarComponent/MobileBarComponent';
import PopupQuestionComponent from '@/components/Popups/PopupQuestionComponent/PopupQuestionComponent';
import PopupsSection from '@/sections/PopupsSection/PopupsSection';
import PreloaderComponent from '@/components/PreloaderComponent/PreloaderComponent';
import {Utils} from '@/scripts/utils';

const AuthController = new AuthDataController();

export default {
    name: 'ProfilePage',

    components: {
        ButtonComponent,
        CookieComponent,
        FooterComponent,
        HeaderComponent,
        InputComponent,
        MobileBarComponent,
        PopupQuestionComponent,
        PopupsSection,
        PreloaderComponent
    },

    props: {
        section: String
    },

    data() {
        return {
            callbackData     : '',
            meetingPopup     : null,
            isProcessing     : true,
            isEmail          : false,
            popupQuestionShow: false,
            unsubscribe      : null
        };
    },

    computed: {
        ...mapGetters({
            isMobile: 'GET_IS_MOBILE',
            user    : 'GET_USER'
        })
    },

    mounted() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            if (mutation.type === 'SET_USER' || mutation.type === 'SET_USER_UK') {
                this.isProcessing = false;
            }
        });

        if (this.user) {
            this.isProcessing = false;
        }
    },

    beforeRouteLeave(to, from, next) {
        this.isProcessing = false;

        this.unsubscribe();

        next();
    },

    methods: {
        ...mapActions(['clearSidebar', 'setActiveSection', 'setUserUkData']),

        /**
         * Переключает флаг указывающий на загрузку данных какой-либо страницы
         * @param {boolean} status - статус идет загрузка данных или нет
         */
        _processingHandler(status) {
            this.isProcessing = status;
        },

        /**
         * Выход
         */
        async _userExit() {
            this.isProcessing = true;
            const logoutResult = await AuthController.logout();

            this.clearSidebar();

            if (logoutResult) {
                this.$router.push('/auth');
            }

            this.isProcessing = false;
        },

        _inputChangeHandler(input, value) {
            const validation = input.validation?.type ? !Utils.validation(input.validation?.type, value) : true;

            if (validation) {
                this.$refs[input.name].inputField = value;
                input.error = false;
            } else {
                input.error = true;
            }
        },

        _saveUK() {
            const data = {};

            Object.keys(this.$refs)?.forEach((key) => {
                data[key] = key === 'area' || key === 'tenants' ?
                    Number(this.$refs[key]?.inputField) :
                    this.$refs[key]?.inputField;
            });

            this.setUserUkData(data);
            this.isProcessing = true;
        },

        /**
         * Отображение требуемого попапа - функция написана на будущее - если
         * вдруг понадобится открывать разные попапы
         * @param {string} popupItem - идентификатор для отображения попапа
         */
        _popupSwitch(popupItem) {
            this[popupItem] = !this[popupItem];

            this[popupItem] ? Utils.bodyFixed(this.$el) : Utils.bodyStatic();
        },

        /**
         * Обрабатываем сигнал на показ попапа из дочернего компонента
         * @param {string} data - текст скрытого поля
         * @param {object} content - данные заголовка, описания и флага для формы обратной связи
         */
        _callbackPopupHandler(data = '', content = {}) {
            this.callbackData = data;
            this.isEmail = content.email ? content.email : false;
            this.questionTitle = content.title ? content.title : '';
            this.questionDescr = content.descr ? content.descr : '';
            this.questionSelect = content.select ? content.select : false;
            this.questionTextarea = content.textarea ? content.textarea : false;
            this._popupSwitch('popupQuestionShow');
        },


        editPopupShow() {
            const title = 'Запрос изменения данных пользователя';
            const formTitle = {
                email: true,
                title: 'Изменить персональные данные',
                // eslint-disable-next-line
                descr: 'Отправьте запрос на&nbsp;изменение ваших данных и&nbsp;с&nbsp;вами свяжется менеджер для уточнения деталей'
            };

            this._callbackPopupHandler(title, formTitle);
        }

    }
};
</script>

<style lang="scss">
@import './ProfilePage';
</style>
