<template>
    <div :class="['error', {'error_theme_global': globalError}]">
        <div class="error__overlay"></div>
        <div class="error__content">
            <div class="error__icon">
                <ErrorIcon />
            </div>
            <h4 class="error__title">Техническая ошибка</h4>
            <p class="error__text">К сожалению, произошла техническая ошибка. Мы уже устраняем неисправность. </p>
            <p class="error__text">Пока попробуйте перезагрузить страницу или свяжитесь
                с&nbsp;нами по&nbsp;номеру телефона:</p>
            <a class="error__phone" :href="`tel:${settings?.supportPhone}`">{{settings.supportPhone}}</a>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
// import {ERequests} from '@/requests';
import ErrorIcon from '@/icons/ErrorIcon';
import {mapGetters} from 'vuex';

export default {
    name: 'ErrorComponent',

    components: {
        ErrorIcon
    },

    props: {
        data: {
            default: () => {
                return {
                    status: null,
                    url   : null
                };
            }
        },

        globalError: Boolean
    },

    data() {
        return {
            sended: false
        };
    },

    computed: {
        ...mapGetters({
            settings: 'GET_SETTINGS'
        })
    },

    mounted() {
        this._sendHandler();
    },

    methods: {
        async _sendHandler() {
            this.$emit('processing', true);

            // const errorData = this.globalError ?
            //     {
            //         statusCode: 500,
            //         url       : 'Произошла ошибка при получении данных пользователя'
            //     } :
            //     this.data;

            this.sended = true;

            await this.$emit('processing', false);

            return true;
            // await axios.post(ERequests.sendError, errorData)
            //     .then(() => {
            //         return true;
            //     })
            //     .catch((error) => {
            //         console.error(error);
            //     })
            //     .then(() => {
            //         this.$emit('processing', false);
            //     });
        }
    }
};
</script>

<style lang="scss">
@import './ErrorComponent';
</style>
