<template>
    <div class="actions-list">
        <ActionsCardComponent
            v-for="item in list"
            :key="item.id"
            :content="item"
            @showAction="showActionHandler" />
    </div>
</template>

<script>
import ActionsCardComponent from '@/components/Actions/ActionsCardComponent/ActionsCardComponent';

export default {
    name: 'ActionsListComponent',

    components: {
        ActionsCardComponent
    },

    props: {
        list: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    methods: {
        moreClickHandler() {
            this.$emit('moreClicked');
        },

        showActionHandler(id) {
            this.$emit('showAction', id);
        }
    }
};
</script>

<style lang="scss">
@import './ActionsListComponent';
</style>
