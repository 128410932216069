<template>
    <div class="order-changed order-body">
        <div class="order-changed__title">
            <div class="order-changed__title-title">
                <InfoIcon fill="#009a38" />
                <h5 v-if="content?.status">{{ content.status }}</h5>
            </div>
            <p
                v-if="content?.text"
                v-html="content.text"
                class="order-changed__title-description"></p>
        </div>

        <div class="order-changed__content">
            <div class="order-changed__content-line">
                <p class="order-changed__content-title">Сумма сделки</p>
                <div class="order-changed__content-prices">
                    <span v-if="content?.oldPrice" class="old">{{ convertToDigit(content.oldPrice) }} ₽</span>
                    <ArrowBack v-if="content?.oldPrice" fill="#c5c5c5" />
                    <span v-if="content?.price" class="new">{{ convertToDigit(content.price) }} ₽</span>
                </div>
            </div>

            <div class="order-changed__content-line">
                <p class="order-changed__content-title">Стоимость за м²</p>
                <div class="order-changed__content-prices">
                    <span v-if="content?.oldPricePerMeter" class="old">
                        {{ convertToDigit(content.oldPricePerMeter) }} ₽/м²
                    </span>
                    <ArrowBack v-if="content?.oldPricePerMeter" fill="#c5c5c5" />
                    <span v-if="content?.priceMeter" class="new">{{ convertToDigit(content.priceMeter) }} ₽/м²</span>
                </div>
            </div>
        </div>

        <div class="order-changed__buttons">
            <LinkComponent
                v-if="content?.bookingLink"
                modify="button_theme_yuntolovo button_theme_gray"
                :href="content.bookingLink"
                type="button"
                leftIcon="ArrowIcon"
                text="Вернуться к выбору квартиры" />

            <ButtonComponent
                modify="button_theme_yuntolovo"
                text="Согласен с новой ценой"
                @click="nextStep" />
        </div>
    </div>
</template>

<script>
import ArrowBack from '@/icons/ArrowBack';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import InfoIcon from '@/icons/InfoIcon';
import LinkComponent from '@/components/LinkComponent/LinkComponent';
import {Utils} from '@/scripts/utils';

export default {
    name: 'OrderChangedPriceComponent',

    components: {
        ArrowBack,
        ButtonComponent,
        InfoIcon,
        LinkComponent
    },

    props: {
        content: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    methods: {
        /**
         * Конвертирует число или строку в строку с разрядами.
         * @param {string | number} num - конвертируемое значение
         * @returns {string} результат конвертации
         */
        convertToDigit(num) {
            return Utils.convertToDigit(num);
        },

        nextStep() {
            this.$emit('clickNextStep');
        }
    }
};
</script>

<style lang="scss">
@import './OrderChangedPriceComponent';
</style>
