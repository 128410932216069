import type {AxiosError, AxiosResponse} from 'axios';
import type {
    ITradeInAddressesResponse,
    ITradeInData,
    ITradeInResponse,
    ITradeInResult} from './types';
import axios from 'axios';
import {ERequests} from '../../requests';
import TradeInData from './tradeinData';
import {Utils} from '../../scripts/utils';

const TradeInDataStore = new TradeInData();

export default class TradeInDataController {
    public get tradeInStore(): ITradeInData {
        return TradeInDataStore;
    }

    public async getData(page: string): Promise<object> {
        const data = {
            page,
            complex : null,
            address : null,
            building: null,
            corpus  : null,
            flat    : null,
            contract: null
        };

        if (page === 'address') {
            data.complex = TradeInDataStore?.dataStore?.items?.complex;
            data.address = TradeInDataStore?.dataStore?.items?.address;
            data.building = TradeInDataStore?.dataStore?.items?.building?.value;
            data.corpus = TradeInDataStore?.dataStore?.items?.corpus?.value;
            data.flat = TradeInDataStore?.dataStore?.items?.flat?.value;
            data.contract = null;
        } else if (page === 'contract') {
            data.complex = null;
            data.address = null;
            data.building = null;
            data.corpus = null;
            data.flat = null;
            data.contract = TradeInDataStore?.dataStore?.items?.contract;
        }

        const result = {
            success: null,
            errors : [],
            info   : {},
            text   : null
        };

        await axios.post(ERequests.agency, data)
            .then((response: AxiosResponse) => {
                result.success = response.data.success;
                result.info = response.data.data;
            });

        return result;
    }

    /**
     * Получаем текстовую часть страницы trade-in
     * @returns {ITradeInResult} - результат запроса
     */
    async getTradeInList(): Promise<ITradeInResult> {
        const tradeInTextData = axios.get(ERequests.tradeIn);
        const tradeInAddressData = axios.get(ERequests.tradeInAddress);

        const result: ITradeInResult = {
            success: true,
            content: {
                title   : null,
                steps   : [],
                evaluate: {
                    title: '',
                    text : ''
                }
            },
            addresses: []
        };

        await Promise.all([tradeInTextData, tradeInAddressData])
            .then((response: [AxiosResponse<ITradeInResponse>, AxiosResponse<ITradeInAddressesResponse>]) => {
                result.content = {
                    title   : response[0].data.data.title,
                    steps   : response[0].data.data.steps,
                    evaluate: response[0].data.data.evaluate
                };

                result.addresses = response[1].data.data;
            })
            .catch((error: AxiosError) => {
                console.error(error);
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }

    async postTradeInRequest(page: string, data: object): Promise<object> {
        const result = {
            success  : true,
            errorData: {},
            info     : {}
        };

        const request = page === 'contract' ?
            ERequests.tradeInContracts :
            ERequests.tradeInAddressEvaluation;

        await axios.post(request, data)
            .then((response: AxiosResponse) => {
                result.info = response.data.data;
            })
            .catch((error: AxiosError) => {
                console.error(error);
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }
}
