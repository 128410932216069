<template>
    <section class="trade-in__steps">
        <div
            v-for="(item, index) in content"
            :key="item.id"
            class="tarde-in__steps-item">
            <div class="trade-in__steps-item-num">{{ index + 1 }}</div>
            <div class="trade-in__steps-item-title">
                <h5 v-if="item.title" v-html="item.title"></h5>
                <p v-if="item.text" v-html="item.text"></p>
            </div>
            <div v-if="item.projectChoose && projects" class="trade-in__steps-item-content">
                <a
                    v-for="project in projects"
                    :key="project.name"
                    :href="project.href"
                    class="trade-in__steps-project"
                    target="_blank"
                >
                    <div class="trade-in__steps-project-img">
                        <img :src="project.image" :alt="project.name">
                    </div>

                    <ButtonComponent
                        modify="button_theme_thin trade-in__steps-project-button"
                        text="Сайт проекта"
                        icon="ExternalIcon"
                        fill="#ffffff" />
                    <div class="trade-in__steps-project-name">
                        Выбрать квартиру в&nbsp;{{ computedProjectName(project.name) }}
                    </div>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import ButtonComponent from '../../../../components/ButtonComponent/ButtonComponent.vue';
import {mapGetters} from 'vuex';

export default {
    name: 'TradeInStepsSection',

    components: {
        ButtonComponent
    },

    props: {
        content: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    computed: {
        ...mapGetters({
            projects: 'GET_PROJECTS_LIST'
        })
    },

    methods: {
        computedProjectName(name) {
            switch (name) {
                case 'ЖК №2':
                    return 'ЖК №2';
                default:
                case 'ЖК №1':
                    return 'ЖК №1';
            }
        }
    }
};
</script>

<style lang="scss">
@import './TradeInStepsSection';
</style>
