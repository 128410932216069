<template>
    <div class="sidebar">
        <div v-if="isMobile && sidebarShow" class="sidebar__mobile-overlay"></div>
        <ButtonComponent
            v-if="!isDesktop"
            :modify="
                sidebarShow ?
                    'sidebar__mobile-button is-active' :
                    'sidebar__mobile-button'
            "
            :text="mobileButtonText"
            rightIcon="ArrowDownIcon"
            fill="#8b96a3"
            @click="_openSidebar" />
        <div v-show="sidebarShow || isDesktop" class="sidebar__content">
            <div v-if="isMobile" class="sidebar__content-title">
                <p>{{ name }}</p>
                <ButtonComponent
                    modify="button_theme_flat sidebar__content-close"
                    icon="CloseIcon"
                    fill="#8b96a3"
                    @click="_openSidebar" />
            </div>
            <ul
                class="sidebar__list">
                <template v-if="sidebarData[section]?.length">
                    <li
                        v-for="sidebarItem in sidebarData[section]"
                        :key="sidebarItem.href"
                        class="sidebar__list-item"
                        @click="_sidebarItemClick"
                    >
                        <router-link :to="sidebarItem.href" exact-active-class="is-active">
                            <CheckboxCircleIcon circle="icon__circle" mark="icon__mark" />
                            <span class="link__text">{{ sidebarItem.text }}</span>
                            <span
                                v-if="isDesktop && sidebarItem.quantity"
                                class="link__counter">{{ sidebarItem.quantity }}</span>
                        </router-link>
                    </li>
                </template>
                <template v-else>
                    <li
                        v-for="i in [0, 1, 2, 3, 4]"
                        :key="i"
                        class="sidebar__list-item sidebar__list-item_theme_empty">
                    </li>
                </template>
            </ul>

            <div v-if="isDesktop && isBuyer" class="sidebar__meetings">
                <ButtonComponent
                    v-if="!meeting?.date"
                    modify="button_theme_flat sidebar__meetings-item sidebar__meetings-manager"
                    leftIcon="PlusIcon"
                    text="Назначить встречу"
                    @click="meetingPopup('meeting')" />
                <router-link
                    v-else-if="meeting?.date"
                    to="/content/meetings"
                    class="sidebar__meetings-item sidebar__meetings-link">
                    <div class="link__text">
                        <div class="link__title">Встреча с менеджером</div>
                        <div class="link__subtext">
                            <template v-if="meeting?.appointed">
                                <p>{{meeting.date}}</p>
                                <p>{{meeting.time}}</p>
                            </template>
                            <template v-else>
                                <p>Идёт согласование времени и даты</p>
                            </template>
                        </div>
                    </div>
                    <div class="link__icon">
                        <ArrowRightIcon fill="#8b96a3"/>
                    </div>
                </router-link>
                <ButtonComponent
                    v-if="!tour?.date"
                    modify="button_theme_flat sidebar__meetings-item sidebar__meetings-excursion"
                    leftIcon="PlusIcon"
                    text="Записаться на экскурсию"
                    @click="meetingPopup('tour')" />
                <router-link
                    v-else-if="tour?.date"
                    to="/content/excursion"
                    class="sidebar__meetings-item sidebar__meetings-link">
                    <div class="link__text">
                        <div class="link__title">
                            Экскурсия <span v-if="tour?.appointed">по {{ tour.project }}</span>
                        </div>
                        <div class="link__subtext">
                            <template v-if="tour?.appointed">
                                <p>{{tour.date}}</p>
                                <p>{{tour.time}}</p>
                            </template>
                            <template v-else>
                                <p>Идёт согласование времени и даты</p>
                            </template>
                        </div>
                    </div>
                    <div class="link__icon">
                        <ArrowRightIcon fill="#8b96a3"/>
                    </div>
                </router-link>
            </div>
        </div>

        <ManagerPlateComponent
            v-if="isDesktop"
            :managers="managers"
            @showPopup="_showPopupHandler"/>
    </div>
</template>

<script>
import ArrowRightIcon from '@/icons/ArrowRightIcon.vue';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import CheckboxCircleIcon from '@/icons/ChecboxCircleIcon';
import ManagerPlateComponent from '@/components/ManagerPlateComponent/ManagerPlateComponent';
import {mapGetters} from 'vuex';
import {Utils} from '@/scripts/utils';

export default {
    name: 'SidebarComponent',

    components: {
        ArrowRightIcon,
        ButtonComponent,
        CheckboxCircleIcon,
        ManagerPlateComponent
    },

    props: {
        name: {
            default: 'Страница личного кабинета',
            type   : String
        },
        section: {
            default: 'buyer',
            type   : String
        }
    },

    data() {
        return {
            sidebarShow     : false,
            mobileButtonText: ''
        };
    },

    computed: {
        ...mapGetters({
            sidebarData: 'GET_SIDEBAR',
            meeting    : 'GET_MEETING',
            tour       : 'GET_TOUR',
            managers   : 'GET_MANAGERS',
            isMobile   : 'GET_IS_MOBILE',
            isTablet   : 'GET_IS_TABLET',
            isDesktop  : 'GET_IS_DESKTOP'
        }),

        isBuyer() {
            return this.$route.matched.some((item) => {
                return item.props?.default?.section === 'buyer';
            });
        }
    },

    watch: {
        $route: {
            handler(route) {
                // Имя активной страницы в мобильном селекторе страниц
                this.mobileButtonText = route.meta.name;
            }
        }
    },

    created() {
        // Показываем или скрываем сайдбар при загрузке компонента в зависимости
        // от разрешения устройства
        this.sidebarShow = this.isDesktop;
        this.mobileButtonText = this.$route.meta.name || 'Выберите страницу';
    },

    methods: {
        /**
         * Прячем или показываем сайдбар
         */
        _openSidebar() {
            this.sidebarShow = !this.sidebarShow;
            this.$emit('sidebarState', this.sidebarShow);

            if (this.isMobile) {
                this.sidebarShow ? Utils.bodyFixed(this.$el) : Utils.bodyStatic();
            }
        },

        _sidebarItemClick() {
            if (this.isMobile || this.isTablet) {
                this._openSidebar();
            }
        },

        _showPopupHandler(popupData) {
            this.$emit('showPopup', popupData);
        },

        meetingPopup(type) {
            this.$root.$emit('showPopup', 'meeting', type);
        }
    }
};
</script>

<style lang="scss">
@import './SidebarComponent';
</style>
