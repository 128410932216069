<template>
    <div class="owner-keys">
        <OwnerKeysNotifySection />

        <OwnerKeysResultComponent
            v-for="status in resultList"
            :key="status.number"
            :resultData="status" />
        <OwnerKeysOrderSection
            :permits="permitsList"
            :contracts="contracts"
            @popupOpen="popupClickHandler" />
    </div>
</template>

<script>
import OwnerDataController from '@/dataControllers/owner';
import OwnerKeysNotifySection from '@/sections/Owner/OwnerKeysNotifySection/OwnerKeysNotifySection';
import OwnerKeysOrderSection from '@/sections/Owner/OwnerKeysOrderSection/OwnerKeysOrderSection';
import OwnerKeysResultComponent from '@/components/Owner/OwnerKeysResultComponent/OwnerKeysResultComponent';

const OwnerController = new OwnerDataController();

export default {
    name: 'OwnerKeysPage',

    components: {
        OwnerKeysNotifySection,
        OwnerKeysOrderSection,
        OwnerKeysResultComponent
    },

    data() {
        return {
            resultList  : null,
            permitsList : null,
            contracts   : null,
            isProcessing: true
        };
    },

    mounted() {
        this.$emit('processing', this.isProcessing);

        OwnerController.getOwnerKeysPageData()
            .then((response) => {
                this.resultList = response.list;
                this.permitsList = response.permits;
                this.contracts = response.contracts;
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                // В axios работает как finally при использовании fetch
                this.isProcessing = false;
                this.$emit('processing', this.isProcessing);
            });
    },

    methods: {
        popupClickHandler() {
            this.$root.$emit('showPopup', 'multi', this.contracts);
        }
    }
};
</script>

<style>
.owner-keys {
    position: relative;
    max-width: 100%;
    overflow-x: hidden;
}
</style>
