<template>
    <div
        v-if="content.programs[0]?.active"
        :class="[
            'mortgage-card',
            {'mortgage-card_theme_blue': content.color && content.color === 'blue'},
            {'mortgage-card_theme_gray': content.color && content.color === 'gray'},
            ]">
        <div class="mortgage-card__top">
            <h5 class="mortgage-card__top-title">{{ content.title }}</h5>
            <div class="mortgage-card__top-name">{{ content.programs[0].name }}</div>
        </div>

        <div class="mortgage-card__details">
            <div v-if="content.programs[0].rate" class="mortgage-card__details-item mortgage-card__rate">
                <span>Процентная ставка</span>
                {{ content.programs[0].rate }} %
            </div>

            <div v-if="content?.programs[0].payment" class="mortgage-card__details-item">
                <span>Ежемесячный платеж</span>
                {{ convertToDigit(content.programs[0].payment) }} ₽/мес
            </div>

            <div v-if="content?.programs[0].term" class="mortgage-card__details-item">
                <span>Срок субсидирования</span>
                {{ content.programs[0].term }}
            </div>

            <div
                v-if="!content?.programs[0].term && content?.programs[0].mortgageTerm"
                class="mortgage-card__details-item">
                <span>Срок ипотеки</span>
                {{ content.programs[0].mortgageTerm }}
            </div>

            <div
                v-if="content?.programs[0].term"
                class="mortgage-card__details-item">
                <span>К стоимости квартиры</span>
                {{ content.programs[0].plus }}
            </div>

            <div v-if="!content?.programs[0].term && content?.programs[0].mortgageTerm"
                class="mortgage-card__details-item">
                <span>Стоимость квартиры</span>
                {{ convertToDigit(flatPrice) }}
            </div>
        </div>

        <ButtonComponent
            modify="mortgage-card__button"
            text="Оставить заявку"
            @click="clickHandler" />
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import {Utils} from '@/scripts/utils';

export default {
    name: 'MortgageCardComponent',

    components: {
        ButtonComponent
    },

    props: {
        content: {
            default: () => {
                return {};
            },
            type: Object
        },
        flatPrice: {
            default: '0',
            type   : String
        }
    },

    methods: {
        convertToDigit(price) {
            return Utils.convertToDigit(price);
        },

        /**
         * Обработка клика по кнопке оставить заявку
         */
        clickHandler() {
            const programData = this.content.programs[0];
            const bank = `Банк: ${programData.name}`;
            const program = `Программа: ${this.content.title}`;
            const term = programData.term ? programData.term : programData.mortgageTerm;
            const payment = `Платеж: ${this.content.programs[0].payment}`;

            const request = `${bank}. ${program}. Срок: ${term}. ${payment}`;

            this.$root.$emit('showPopup', 'mortgage', request);
        }
    }
};
</script>

<style lang="scss">
@import './MortgageCardComponent';
</style>
