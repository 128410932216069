<template>
    <div class="order-error order-body">
        <div class="order-error__icon">
            <img v-if="content?.isBooked" src="/webicons/order/lock.svg" :alt="content?.status">
            <img v-else-if="content?.isCountdown" src="/webicons/order/timer.svg" :alt="content?.status">
            <img v-else src="/webicons/order/error.svg" alt="Техническая ошибка" />
        </div>

        <h5 class="order-error__title">{{ content?.status }}</h5>

        <div class="order-error__content" v-html="content?.text"></div>

        <LinkComponent
            v-if="content?.catalogLink"
            :href="content?.catalogLink"
            type="button"
            modify="button_theme_yuntolovo order-error__button"
            text="Вернуться к выбору квартиры" />

        <LinkComponent
            v-else-if="content?.bookingLink"
            :href="content?.bookingLink"
            type="button"
            modify="button_theme_yuntolovo order-error__button"
            text="Смотреть мои бронирования" />

        <ButtonComponent
            v-else
            modify="button_theme_yuntolovo order-error__button"
            text="Вернуться назад"
            @click="closeError" />
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import LinkComponent from '@/components/LinkComponent/LinkComponent';

export default {
    name: 'OrderErrorComponent',

    components: {
        ButtonComponent,
        LinkComponent
    },

    props: {
        content: {
            default: () => {
                return {};
            },
            type: [Object, Array]
        }
    },

    methods: {
        closeError() {
            this.$emit('closeError');
        }
    }
};
</script>

<style lang="scss">
@import './OrderErrorComponent';
</style>
