import type {AxiosError, AxiosResponse} from 'axios';
import type {
    IItemObject,
    IKeysSlotResponse,
    IKeysSlotResult,
    ISendFormErrorResponse,
    ISendFormRequest,
    ISendFormResponse,
    IUserFormData,
    IViewsRequestResponse,
    IViewsRequestResult
} from './types';
import axios from 'axios';
import {ERequests} from '../../requests';
import {UserFormData} from './formData';

const UserFormDataStore = new UserFormData();

export default class FormDataController {
    public get formDataStore(): IUserFormData {
        return UserFormDataStore;
    }

    public async sendForm(formID: string | Blob = '0'): Promise<object> {
        if (!Object.keys(UserFormDataStore.dataStore?.items)?.length) {
            return null;
        }

        const inputData = {};

        const inputs = UserFormDataStore.dataStore.items;

        // Получаем все переданные инпуты и проверяем на ошибки
        Object.keys(inputs).forEach((input: string) => {
            const currentInput = UserFormDataStore.getData(input) as IItemObject;

            if (typeof currentInput === 'string') {
                inputData[input] = currentInput;
            } else if (currentInput && !currentInput?.error && currentInput?.value?.length > 0) {
                inputData[input] = currentInput.value;
            }
        });

        if (!Object.keys(inputData).length) {
            return {};
        }

        const registerResult = await this._sendFormRequest(formID, inputData);

        return registerResult;
    }

    public async getKeysSlot(data: object): Promise<IKeysSlotResult> {
        let result: IKeysSlotResult = {
            success: true
        };

        await axios.post(ERequests.keysSlot, data)
            .then((response: AxiosResponse<IKeysSlotResponse>) => {
                result.success = response.data.success;
                result.availableDates = response.data.data.available_dates;
            })
            .catch((error: AxiosError<ISendFormErrorResponse>) => {
                result = {...this._prepareErrorResult(error.response?.data)};
            });

        return result;
    }

    public async sendViewsRequest(): Promise<IViewsRequestResult> {
        const store = UserFormDataStore.dataStore.items;

        const data = {};

        for (const item in store) {
            if (Object.prototype.hasOwnProperty.call(store, item)) {
                data[item] = store[item]?.value;
            }
        }

        const result = {
            success    : null,
            title      : '',
            text       : '',
            requestNote: {
                date   : null,
                address: null,
                note   : null
            }
        };

        await axios.post(ERequests.keys, data)
            .then((response: AxiosResponse<IViewsRequestResponse>) => {
                result.success = response.data.success;
                result.title = response.data.data.title;
                result.text = response.data.data?.text;
                result.requestNote = {
                    date   : response.data.data?.date,
                    address: response.data.data?.address,
                    note   : response.data.data?.note
                };
            })
            .catch((error: AxiosError<ISendFormErrorResponse>) => {
                const errorData = error.response?.data;
                const preparedError = this._prepareErrorResult(errorData);

                result.success = preparedError.success;
                result.title = preparedError.title;
                result.text = preparedError.text;
                result.requestNote = null;
            });

        return result;
    }

    public clearDataStore(): void {
        UserFormDataStore.clearData();
    }

    private async _sendFormRequest(formID: string | Blob, inputs: object): Promise<ISendFormRequest> {
        let result = {
            success: null,
            title  : '',
            text   : ''
        };

        if (!Object.keys(inputs).length) {
            return result;
        }

        const formData = new FormData();

        formData.append('id', formID);

        Object.keys(inputs).forEach((key: string) => {
            formData.append(key, inputs[key]);
        });

        await axios.post(ERequests.formSubmit, formData)
            .then((response: AxiosResponse<ISendFormResponse>) => {
                result.success = response.data.success;
                result.title = response.data.data.title;
                result.text = response.data.data.text;
            })
            .catch((error: AxiosError<ISendFormErrorResponse>) => {
                const errorData = error.response?.data;

                result = {...this._prepareErrorResult(errorData)};
            });

        return result;
    }

    private _prepareErrorResult(errorData: ISendFormErrorResponse): ISendFormRequest {
        const result = {
            success: false,
            title  : 'Ошибка',
            text   : null
        };

        result.success = errorData?.success;
        result.text = errorData?.errors?.length ?
            errorData.errors[0] :
            'К сожалению, произошла техническая ошибка. Попробуйте еще раз позже или перезагрузите страницу.';

        return result;
    }
}
