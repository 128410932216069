<template>
    <div class="order">
        <transition name="fade">
            <PreloaderComponent v-if="isProcessing" modify="preloader_theme_opacity preloader_theme_order" />
        </transition>
        <div class="order__grid">
            <a :href="backLink" class="order__back">
                <img src="/webicons/order/back.svg" alt="Вернуться назад">
                Вернуться назад
            </a>
            <h3 class="order__title">Бронирование&nbsp;в 3&nbsp;шага</h3>
            <transition name="fade">
                <div v-if="step > 0 && step !== 3" class="order__timer">
                    <InfoIcon fill="#62725b" />
                    Завершите бронирования за
                    <span>{{ timerText }}</span>
                </div>
            </transition>
            <div class="order__steps">
                <div
                    ref="step-1"
                    :class="['order__step', {'is-active': step === 1}, {'is-complete': step > 1}]">
                    <div class="order__step-num">
                        <span v-if="step <= 1">1</span>
                        <img v-if="step > 1" src="/webicons/order/check.svg" alt="Шаг завершен">
                    </div>
                    <p class="order__step-title">Авторизация/ Регистрация</p>
                </div>
                <div
                    ref="step-2"
                    :class="['order__step', {'is-active': step === 2},{'is-complete': step > 2}]">
                    <div class="order__step-num">
                        <span v-if="step <= 2">2</span>
                        <img v-if="step > 2" src="/webicons/order/check.svg" alt="Шаг завершен">
                    </div>
                    <p class="order__step-title">Персональные данные</p>
                </div>
                <div
                    ref="step-3"
                    :class="['order__step', {'is-active': step === 3}]">
                    <p class="order__step-num">
                        <span>3</span>
                    </p>
                    <p class="order__step-title">Завершение бронирования</p>
                </div>
            </div>
            <OrderPreviewStepComponent
                v-if="step === 0 && !error"
                :content="content"
                @clickNextStep="clickNextStepHandler" />
            <OrderFirstStepComponent
                v-if="step === 1 && !error"
                @clickNextStep="clickNextStepHandler"
                @cancelBooking="cancelBookingHandler" />
            <OrderSecondStepComponent
                v-if="step === 2 && !error"
                @bookingRequest="bookingRequestHandler"
                @cancelBooking="cancelBookingHandler" />
            <OrderThirdStepComponent
                v-if="step === 3 && !error"
                :content="content"
                :backLink="backLink" />

            <OrderErrorComponent
                v-if="error && errorData && errorData.reason !== 'price'"
                :content="errorData"
                @closeError="closeErrorHandler" />
            <OrderChangedPriceComponent
                v-else-if="error && errorData?.reason === 'price'"
                :content="errorData"
                @clickNextStep="acceptPriceHandler" />
        </div>
    </div>
</template>

<script>
import InfoIcon from '@/icons/InfoIcon';
import OrderChangedPriceComponent from '@/components/Order/OrderChangedPriceComponent/OrderChangedPriceComponent';
import OrderDataController from '@/dataControllers/order';
import OrderErrorComponent from '@/components/Order/OrderErrorComponent/OrderErrorComponent';
import OrderFirstStepComponent from '@/components/Order/OrderFirstStepComponent/OrderFirstStepComponent';
import OrderPreviewStepComponent from '@/components/Order/OrderPreviewStepComponent/OrderPreviewStepComponent';
import OrderSecondStepComponent from '@/components/Order/OrderSecondStepComponent/OrderSecondStepComponent';
import OrderThirdStepComponent from '@/components/Order/OrderThirdStepComponent/OrderThirdStepComponent';
import PreloaderComponent from '@/components/PreloaderComponent/PreloaderComponent';
import {Utils} from '@/scripts/utils';

const OrderController = new OrderDataController();

export default {
    name: 'OrderPage',

    components: {
        InfoIcon,
        OrderChangedPriceComponent,
        OrderErrorComponent,
        OrderPreviewStepComponent,
        OrderFirstStepComponent,
        OrderSecondStepComponent,
        OrderThirdStepComponent,
        PreloaderComponent
    },

    data() {
        return {
            premissesID : null,
            step        : 0,
            timer       : 900,
            timerText   : null,
            content     : null,
            error       : true,
            errorData   : null,
            backLink    : null,
            isProcessing: false
        };
    },

    computed: {
        OrderStore() {
            return OrderController.orderDataStore;
        }
    },

    mounted() {
        this.premissesID = this.$route?.params?.id;
        this.getOrderData('check');
        this.countdownCalc();
    },

    watch: {
        timer() {
            if (this.timer === 0 && this.step !== 3) {
                setTimeout(() => {
                    this.error = true;
                    this.errorData = {
                        isCountdown: true,
                        status     : 'Время бронирования истекло',
                        // eslint-disable-next-line
                        text       : 'К сожалению, срок вашего бронирования истек. <br />Вы можете попробовать заново, воспользовавшись поиском квартир на сайте проекта.',
                        link       : this.backLink
                    };

                    this.step = 0;
                }, 1000);
            }
        }
    },

    methods: {
        /**
         * Получаем данные об авторизации пользователя
         * @returns {string} - возвращает 0 или 1, что является false или true
         */
        getAuthState() {
            return document.cookie.split('; ').reduce((acc, value) => {
                const parts = value.split('=');

                return parts[0] === 'glavstroy-lk-auth' ? decodeURIComponent(parts[1]) : acc;
            }, '');
        },

        /**
         * Обработчик перехода между экранами бронирования
         * @param {boolean} clearError - надо ли чистить ошибки
         */
        clickNextStepHandler(clearError = false) {
            this.error = clearError;
            if (this.step === 0) {
                this.countdown();
            }

            // Если активный шаг это шаг с характеристиками и при этом
            // пользователь авторизован, то отправляем его сразу на шаг
            // заполнения данных
            if (this.step === 0 && this.getAuthState()) {
                this.step = 2;
            } else {
                this.step++;
            }

            this.scrollStepOnMobile();

            window.scrollTo({
                top     : 0,
                behavior: 'smooth'
            });
        },

        /**
         * Обработка отмены бронирования
         */
        cancelBookingHandler() {
            this.error = true;
            this.errorData = {
                isCanceling: true,
                status     : 'Квартира уже забронирована',
                text       : 'Попробуйте вернуться к поиску и выберите <br />другую квартиру для бронирования',
                link       : this.backLink
            };
        },

        /**
         * Отправка запроса на бронирование
         */
        bookingRequestHandler() {
            this.getOrderData('create');
        },

        stepErrorHandler(content) {
            this.error = true;
            this.errorData = content;
        },

        /**
         * Обработка запроса данных
         * @param {string} type - тип запроса
         */
        getOrderData(type) {
            if (!this.premissesID) {
                return;
            }

            let data = null;

            if (type === 'check') {
                data = new FormData();
                data.append('id', this.premissesID);
            } else if (type === 'create') {
                this.isProcessing = true;

                data = this.getBookingData();
            }

            OrderController.getOrderData(data, type)
                .then((result) => {
                    if (result.error) {
                        this.error = true;
                        this.errorData = result.errorData;

                        OrderController.setLinks('agreementLink', this.errorData?.agreementLink ?
                            this.errorData.agreementLink :
                            null);

                        if (result.errorData?.reason && result.errorData.reason === 'price') {
                            this.content = result.errorData;
                        }
                    } else {
                        this.content = result;
                        this.backLink = result.link;
                        this.error = false;

                        OrderController.setLinks('agreementLink', this.content?.agreementLink ?
                            this.content.agreementLink :
                            null);

                        if (type === 'create') {
                            this.step++;
                        }
                    }
                })
                .then(() => {
                    this.isProcessing = false;

                    window.scrollTo({
                        top     : 0,
                        behavior: 'smooth'
                    });
                });
        },

        /**
         * Запуск обратного отсчета
         */
        countdown() {
            const countdownInterval = setInterval(() => {
                if (this.timer === 0) {
                    clearInterval(countdownInterval);
                }

                this.countdownCalc();

                --this.timer;
            }, 1000);
        },

        /**
         * Расчет обратного отсчета
         */
        countdownCalc() {
            let minutes = parseInt(this.timer / 60);
            let seconds = parseInt(this.timer % 60);

            minutes = minutes < 10 ? `0${minutes}` : minutes;
            seconds = seconds < 10 ? `0${seconds}` : seconds;

            this.timerText = `${minutes}:${seconds}`;
        },

        /**
         * Подготовка данных для отправки бронирования
         * @returns {FormData} - данные бронирования
         */
        getBookingData() {
            const bookingData = new FormData();

            const inputs = this.OrderStore.dataStore.inputs;

            Object.keys(inputs).forEach((key) => {
                bookingData.append(key, inputs[key].value);
            });

            if (this.OrderStore.dataStore.files.length) {
                this.OrderStore.dataStore.files.forEach((file, index) => {
                    bookingData.append(`files[${index}]`, file);
                });
            }

            bookingData.append('premissesID', this.premissesID);

            bookingData.append('price', Utils.convertToNumber(this.content?.conditionals?.price || 0));
            bookingData.append('priceMeter', Utils.convertToNumber(this.content?.conditionals?.pricePerMeter || 0));

            return bookingData;
        },

        /**
         * Подкрутка до индикатора активного шага на мобильных
         */
        scrollStepOnMobile() {
            if (this.$refs[`step-${this.step}`]) {
                this.$refs[`step-${this.step}`].scrollIntoView({
                    behavior: 'smooth',
                    inline  : 'start'
                });
            }
        },

        /**
         * Закрываем блок с технической ошибкой
         */
        closeErrorHandler() {
            this.error = false;
        },

        /**
         * Обработка по клику согласия с изменением цены
         */
        acceptPriceHandler() {
            if (this.step === 0) {
                this.error = false;
            } else {
                this.getOrderData('create');
            }
        }
    }
};
</script>

<style lang="scss">
@import './OrderPage';
</style>
