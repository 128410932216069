<template>
    <div class="popup popup-meeting">
        <div
            class="popup__overlay"
            @click="_closeClick"
            ></div>
        <div class="popup__content popup-meeting__content">
            <div class="popup__content-top">
                <ButtonComponent
                    modify="button_theme_thin button_theme_transparent popup__content-close"
                    icon="CloseIcon"
                    fill="#8b96a3"
                    @click="_closeClick" />
            </div>
            <div class="popup__content-body popup-meeting__content-body">
                <h3 class="popup-booking-steps__content-title" v-html="computedTitle"></h3>
                <div class="popup-owners__content-descr">
                    Наш менеджер перезвонит вам в&nbsp;ближайшее время и&nbsp;утвердит все детали встречи
                </div>

                <form action="" class="popup-meeting__content-form">
                    <SelectComponent
                        :list="computedProjects"
                        label="Выберите ЖК"
                        modify="popup-meeting__content-select"
                        @clickRadio="clickRadioHandler" />

                    <InputComponent
                        name="phone"
                        label="Ваш номер телефона"
                        mask="+7 (###) ###-##-##"
                        :validation="{
                            type: 'phone',
                            msg: 'Введите корректный номер телефона'
                        }"
                        :value="user?.phone"
                        :parent-error="parentError"
                    />

                    <ButtonComponent
                        :disabled="isDisabled"
                        modify="button_theme_big"
                        text="Оставить заявку"
                        @click="sendForm" />
                </form>

                <div
                    v-if="formSend && formSuccess"
                    class="popup__response popup__success">
                    <div class="popup__response-icon">
                        <img src="/webicons/form/success.svg" alt="Успешная отправка">
                    </div>
                    <h3 class="popup__response-title">Заявка принята!</h3>
                    <p class="popup__response-description">Мы перезвоним вам в ближайшее время</p>

                    <ButtonComponent
                        modify="button_theme_big popup__response-button"
                        text="Хорошо"
                        :disabled="buttonDisabled"
                        @click="_closeClick" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import InputComponent from '@/components/InputComponent/InputComponent';
import MeetingFormController from '@/dataControllers/meetings';
import SelectComponent from '@/components/SelectComponent/SelectComponent.vue';

const MeetingController = new MeetingFormController();

export default {
    name: 'PopupMeetingComponent',

    components: {
        ButtonComponent,
        InputComponent,
        SelectComponent
    },

    props: {
        type: {
            default: 'meeting',
            type   : String
        }
    },

    data() {
        return {
            isDisabled   : true,
            parentError  : null,
            activeProject: null,
            formSend     : false,
            formSuccess  : false,
            activeSection: null
        };
    },

    computed: {
        computedTitle() {
            switch (this.type) {
                default:
                case 'meeting':
                    return 'Назначить встречу';
                case 'tour':
                    return 'Записаться<br />на экскурсию';
            }
        },

        computedProjects() {
            const projects = [];

            Object.keys(this.projectList).forEach((key, index) => {
                projects.push({
                    id  : index,
                    text: this.projectList[key]['name']
                });
            });

            return projects;
        },

        ...mapGetters({
            user       : 'GET_USER',
            projectList: 'GET_PROJECTS_LIST'
        })
    },

    mounted() {
        this.activeSection = this.$route.matched[0]?.props?.['default']?.section ?
            this.$route.matched[0]?.props?.['default']?.section :
            null;
    },

    methods: {
        ...mapActions(['updateSidebar']),

        _closeClick() {
            this.$emit('closePopup');
        },

        clickRadioHandler(id) {
            if (!id) {
                return;
            }

            this.activeProject = id.text;

            this.isDisabled = false;
        },

        sendForm() {
            this.isDisabled = true;

            const sendData = {
                complex: this.activeProject,
                phone  : this.user?.phone,
                type   : this.type === 'meeting' ? 'manager' : 'excursion'
            };

            MeetingController.sendRequest(this.type, sendData)
                .then((response) => {
                    this.formSend = true;
                    this.formSuccess = response.success;
                    this.isDisabled = false;

                    const params = new URLSearchParams([['section', this.activeSection]]);

                    this.updateSidebar(params);
                });
        }
    }
};
</script>

<style lang="scss">
@import './PopupMeetingComponent';
</style>
