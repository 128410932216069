<template>
    <div class="page">
        <transition name="fade">
            <PreloaderComponent v-if="isProcessing" modify="preloader_theme_opacity" />
        </transition>
        <HeaderComponent :name="getHeaderTitle" />

        <div class="page__wrapper">
            <div :class="['page__sidebar', {'is-open': sidebarOpened}]">
                <SidebarComponent
                    :name="getHeaderTitle"
                    :section="section"
                    :managers="managers"
                    @sidebarState="_sidebarStateHandler" />
            </div>

            <div :class="[
                'page__content',
                `page__content_theme_${$route?.name}`,
                {'page__content_theme_processing': isProcessing}
            ]">
                <ErrorComponent
                    v-if="pageError || globalError"
                    :data="pageErrorData"
                    :globalError="globalError"
                    @processing="_processingHandler" />

                <router-view
                    @managers="_updateManagers"
                    @processing="_processingHandler"
                    @errorShow="_errorPageHandler"
                ></router-view>
            </div>
        </div>

        <FooterComponent />

        <MobileBarComponent
            v-if="isMobile"
            :modify="sidebarOpened || selectOpened ? 'mobile-bar_theme_sidebar-opened' : ''" />

        <PopupsSection />

        <CookieComponent />
    </div>
</template>


<script>
import {mapActions, mapGetters} from 'vuex';
import CookieComponent from '@/components/CookieComponent/CookieComponent';
import ErrorComponent from '@/components/ErrorComponent/ErrorComponent';
import FooterComponent from '@/components/FooterComponent/FooterComponent';
import HeaderComponent from '@/components/HeaderComponent/HeaderComponent';
import MobileBarComponent from '@/components/MobileBarComponent/MobileBarComponent';
import PopupsSection from '@/sections/PopupsSection/PopupsSection';
import PreloaderComponent from '@/components/PreloaderComponent/PreloaderComponent';
import SidebarComponent from '@/components/SidebarComponent/SidebarComponent';

export default {
    name: 'DefaultPage',

    components: {
        CookieComponent,
        ErrorComponent,
        FooterComponent,
        HeaderComponent,
        MobileBarComponent,
        PopupsSection,
        PreloaderComponent,
        SidebarComponent
    },

    props: {
        section: String,

        globalError: {
            default: false,
            type   : Boolean
        }
    },

    data() {
        return {
            managers     : [],
            callbackData : '',
            meetingPopup : null,
            isProcessing : false,
            sidebarOpened: false,
            selectOpened : false,
            popupData    : null,
            pageError    : false,
            pageErrorData: null
        };
    },

    computed: {
        ...mapGetters({
            isMobile : 'GET_IS_MOBILE',
            isDesktop: 'GET_IS_DESKTOP'
        }),

        getHeaderTitle() {
            switch (this.section) {
                case 'owner':
                    return 'Дольщикам';
                case 'agency':
                    return 'Агентство недвижимости';
                case 'management':
                    return 'Управляющая компания';
                case 'buyer':
                default:
                    return 'Покупка объекта';
            }
        }
    },

    beforeRouteLeave(to, from, next) {
        this.isProcessing = false;

        next();
    },

    mounted() {
        this.$root.$on('selectOpened', this._openedSelectHandler);
    },

    watch: {
        $route() {
            if (this.pageError) {
                this._errorPageHandler(false);
            }
        }
    },

    methods: {
        ...mapActions(['setActiveSection', 'updateSidebar']),

        /**
         * Получаем список менеджеров из дочернего компонента
         * @param {array} managers - массив с объектами менеджеров
         */
        _updateManagers(managers) {
            this.managers = managers;
        },

        /**
         * Переключает флаг указывающий на загрузку данных какой-либо страницы
         * @param {boolean} status - статус идет загрузка данных или нет
         */
        _processingHandler(status) {
            const method = status ? 'add' : 'remove';

            this.isProcessing = status;

            document.body.classList[method]('is-processing');
        },

        /**
         * Открыт или закрыт сайдбар или селект - на мобильных
         * @param {boolean} state - состояние сайдбара на мобильных
         */
        _sidebarStateHandler(state) {
            this.sidebarOpened = state;
        },

        _openedSelectHandler(state) {
            this.selectOpened = state;
        },

        _errorPageHandler(flag, errorData) {
            this.pageError = flag;

            this.pageErrorData = flag ? errorData : null;
        }
    }
};
</script>

<style lang="scss">
@import './ContentPage';
</style>
