<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 1.5C10.5521 1.5 10.9998 1.94772 10.9998 2.5V17.5C10.9998 18.0523 10.5521 18.5 9.99984 18.5C9.44756 18.5 8.99984 18.0523 8.99984 17.5V2.5C8.99984 1.94772 9.44756 1.5 9.99984 1.5ZM15.8332 6.5C16.3855 6.5 16.8332 6.94772 16.8332 7.5V17.5C16.8332 18.0523 16.3855 18.5 15.8332 18.5C15.2809 18.5 14.8332 18.0523 14.8332 17.5V7.5C14.8332 6.94772 15.2809 6.5 15.8332 6.5ZM4.1665 9.8333C4.71879 9.8333 5.1665 10.281 5.1665 10.8333V17.5C5.1665 18.0523 4.71879 18.5 4.1665 18.5C3.61422 18.5 3.1665 18.0523 3.1665 17.5V10.8333C3.1665 10.281 3.61422 9.8333 4.1665 9.8333Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'CompareIcon',

    props: {
        fill: {
            default: '#ffffff',
            type   : String
        }
    }
};
</script>
