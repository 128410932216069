<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.62614 2.6264C3.01667 2.23587 3.64983 2.23587 4.04036 2.62639L7.99993 6.58594L11.9595 2.62639C12.35 2.23587 12.9832 2.23587 13.3737 2.6264C13.7642 3.01692 13.7642 3.65009 13.3737 4.04061L9.41414 8.00015L13.3737 11.9597C13.7642 12.3502 13.7642 12.9834 13.3737 13.3739C12.9832 13.7644 12.35 13.7644 11.9595 13.3739L7.99993 9.41436L4.04036 13.3739C3.64983 13.7644 3.01667 13.7644 2.62614 13.3739C2.23562 12.9834 2.23562 12.3502 2.62615 11.9597L6.58571 8.00015L2.62615 4.04061C2.23562 3.65009 2.23562 3.01692 2.62614 2.6264Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'CompareFalseIcon',

    props: {
        fill: {
            default: '#8B96A3',
            type   : String
        }
    }
};
</script>
