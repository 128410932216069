<template>
    <div class="treatment-page">
        <TreatmentEmptySection
            v-if="!list?.active?.length"
            @scrollToCreate="_scrollHandler" />

        <TreatmentListSection
            v-else
            :list="list.active"
            :finished=false />

        <TreatmentCreateSection
            ref="treatmentCreate"
            :objects="objects"
            :themes="themes"
            @isProcessing="_createProcessingHandler" />

        <TreatmentListSection
            v-if="list?.finished?.length"
            :list="list.finished"
            :finished=true />
    </div>
</template>

<script>
import TreatmentController from '@/dataControllers/treatments';
import TreatmentCreateSection from '@/sections/Treatment/TreatmentCreateSection/TreatmentCreateSection';
import TreatmentEmptySection from '@/sections/Treatment/TreatmentEmptySection/TreatmentEmptySection';
import TreatmentListSection from '@/sections/Treatment/TreatmentListSection/TreatmentListSection';

const TreatmentDataController = new TreatmentController();

export default {
    name: 'TreatmentPage',

    components: {
        TreatmentCreateSection,
        TreatmentEmptySection,
        TreatmentListSection
    },

    data() {
        return {
            isProcessing: false,
            list        : null,
            objects     : null,
            themes      : null
        };
    },

    watch: {
        isProcessing() {
            this.$emit('processing', this.isProcessing);
        }
    },

    mounted() {
        this.isProcessing = true;

        TreatmentDataController.getTreatments()
            .then((response) => {
                this.list = response.list;
                this.objects = response.objects;
                this.themes = response.themes;
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                this.isProcessing = false;
            });
    },

    methods: {
        /**
         * Подкрутка до блока
         */
        _scrollHandler() {
            this.$refs?.treatmentCreate?.$el?.scrollIntoView({behavior: 'smooth'});
        },

        /**
         * Переключение флага загрузки при отправке формы обращения
         * @param {boolean} state - флаг состояния отправки
         */
        _createProcessingHandler(state) {
            this.isProcessing = state;
        }
    }
};
</script>

<style lang="scss">
@import './TreatmentPage';
</style>
