<template>
    <div class="page empty-page">
        <HeaderComponent :emptyName="true" />

        <div class="page__wrapper">
            <div class="page__content empty-page__content">
                <h5 class="page__content-error empty-page__content-error">404 ошибка</h5>
                <h2 class="page__content-title empty-page__content-title">Нет такой страницы</h2>
                <p class="page__content-descr empty-page__content-descr">Страницы по такому адресу не существует. <br />
                    Попробуйте начать с избранного или другого раздела.</p>

                <router-link to="/content/favorites" class="button button_theme_gray empty-page__content-button">
                    В избранное
                </router-link>
            </div>
        </div>

        <FooterComponent />

        <CookieComponent />
    </div>
</template>

<script>
import CookieComponent from '@/components/CookieComponent/CookieComponent';
import FooterComponent from '@/components/FooterComponent/FooterComponent';
import HeaderComponent from '@/components/HeaderComponent/HeaderComponent';

export default {
    name: 'EmptyPage',

    components: {
        CookieComponent,
        FooterComponent,
        HeaderComponent
    }
};
</script>

<style lang="scss">
@import './EmptyPage';
</style>
