<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 10.8333C11.3807 10.8333 12.5 9.71404 12.5 8.33332C12.5 6.95261 11.3807 5.83332 10 5.83332C8.61929 5.83332 7.5 6.95261 7.5 8.33332C7.5 9.71404 8.61929 10.8333 10 10.8333Z" :stroke="fill" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 18.3333C13.3333 15 16.6667 12.0152 16.6667 8.33332C16.6667 4.65142 13.6819 1.66666 10 1.66666C6.3181 1.66666 3.33333 4.65142 3.33333 8.33332C3.33333 12.0152 6.66667 15 10 18.3333Z" :stroke="fill" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'PhoneIcon',

    props: {
        fill: {
            default: '#ffffff',
            type   : String
        }
    }
};
</script>
