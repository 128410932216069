<template>
    <div
        :class="[
            'header',
            {'is-fixed': isFixed}
        ]">
        <div class="header__top">
            <div class="grid">
                <div class="grid__row">
                    <ul v-if="Object.keys(settings?.sites).length && isDesktop"  class="header__top-projects">
                        <li
                            v-for="project in Object.keys(settings.sites)"
                            :key="project"
                        >
                            <LinkComponent
                                :href="settings.sites[project]?.url"
                                :text="settings.sites[project]?.title" />
                        </li>
                    </ul>

                    <div v-if="settings?.phone"
                        class="header__top-contacts">
                        <LinkComponent
                            :href="`tel:${settings.phone}`"
                            modify="header__top-contact header__top-phone"
                            :text="settings.phone" />
                        <div class="header__top-pointer"></div>
                        <ButtonComponent
                            modify="button_theme_thin button_theme_flat header__top-contact header__top-question"
                            text="Задать вопрос"
                            @click="_clickHandler('callback')" />
                    </div>
                </div>
            </div>
        </div>

        <div class="header__main">
            <div class="grid">
                <div class="grid__row">
                    <div class="header__main-logo">
                        <img
                            v-if="settings?.logoLight"
                            :src="settings.logoLight"
                            :alt="settings?.name">
                        <p v-html="settings?.name ? settings.name : 'Единый личный&nbsp;кабинет'"></p>
                    </div>

                    <nav
                        v-if="isDesktop && menuData"
                        class="header__main-menu"
                    >
                        <ul class="header__menu">
                            <li
                                v-for="item in _preparedMenu('start')"
                                :key="item.href"
                                class="header__menu-item"
                            >
                                <router-link
                                    :to="item.href"
                                    class="header__menu-link"
                                    active-class="is-active"
                                >
                                    {{ item.text }}
                                </router-link>
                            </li>
                        </ul>
                        <div
                            v-if="menuData.length > menuMaxLength"
                            class="header__menu-more"
                            @mouseenter="_menuMoreSwitch(true)"
                            @mouseleave="_menuMoreSwitch(false)">
                            <div
                                class="header__menu-more-button"
                             >
                                <MoreIcon />
                            </div>

                            <transition name="fade">
                                <ul
                                    v-if="menuMoreShow"
                                    @mouseenter="atMenuMore = true"
                                    @mouseleave="atMenuMore = false"
                                    class="header__menu-more-list">
                                    <li
                                        v-for="item in _preparedMenu('half')"
                                        :key="item.href">
                                        <router-link
                                            :to="item.href"
                                            active-class="is-active"
                                        >
                                            {{ item.text }}
                                        </router-link>
                                    </li>
                                </ul>
                            </transition>
                        </div>
                    </nav>
                    <div v-if="isMobile" class="header__main-mobile">
                        <ButtonComponent
                            modify="button_theme_thin header__main-question"
                            text="Задать вопрос"
                            @click="_clickHandler('callback')" />

                        <LinkComponent
                            v-if="settings?.phone"
                            :href="`tel:${settings.phone}`"
                            modify="header-main-callback"
                            icon="PhoneIcon" />
                    </div>

                    <div v-if="!isMobile" class="header__main-tablet">
                        <!-- <button class="button button_theme_thin header__main-notification">
                            <span class="button__icon">
                                <NotificationIcon fill="#ffffff"/>
                            </span>
                            <span class="button__notification">3</span>
                        </button> -->
                        <LinkComponent
                            v-if="favoritesLink"
                            type="button"
                            :href="favoritesLink"
                            modify="button_theme_thin header__main-favorites"
                            icon="FavoritesIcon"
                            fill="#ffffff" />

                        <LinkComponent
                            v-if="compareLink"
                            type="button"
                            :href="compareLink"
                            modify="button_theme_thin header__main-compare"
                            icon="CompareIcon"
                            fill="#ffffff" />

                        <ButtonComponent
                            v-if="isTablet"
                            modify="button_theme_thin header__main-burger"
                            icon="MenuIcon"
                            fill="#ffffff"
                            @click="_openMenu" />

                        <ButtonComponent
                            v-if="isDesktop"
                            modify="button_theme_thin header__main-burger"
                            icon="UserIcon"
                            fill="#ffffff"
                            @click="_openTooltip" />

                        <transition name="fade">
                            <div
                                v-if="isDesktop && userTooltipShow"
                                class="header__main-tooltip">
                                <UserComponent @closeUserTooltip="userTooltipShow = false"/>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="!emptyName && !isFixed" class="header__title">
            <h2 v-html="name"></h2>
        </div>

        <transition name="header-menu-open">
            <MobileMenuComponent
                v-if="menuState" />
        </transition>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import LinkComponent from '@/components/LinkComponent/LinkComponent.vue';
import MobileMenuComponent from '@/components/MobileMenuComponent/MobileMenuComponent';
import MoreIcon from '@/icons/MoreIcon';
// import NotificationIcon from '../../icons/NotificationIcon';
import UserComponent from '@/components/UserComponent/UserComponent';
import {Utils} from '@/scripts/utils';

export default {
    name: 'HeaderComponent',

    components: {
        ButtonComponent,
        LinkComponent,
        MobileMenuComponent,
        MoreIcon,
        // NotificationIcon,
        UserComponent
    },

    props: {
        name: {
            default: 'Страница личного кабинета',
            type   : String
        },
        emptyName: {
            default: false,
            type   : Boolean
        }
    },

    data() {
        return {
            userTooltipShow   : false,
            scrollPosition    : null,
            isFixed           : false,
            prevScrollPosition: null,
            menuMoreShow      : false,
            atMenuMore        : false,
            menuMaxLength     : 4
        };
    },

    computed: {
        ...mapGetters({
            settings     : 'GET_SETTINGS',
            menuData     : 'GET_MENU',
            menuState    : 'GET_MOBILE_MENU_STATE',
            isMobile     : 'GET_IS_MOBILE',
            isTablet     : 'GET_IS_TABLET',
            isDesktop    : 'GET_IS_DESKTOP',
            favoritesLink: 'GET_FAVORITES_LINK',
            compareLink  : 'GET_COMPARE_LINK'
        })
    },

    updated() {
        this._setHeaderHeight();
    },

    mounted() {
        window.addEventListener('scroll', Utils.debounce(() => {
            this._changeState();
        }, 10));

        window.addEventListener('resize', Utils.debounce(() => {
            this._changeState();
            this._setHeaderHeight();
        }, 10));

        this.$nextTick(() => {
            this._setHeaderHeight();
        });
    },

    methods: {
        ...mapActions(['setMobileMenuState']),

        /**
         * Подготовка массива меню для рендера на десктопе
         * @param {string} direction - направление начала нового массива
         * @return {array} - массив нужного меню
         */
        _preparedMenu(direction) {
            if (!this.menuData) {
                return [];
            }

            const enterPoint = direction === 'start' ? 0 : this.menuMaxLength;
            const endPoint = direction === 'start' ? this.menuMaxLength : this.menuData.length;

            return this.menuData.slice(enterPoint, endPoint);
        },

        _openMenu() {
            this.setMobileMenuState();
        },

        _openTooltip() {
            if (!this.isDesktop) {
                return;
            }

            this.userTooltipShow = !this.userTooltipShow;
        },

        _clickHandler(popupType) {
            this.$root.$emit('showPopup', popupType);
        },

        /**
         * Изменяет состояние хэдера
         * @private
         */
        _changeState() {
            this.scrollPosition = window.scrollY;

            if (this.scrollPosition > 180) {
                this.isFixed = true;
            } else {
                this.isFixed = false;
            }
        },

        _setHeaderHeight() {
            document.documentElement.style.setProperty('--header-height', `${this.$el.clientHeight}px`);
        },

        _menuMoreSwitch(state) {
            if (state) {
                this.menuMoreShow = state;
            } else {
                setTimeout(() => {
                    this.menuMoreShow = this.atMenuMore;
                }, 300);
            }
        }
    }
};
</script>

<style lang="scss">
@import "./HeaderComponent";
</style>
