<template>
    <div class="order-second order-body">
        <div class="order-second__personal">
            <h5 class="order-second__personal-title">Паспортные данные</h5>
            <div class="order-second__personal-content order-universal__block">
                <InputComponent
                    name="passport-series"
                    placeholder="Серия паспорта"
                    inputModify="input_theme_order"
                    maxlength="4"
                    :validation="{
                        type: 'passportSeries',
                        msg: 'Номер паспорта может содержать только цифры и буквы латинского алфавита'
                    }"
                    :data-store-instance="OrderStore"
                    @inputChanged="inputChanged"
                />
                <InputComponent
                    name="passport-num"
                    placeholder="Номер паспорта"
                    inputModify="input_theme_order"
                    maxlength="6"
                    :validation="{
                        type: 'betweenValue',
                        length: 4,
                        maxLength: 6,
                        msg: 'Серия паспорта должна состоять из 6 цифр'
                    }"
                    :data-store-instance="OrderStore"
                    @inputChanged="inputChanged"
                />
                <DatePicker
                    v-model="date"
                    :max-date="new Date()"
                    @dayclick="onDayClick"
                    class="input input_theme_date-selector" >
                    <template v-slot="{ togglePopover }">
                        <InputComponent
                            name="birthday"
                            placeholder="Дата рождения"
                            inputModify="input_theme_order"
                            mask="##.##.####"
                            maxlength="10"
                            :value="birthdayDate"
                            :validation="{
                                type: 'birthday',
                                msg : 'Введите корректную дату рождения'
                            }"
                            :data-store-instance="OrderStore"
                            @inputChanged="inputChanged"
                            @click="changeDate($event, togglePopover)"
                        />
                    </template>
                </DatePicker>
                <InputComponent
                    name="surname"
                    placeholder="Фамилия"
                    type="text"
                    inputModify="input_theme_order"
                    :validation="{
                        type: 'letters',
                        msg: 'Введите только буквы'
                    }"
                    :data-store-instance="OrderStore"
                    @inputChanged="inputChanged"
                />

                <InputComponent
                    name="name"
                    placeholder="Имя"
                    type="text"
                    inputModify="input_theme_order"
                    :validation="{
                        type: 'letters',
                        msg: 'Введите только буквы'
                    }"
                    :data-store-instance="OrderStore"
                    @inputChanged="inputChanged"
                />

                <InputComponent
                    name="father-name"
                    placeholder="Отчество"
                    type="text"
                    inputModify="input_theme_order"
                    :validation="{
                        type: 'letters',
                        msg: 'Введите только буквы'
                    }"
                    :data-store-instance="OrderStore"
                    @inputChanged="inputChanged"
                />
            </div>
        </div>

        <div class="order-second__passport">
            <h5 class="order-second__passport-title">Отсканированные страницы паспорта</h5>
            <div class="order-second__passport-descr">
                <p>Необходимо загрузить 2 разворота паспорта, где расположены:</p>
                <ul>
                    <li>основные данные владельца паспорта с фотографией;</li>
                    <li>данными о прописке.</li>
                </ul>
            </div>

            <div class="order-second__passport-upload">
                <FileUploadComponent
                    :filesCount="files?.length"
                    @uploadedFiles="uploadHandler" />
            </div>

            <div v-if="errorFiles?.length" class="order-second__passport-error">
                <img src="/webicons/order/alert.svg" alt="Ошибка при загрузке файла">
                <span>Неверный формат или размер файла(-ов): {{ getErrorFileNames }}!
                    Допустимые форматы — jpeg, jpg, png, pdf, doc, docx размером не более {{ maxSize }}&nbsp;Мб.</span>
            </div>

            <div v-if="files?.length" class="order-second__passport-files">
                <div
                    v-for="(file, index) in files"
                    :key="file.name"
                    class="order-second__passport-file">
                    <img src="/webicons/upload/file.svg" :alt="`Файл: ${file.name}`">
                    <div class="order-second__passport-file-content">
                        <p>{{ file.name }}</p>
                        <span>{{ (file.size / 1024 / 1024).toFixed(2) }}мб</span>
                    </div>
                    <ButtonComponent
                        modify="button_theme_flat order-second__passport-file-clear"
                        rightIcon="CloseIcon"
                        fill="#8d9aa8"
                        @click="removeFileHandler(file.name, index)" />
                </div>
            </div>
        </div>

        <div class="order-second__payment">
            <h5 class="order-second__payment-title">Выберите способ оплаты квартиры</h5>
            <p class="order-second__payment-description">Выбор способа поможет нам подготовить лучшее предложение
                для вас и&nbsp;проконсультировать вас по&nbsp;выбранному продукту</p>

            <div v-if="paymentMethods?.length" class="order-second__payment-methods">
                <CheckboxComponent
                    v-for="method in paymentMethods"
                    :key="method.id"
                    type="radio"
                    modify="order-second__payment-radio"
                    name="payment-method"
                    :text="method.text"
                    :checked="method.active"
                    @checkboxChanged="setPaymentMethod(method.text)" />
            </div>
        </div>

        <div class="order-second__confirm">
            <AgreementComponent
                modify="order-second__agreement"
                :agreementLink="agreementLink"
                @agreementChanged="agreementChangedHandler" />

            <ButtonComponent
                modify="button_theme_yuntolovo button_theme_gray order-second__button-cancel"
                text="Отмена бронирование"
                leftIcon="CloseIcon"
                fill="#8d9aa8"
                @click="cancelBooking"/>

            <ButtonComponent
                modify="button_theme_yuntolovo order-second__button-next"
                text="Продолжить"
                rightIcon="ArrowIcon"
                :fill="isOrderOpen ? '#ffffff' : '#8d9aa8'"
                :disabled="!isOrderOpen || !agreementState || isBookingStart"
                @click="nextStep" />
        </div>
    </div>
</template>

<script>
import AgreementComponent from '@/components/AgreementComponent/AgreementComponent';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import CheckboxComponent from '@/components/CheckboxComponent/CheckboxComponent';
import {DatePicker} from 'v-calendar';
import FileUploadComponent from '@/components/FileUploadComponent/FileUploadComponent';
import InputComponent from '@/components/InputComponent/InputComponent';
import OrderDataController from '@/dataControllers/order';

const OrderController = new OrderDataController();

export default {
    name: 'OrderSecondStepComponent',

    components: {
        AgreementComponent,
        ButtonComponent,
        CheckboxComponent,
        DatePicker,
        FileUploadComponent,
        InputComponent
    },

    data() {
        return {
            date          : new Date(),
            files         : [],
            errorFiles    : [],
            formError     : 0,
            isOrderOpen   : false,
            agreementState: true,
            dateSelected  : false,
            birthdayDate  : null,
            paymentMethods: null,
            activePayment : null,
            maxSize       : 8,
            isBookingStart: false,
            agreementLink : null
        };
    },

    computed: {
        OrderStore() {
            return OrderController.orderDataStore;
        },

        getErrorFileNames() {
            const names = [];

            this.errorFiles.forEach((file) => {
                names.push(file.name);
            });

            return names.join(',');
        }
    },

    mounted() {
        this.files = OrderController.getFiles();
        this.paymentMethods = OrderController.getPaymentMethods();

        this.activePayment = this.paymentMethods?.find((method) => {
            return method.active;
        });

        this.agreementLink = OrderController.getLinks('agreementLink');

        this.saveActivePayment();
    },

    methods: {
        /**
         * Отправка запроса на бронирование, в случае отсутствия ошибок в
         * заполнении формы бронирования
         */
        nextStep() {
            Object.keys(this.$refs).forEach((key) => {
                this.formError = 0;

                if (!this.$refs[key].inputField.length || this.$refs[key].error) {
                    this.$refs[key].error = true;
                    this.formError++;
                }
            });

            if (this.formError === 0) {
                this.$emit('bookingRequest');

                this.isBookingStart = true;
            }
        },

        /**
         * Обработчик загрузки файлов
         * @param {Array} files - массив валидных файлов
         * @param {Array} errorFiles - массив файлов которые не прошли валидацию
         */
        uploadHandler(files, errorFiles) {
            if (!files?.length && !errorFiles?.length) {
                return;
            }

            this.errorFiles = errorFiles?.length ? errorFiles : [];

            if (files?.length) {
                OrderController.setFiles(files);
                this.files = OrderController.getFiles();

                this.isOrderOpen =
                    OrderController.isOrderPossible(['passport-num', 'passport-series', 'birthday', 'name', 'surname']);
            }
        },

        /**
         * Обработчик удаления файлов
         * @param {string} name - Имя файла
         * @param {string | number} index - индекс файла в массиве файлов
         */
        removeFileHandler(name, index) {
            OrderController.removeFile(name, index);

            // Проверка на возможность бронирования
            this.isOrderOpen =
                OrderController.isOrderPossible(['passport-num', 'passport-series', 'birthday', 'name', 'surname']);
        },

        /**
         * Действие при изменении полей формы, которое вызывает проверку на
         * возможность бронирования помещения
         */
        inputChanged() {
            this.isOrderOpen =
                OrderController.isOrderPossible(['passport-num', 'passport-series', 'birthday', 'name', 'surname']);
        },

        /**
         * Изменение состояния чекбокса политики конфиденциальности
         * @param {boolean} state - состояние
         */
        agreementChangedHandler(state) {
            this.agreementState = state;
        },

        /**
         * Отмена бронирования
         */
        cancelBooking() {
            this.$emit('cancelBooking');
        },

        onDayClick(day) {
            this.dateSelected = true;
            this.birthdayDate = `${this.datePretty(day.day)}.${this.datePretty(day.month)}.${day.year}`;
        },

        changeDate(event, togglePopover) {
            togglePopover({ref: event.target});
        },

        datePretty(num) {
            return num < 10 ?
                String(num).padStart(2, 0) :
                num;
        },

        /**
         * Получаем и передаем на запись в стор метод оплаты
         * @param {string} method - метод оплаты
         */
        setPaymentMethod(method) {
            this.activePayment = this.paymentMethods?.find((item) => {
                return item.text === method;
            });

            this.saveActivePayment();
        },

        /**
         * Сохраняем в стор выбранный метод оплаты
         */
        saveActivePayment() {
            this.OrderStore.setData('payment', {value: this.activePayment.text});
        }
    }
};
</script>

<style lang="scss">
@import './OrderSecondStepComponent';
</style>
