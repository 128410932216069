<template>
    <div class="popup-owners__worktime">
        <div
            v-for="item in data"
            :key="item.id"
            class="popup-owners__worktime-item">
            <h5 class="popup-owners__worktime-title">{{ item.title }}:</h5>
            <p class="popup-owners__worktime-content" v-html="item.address"></p>

            <h5 class="popup-owners__worktime-title">Часы работы:</h5>
            <p class="popup-owners__worktime-content" v-html="item.time"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OwnerWorkTimeTemplate',

    props: {
        data: {
            default: () => {
                return [];
            },
            type: Array
        }
    }
};
</script>
