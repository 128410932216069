<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.5C8.48122 3.5 7.25 4.73122 7.25 6.25C7.25 7.76878 8.48122 9 10 9C11.5188 9 12.75 7.76878 12.75 6.25C12.75 4.73122 11.5188 3.5 10 3.5ZM5.25 6.25C5.25 3.62665 7.37665 1.5 10 1.5C12.6234 1.5 14.75 3.62665 14.75 6.25C14.75 8.87335 12.6234 11 10 11C7.37665 11 5.25 8.87335 5.25 6.25ZM10 14.3333C7.39097 14.3333 5.00403 15.4685 3.2276 17.3527C2.84873 17.7545 2.21584 17.7731 1.814 17.3943C1.41216 17.0154 1.39353 16.3825 1.7724 15.9807C3.88896 13.7358 6.7874 12.3333 10 12.3333C13.2126 12.3333 16.111 13.7358 18.2276 15.9807C18.6065 16.3825 18.5878 17.0154 18.186 17.3943C17.7842 17.7731 17.1513 17.7545 16.7724 17.3527C14.996 15.4685 12.609 14.3333 10 14.3333Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'UserIcon',

    props: {
        fill: {
            default: '#ffffff',
            type   : String
        }
    }
};
</script>
