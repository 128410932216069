export enum ERequests {
    actions = '/tests/lk/actions/actions.php',
    actionItem = '/tests/lk/actions/actionItem.php',
    additionalService = '/tests/lk/agency/services.php',
    agency = '/tests/lk/agency/agency.php',
    agencyCommerce = '/tests/lk/agency/commerce.php',
    auth = '/tests/lk/auth/sendCode.php',
    authCheck = '/tests/lk/auth/checkCode.php',
    banners = '/tests/lk/banners.php',
    booking = '/tests/lk/booking/booking.php',
    compare = '/tests/lk/compare.php',
    compareAdd = '/tests/lk/compare.php',
    compareRemove = '/tests/lk/compare.php',
    deleteBooking = '/tests/lk/booking/booking-empty.php',
    estateSell = '/tests/lk/agency/estateSell.php',
    favoritesAdd = '/tests/lk/favorites.php',
    favoritesRemove = '/tests/lk/favorites.php',
    favoritesResult = '/tests/lk/favorites.php',
    forms = '/tests/lk/forms.php',
    formSubmit = '/tests/lk/callbackForms.php',
    getTreatments = '/tests/lk/treatment.php',
    keys = '/tests/lk/owner/keysSuccess.php',
    keysSlot = '/tests/lk/owner/keysSlot.php',
    // logout = '/api/v1/auth/logout',
    managementContacts = '/tests/lk/management/managementContacts.php',
    managementInfo = '/tests/lk/management/managementInfo.php',
    meeting = '/tests/lk/meetings.php',
    menu = '/tests/lk/menu.php',
    sidebar = '/tests/lk/sidebar.php',
    mortgage = '/tests/lk/mortgage.php',
    news = '/tests/lk/management/managementNews.php',
    newsItem = '/tests/lk/management/managementNewsItem.php',
    objects = '/tests/lk/objects.php',
    order = '/tests/lk/order.php',
    ownerAboutBanner = '/tests/lk/owner/about.php',
    ownerAboutYuntolovo = '/tests/lk/owner/aboutProject1.php',
    ownerAboutSevdol = '/tests/lk/owner/aboutProject2.php',
    ownerKeys = '/tests/lk/owner/keys.php',
    ownerContracts = '/tests/lk/owner/ownerContracts.php',
    paymentMethods = '/tests/lk/management/managementPayment.php',
    projects = '/tests/lk/complexes.php',
    projectMain = '/tests/lk/projectMain.php',
    // register = '/api/v1/auth/register',
    // sendError = '/api/v1/error',
    services = '/tests/lk/management/managementServices.php',
    settings = '/tests/lk/settings.php',
    // setTreatment = '/api/v1/user/request',
    slider = '/tests/lk/slider.php',
    tour = '/tests/lk/meetings.php',
    tradeIn = '/tests/lk/tradeIn/tradeIn.php',
    tradeInAddress = '/tests/lk/tradeIn/tradeInAddress.php',
    tradeInContracts = '/tests/lk/tradeIn/tradeInContracts.php',
    tradeInAddressEvaluation = '/tests/lk/tradeIn/tradeInAddressEvaluation.php',
    user = '/tests/lk/user.php',
    userUK = '/tests/lk/user.php',
}
