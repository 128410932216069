<template>
    <div v-if="actionsData" class="actions">
        <ActionsListComponent
            v-if="actionsData?.list?.length"
            :list="actionsData.list"
            @showAction="showActionHandler" />

        <ButtonComponent
            v-if="actionsData?.more?.more"
            modify="button_theme_white actions__more"
            :text="isProcessing ? 'Загрузка...' : 'Показать ещё'"
            @click="_getRequest('more');" />

        <HelpBannerComponent modify="actions__help is-full-image"
                             image="/images/help-choose/help-choose-interior.jpg" />
    </div>
</template>

<script>
import {ActionsDataController} from '@/dataControllers/actions';
import ActionsListComponent from '@/components/Actions/ActionsListComponent/ActionsListComponent';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import HelpBannerComponent from '@/components/HelpBannerComponent/HelpBannerComponent';

const ActionsController = new ActionsDataController();

export default {
    name: 'ActionsPage',

    components: {
        ActionsListComponent,
        ButtonComponent,
        HelpBannerComponent
    },

    data() {
        return {
            isProcessing: false,
            isEmpty     : false,
            actionsData : null,
            popupData   : null,
            listData    : []
        };
    },

    mounted() {
        this._getRequest('init');
    },

    methods: {
        /**
         * Универсальный обработчик запроса к апи списка Акций
         * @param {string} type - тип запроса - инит / подгрузка данных
         * @param {string} id - идентификатор объекта для получения данных новости
         */
        _getRequest(type, id = null) {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            ActionsController.getActions({
                type,
                id,
                page: type === 'init' ? 1 : this.actionsData?.more?.page + 1 || null
            })
                .then((response) => {
                    if (response.success) {
                        this.actionsData = response;
                        this.listData.push(this.actionsData.list);
                        this.isEmpty = this.actionsData.noResults;
                    } else {
                        this.$emit('errorShow', true, {
                            statusCode: response.errorData?.status,
                            url       : response.errorData?.url
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.$emit('errorShow', true, {
                        statusCode: 500,
                        url       : 'Неизвестная ошибка на странице Акции / Новости'
                    });
                })
                .then(() => {
                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                });
        },

        _getRequestAction(id = null) {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            ActionsController.getActionContent(id)
                .then((response) => {
                    this.popupData = response;

                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                    this.$root.$emit('showPopup', 'actions', this.popupData);
                });
        },

        /**
         * Открываем попап акции
         * @param {string} id - айди статьи
         */
        showActionHandler(id) {
            this.popupData = null;
            this._getRequestAction(id);
        }
    }
};
</script>

<style lang="scss">
@import './ActionsPage';
</style>
