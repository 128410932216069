<template>
    <div class="mortgage-page">
        <TabsComponent
            v-if="types?.length"
            modify="mortgage-page__tabs"
            type="button"
            :tabsData="types"
            :activeTab="activeTab"
            @changeTab="changeTabHandler" />

        <div class="mortgage-content">
            <div class="mortgage-title">
                <h4 v-if="activeTab?.name">{{ activeTab.name }}</h4>
                <p v-if="activeTab?.descr" v-html="activeTab.descr"></p>
            </div>
            <MortgageFiltersComponent
                :filters="filters"
                :activeProject="activeProject?.slug"
                :activeStatus="activeStatus?.slug"
                :percent="Number(percent)"
                :payment="payment"
                @projectUpdated="projectUpdate"
                @statusUpdated="statusUpdate"
                @sliderUpdated="sliderUpdate" />

            <MortgageListComponent
                :programs="programs"
                :flatPrice="flatPrice"
                @popupShow="popupHandler" />
        </div>
    </div>
</template>

<script>
import MortgageCalculator from '@/dataControllers/mortgage';
import MortgageFiltersComponent from '@/components/Mortgage/MortgageFiltersComponent/MortgageFiltersComponent';
import MortgageListComponent from '@/sections/MortgageListSection/MortgageListSection';
import TabsComponent from '@/components/TabsComponent/TabsComponent';

const MortgageController = new MortgageCalculator();

export default {
    name: 'MortgagePage',

    components: {
        MortgageFiltersComponent,
        MortgageListComponent,
        TabsComponent
    },

    data() {
        return {
            types        : [],
            programs     : [],
            flatPrice    : '0',
            filters      : {},
            activeTab    : null,
            activeProject: null,
            activeStatus : null,
            isProcessing : false,
            percent      : 0,
            payment      : 0
        };
    },

    computed: {
        MortgageDataStore() {
            return MortgageController.mortgageDataStore;
        }
    },

    mounted() {
        /**
         * Получаем данные страницы
         */
        this.getRequest('init');
    },

    methods: {
        /**
         * Универсальный обработчик запросов на странице
         * @param {string} type - тип запроса к АПИ
         * @param {number} project - ид проекта
         * @param {number} program - тип программы
         * @param {number} status - статус строительства
         */
        getRequest(type, project = 1, program = 2, status = 0) {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            MortgageController.getMortgage(type, project, program, status)
                .then(() => {
                    const store = this.MortgageDataStore.dataStore;

                    this.programs = store.resultList;
                    this.flatPrice = store.price;
                    this.filters = store.filters;
                    this.types = store.types;

                    // Если список табов не пустой, устанавливаем активный таб
                    // аналогичный тому что пришел в запросе
                    this.activeTab = this.types?.length ?
                        this.types.find((item) => {
                            return item?.slug === Number(program);
                        }) :
                        null;

                    this.activeProject = this.filters.projects?.list?.length ?
                        this.filters.projects?.list?.find((item) => {
                            return item.id === Number(project);
                        }) :
                        null;

                    this.activeStatus = this.filters.status?.list?.length ?
                        this.filters.status.list.find((item) => {
                            return item.id === Number(status);
                        }) :
                        null;

                    MortgageController.updateFirstPayment(this.flatPrice);

                    this.percent = store.creditPercent;
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                });
        },

        /**
         * Действие при апдейте слайдеров
         * @param {object} slider - объект "потроганного" слайдера
         */
        sliderUpdate(slider) {
            MortgageController.sliderHandled(slider.category, slider.value);

            this.filters = this.MortgageDataStore.dataStore.filters;
            this.percent = this.MortgageDataStore.dataStore.creditPercent;
            this.programs = this.MortgageDataStore.dataStore.resultList;
        },

        /**
         * Обрабатываем клик по выбору селекта с проектами
         * @param {object} item - объект выбранного элемента
         */
        projectUpdate(item) {
            if (this.activeProject?.slug === item?.slug) {
                return;
            }

            this.getRequest('change', item.id, this.activeTab.id, this.activeStatus.id);
        },

        /**
         * Обрабатываем клик по выбору селекта со статусами
         * @param {object} item - объект выбранного элемента
         */
        statusUpdate(item) {
            if (this.activeStatus.slug === item.slug) {
                return;
            }

            this.getRequest('change', this.activeProject.id, this.activeTab.id, item.id);
        },

        /**
         * Клик по программе ипотеки
         * @param {string} id - программа ипотеки
         */
        changeTabHandler(id) {
            if (this.activeTab?.id === id) {
                return;
            }

            const tab = this.types.find((item) => {
                return item.id === id;
            });

            this.getRequest('change', this.activeProject.id, tab.id);
        },

        popupHandler(text) {
            const phrase = this.filters.paymentSlider ?
                `${text}. Первый взнос: ${this.filters.paymentSlider.value}` :
                text;

            this.$emit('popupShow', phrase);
        }
    }
};
</script>

<style lang="scss">
@import './MortgagePage';
</style>
