<template>
    <div :class="['form__agreement', modify]">
        <CheckboxComponent
            type='checkbox'
            :icon=true
            name='agreement'
            :text='`Подтверждаю своё согласие на
                <a
                    class="link"
                    href="${agreementLink ? agreementLink : "#" }"
                    target="_blank">обработку персональных данных</a>`'
            :checked='checked'
            @checkboxChanged="_agreementChanged"
        />
    </div>
</template>

<script>
import CheckboxComponent from '../CheckboxComponent/CheckboxComponent';

export default {
    name: 'AgreementComponent',

    props: ['checked', 'modify', 'agreementLink'],

    components: {
        CheckboxComponent
    },

    methods: {
        _agreementChanged(state) {
            this.$emit('agreementChanged', state);
        }
    }
};
</script>
