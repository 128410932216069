var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"user",staticClass:"user"},[(_vm.isDesktop)?_c('ButtonComponent',{attrs:{"modify":"button_theme_transparent button_theme_thin user__close","icon":"CloseIcon","fill":"#8b96a3"},on:{"click":_vm._closeUserTooltip}}):_vm._e(),_vm._v(" "),(Object.keys(_vm.user)?.length)?[(!_vm.isDesktop)?_c('div',{staticClass:"user__ico"},[_c('img',{attrs:{"src":_vm.userPhoto,"alt":_vm.user?.name}})]):_vm._e(),_vm._v(" "),(!_vm.isDesktop)?_c('h4',{staticClass:"user__name"},[_vm._v(_vm._s(_vm.user?.name)+" "+_vm._s(_vm.user?.surname))]):_c('h5',{staticClass:"user__name"},[_vm._v(_vm._s(_vm.user?.name)+" "+_vm._s(_vm.user?.surname))]),_vm._v(" "),_c('div',{staticClass:"user__contacts"},[(_vm.user?.phone)?_c('div',{class:[
                    'user__contacts-item',
                    'user__phone',
                    {'is-desktop': _vm.isDesktop}
                ]},[(_vm.isDesktop)?_c('span',[_vm._v("Номер телефона")]):_vm._e(),_vm._v(" "),_c('LinkComponent',{attrs:{"href":`tel:+7${_vm.user.phone}`,"text":`+7${_vm.user.phone}`}})],1):_vm._e(),_vm._v(" "),(_vm.user?.email)?_c('div',{class:[
                    'user__contacts-item',
                    'user__email',
                    {'is-desktop': _vm.isDesktop}
                ]},[(_vm.isDesktop)?_c('span',[_vm._v("E-mail")]):_vm._e(),_vm._v(" "),_c('LinkComponent',{attrs:{"href":`mailto:${_vm.user.email}`,"text":_vm.user.email}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"user__buttons"},[_c('ButtonComponent',{attrs:{"modify":"user__buttons-button","text":"Изменить","disabled":_vm.isProcessing},on:{"click":_vm._userChange}}),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"button_theme_gray user__buttons-button","text":"Выйти","disabled":_vm.isProcessing},on:{"click":_vm._userExit}})],1)]:[_c('div',{staticClass:"user__empty"},[_c('div',{staticClass:"user__name"}),_vm._v(" "),_c('div',{staticClass:"user__contacts"},_vm._l((_vm.emptyElement),function(i){return _c('div',{key:i,staticClass:"user__contacts-item"},[_c('span'),_vm._v(" "),_c('p')])}),0),_vm._v(" "),_c('div',{staticClass:"user__buttons"},_vm._l((_vm.emptyElement),function(i){return _c('div',{key:i,staticClass:"user__buttons-button"})}),0)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }