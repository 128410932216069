<template>
    <div class="clear">
        <div class="clear__overlay"></div>
        <div class="clear__content">
            <div class="clear__icon">
                <TrashBigIcon />
            </div>
            <h5 class="clear__title">{{ clearTitle }}</h5>
            <div class="clear__description">{{ clearDescr }}</div>
            <div class="clear__buttons">
                <ButtonComponent class="button_theme_gray" :text="closeButtonText" @click="_closePopup" />

                <ButtonComponent v-if="!clearComplete" :text="buttonText" @click="_clearResult" />
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import TrashBigIcon from '../../icons/TrashBigIcon.vue';

export default {
    name: 'ClearComponent',

    components: {
        ButtonComponent,
        TrashBigIcon
    },

    props: {
        title: {
            default: 'Очистить результат?',
            type   : String
        },

        description: {
            default: 'Вы уверены, что хотите полностью очистить результат?',
            type   : String
        },

        buttonText: {
            default: 'Да, очистить',
            type   : String
        },

        closeText: {
            type: String
        },

        clearComplete: {
            default: false,
            type   : Boolean
        }
    },

    computed: {
        clearTitle() {
            return this.clearComplete ? 'Заявка отправлена' : this.title;
        },

        clearDescr() {
            return this.clearComplete ? 'Менеджер свяжется с вами в ближайшее время' : this.description;
        },

        closeButtonText() {
            let text = null;

            if (this.closeText) {
                text = this.closeText;
            } else {
                text = this.clearComplete ? 'Ок' : 'Отмена';
            }

            return text;
        }
    },

    methods: {
        _closePopup() {
            this.$emit('closeClearPopup');
        },

        _clearResult() {
            this.$emit('makeClear');
        }
    }
};
</script>

<style lang="scss">
@import './ClearComponent';
</style>
