<template>
    <div class="favorites-list">
        <div class="favorites-list__content">
            <FavoritesCard
                v-for="card in listData?.premises"
                :key="card.id"
                :card="card"
                :activeTab="activeTab"
                :activeSection="activeSection"
                @removeFavorite="_removeHandler"
                @processing="_processingHandler"
            />
        </div>

        <ButtonComponent
            v-if="listData?.more?.more"
            modify="button_theme_white favorites-list__more-button"
            :text="listData?.more?.text"
            @click="_getMoreData"
        />
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import FavoritesCard from '../FavoritesCard/FavoritesCard';

export default {
    name: 'FavoritesListComponent',

    components: {
        ButtonComponent,
        FavoritesCard
    },

    props: {
        listData: {
            default() {
                return {};
            },
            type: Object
        },
        activeTab: {
            default: 'flats',
            type   : String
        },
        activeSection: {
            default: 'buyers',
            type   : String
        }
    },

    methods: {
        _getMoreData() {
            this.$emit('getMoreFavorites');
        },

        _removeHandler(id) {
            this.$emit('removeHandler', id);
        },

        _processingHandler(state) {
            this.$emit('processing', state);
        }
    }
};
</script>

<style lang="scss">
@import './FavoritesListComponent';
</style>
