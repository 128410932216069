import type {AxiosError, AxiosResponse} from 'axios';
import type {
    IOrderData,
    IOrderDataController,
    IOrderResponse,
    IOrderResult
} from './types';
import axios from 'axios';
import {ERequests} from '../../requests';
import {OrderData} from './orderData';

const OrderDataStore = new OrderData();

export default class OrderDataController implements IOrderDataController {
    private error: boolean;

    constructor() {
        this.error = false;
    }

    /**
     * Возвращаем данные бронирования
     */
    public get orderDataStore(): IOrderData {
        return OrderDataStore;
    }

    /**
     * Запрос к апи бронирования
     * @param {object} data - данные запроса
     * @param {string} type - "хвост" адреса запроса
     * @returns {object} - результат ответа api
     */
    public async getOrderData(data: object, type: string): Promise<IOrderResult> {
        const result = {
            note         : {},
            object       : {},
            conditionals : {},
            booking      : {},
            error        : false,
            errorData    : {},
            link         : null,
            agreementLink: null
        };

        //  await axios.post(`${ERequests.order}${type}`, data)
        await axios.post(`${ERequests.order}`, {
            ...data,
            type: type === 'check' ? 'check' : 'booking'
        })
            .then((response: AxiosResponse<IOrderResponse>) => {
                if (response.data.success) {
                    const resultData = response.data.data;

                    result.link = resultData?.catalogLink ? resultData.catalogLink : resultData.bookingLink;
                    result.note = resultData?.note;
                    result.object = resultData?.object;
                    result.conditionals = resultData?.conditionals;
                    result.booking = resultData?.booking;
                    result.agreementLink = resultData.agreementLink;
                } else {
                    result.error = true;
                    result.errorData = response.data.messages;
                }
            })
            .catch((error: AxiosError) => {
                result.error = true;

                if (error.response?.data?.messages?.constructor?.name === 'Object') {
                    result.errorData = error.response.data.messages;
                } else {
                    const returnResult = {
                        status: 'Техническая ошибка',
                        text  : ''
                    };

                    if (error?.response?.data?.errors && Object.keys(error.response.data.errors).length) {
                        returnResult.text = 'Проверьте корректность заполнения полей';
                    } else {
                        returnResult.text = 'Попробуйте вернуться к поиску или <br />попробуйте еще раз позже';
                    }

                    result.errorData = returnResult;
                }
            });

        return result;
    }

    /**
     * Добавляет файлы в массив
     * @param {array} files - список файлов для добавления
     */
    public setFiles(files: File[]): void {
        OrderDataStore.pushFile(files);
    }

    /**
     * Возвращаем список загруженных файлов
     * @returns {Array} - список загруженных файлов
     */
    public getFiles(): File[] {
        return OrderDataStore.getFiles();
    }

    public setLinks(link: string, value: string): void {
        OrderDataStore.setLinks(link, value);
    }

    public getLinks(link: string): string | null {
        return OrderDataStore.getLinks(link);
    }

    /**
     * Удаляем файл из списка файлов
     * @param {string} name - имя файла
     * @param {number} index - индекс файла в массиве файлов
     */
    public removeFile(name: string, index: number): void {
        OrderDataStore.removeFile(name, Number(index));
    }

    /**
     * Проверка заполнености требуемых данных для бронирования
     * @param {array} inputs - список полей для проверки заполнености
     * @returns {boolean} - результат проверки
     */
    public isOrderPossible(inputs: string[]): boolean {
        if (!inputs.length) {
            return false;
        }

        let inputsPossible = true;

        inputs.forEach((input: string) => {
            if (!OrderDataStore.dataStore.inputs[input] ||
                OrderDataStore.dataStore.inputs[input]['error'] ||
                !OrderDataStore.dataStore.inputs[input]['value'].length
            ) {
                inputsPossible = false;
            }
        });

        return inputsPossible && this.getFiles().length > 0;
    }

    public getPaymentMethods(): object[] {
        return OrderDataStore.returnPaymentMethods();
    }
}
