<template>
    <div class="owner-steps">
        <div class="owner-steps__list">
            <div
                v-for="(step, index) in steps"
                :key="step.id"
                class="owner-steps__step">
                <div class="owner-steps__step-num">{{ index + 1 }}</div>
                <div class="owner-steps__step-content">
                    <h5 class="owner-steps__step-title" v-html="step.title"></h5>
                    <div v-if="step.text" class="owner-steps__step-text" v-html="step.text"></div>

                    <template v-if="step.components?.length">
                        <UniversalBlockComponent
                            v-for="component in step.components"
                            :modify="`owner-steps__step-${component.type}`"
                            :componentTitleModify="`owner-steps__step-${component.type}-title`"
                            :componentBlockModify="_calcOwnerButtonClass(component.type)"
                            :key="component.id"
                            :type="component.type"
                            :title="component.title"
                            :content="component.content" />
                    </template>

                    <template v-if="step.modals?.length">
                        <UniversalBlockComponent
                            v-for="component in step.modals"
                            modify="owner-steps__step-button"
                            componentBlockModify="button_theme_gray owner-steps__step-links-item"
                            :key="component.id"
                            type="modals"
                            :content="component" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UniversalBlockComponent from '@/components/UniversalBlockComponent/UniversalBlockComponent';

export default {
    name: 'OwnerAcceptSteps',

    components: {
        UniversalBlockComponent
    },

    props: {
        steps: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    methods: {
        _calcOwnerButtonClass(type) {
            return type === 'links' ?
                `button_theme_gray owner-steps__step-${type}-item` :
                `owner-steps__step-${type}-item`;
        }
    }
};
</script>

<style lang="scss">
@import './OwnerAcceptSteps';
</style>
