<template>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#62725b"/>
        <path d="M26 22H26.0067M26 26C28.2091 26 30 24.2091 30 22C30 19.7909 28.2091 18 26 18C23.7909 18 22 19.7909 22 22C22 22.1825 22.0122 22.3621 22.0359 22.538C22.0748 22.8275 22.0942 22.9722 22.0811 23.0637C22.0675 23.1591 22.0501 23.2105 22.0031 23.2946C21.958 23.3753 21.8785 23.4549 21.7194 23.6139L18.3124 27.0209C18.1971 27.1362 18.1395 27.1939 18.0982 27.2611C18.0617 27.3208 18.0348 27.3858 18.0184 27.4538C18 27.5306 18 27.6121 18 27.7752V28.9333C18 29.3067 18 29.4934 18.0727 29.636C18.1366 29.7614 18.2386 29.8634 18.364 29.9273C18.5066 30 18.6933 30 19.0667 30H20.2248C20.3879 30 20.4694 30 20.5462 29.9816C20.6142 29.9652 20.6792 29.9383 20.7389 29.9018C20.8061 29.8605 20.8638 29.8029 20.9791 29.6876L24.3861 26.2806C24.5451 26.1215 24.6247 26.042 24.7054 25.9969C24.7895 25.9499 24.8409 25.9325 24.9363 25.9189C25.0278 25.9058 25.1725 25.9252 25.462 25.9641C25.6379 25.9878 25.8175 26 26 26Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'KeysIcon'
};
</script>
