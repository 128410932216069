import type {
    ISetTreatmentData,
    // ISetTreatmentResponse,
    ITreatmentItem,
    ITreatmentResponse,
    ITreatmentResult,
    ITreatmentSortedList
} from './types';
import axios from 'axios';
import type {AxiosResponse} from 'axios';
import {ERequests} from '../../requests';

export default class TreatmentController {
    /**
     * Получение списка обращений
     * @returns {object} - возвращает подготовленный объект с массивами активных
     * и завершенных обращений
     */
    public async getTreatments(): Promise<ITreatmentResult> {
        const result = {
            list   : null,
            objects: null,
            themes : null
        };

        await axios.get(ERequests.getTreatments)
            .then((response: AxiosResponse<ITreatmentResponse>) => {
                result.list = this._sortResultList(response.data.data.list);
                result.objects = response.data.data.objects;
                result.themes = response.data.data.themes;
            });

        return result;
    }

    /**
     * Обработчик отправки обращения
     * @param {object} data - объект с данными для отправки
     * @returns {boolean} - возвращает успех или ошибку отправки
     */
    public async setTreatments(data: ISetTreatmentData): Promise<boolean> {
        let result = false;

        // await axios.post(ERequests.setTreatment, data)
        //     .then((response: AxiosResponse<ISetTreatmentResponse>) => {
        //         result = response.data.success;
        //     });

        result = true;

        return result;
    }

    /**
     * Сортирует список из ответа бека по признаку активности
     * @param {array} list - список обращений
     * @returns {object} - объект с массивами списков по признаку завершенности
     */
    private _sortResultList(list: ITreatmentItem[]): ITreatmentSortedList {
        const sortedList = list.reduce((acc: ITreatmentSortedList, item: ITreatmentItem) => {
            const type = item.status.slug === 'completed' ? 'finished' : 'active';

            acc[type].push(item);

            return acc;
        }, {
            active  : [],
            finished: []
        });

        return sortedList;
    }
}
