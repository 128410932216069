<template>
    <div :class="['empty', modify]">
        <div class="empty__main">
            <h4 class="empty__main-title">{{ title }}</h4>

            <div v-if="favorites" class="empty__main-text">
                <span>Нажмите на</span>
                <FavoritesIcon fill="#62725b" />
                <span>чтобы добавить объект недвижимости в&nbsp;избранное
                        и&nbsp;получить доступ к&nbsp;удобному сравнению</span>
            </div>
            <p class="empty__main-text" v-html="text"></p>

            <ButtonComponent
                modify="empty__main-button"
                text="Выберите проект"
                @click="_projectScroll" />
        </div>
        <div ref="projectsList" class="empty__projects">
            <h5 class="empty__projects-title">Наши проекты</h5>
            <div class="empty__projects-list">
                <div
                    v-for="project in projects"
                    :key="project.id"
                    class="empty__projects-item">
                    <div class="empty__projects-image">
                        <img :src="project.image" :alt="project.name">
                    </div>
                    <div class="empty__projects-content">
                        <div class="empty__projects-description">
                            <h3 class="empty__projects-name">{{ project.name }}</h3>
                            <div class="empty__projects-subinfo">
                                <div class="empty__projects-price">
                                    {{ project?.price || 'Цена от 16 млн. Р' }}
                                </div>
                                <div class="empty__projects-metro">
                                    {{ project?.metro || '5 мин. до м. «Парнас» и КАД' }}
                                </div>
                            </div>
                        </div>
                        <LinkComponent
                            :href="project.href"
                            class="button empty__projects-button"
                            text="Сайт проекта" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import FavoritesIcon from '@/icons/FavoritesIcon';
import LinkComponent from '@/components/LinkComponent/LinkComponent';
import {mapGetters} from 'vuex';

export default {
    name: 'FavoritesEmptyComponent',

    components: {
        ButtonComponent,
        FavoritesIcon,
        LinkComponent
    },

    props: {
        modify: {
            default: '',
            type   : String
        },
        title: {
            default: 'Здесь ничего нет',
            type   : String
        },
        text: {
            default: '',
            type   : String
        },
        favorites: {
            default: false,
            type   : Boolean
        }
    },

    computed: {
        ...mapGetters({
            projects: 'GET_PROJECTS_LIST'
        })
    },

    methods: {
        _projectScroll() {
            this.$refs?.projectsList?.scrollIntoView({behavior: 'smooth'});
        }
    }
};
</script>

<style lang="scss">
@import './EmptyComponent';
</style>
