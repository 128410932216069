<template>
    <div class="popup popup-question">
        <div
            class="popup__overlay"
            @click="_closeClick"
            ></div>
        <div class="popup__content popup-question__content">
            <div class="popup__content-top">
                <ButtonComponent
                    modify="button_theme_thin button_theme_transparent popup__content-close"
                    icon="CloseIcon"
                    fill="#8b96a3"
                    @click="_closeClick" />
            </div>
            <div class="popup__content-body popup-question__content-body">
                <h3 class="popup-question__content-title" v-html="computedTitle('title')"></h3>
                <div class="popup-question__content-descr" v-html="computedTitle('descr')"></div>
                <form action="" class="popup-question__content-form">
                    <InputComponent
                        v-if="callbackData?.length"
                        name="callbackData"
                        type="hidden"
                        :value="callbackData"
                        :data-store-instance="UserFormStore"
                    />

                    <SelectComponent
                        v-if="select"
                        :list="themeList"
                        label="Выберите тему"
                        modify="popup-meeting__content-select"
                        @clickRadio="clickRadioHandler" />

                    <InputComponent
                        name="phone"
                        label="Ваш номер телефона"
                        mask="+7 (###) ###-##-##"
                        :validation="{
                            type: 'phone',
                            msg: 'Введите корректный номер телефона'
                        }"
                        :value="user?.phone"
                        :data-store-instance="UserFormStore"
                        :parent-error="parentError"
                        @inputChanged="_inputChanged"
                    />

                    <InputComponent
                        name="name"
                        label="Имя"
                        type="text"
                        :validation="{
                            type: 'letters',
                            msg: 'Введите только буквы'
                        }"
                        :value="user?.name"
                        :data-store-instance="UserFormStore"
                        :parent-error="parentError"
                        @inputChanged="_inputChanged"
                    />

                    <InputComponent
                        v-if="textarea"
                        name="question"
                        label="Ваш вопрос"
                        type="textarea"
                        :data-store-instance="UserFormStore"
                        :parent-error="parentError" />

                    <InputComponent
                        v-if="email"
                        name="email"
                        label="Email"
                        type="text"
                        :validation="{
                            type: 'email',
                            msg: 'Введите корректный email адрес'
                        }"
                        :value="user?.email"
                        :data-store-instance="UserFormStore"
                        :parent-error="parentError"
                        @inputChanged="_inputChanged" />

                    <ButtonComponent
                        :disabled="isDisabled"
                        modify="button_theme_big"
                        :text="buttonText"
                        @click="_sendForm" />
                </form>

                <FormResultComponent
                    v-if="Object.keys(formResult)?.length"
                    :success="formResult.success"
                    :title="formResult.title"
                    :service="result?.service ? result.service : null"
                    :text="formResult.text"
                    @closeResult="formResult = {}"
                    @closePopup="_closeClick" />
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import FormDataController from '@/dataControllers/form/index';
import FormResultComponent from '@/components/FormResultComponent/FormResultComponent';
import InputComponent from '@/components/InputComponent/InputComponent';
import {mapGetters} from 'vuex';
import SelectComponent from '@/components/SelectComponent/SelectComponent.vue';

const FormController = new FormDataController();

export default {
    name: 'PopupQuestionComponent',

    components: {
        ButtonComponent,
        FormResultComponent,
        InputComponent,
        SelectComponent
    },

    props: {
        title       : String,
        descr       : String,
        callbackData: {
            default: 'callbackData',
            type   : String
        },
        select: {
            default: false,
            type   : Boolean
        },
        textarea: {
            default: false,
            type   : Boolean
        },
        email: {
            default: false,
            type   : Boolean
        },
        buttonText: {
            default: 'Заказать звонок',
            type   : String
        },
        result: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    data() {
        return {
            parentError: null,
            isDisabled : true,
            formResult : {},
            themeList  : [{
                id  : 0,
                text: 'Тема 1'
            }, {
                id  : 1,
                text: 'Тема 2'
            }]
        };
    },

    computed: {
        ...mapGetters({
            user: 'GET_USER'
        }),

        UserFormStore() {
            return FormController.formDataStore;
        }
    },

    mounted() {
        this._setUserData();
    },

    destroyed() {
        FormController.clearDataStore();
    },

    methods: {
        _closeClick() {
            this.$emit('closePopup');
        },

        _inputChanged() {
            this.parentError = null;

            this._checkFields();
        },

        _sendForm() {
            this.isDisabled = true;

            FormController.sendForm()
                .then((response) => {
                    this.formResult = response;
                })
                .catch((error) => {
                    this.formResult = error;
                })
                .then(() => {
                    this.isDisabled = false;
                });
        },

        _checkFields() {
            const store = this.UserFormStore.dataStore?.items;

            // Дизейблим кнопку - если поля имени и телефона пустые
            this.isDisabled = this.email ?
                store?.name?.value?.length && store?.phone?.value?.length && store?.email?.error :
                store?.name?.value?.length && store?.phone?.value?.length;
        },

        clickRadioHandler(id) {
            if (!id) {
                return;
            }

            this.UserFormStore.setData('theme-select', id.text);

            this.isDisabled = false;
        },

        computedTitle(source) {
            const returnData = source === 'title' ?
                'Заказать обратный звонок' :
                'Наш менеджер перезвонит вам в&nbsp;ближайшее время и&nbsp;ответит на&nbsp;все возникшие вопросы';

            return this[source]?.length ?
                this[source] :
                returnData;
        },

        _setUserData() {
            if (this.user?.phone) {
                this.UserFormStore.setData('phone', this.user?.phone);
            }

            if (this.user?.name) {
                this.UserFormStore.setData('name', this.user?.name);
            }

            this._checkFields();
        }
    }
};
</script>

<style lang="scss">
@import './PopupQuestionComponent';
</style>
