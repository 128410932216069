import type {
    ICompareData,
    IDataStore,
    IResponseCompareRequest
} from './types';

let compareDataInstance: ICompareData | null = null;

export class CompareData implements ICompareData {
    public dataStore: IDataStore;

    constructor() {
        if (!compareDataInstance) {
            // eslint-disable-next-line
            compareDataInstance = this;
        }

        this.dataStore = {
            flats            : [],
            commerce         : [],
            descriptions     : {},
            activeSlide      : [0],
            activeMobileSlide: 1,
            activeType       : null,
            length           : 5,
            success          : true
        };

        return compareDataInstance;
    }

    /**
     * Записываем в стор значение выполнения запроса на получение списка сравнения
     * @param {object} response - объект с данными результата запроса
     * @param {string} type - тип запроса
     */
    public setRequestResponse(response: IResponseCompareRequest): void {
        this.dataStore.flats = response.data.flats;
        this.dataStore.commerce = response.data.commerce;
        this.dataStore.descriptions = response.data.descriptions;
        this.dataStore.success = response.success;
    }

    /**
     * Изменяем значения активных слайдов
     * @param {array} activeSlide - массив активных слайдов ПК версии или левого
     * слайдера мобильной версии
     * @param {number} activeMobileSlide - номер активного слайда правого
     * слайдера мобильной версии
     */
    public setDataStore(activeSlide: number[], activeMobileSlide: number): void {
        this.dataStore.activeSlide = activeSlide;
        this.dataStore.activeMobileSlide = activeMobileSlide;
    }

    /**
     * Устанавливаем общее количество слайдов
     * @param {number} length - общее количество слайдов
     */
    public setSlidesLength(length: number): void {
        this.dataStore.length = length;
    }

    /**
     * Возвращаем значения dataStore
     * @returns {object} - возвращает значение dataStore
     */
    public getDataStore(): IDataStore {
        return this.dataStore;
    }

    public clearDataStore(): void {
        this.dataStore = {
            flats            : [],
            commerce         : [],
            descriptions     : {},
            activeSlide      : [0],
            activeMobileSlide: 1,
            activeType       : null,
            length           : 0,
            success          : true
        };
    }
}
