<template>
    <section class="auth">
        <transition name="fade">
            <PreloaderComponent v-if="!projects || !slides" />
        </transition>
        <AuthFormComponent
            v-if="projects"
            :projects="projects"
            :returnPath="returnPath"
            @openSlider="sliderSwitch" />

        <AuthSliderComponent v-if="slides" :slides="slides" :sliderShow="mobileModify" @closeSlider="sliderSwitch" />
    </section>
</template>

<script>
import {EBreakpoint, RESIZE_EVENTS} from '../../scripts/constants';
import {mapActions, mapGetters} from 'vuex';
import AuthFormComponent from '../../components/AuthFormComponent/AuthFormComponent';
import AuthSliderComponent from '../../components/AuthSliderComponent/AuthSliderComponent';
import PreloaderComponent from '@/components/PreloaderComponent/PreloaderComponent';
import store from '../../store/index';
import {Utils} from '../../scripts/utils';

export default {
    name: 'AuthPage',

    components: {
        AuthFormComponent,
        AuthSliderComponent,
        PreloaderComponent
    },

    data() {
        return {
            mobileModify: null,
            windowWidth : Utils.getWindowWidth(),
            returnPath  : null
        };
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch('getAuthSlider')
            .then(() => {
                next();
            });
    },

    computed: {
        ...mapGetters({
            projects: 'GET_PROJECTS_LIST',
            slides  : 'GET_AUTH_SLIDER_DATA'
        })
    },

    mounted() {
        RESIZE_EVENTS.forEach((event) => {
            window.addEventListener(event, this._onResize.bind(this));
        });

        const params = new URLSearchParams(window.location.search);

        this.returnPath = params?.get('returnPath') ? params.get('returnPath') : null;
    },

    watch: {
        windowWidth() {
            if (this.windowWidth > EBreakpoint.DESKTOP) {
                this.mobileModify = true;
            }
        }
    },

    methods: {
        ...mapActions(['getAuthSlider', 'getAuthProjects']),

        sliderSwitch() {
            this.mobileModify = this.mobileModify === null ? 'true' : null;
        },

        _onResize() {
            this.windowWidth = Utils.getWindowWidth();
        }
    }
};
</script>

<style lang="scss">
    @import "AuthPage";
</style>
