<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0833 2.25C14.725 2.25 16.5 4.76438 16.5 7.11C16.5 11.8603 9.13333 15.75 9 15.75C8.86667 15.75 1.5 11.8603 1.5 7.11C1.5 4.76438 3.275 2.25 5.91667 2.25C7.43333 2.25 8.425 3.01781 9 3.69281C9.575 3.01781 10.5667 2.25 12.0833 2.25Z" fill="#62725b"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.91667 3.25C3.98393 3.25 2.5 5.14576 2.5 7.11C2.5 8.03558 2.85966 8.97684 3.48359 9.90697C4.10672 10.8359 4.9577 11.6994 5.84445 12.4467C6.72744 13.1909 7.6189 13.7975 8.29801 14.2176C8.57563 14.3894 8.81485 14.5282 9 14.6309C9.18515 14.5282 9.42437 14.3894 9.70199 14.2176C10.3811 13.7975 11.2726 13.1909 12.1556 12.4467C13.0423 11.6994 13.8933 10.8359 14.5164 9.90697C15.1403 8.97684 15.5 8.03558 15.5 7.11C15.5 5.14576 14.0161 3.25 12.0833 3.25C10.9476 3.25 10.2119 3.8123 9.76124 4.34128C9.57125 4.56432 9.29299 4.69281 9 4.69281C8.70701 4.69281 8.42875 4.56432 8.23876 4.34128C7.78814 3.8123 7.05237 3.25 5.91667 3.25ZM0.5 7.11C0.5 4.38299 2.56607 1.25 5.91667 1.25C7.25661 1.25 8.27243 1.7353 9 2.30959C9.72757 1.7353 10.7434 1.25 12.0833 1.25C15.4339 1.25 17.5 4.38299 17.5 7.11C17.5 8.55958 16.9388 9.8859 16.1773 11.0211C15.4151 12.1575 14.416 13.1572 13.4444 13.976C12.4691 14.798 11.4939 15.4608 10.7543 15.9184C10.3838 16.1476 10.068 16.3281 9.83561 16.4535C9.72019 16.5159 9.62041 16.5673 9.54173 16.6053C9.50374 16.6237 9.46165 16.6434 9.42084 16.6605C9.40168 16.6686 9.37019 16.6814 9.33301 16.6941C9.31478 16.7003 9.28332 16.7105 9.24409 16.7201L9.24332 16.7203C9.21843 16.7265 9.12323 16.75 9 16.75C8.87677 16.75 8.78157 16.7265 8.75668 16.7203L8.75591 16.7201C8.71668 16.7105 8.68522 16.7003 8.66699 16.6941C8.62981 16.6814 8.59832 16.6686 8.57916 16.6605C8.53835 16.6434 8.49626 16.6237 8.45827 16.6053C8.37959 16.5673 8.27981 16.5159 8.16439 16.4535C7.93195 16.3281 7.61623 16.1476 7.24574 15.9184C6.5061 15.4608 5.53089 14.798 4.55555 13.976C3.58397 13.1572 2.58495 12.1575 1.82266 11.0211C1.06117 9.8859 0.5 8.55958 0.5 7.11Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'FavoritesFilledIcon',

    props: {
        fill: {
            default: '#62725b',
            type   : String
        }
    }
};
</script>
