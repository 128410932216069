<template>
    <div class="booking-page">
        <TabsComponent
            modify="booking-page__tabs"
            type="button"
            :tabsData="tabsData"
            :activeTab="activeTab"
            @changeTab="_changeActiveTab" />
        <BookingDeletedComponent v-if="deleted && deleted?.length" :list="deleted" />
        <BookingListComponent
            v-if="listData?.length"
            :annotation="annotation"
            :listData="listData"
            :bookingStatus="bookingStatus"
            :activeTab="activeTab?.slug"
            @deleteBooking="_deleteHandler"/>

        <EmptyComponent
            v-else
            modify="booking-page__empty"
            title="Нет активных сделок"
            text="Забронируйте объект на&nbsp;сайте&nbsp;ЖК, чтобы увидеть его в&nbsp;этом списке"
        />

        <ComfortLevelComponent
            v-if="bannersData?.banners && Object.keys(bannersData?.banners)?.length"
            :banners="bannersData?.banners"
            :tabs="bannersData?.tabs" />

        <transition name="fade">
            <ClearComponent
                v-if="clearShow"
                :clearComplete="clearComplete"
                title="Удаление бронирования"
                description="Удаление бронирования возможно только через заявку менеджеру.
                    Отправьте заявку, нажав на кнопку ниже."
                buttonText="Отправить заявку"
                @closeClearPopup="_clearClose"
                @makeClear="_removeHandler"
            />
        </transition>
    </div>
</template>

<script>
import BookingDataController from '@/dataControllers/booking';
import BookingDeletedComponent from '@/components/Booking/BookingDeletedComponent/BookingDeletedComponent';
import BookingListComponent from '@/components/Booking/BookingListComponent/BookingListComponent';
import ClearComponent from '@/components/ClearComponent/ClearComponent';
import ComfortLevelComponent from '@/components/ComfortLevelComponent/ComfortLevelComponent';
import EmptyComponent from '@/components/EmptyComponent/EmptyComponent';
import TabsComponent from '@/components/TabsComponent/TabsComponent';

const BookingController = new BookingDataController();

export default {
    name: 'BookingPage',

    components: {
        BookingDeletedComponent,
        EmptyComponent,
        BookingListComponent,
        ClearComponent,
        ComfortLevelComponent,
        TabsComponent
    },

    data() {
        return {
            annotation   : null,
            clearShow    : false,
            clearComplete: false,
            deleteElement: null,
            deleted      : [],
            listData     : [],
            bookingSteps : [],
            activeObject : 'all',
            isProcessing : true,
            tabsData     : [],
            activeTab    : {},
            bannersData  : {},
            bookingStatus: []
        };
    },

    mounted() {
        this._getRequest();
    },

    methods: {
        /**
         * Обработчик запроса к апи бронирования
         */
        _getRequest() {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            BookingController.getBooking()
                .then(({bookingData, bannersData}) => {
                    this.annotation = bookingData.annotation;
                    this.bookingStatus = bookingData?.bookingStatus || [];
                    this.listData = this._getPreparedBookingsList(bookingData?.booking, bookingData?.bookingStatus);
                    this.bookingSteps = bookingData?.steps;
                    this.tabsData = bookingData?.tabsData;
                    this.activeTab = this.tabsData?.length ? this.tabsData[0] : {};

                    this.deleted = bookingData?.deleted;

                    this.bannersData = bannersData;
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                });
        },

        /**
         * Обработчик запроса на удаление к апи бронирования
         * @param {string} id - идентификатор объекта для удаления
         */
        _deleteRequest(id = null) {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            BookingController.deleteBooking({id})
                .then((response) => {
                    if (!response?.list) {
                        console.error('list is empty');

                        return;
                    }

                    this.listData = response?.list;
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                });
        },

        /**
         * Отображение уведомления о попытке удалить объект из выдачи
         * @param {string} id - идентификатор удаляемого объекта
         */
        _deleteHandler(id) {
            this.deleteElement = id;
            this.clearShow = true;
        },

        /**
         * Поиск элемента и запрос на его удаление
         */
        _removeHandler() {
            this._deleteRequest(this.deleteElement);
            this.clearComplete = true;
        },

        /**
         * Закрытие попапа с уведомление об удалении объекта
         */
        _clearClose() {
            this.clearShow = false;
            this.clearComplete = false;
        },

        /**
         * Переключаем табы
         * @param {string} id - ид нового активного таба
         */
        _changeActiveTab(id) {
            this.activeTab = this.tabsData.find((item) => {
                return item.id === id;
            });
        },

        /**
         * Добавляем каждому помещению ID активного статуса, для корректной
         * отрисовки списка статусов
         * @param {array} list - список помещений
         * @param {array} statuses - список статусов
         * @returns {array} - подготовленный список помещений
         */
        _getPreparedBookingsList(list, statuses) {
            const completedList = list.map((item) => {
                item.currentStatusID = statuses.findIndex((statusItem) => {
                    return statusItem.id === item.currentStatus;
                });

                return item;
            });

            return completedList;
        }
    }
};
</script>

<style lang="scss">
@import './BookingPage';
</style>
