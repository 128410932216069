<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7071 5.9594C17.0976 6.34993 17.0976 6.98309 16.7071 7.37361L9.08085 14.9998H25.3333C25.8856 14.9998 26.3333 15.4475 26.3333 15.9998C26.3333 16.5521 25.8856 16.9998 25.3333 16.9998H9.08083L16.7071 24.6261C17.0976 25.0166 17.0976 25.6498 16.7071 26.0403C16.3166 26.4308 15.6834 26.4308 15.2929 26.0403L5.95952 16.7069C5.77198 16.5194 5.66663 16.265 5.66663 15.9998C5.66663 15.7346 5.77198 15.4802 5.95952 15.2927L15.2929 5.95939C15.6834 5.56887 16.3166 5.56887 16.7071 5.9594Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'ArrowBack',

    props: {
        fill: {
            default: '#ffffff',
            type   : String
        }
    }
};
</script>
