<template>
    <div v-if="content" class="additional-service">
        <AdditionalServiceListSection
            v-for="list in content"
            :key="list.id"
            :list="list"
            @popupShow="_popupShowHandler" />
    </div>
</template>

<script>
import AdditionalServiceListSection from '@/sections/Agency/AdditionalServiceListSection/AdditionalServiceListSection';
import AgencyDataController from '@/dataControllers/agency';

const AgencyController = new AgencyDataController();

export default {
    name: 'AdditionalServicePage',

    components: {
        AdditionalServiceListSection
    },

    data() {
        return {
            content     : null,
            isProcessing: true
        };
    },

    mounted() {
        this.getContent();
    },

    methods: {
        /**
         * Обработчик получения данных от бека
         */
        getContent() {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            AgencyController.getAdditionalServices()
                .then((response) => {
                    if (response.success) {
                        this.content = response.additional;
                    } else {
                        this.$emit('errorShow', true, {
                            statusCode: response.errorData?.status,
                            url       : response.errorData?.url
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.$emit('errorShow', true, {
                        statusCode: 500,
                        url       : 'Неизвестная ошибка на странице Дополнительные услуги'
                    });
                })
                .then(() => {
                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                });
        },

        /**
         * Обработка эмита - показа попапа
         * @param {string} service - наименование заказываемой услуги
         */
        _popupShowHandler(service) {
            // Заголовки для попапа
            const popupData = {
                title     : 'Заказать услугу',
                descr     : 'Наш менеджер перезвонит вам в&nbsp;ближайшее время и&nbsp;оформит вашу заявку на услугу',
                buttonText: 'Заказать услугу'
            };

            this.$emit('popupShow', service, popupData);
        }
    }
};
</script>

<style lang="scss">
@import './AdditionalServicePage';
</style>
