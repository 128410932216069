import type {AxiosError, AxiosResponse} from 'axios';
import type {
    IOwnerAboutBannerRequestResponse,
    IOwnerAboutBannerRequestResult,
    IOwnerAboutRequestResponse,
    IOwnerAboutRequestResult,
    IOwnerContractsResponse,
    IOwnerDataController,
    IOwnerKeysResult,
    IOwnerKeysStatusResponse
} from './types';
import axios from 'axios';
import {ERequests} from '../../requests';
import {Utils} from '../../scripts/utils';

export default class OwnerDataController implements IOwnerDataController {
    public async getOwnerAboutBanner(): Promise<IOwnerAboutBannerRequestResult> {
        const result: IOwnerAboutBannerRequestResult = {
            success: true,
            banner : {
                image: '',
                title: '',
                text : '',
                email: ''
            }
        };

        await axios.get(ERequests.ownerAboutBanner)
            .then((response: AxiosResponse<IOwnerAboutBannerRequestResponse>) => {
                result.banner = response.data.data.banner;
            })
            .catch((error: AxiosError) => {
                console.error(error);
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }

    public async getOwnerAboutData(project: string): Promise<IOwnerAboutRequestResult> {
        const result: IOwnerAboutRequestResult = {
            success: true,
            steps  : []
        };

        const address = project === 'yuntolovo' ?
            ERequests.ownerAboutYuntolovo :
            ERequests.ownerAboutSevdol;

        await axios.get(address)
            .then((response: AxiosResponse<IOwnerAboutRequestResponse>) => {
                result.steps = response.data?.data?.steps;
            })
            .catch((error: AxiosError) => {
                console.error(error);
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }

    public async getOwnerKeysPageData(): Promise<IOwnerKeysResult> {
        const result: IOwnerKeysResult = {
            success  : true,
            list     : [],
            permits  : [],
            contracts: []
        };

        const pageData = axios.get(ERequests.ownerKeys);
        const contractsData = axios.get(ERequests.ownerContracts);

        await Promise.all([pageData, contractsData])
            .then((response: [AxiosResponse<IOwnerKeysStatusResponse>, AxiosResponse<IOwnerContractsResponse>]) => {
                const data = response[0].data.data;

                result.list = data.list;
                result.permits = data.permits;

                if (response[1].data.data.contracts?.length) {
                    result.contracts = response[1].data.data.contracts.map((contract: string, index: number) => {
                        return {
                            id  : index,
                            text: contract
                        };
                    });
                }
            })
            .catch((error: AxiosError) => {
                console.error(error);
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }
}
