import AdditionalServicePage from '../../pages/Agency/AdditionalServicePage/AdditionalServicePage.vue';
import AgencyCommerceRentPage from '../../pages/Agency/AgencyCommerceRentPage/AgencyCommerceRentPage.vue';
import EstateSellPage from '../../pages/Agency/EstateSellPage/EstateSellPage.vue';
import TradeInPage from '../../pages/Agency/TradeInPage/TradeInPage.vue';

const agencyChildren = [{
    path     : 'trade-in',
    name     : 'trade-in',
    component: TradeInPage,
    meta     : {
        name: 'Трейд-ин'
    },
    props: {
        section: 'agency'
    }
}, {
    path     : 'sell',
    name     : 'sell',
    component: EstateSellPage,
    meta     : {
        name: 'Продажа недвижимости'
    },
    props: {
        section: 'agency'
    }
}, {
    path     : 'additional',
    name     : 'additional',
    component: AdditionalServicePage,
    meta     : {
        name: 'Дополнительные услуги'
    },
    props: {
        section: 'agency'
    }
}, {
    path     : 'commerce',
    name     : 'commerce',
    component: AgencyCommerceRentPage,
    meta     : {
        name: 'Аренда коммерции'
    }
}];

export default agencyChildren;
