<template>
    <form class="evaluate__form evaluate__form_theme_address">
        <SelectComponent
            :list="complexes"
            label="Комплекс"
            @clickRadio="_clickComplexRadioHandler" />

        <SelectComponent
            :list="addresses"
            :disabled="!addresses.length"
            label="Адрес квартиры"
            @clickRadio="_clickAddressRadioHandler" />

        <InputComponent
            name="building"
            label="Номер дома"
            maxlength="4"
            :validation="{
                type: 'number',
                msg: 'Номер должен состоять только из цифр'
            }"
            :data-store-instance="TradeInStore"
            @inputChanged="_inputChanged" />

        <InputComponent
            name="corpus"
            label="Номер корпуса"
            :data-store-instance="TradeInStore"
            @inputChanged="_inputChanged" />

        <InputComponent
            name="flat"
            label="Номер квартиры"
            :validation="{
                type: 'number',
                msg: 'Номер должен состоять только из цифр'
            }"
            :data-store-instance="TradeInStore"
            @inputChanged="_inputChanged" />

        <ButtonComponent
            modify="button_theme_big evaluate__form-button"
            text="Оценить квартиру"
            :disabled="!isDisabled"
            @click="_clickHandler" />
    </form>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import InputComponent from '@/components/InputComponent/InputComponent';
import SelectComponent from '@/components/SelectComponent/SelectComponent';
import TradeInDataController from '@/dataControllers/tradein/index';

const TradeInController = new TradeInDataController();

export default {
    name: 'AddressForm',

    components: {
        ButtonComponent,
        InputComponent,
        SelectComponent
    },

    props: {
        complexes: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    data() {
        return {
            addresses : [],
            inputError: true,
            isDisabled: false
        };
    },

    computed: {
        TradeInStore() {
            return TradeInController.tradeInStore;
        }
    },

    methods: {
        /**
         * Обрабатываем клик по селекту выбора комплексов
         * @param {object} complex - объект с данными комплекса
         */
        _clickComplexRadioHandler(complex) {
            this.addresses = complex?.addresses;
            this.TradeInStore.setData('complex', [complex?.id]);

            this._checkFields();
        },

        /**
         * Обрабатываем клик по селекту выбора адресов
         * @param {object} address - объект с данными адреса
         */
        _clickAddressRadioHandler(address) {
            this.TradeInStore.setData('address', [address?.id]);

            this._checkFields();
        },

        _inputChanged(inputScope) {
            this.inputError = inputScope.error;
            this._checkFields();
        },

        /**
         * Проверяем заполнение полей
         */
        _checkFields() {
            const store = this.TradeInStore.dataStore.items;

            this.isDisabled = store?.complex?.length && store?.address?.length &&
                store?.building?.value?.length && store?.flat?.value?.length;
        },

        _clickHandler() {
            const store = this.TradeInStore.dataStore.items;

            const preparedData = {
                complex  : store.complex,
                address  : store.address,
                building : store.building.value,
                apartment: store.flat.value,
                corpus   : store.corpus?.value || null
            };

            this.$emit('sendData', 'address', preparedData);
        }
    }
};
</script>
