<template>
    <ul class="popup-owners__text-list">
        <li
            v-for="item in list"
            :key="item.id"
            class="popup-owners__text-item">
            {{ item.title }}
            <span v-html="item.note"></span>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'OwnerTextTemplate',

    props: {
        list: {
            default: () => {
                return [];
            },
            type: Array
        }
    }
};
</script>
