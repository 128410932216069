import type {ActionTree, Commit, GetterTree, MutationTree, StoreOptions} from 'vuex';
import type {AxiosError, AxiosResponse} from 'axios';
import {EGetters, ESetters, IResponseProjectMainData} from './types';
import type {
    IFormItem,
    IMenuChildrenItem,
    IMenuItem,
    IResponseForm,
    IResponseMeeting,
    IResponseMenuData,
    IResponseProjectsData,
    IResponseSettingsData,
    IResponseSidebarCounterElement,
    IResponseSidebarData,
    IResponseSliderData,
    IResponseUserData,
    RootState
} from './types';
import axios from 'axios';
import {defaultSection} from '../router/index';
import {ERequests} from '../requests';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const getters: GetterTree<RootState, RootState> = {
    [EGetters.GET_IS_MOBILE](state: RootState): boolean {
        return state.data.isMobile;
    },

    [EGetters.GET_IS_TABLET](state: RootState): boolean {
        return state.data.isTablet;
    },

    [EGetters.GET_IS_DESKTOP](state: RootState): boolean {
        return state.data.isDesktop;
    },

    [EGetters.GET_SETTINGS](state: RootState): object {
        return state.data.settings;
    },

    /**
         * Возвращаем данные слайдера экрана аутентификации
         * @param {object} state - объект хранилища данных
         * @returns {object} возвращает данные слайдера
         */
    [EGetters.GET_AUTH_SLIDER_DATA](state: RootState): object[] {
        return state.data.authSlider;
    },

    [EGetters.GET_AUTH_TIMER](state: RootState) {
        return state.data.authTimer;
    },

    /**
     * Возвращаем список проектов
     * @param {object} state - объект хранилища данных
     * @returns {object} возвращает список проектов
     */
    [EGetters.GET_PROJECTS_LIST](state: RootState) {
        return state.data.projects;
    },

    /**
     * Возвращает главный проект
     * @param {object} state - объект хранилища данных
     * @returns {object} возвращает главный проект
     */
    [EGetters.GET_PROJECTS_MAIN](state: RootState) {
        return state.data.projectMain;
    },

    /**
     * Возвращаем данные сайдбара
     * @param {object} state - объект хранилища данных
     * @returns {array} возвращает данные сайдбара
     */
    [EGetters.GET_SIDEBAR](state: RootState): object {
        return state.data.sidebar;
    },

    /**
     * Возвращаем данные сайдбара с записью к менеджеру
     * @param {object} state - объект хранилища данных
     * @returns {array} возвращает данные по записям
     */
    [EGetters.GET_MEETING](state: RootState): object {
        return state.data.meeting;
    },

    /**
     * Возвращаем данные сайдбара с записями на экскурсию
     * @param {object} state - объект хранилища данных
     * @returns {array} возвращает данные по записям
     */
    [EGetters.GET_TOUR](state: RootState): object {
        return state.data.tour;
    },

    /**
     * Возвращаем данные меню
     * @param {object} state - объект хранилища данных
     * @returns {array} возвращает данные меню
     */
    [EGetters.GET_MENU](state: RootState): object {
        return state.data.menu;
    },

    [EGetters.GET_MOBILE_MENU_STATE](state: RootState): boolean {
        return state.data.mobileMenuOpen;
    },

    /**
     * Возвращаем данные пользователя
     * @param {object} state - объект хранилища данных
     * @returns {object} - данные пользователя
     */
    [EGetters.GET_USER](state: RootState): object {
        return state.data.user;
    },

    [EGetters.GET_REFERRER](state: RootState): string | null {
        return state.data.referrer;
    },

    /**
     * Возвращаем ссылку для иконки избранного в шапке
     * @param {object} state - объект хранилища данных
     * @returns {string | null} - ссылка
     */
    [EGetters.GET_FAVORITES_LINK](state: RootState): string | null {
        return state.data.favoritesLink;
    },

    /**
     * Возвращаем ссылку для иконки сравнения в шапке
     * @param {object} state - объект хранилища данных
     * @returns {string | null} - ссылка
     */
    [EGetters.GET_COMPARE_LINK](state: RootState): string | null {
        return state.data.compareLink;
    },

    [EGetters.GET_MANAGERS](state: RootState): null {
        return state.data.managers;
    },

    [EGetters.GET_DEFAULT_FORMS](state: RootState): IFormItem[] | null {
        return state.data.forms;
    },

    [EGetters.GET_GLOBAL_ERROR](state: RootState): boolean {
        return state.data.globalError;
    }
};

const actions: ActionTree<RootState, RootState> = {
    /**
     * Получаем список настроек сайта
     */
    getSettings({commit}: { commit: Commit }) {
        axios.get(ERequests.settings)
            .then((response: AxiosResponse<IResponseSettingsData>) => {
                commit(ESetters.SET_SETTINGS, response.data.data);
            });
    },

    /**
     * Получаем данные для слайдера на экране аутентификации
     */
    getAuthSlider({commit}: { commit: Commit }) {
        axios.get(ERequests.slider)
            .then((response: AxiosResponse<IResponseSliderData>) => {
                commit(ESetters.SET_AUTH_SLIDER_DATA, response.data.data.slider);
            });
    },

    /**
     * Получаем список проектов
     */
    getProjectsList({commit}: { commit: Commit }) {
        axios.get(ERequests.projects)
            .then((response: AxiosResponse<IResponseProjectsData>) => {
                commit(ESetters.SET_PROJECTS_LIST, response.data.data.projects);
            });
    },

    /**
     * Получаем основной проект
     * @param commit
     */
    getProjectsMain({commit}: { commit: Commit }) {
        axios.get(ERequests.projectMain)
            .then((response: AxiosResponse<IResponseProjectMainData>) => {
                commit(ESetters.SET_PROJECT_MAIN, response.data.data.project);
            });
    },

    /**
     * Обновляем данные сайдбара
     * @param {object} data - данные запроса
     */
    async updateSidebar({commit}: { commit: Commit }, data: URLSearchParams) {
        await axios.get(ERequests.sidebar, {params: data})
            .then((response: AxiosResponse<IResponseSidebarData>) => {
                const responseData = response.data.data;

                const sidebarData = this.getters[EGetters.GET_SIDEBAR];
                const section = data.get('section');

                if (Object.keys(sidebarData)?.length && sidebarData[section]) {
                    sidebarData[section].forEach((item: IMenuChildrenItem) => {
                        const element = responseData.counters.find((counter: IResponseSidebarCounterElement) => {
                            return counter.id === item.id;
                        });

                        item.quantity = element?.quantity ? element.quantity : item.quantity;
                    });
                }

                commit(ESetters.SET_SIDEBAR, sidebarData);
                commit(ESetters.SET_TOUR, responseData.tour);
                commit(ESetters.SET_MEETING, responseData.meeting);
                commit(ESetters.SET_MANAGERS, responseData.managers);
            });
    },

    /**
     * Получаем список пунктов меню, сайдбара, встреч и менеджеров
     */
    async getMenu({commit}: { commit: Commit }) {
        await axios.get(ERequests.menu)
            .then((response: AxiosResponse<IResponseMenuData>) => {
                const data = response.data.data;

                commit(ESetters.SET_MENU, data.menu);
                commit(ESetters.SET_TOUR, data.tour);
                commit(ESetters.SET_MEETING, data.meeting);
                commit(ESetters.SET_FAVORITES_LINK, data.favoritesLink);
                commit(ESetters.SET_COMPARE_LINK, data.compareLink);
                commit(ESetters.SET_MANAGERS, data.managers);

                const preparedSidebar = data.menu.reduce((acc: object, item: IMenuItem) => {
                    acc[item.slug] = item.children;

                    return acc;
                }, {});

                commit(ESetters.SET_SIDEBAR, preparedSidebar);

                this.dispatch('updateSidebar', new URLSearchParams([['section', defaultSection]]));
            })
            .catch((error: AxiosError) => {
                console.error(error.response);
            });
    },

    /**
     * Получаем данные пользователя
     */
    async getUserData({commit}: {commit: Commit}) {
        commit(ESetters.SET_GLOBAL_ERROR, false);

        await axios.get(ERequests.user)
            .then((response: AxiosResponse<IResponseUserData>) => {
                const userData = response.data.data.user;

                if (userData?.phone && Number(userData.phone[0]) === 7) {
                    userData.phone = userData.phone.slice(1);
                }

                commit(ESetters.SET_USER, userData);
            })
            .catch((error: AxiosError) => {
                console.error(error.response);
                commit(ESetters.SET_GLOBAL_ERROR, true);
            });
    },

    /**
     * Получаем список стандартных форм
     */
    async getDefaultForms({commit}: {commit: Commit}) {
        await axios.get(ERequests.forms)
            .then((response: AxiosResponse<IResponseForm>) => {
                commit(ESetters.SET_DEFAULT_FORMS, response.data.data.forms);
            });
    },

    /**
     * Устанавливает - мобильное устройство или нет
     * @param {Boolean} value - определнеи мобильное устройство или нет
     */
    setIsMobile({commit}: { commit: Commit }, value: boolean) {
        commit(ESetters.SET_IS_MOBILE, value);
    },

    /**
     * Устанавливает - планшетное устройство или нет
     * @param {Boolean} value - определение планшетного устройство или нет
     */
    setIsTablet({commit}: { commit: Commit }, value: boolean) {
        commit(ESetters.SET_IS_TABLET, value);
    },

    /**
     * Устанавливает - пк устройство или нет
     * @param {Boolean} value - определение пк устройство или нет
     */
    setIsDesktop({commit}: { commit: Commit }, value: boolean) {
        commit(ESetters.SET_IS_DESKTOP, value);
    },

    /**
     * Изменяем состояние мобильного меню
     */
    setMobileMenuState({commit}: { commit: Commit }) {
        commit(ESetters.SET_MOBILE_MENU_STATE);
    },

    /**
     * Сохраняем данные УК пользователя
     * @param {object} data - данные УК
     */
    setUserUkData({commit}: { commit: Commit }, data: object) {
        axios.post(ERequests.userUK, data)
            .then((response: AxiosResponse) => {
                commit(ESetters.SET_USER_UK, response.data.data.user.uk);
                // commit(ESetters.SET_USER_UK, response.data.data.uk);
            });
    },

    clearSidebar({commit}: { commit: Commit }) {
        commit(ESetters.SET_SIDEBAR, {});
    }
};

const mutations: MutationTree<RootState> = {
    /**
         * Сохраняем в хранилище результат получения данных о проекте и
         * пользователе
         * @param {object} state - объект хранилища данных
         * @param {object} data - данные слайдера для сохранения
         */
    [ESetters.SET_SETTINGS](state: RootState, data: object) {
        state.data.settings = data;
    },

    /**
     * Сохраняем в хранилище результат получения данных слайдера экрана
     * аутентификации
     * @param {object} state - объект хранилища данных
     * @param {object} data - данные слайдера для сохранения
     */
    [ESetters.SET_AUTH_SLIDER_DATA](state: RootState, data: object[]) {
        state.data.authSlider = data;
    },

    /**
     * Сохраняем в хранилище результат получения списка проектов
     * @param {object} state - объект хранилища данных
     * @param {object} data - список проектов
     */
    [ESetters.SET_PROJECTS_LIST](state: RootState, data: object[]) {
        state.data.projects = data;
    },

    /**
         * Сохраняем в хранилище результат получения списка навигации в сайдбаре
         * @param {object} state - объект хранилища данных
         * @param {array} data - список навигации в сайдбаре
         */
    [ESetters.SET_SIDEBAR](state: RootState, data: IMenuItem[]) {
        state.data.sidebar = data;
    },

    /**
     * Сохраняем в хранилище результат получения списка записей на встречу с
     * менеджером
     * @param {object} state - объект хранилища данных
     * @param {array} data - список записей к менеджеру
     */
    [ESetters.SET_MEETING](state: RootState, data: IResponseMeeting) {
        state.data.meeting = data;
    },

    /**
     * Сохраняем в хранилище результат получения списка записей на экскурсии
     * @param {object} state - объект хранилища данных
     * @param {array} data - список записей на экскурсию
     */
    [ESetters.SET_TOUR](state: RootState, data: IResponseMeeting) {
        state.data.tour = data;
    },

    /**
     * Сохраняем в хранилище результат получения списка меню
     * @param {object} state - объект хранилища данных
     * @param {array} data - список меню
     */
    [ESetters.SET_MENU](state: RootState, data: IMenuItem[]) {
        const preparedMenu = data.map((item: IMenuItem) => {
            return {
                id  : item.id,
                slug: item.slug,
                href: item.href,
                text: item.text
            };
        });

        state.data.menu = preparedMenu;
    },

    /**
     * Сохраняем тип устройства
     * @param {object} state - объект хранилища данных
     * @param {boolean} data - состояние
     */
    [ESetters.SET_IS_MOBILE](state: RootState, data: boolean) {
        state.data.isMobile = data;
    },

    /**
     * Сохраняем тип устройства
     * @param {object} state - объект хранилища данных
     * @param {boolean} data - состояние
     */
    [ESetters.SET_IS_TABLET](state: RootState, data: boolean) {
        state.data.isTablet = data;
    },

    /**
     * Сохраняем тип устройства
     * @param {object} state - объект хранилища данных
     * @param {boolean} data - состояние
     */
    [ESetters.SET_IS_DESKTOP](state: RootState, data: boolean) {
        state.data.isDesktop = data;
    },

    [ESetters.SET_MOBILE_MENU_STATE](state: RootState) {
        state.data.mobileMenuOpen = !state.data.mobileMenuOpen;
    },

    /**
     * Сохраняем данные о пользователе
     * @param {object} state - объект хранилища данных
     * @param {object} data - данные о пользователе
     */
    [ESetters.SET_USER](state: RootState, data: object) {
        state.data.user = data;
    },

    /**
     * Сохраняем данные об УК пользователе
     * @param {object} state - объект хранилища данных
     * @param {object} data - данные об УК пользователе
     */
    [ESetters.SET_USER_UK](state: RootState, data: object) {
        state.data.user.uk = data;
    },

    [ESetters.SET_REFERRER](state: RootState, data: string | null) {
        state.data.referrer = data;
    },

    /**
     * Сохраняем ссылку для иконки избранного в шапке
     * @param {object} state - объект хранилища данных
     * @param {object} link - ссылка
     */
    [ESetters.SET_FAVORITES_LINK](state: RootState, link: string | null) {
        state.data.favoritesLink = link;
    },

    /**
     * Сохраняем ссылку для иконки сравнения в шапке
     * @param {object} state - объект хранилища данных
     * @param {object} link - ссылка
     */
    [ESetters.SET_COMPARE_LINK](state: RootState, link: string | null) {
        state.data.compareLink = link;
    },

    [ESetters.SET_MANAGERS](state: RootState, managers: null) {
        state.data.managers = managers;
    },

    [ESetters.SET_DEFAULT_FORMS](state: RootState, forms: IFormItem[]) {
        state.data.forms = forms;
    },

    [ESetters.SET_GLOBAL_ERROR](state: RootState, flag: boolean) {
        state.data.globalError = flag;
    },

    [ESetters.SET_PROJECT_MAIN](state: RootState, project: object) {
        state.data.projectMain = project;
    },
};

const store: StoreOptions<RootState> = {
    /**
     * Глобальное хранилище для всего приложения.
     * Доступ только через геттеры, изменение через мутацию.
     */
    state: {
        data: {
            referrer   : null,
            authSlider : null,
            authTimer  : 300,
            projects   : null,
            projectMain: null,
            settings  : {
                copyright: '',
                logoDark : '',
                logoLight: '',
                name     : 'Единый личный&nbsp;кабинет',
                phone    : '',
                sites    : []
            },
            user          : {},
            // userUk        : {},
            forms         : null,
            meeting       : {},
            tour          : {},
            sidebar       : {},
            menu          : [],
            managers      : null,
            isMobile      : false,
            isTablet      : false,
            isDesktop     : true,
            mobileMenuOpen: false,
            compareLink   : null,
            favoritesLink : null,
            globalError   : false
        }
    },

    getters,

    actions,

    mutations
};

export default new Vuex.Store<RootState>(store);
