<template>
    <div class="comfort-level">
        <div class="comfort-level__top">
            <h5 class="comfort-level__top-title">Повысьте уровень комфорта</h5>

            <TabsComponent
                modify="comfort-level__top-selector"
                :tabsData="tabs"
                :activeTab="activeTab"
                type="button"
                @changeTab="tabChanged" />
        </div>

        <div class="comfort-level__banners">
            <div
                v-for="banner in banners[activeTab.slug]"
                :key="banner.id"
                class="comfort-level__banner">
                <div class="comfort-level__banner-image">
                    <img :src="banner.image" :alt="banner.title">
                </div>
                <div class="comfort-level__banner-content">
                    <h4 class="comfort-level__banner-title">{{ banner.title }}</h4>
                    <p class="comfort-level__banner-text" v-html="banner.text"></p>

                    <LinkComponent
                        modify="comfort-level__banner-button"
                        type="button"
                        text="Выбрать"
                        :href="banner.link" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LinkComponent from '@/components/LinkComponent/LinkComponent';
import TabsComponent from '@/components/TabsComponent/TabsComponent';

export default {
    name: 'ComfortLevelComponent',

    components: {
        LinkComponent,
        TabsComponent
    },

    props: {
        tabs: {
            default: () => {
                return [];
            },
            type: Array
        },
        banners: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    data() {
        return {
            activeTab: null
        };
    },

    watch: {
        tabs: {
            handler() {
                if (!this.activeTab) {
                    this.activeTab = this.tabs[0];
                }
            },
            deep     : true,
            immediate: true
        }
    },

    methods: {
        tabChanged(id) {
            this.activeTab = this.tabs.find((item) => {
                return item.id === id;
            });
        }
    }
};
</script>

<style lang="scss">
@import './ComfortLevelComponent';
</style>
