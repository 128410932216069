<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.45988 3.45964C3.85041 3.06912 4.48357 3.06912 4.8741 3.45964L10.0003 8.58586L15.1266 3.45964C15.5171 3.06912 16.1503 3.06912 16.5408 3.45964C16.9313 3.85017 16.9313 4.48333 16.5408 4.87386L11.4146 10.0001L16.5408 15.1263C16.9313 15.5168 16.9313 16.15 16.5408 16.5405C16.1503 16.931 15.5171 16.931 15.1266 16.5405L10.0003 11.4143L4.8741 16.5405C4.48357 16.931 3.85041 16.931 3.45988 16.5405C3.06936 16.15 3.06936 15.5168 3.45989 15.1263L8.58613 10.0001L3.45989 4.87386C3.06936 4.48333 3.06936 3.85017 3.45988 3.45964Z" :fill="`${fill}`"/>
    </svg>
</template>

<script>
export default {
    name: 'CloseIcon',

    props: {
        fill: {
            default: '#8b96a3',
            type   : String
        }
    }
};
</script>
