export interface RootState {
    data: DataState;
}

export interface DataState {
    referrer?: string | null;
    authSlider: object[];
    authTimer: number;
    projects: object[];
    projectMain: object;
    settings: {
        copyright?: string;
        logoDark?: string;
        logoLight?: string;
        name?: string;
        phone?: string;
        sites?: ISites[];
    };
    user: {
        name?: string;
        phone?: string;
        email?: string;
        photo?: string;
        uk?: object;
    };
    forms: IFormItem[] | null;
    sidebar: object;
    meeting: object;
    tour: object;
    menu: object;
    managers: null;
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    mobileMenuOpen: boolean;
    compareLink: string;
    favoritesLink: string;
    globalError: boolean;
}

export interface IResponseUserData {
    success: boolean;
    data: {
        user: {
            surname?: string;
            name?: string;
            phone?: string;
            email?: string;
            photo?: string;
        };
    };
    messages: any[];
}

export interface ISites {
    title: string;
    url: string;
}
export interface IResponseSettingsData {
    success: boolean;
    data: {
        copyright?: string;
        logoDark?: string;
        logoLight?: string;
        name?: string;
        phone?: string;
        sites?: ISites[];
    };
    messages: any[];
}

export interface IResponseSliderData {
    success: boolean;
    data: {
        slider: object[];
    };
    messages: any[];
}

export interface IResponseProjectsData {
    success: boolean;
    data: {
        projects: object[];
    };
    messages: any[];
}

export interface IResponseProjectMainData {
    success: boolean;
    data: {
        project: object[];
    };
    messages: any[];
}

export interface IResponseSidebarData {
    success: boolean;
    data: {
        counters: IResponseSidebarCounterElement[];
        meeting: IResponseMeeting;
        tour: IResponseMeeting;
        managers: null;
    };
    messages: any[];
}

export interface IResponseSidebarCounterElement {
    id: number;
    quantity: number;
}

export interface IMenuItem {
    id: number;
    slug: string;
    href: string;
    text: string;
    children: IMenuChildrenItem[];
}

export interface IMenuChildrenItem {
    id: number;
    slug: boolean;
    href: string;
    text: string;
    quantity: number;
}

export interface IResponseMenuData {
    success: boolean;
    data: {
        menu: IMenuItem[];
        meeting: object | null;
        compareLink: string;
        favoritesLink: string;
        tour: object | null;
        managers: null;
    };
    messages: any[];
}
export interface ResponseValue {
    slides: object[];
    projects: object[];
}

export interface IResponseMeeting {
    appointed: boolean;
    date: string;
    project: string;
    time: string;
}

export interface IFormFieldItem {
    id: number | string;
    name: string;
    type: string;
    title: string;
    placeholder: string;
    required: boolean;
    validation?: {
        type: string;
        msg: string;
    };
    mask?: string;
}

export interface IFormItem {
    id: number | string;
    code: string;
    title: string;
    description: string;
    buttonText: string;
    fields: IFormFieldItem[];
}
export interface IResponseForm {
    success: boolean;
    data: {
        forms: IFormItem[];
    };
    messages: any[];
}

export enum EGetters {
    GET_IS_MOBILE = 'GET_IS_MOBILE',
    GET_IS_TABLET = 'GET_IS_TABLET',
    GET_IS_DESKTOP = 'GET_IS_DESKTOP',
    GET_SETTINGS = 'GET_SETTINGS',
    GET_AUTH_SLIDER_DATA = 'GET_AUTH_SLIDER_DATA',
    GET_AUTH_TIMER = 'GET_AUTH_TIMER',
    GET_PROJECTS_LIST = 'GET_PROJECTS_LIST',
    GET_SIDEBAR = 'GET_SIDEBAR',
    GET_MENU = 'GET_MENU',
    GET_MOBILE_MENU_STATE = 'GET_MOBILE_MENU_STATE',
    GET_USER = 'GET_USER',
    GET_REFERRER = 'GET_REFERRER',
    GET_MEETING = 'GET_MEETING',
    GET_TOUR = 'GET_TOUR',
    GET_FAVORITES_LINK = 'GET_FAVORITES_LINK',
    GET_COMPARE_LINK = 'GET_COMPARE_LINK',
    GET_MANAGERS = 'GET_MANAGERS',
    GET_DEFAULT_FORMS = 'GET_DEFAULT_FORMS',
    GET_GLOBAL_ERROR = 'GET_GLOBAL_ERROR',
    GET_PROJECTS_MAIN = 'GET_PROJECTS_MAIN'
}

export enum ESetters {
    SET_SETTINGS = 'SET_SETTINGS',
    SET_AUTH_SLIDER_DATA = 'SET_AUTH_SLIDER_DATA',
    SET_PROJECTS_LIST = 'SET_PROJECTS_LIST',
    SET_SIDEBAR = 'SET_SIDEBAR',
    SET_MENU = 'SET_MENU',
    SET_IS_MOBILE = 'SET_IS_MOBILE',
    SET_IS_TABLET = 'SET_IS_TABLET',
    SET_IS_DESKTOP = 'SET_IS_DESKTOP',
    SET_MOBILE_MENU_STATE = 'SET_MOBILE_MENU_STATE',
    SET_USER = 'SET_USER',
    SET_USER_UK = 'SET_USER_UK',
    SET_REFERRER = 'SET_REFERRER',
    SET_MEETING = 'SET_MEETING',
    SET_TOUR = 'SET_TOUR',
    SET_FAVORITES_LINK = 'SET_FAVORITES_LINK',
    SET_COMPARE_LINK = 'SET_COMPARE_LINK',
    SET_MANAGERS = 'SET_MANAGERS',
    SET_DEFAULT_FORMS = 'SET_DEFAULT_FORMS',
    SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR',
    SET_PROJECT_MAIN = 'SET_PROJECT_MAIN'
}
