<template>
    <div class="owner-about">
        <OwnerBannerComponent v-if="ownerAboutBanner?.banner?.[0]"
                              modify="is-full-image"
                              :banner="ownerAboutBanner.banner[0]"/>

        <OwnerStepsTabs @tabUpdated="updateSteps" />
        <OwnerAcceptSteps v-if="ownerAboutData.steps?.length" :steps="ownerAboutData.steps" />
        <HelpBannerComponent
            modify="owner-about__help is-full-image"
            image="/images/help-choose/help-choose-interior.jpg"
            title="Есть вопросы о&nbsp;заселении?" />
    </div>
</template>

<script>
import HelpBannerComponent from '@/components/HelpBannerComponent/HelpBannerComponent';
import OwnerAcceptSteps from '@/components/Owner/OwnerAcceptSteps/OwnerAcceptSteps';
import OwnerBannerComponent from '@/components/Owner/OwnerBannerComponent/OwnerBannerComponent';
import OwnerDataController from '@/dataControllers/owner';
import OwnerStepsTabs from '@/components/Owner/OwnerStepsTabs/OwnerStepsTabs';

const OwnerController = new OwnerDataController();

export default {
    name: 'OwnerAboutPage',

    components: {
        HelpBannerComponent,
        OwnerAcceptSteps,
        OwnerBannerComponent,
        OwnerStepsTabs
    },

    data() {
        return {
            ownerAboutData  : {},
            ownerAboutBanner: null,
            isProcessing    : true
        };
    },

    mounted() {
        this.$emit('processing', this.isProcessing);

        const banner = OwnerController.getOwnerAboutBanner();
        const data = OwnerController.getOwnerAboutData('yuntolovo');

        Promise.all([banner, data])
            .then((response) => {
                this.ownerAboutBanner = response[0];
                this.ownerAboutData = response[1];
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                // В axios работает как finally при использовании fetch
                this.isProcessing = false;
                this.$emit('processing', this.isProcessing);
            });
    },

    methods: {
        updateSteps(step) {
            this.$emit('processing', true);

            OwnerController.getOwnerAboutData(step)
                .then((response) => {
                    this.ownerAboutData = response;
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                });
        }
    }
};
</script>

<style>
.owner-about {
    position: relative;
    max-width: 100%;
    overflow-x: hidden;
}
</style>
