<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.79289 6.04289C4.18342 5.65237 4.81658 5.65237 5.20711 6.04289L9 9.83579L12.7929 6.04289C13.1834 5.65237 13.8166 5.65237 14.2071 6.04289C14.5976 6.43342 14.5976 7.06658 14.2071 7.45711L9.70711 11.9571C9.31658 12.3476 8.68342 12.3476 8.29289 11.9571L3.79289 7.45711C3.40237 7.06658 3.40237 6.43342 3.79289 6.04289Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'ArrowDownIcon',

    props: {
        fill: {
            default: '#8b96a3',
            type   : String
        }
    }
};
</script>
