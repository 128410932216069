<template>
    <div class="owner-about__tabs">
        <h4 class="owner-about__tabs-title">Приемка квартиры</h4>
        <TabsComponent
            :tabsData="tabsData"
            :activeTab="activeTab"
            type="button"
            @changeTab="tabChanged" />
    </div>
</template>

<script>
import TabsComponent from '@/components/TabsComponent/TabsComponent';

export default {
    name: 'OwnerStepsTabs',

    components: {
        TabsComponent
    },

    data() {
        return {
            tabsData: [{
                id  : 0,
                slug: 'yuntolovo',
                name: 'ЖК №1'
            }, {
                id  : 1,
                slug: 'sevdol',
                name: 'ЖК №2'
            }],
            activeTab: {
                id  : 0,
                slug: 'yuntolovo',
                name: 'ЖК №1'
            }
        };
    },

    methods: {
        tabChanged(id) {
            this.activeTab = this.tabsData.find((item) => {
                return item.id === id;
            });

            this.$emit('tabUpdated', this.activeTab.slug);
        }
    }
};
</script>

<style lang="scss">
@import './OwnerStepsTabs';
</style>
