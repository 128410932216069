<template>
    <div class="agency-commerce">
        <AgencyProjectListSection
            v-if="content?.complexes && content.complexes.length"
            :complexes="content.complexes" />

        <div class="agency-commerce__filter">
            <SortComponent
                v-if="content?.sort"
                :activeSort="activeSort"
                :sortList="content.sort"
                @sorted="_sortChanged" />

            <ProjectSelectorComponent
                v-if="content?.projects?.length"
                projectLabel="Выберите проект"
                :projectsList="content.projects"
                :activeProject="activeProject"
                @changeValue="_clickProjectHandler" />
        </div>

        <AgencyCommerceListSection
            v-if="content && content.premises?.length"
            :content="content"
            @more="getContent('more')" />
    </div>
</template>

<script>
import AgencyCommerceListSection from '@/sections/Agency/AgencyCommerceListSection/AgencyCommerceListSection';
import AgencyDataController from '../../../dataControllers/agency';
import AgencyProjectListSection from '@/sections/Agency/AgencyProjectListSection/AgencyProjectListSection';
import ProjectSelectorComponent from '@/components/ProjectSelectorComponent/ProjectSelectorComponent';
import SortComponent from '@/components/SortComponent/SortComponent';

const AgencyController = new AgencyDataController();

export default {
    name: 'AgencyCommerceRentPage',

    components: {
        AgencyCommerceListSection,
        AgencyProjectListSection,
        ProjectSelectorComponent,
        SortComponent
    },

    data() {
        return {
            content      : null,
            activeSort   : null,
            activeProject: null,
            isEmpty      : false,
            isProcessing : true
        };
    },

    mounted() {
        this.getContent('init');

        this.$root.$on('order', this._orderHandler);
    },

    methods: {
        /**
         * Обработчик получения данных от бека
         * @param {string} type - тип запроса
         */
        getContent(type) {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            const data = {
                type,
                sort   : type === 'init' ? null : this.activeSort?.['id'] || null,
                more   : type === 'more' ? this.content?.more?.count : null,
                project: type === 'init' ? null : this.activeProject?.['id'] || null
            };

            AgencyController.getCommerceData(data)
                .then((response) => {
                    this.content = response;
                    this.activeProject = AgencyController.getActiveFilter('projects', 'checked');
                    this.activeSort = AgencyController.getActiveFilter('sortList', 'selected');
                    this.isEmpty = this.content.noResults;
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                });
        },

        /**
         * Изменяем сортировку
         * @param {string} id - ид выбранной сортировки
         */
        _sortChanged(id) {
            this.activeSort = this.content.sort.find((sort) => {
                return sort.id === id;
            });

            this.getContent('sort');
        },

        /**
         * Выбираем проект для фильтрации
         * @param {string} id - ид выбранного проекта
         */
        _clickProjectHandler(id) {
            if (this.content?.projects) {
                this.activeProject = this.content.projects.find((project) => {
                    return project.id === id;
                });

                this.getContent('filter');
            }
        }
    }
};
</script>

<style lang="scss">
@import './AgencyCommerceRentPage';
</style>
