<template>
    <div
        :class="[
            'common-card',
            {
                'common-card_theme_finished': content.finished && !content.passed,
                'common-card_theme_passed': (content.finished && content.passed) || content.isComplete,
                'common-card_theme_ready': content.isReady
            },
            modify
        ]"
    >
        <div class="common-card__info">
            <div v-if="!content.isReady" class="common-card__preview">
                <img :src="content.image" :alt="content.name">
            </div>

            <div v-if="content.project" class="common-card__project">{{ content.project }}</div>

            <h4 class="common-card__title">
                <span class="common-card__title-item common-card__title-name">{{ content.name }}</span>
                <span class="common-card__title-item common-card__title-price">
                    {{ _convertToDigit(content.price) }} ₽
                </span>
                <span v-if="isBooking" class="common-card__title-item common-card__title-icon"><LockIcon /></span>
            </h4>

            <ButtonComponent
                v-if="!content.isComplete"
                :modify="computedOpenButtonModify"
                :text="
                    isMobile ?
                        isOpened ? 'Скрыть подробности' : 'Показать подробности' :
                        null
                "
                :icon="!isMobile ? 'ArrowDownIcon' : null"
                :fill="isOpened ? '#ffffff' : '#455569'"
                @click="isOpened = !isOpened" />

            <transition
                name="common-card__expand"
                @enter="enter"
                @after-enter="afterEnter"
                @leave="leave"
            >
                <div v-if="isOpened && !content.isComplete" class="common-card__content">
                    <div class="common-card__image">
                        <img :src="content.image" :alt="content.name">
                    </div>

                    <div class="common-card__description">
                        <div v-if="!isBooking && content.owners" class="common-card__owners">
                            <div class="common-card__owners-title">Дольщики</div>
                            <div class="common-card__owners-list">
                                <div v-for="owner in content.owners" :key="owner.id" class="common-card__owners-item">
                                    {{owner.shortName}}
                                </div>

                                <ButtonComponent
                                    text="Подробнее"
                                    modify="button_theme_flat button_theme_thin common-card__owners-more"
                                    @click="_showOwners" />
                            </div>
                        </div>

                        <div v-if="cardOptions" class="common-card__options">
                            <template v-for="option in cardOptions">
                                <div v-for="(value, name) in option" :key="name" class="common-card__options-item">
                                    <div class="common-card__options-name">{{ name }}</div>
                                    <div class="common-card__options-value">{{ value }}</div>
                                </div>
                            </template>
                        </div>

                        <div v-if="content.tags?.length" class="common-card__tags">
                            <div v-for="tag in content.tags" :key="tag" class="common-card__tags-item">{{ tag }}</div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <div
            :class="[
                'common-card__status',
                // Если раздел бронирования и бронирование завершено
                {'is-booking-complete': isBooking && content.isComplete}
            ]">
            <template v-if="bookingStatus?.length && !content.isComplete">
                <div
                    v-for="(status, index) in bookingStatus"
                    :key="status.id"
                    class="common-card__status-item">
                    <div
                        :class="[
                            'common-card__status-index',
                            {'is-disabled': index !== content.currentStatusID && index > content.currentStatusID}
                        ]">
                        <span v-if="index >= content.currentStatusID || content.currentStatusID === 0">
                            {{ index + 1 }}
                        </span>
                        <span v-else>
                            <CheckboxCircleIcon />
                        </span>
                    </div>
                    <DetailsComponent
                        v-if="content.currentStatusID >= index"
                        modify="common-card__status-details"
                        :plus=true
                        :open="content.currentStatusID === index"
                        :title="status.name"
                        :text="status.text" />
                    <div v-else class="common-card__status-name">{{ status.name }}</div>
                </div>
                <div v-if="annotation" class="common-card__status-annotation" v-html="annotation"></div>
            </template>

            <template v-else>
                <div class="common-card__status-universal">
                    <div v-if="!isBooking || (isBooking && content.isComplete)" class="common-card__status-booking">
                        <CheckboxCircleIcon v-if="!content.finished && !content.passed" />
                        <KeysIcon v-else />
                    </div>
                    <div class="common-card__status-text">
                        {{ content.statusText }}
                        <span
                            v-if="content.statusDeadline && content.statusDeadline?.length"
                            class="common-card__status-deadline">
                            до {{ content.statusDeadline }}
                        </span>
                    </div>

                    <div v-if="content.statusAnno && content.statusAnno?.length" class="common-card__status-anno">
                        {{ content.statusAnno }}
                    </div>
                </div>
                <router-link
                    v-if="isBooking && content.isComplete"
                    to="/content/objects"
                    class="button button_theme_gray common-card__status-link">
                    Перейти в Мои объекты
                </router-link>
            </template>
        </div>

        <div class="common-card__manage">
            <template v-if="isBooking && !content.isComplete">
                <ButtonComponent
                    modify="button_theme_gray button_theme_circle common-card__manage-delete"
                    icon="TrashIcon"
                    fill="#455569"
                    @click="_deleteCard" />

                <ButtonComponent
                    modify="button_theme_gray common-card__manage-info"
                    leftIcon="InfoIcon"
                    fill="#455569"
                    text="Об этапах покупки"
                    @click="_showPopupHandler('bookingSteps')" />

                <ButtonComponent
                    class="common-card__manage-question"
                    text="Задать вопрос"
                    @click="_showPopupHandler('booking-question')" />
            </template>

            <template v-else-if="!isBooking">
                <LinkComponent
                    v-if="content.progress && !content.passed"
                    :href="content.progress"
                    modify="button_theme_gray common-card__manage-progress"
                    type="button"
                    text="Ход строительства" />

                <LinkComponent
                    v-if="content.news"
                    :href="content.news"
                    type="button"
                    modify="button_theme_gray common-card__manage-news"
                    text="Новости" />

                <ButtonComponent
                    v-if="!content.passed"
                    modify="common-card__manage-keys"
                    :disabled="!content.finished"
                    text="Выдача ключей"
                    @click="_keysClickHandler(content.keys || '/owner/keys')" />

                <ButtonComponent
                    v-else
                    class="common-card__manage-question"
                    text="Задать вопрос"
                    @click="_showPopupHandler('booking-question')" />
            </template>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import CheckboxCircleIcon from '@/icons/ChecboxCircleIcon';
import DetailsComponent from '@/components/DetailsComponent/DetailsComponent';
import KeysIcon from '@/icons/KeysIcon';
import LinkComponent from '@/components/LinkComponent/LinkComponent';
import LockIcon from '@/icons/LockIcon';
import {mapGetters} from 'vuex';
import {Utils} from '@/scripts/utils';


export default {
    name: 'BookingCard',

    components: {
        ButtonComponent,
        CheckboxCircleIcon,
        DetailsComponent,
        KeysIcon,
        LinkComponent,
        LockIcon
    },

    props: {
        annotation: {
            default: null,
            type   : String
        },
        content: {
            default: () => {
                return {};
            },
            type: Object
        },
        cardOptions: {
            default: () => {
                return [];
            },
            type: Array
        },
        isBooking: {
            default: false,
            type   : Boolean
        },
        modify: {
            default: '',
            type   : String
        },
        steps: {
            default: () => {
                return [];
            },
            type: Array
        },
        bookingStatus: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    data() {
        return {
            isOpened: true
        };
    },

    computed: {
        ...mapGetters({
            isMobile: 'GET_IS_MOBILE'
        }),

        computedOpenButtonModify() {
            const modifyArray = ['common-card__open-button'];

            if (this.isMobile) {
                modifyArray.push('is-mobile');
            } else {
                modifyArray.push('button_theme_circle');
            }

            if (this.isOpened) {
                modifyArray.push('is-opened');
            } else {
                modifyArray.push('button_theme_gray');
            }

            return modifyArray.join(' ');
        }
    },

    methods: {
        /**
         * Передаем в родительский компонент запрос на удаление из списка
         * бронирования текущее помещение
         */
        _deleteCard() {
            this.$emit('deleteBooking', this.content.dealId);
        },

        /**
         * Передаем в родительский компонент запрос на отображение попапа
         * @param {string} popup - ид попапа
         */
        _showPopupHandler(popup) {
            const data = popup === 'bookingSteps' ? this.steps : this.content.guid;

            this.$root.$emit('showPopup', popup, data);
        },

        _showOwners() {
            this.$emit('showOwners');
        },

        // Источник анимации тут -
        // https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
        enter(element) {
            const width = getComputedStyle(element).width;

            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';

            const height = getComputedStyle(element).height;

            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;

            // Trigger the animation.
            // We use `requestAnimationFrame` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            requestAnimationFrame(() => {
                element.style.height = height;
            });
        },

        afterEnter(element) {
            element.style.height = 'auto';
        },

        leave(element) {
            const height = getComputedStyle(element).height;

            element.style.height = height;

            requestAnimationFrame(() => {
                element.style.height = 0;
            });
        },

        _convertToDigit(num) {
            return Utils.convertToDigit(num);
        },

        _keysClickHandler(link) {
            this.$router.push(link);
        }
    }
};
</script>

<style lang="scss">
@import './CommonCard';
</style>
