<template>
    <div class="object-page">
        <TabsComponent
            modify="object-page__tabs"
            type="button"
            :tabsData="tabsData"
            :activeTab="activeTab"
            @changeTab="_changeActiveTab" />
        <ObjectsListComponent
            v-if="listData?.length"
            :listData="listData"
            :activeTab="activeTab.slug"
            @showOwners="_showOwnersHandler" />

        <EmptyComponent
            v-else
            modify="booking-page__empty"
            title="Нет активных сделок" />

        <portal to="popups">
            <transition name="fade">
                <PopupOwnersComponent v-if="showOwners" @closePopup="showOwners = !showOwners" :popupData="popupData" />
            </transition>
        </portal>
    </div>
</template>

<script>
import EmptyComponent from '@/components/EmptyComponent/EmptyComponent';
import ObjectsDataController from '@/dataControllers/objects';
import ObjectsListComponent from '@/components/Objects/ObjectsListComponent/ObjectsListComponent';
import PopupOwnersComponent from '@/components/Popups/PopupOwnersComponent/PopupOwnersComponent';
import TabsComponent from '@/components/TabsComponent/TabsComponent';

const ObjectsController = new ObjectsDataController();

export default {
    name: 'ObjectPage',

    components: {
        EmptyComponent,
        ObjectsListComponent,
        PopupOwnersComponent,
        TabsComponent
    },

    data() {
        return {
            listData    : [],
            showOwners  : false,
            popupData   : {},
            isProcessing: true,
            tabsData    : [],
            activeTab   : {}
        };
    },

    mounted() {
        this._getRequest();
    },

    methods: {
        /**
         * Универсальный обработчик запроса к апи бронирования
         */
        _getRequest() {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            ObjectsController.getObjects()
                .then((response) => {
                    if (response.success) {
                        this.listData = response?.list;
                        this.tabsData = response.tabsData;
                        this.activeTab = this.tabsData?.length ? this.tabsData[0] : [];
                    } else {
                        this.$emit('errorShow', true, {
                            statusCode: response.errorData?.status,
                            url       : response.errorData?.url
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.$emit('errorShow', true, {
                        statusCode: 500,
                        url       : 'Неизвестная ошибка на странице Списка объектов'
                    });
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                });
        },

        _showOwnersHandler(data) {
            this.popupData = data;
            this.showOwners = !this.showOwners;
        },

        /**
         * Переключаем табы
         * @param {string} id - ид нового активного таба
         */
        _changeActiveTab(id) {
            this.activeTab = this.tabsData.find((item) => {
                return item.id === id;
            });
        }
    }
};
</script>

<style lang="scss">
@import './ObjectPage';
</style>
