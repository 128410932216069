<template>
    <section class="treatment-create">
        <h4 class="treatment-create__title">Создать новое обращение</h4>

        <form class="treatment-create__form">
            <SelectComponent
                :list="themes"
                label="Выберите тему"
                modify="treatment-create__select"
                :clearActive="clearActiveTheme"
                @clickRadio="_clickRadioHandler($event, 'theme')" />

            <SelectComponent
                :list="objects"
                label="Выберите объект"
                modify="treatment-create__select"
                :clearActive="clearActiveProject"
                @clickRadio="_clickRadioHandler($event, 'objectGuid')" />

            <InputComponent
                ref="question"
                name="treatment-question"
                type="textarea"
                label="Ваше обращение или вопрос"
                inputModify="treatment-create__textarea"
                @inputChanged="_inputChanged" />

            <ButtonComponent
                modify="treatment-create__button"
                :disabled="!requestDisabled"
                text="Отправить"
                @click="_clickHandler" />
        </form>
    </section>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import InputComponent from '@/components/InputComponent/InputComponent';
import SelectComponent from '@/components/SelectComponent/SelectComponent.vue';
import TreatmentController from '@/dataControllers/treatments';

const TreatmentDataController = new TreatmentController();

export default {
    name: 'TreatmentCreateSection',

    components: {
        ButtonComponent,
        InputComponent,
        SelectComponent
    },

    props: {
        objects: {
            default: () => {
                return [];
            },
            type: Array
        },
        themes: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    data() {
        return {
            formData: {
                objectGuid: null,
                theme     : null,
                text      : null
            },
            requestDisabled   : false,
            clearActiveProject: false,
            clearActiveTheme  : false,
            formSuccess       : true,
            formSend          : false
        };
    },

    methods: {
        /**
         * Обработчик клика по нажатому варианту в выпадающем списке
         * @param {object} item - Vue объект элемента
         * @param {string} target - элемент какого из выпадающих списков был выбран
         */
        _clickRadioHandler(item, target) {
            if (!item?.text || !target) {
                return;
            }

            this.formData[target] = target === 'objectGuid' ? item.id : item.text;

            this._checkRequestIsPossible();
        },

        /**
         * Обработчик ввода текста в поле ввода
         * @param {object} input - VUE объект элемента
         */
        _inputChanged(input) {
            this.formData.text = input.inputField;
            this._checkRequestIsPossible();
        },

        /**
         * Проверка на возможность отправки обращения
         */
        _checkRequestIsPossible() {
            this.requestDisabled = this.formData.objectGuid &&
                this.formData.theme && (this.formData.text?.length > 30);
        },

        /**
         * Обработчик клика по кнопке отправки
         */
        _clickHandler() {
            if (!this._checkRequestIsPossible) {
                return;
            }

            this.$emit('isProcessing', true);

            TreatmentDataController.setTreatments(this.formData)
                .then((response) => {
                    this.formSend = true;
                    this.formSuccess = response;
                    this.requestDisabled = true;
                })
                .catch((error) => {
                    this.formSend = true;
                    console.error(error);
                })
                .then(() => {
                    this.$emit('isProcessing', false);

                    this._clearForm();
                });
        },

        /**
         * Очищаем форму после отправки
         */
        _clearForm() {
            this.clearActiveProject = true;
            this.clearActiveTheme = true;

            if (this.$refs?.question?.inputField) {
                this.$refs.question.inputField = '';
                this.$refs.question.$el?.classList.remove('is-focus');
            }
        }
    }
};
</script>

<style lang="scss">
@import './TreatmentCreateSection';
</style>
