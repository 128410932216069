import type {AxiosError, AxiosResponse} from 'axios';
import type {
    IObjectsDataController,
    IObjectsResponseResult,
    IObjectsResult
} from './types';
import axios from 'axios';
import {ERequests} from '../../requests';
import {ObjectsData} from './objectsData';
import {Utils} from '../../scripts/utils';

const ObjectsDataStore = new ObjectsData();

export default class ObjectsDataController implements IObjectsDataController {
    /**
     * Отправляем запрос на апи - результат сохраняется в "привязанный" дата
     * стор (не глобальный) и передается в ту функцию , что обратилась
     * к текущей
     * @returns {object} - возвращает результат запроса
     */
    public async getObjects(): Promise<IObjectsResult> {
        const result: IObjectsResult = {
            success : true,
            list    : [],
            tabsData: []
        };

        await axios.get(ERequests.objects)
            .then((response: AxiosResponse<IObjectsResponseResult>) => {
                ObjectsDataStore.setStoreData(response.data);
                result.list = response.data.data.objects;
                result.tabsData = response.data.data.tabsData || [];
            })
            .catch((error: AxiosError) => {
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }

    /**
     * Формируем список опций помещения
     * @param {object} item - объект с данными помещения
     * @returns {array} - массив с объектами данных опций помещения
     */
    public getCardOptions(item: object): object[] {
        if (!item) {
            return [];
        }

        const options = [];
        const optionsKeys = ObjectsDataStore.getStoreData().optionsKeys;

        for (const key in optionsKeys) {
            if (Object.prototype.hasOwnProperty.call(optionsKeys, key)) {
                if (item[key]) {
                    options.push({[optionsKeys[key]]: item[key]});
                }
            }
        }

        return options;
    }
}
