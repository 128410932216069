<template>
    <div v-if="meeting" class="meeting">
        <transition name="fade">
            <ClearComponent
                v-if="clearShow"
                :clearComplete="clearComplete"
                title="Отменить встречу"
                description="Вы уверены, что хотите отменить встречу?"
                closeText="Отклонить"
                buttonText="Подтвердить"
                @closeClearPopup="_clearSwitch"
                @makeClear="_cancelMeeting"
            />
        </transition>
        <template v-if="!isProcessing && meeting?.date">
            <h4 class="meeting__title">{{ computedTitle }}</h4>
            <p class="meeting__title-description">{{ computedDescription }}</p>

            <template v-if="meeting.appointed">
                <div class="meeting__details">
                    <div v-if="meeting.date" class="meeting__details-item">
                        <p class="meeting__details-title">Дата</p>
                        <h5 class="meeting__details-content">{{ meeting.date }}</h5>
                    </div>
                    <div v-if="meeting.time" class="meeting__details-item">
                        <p class="meeting__details-title">Время</p>
                        <h5 class="meeting__details-content">{{ meeting.time }}</h5>
                    </div>
                </div>

                <div v-if="meeting.manager || meeting.place || meeting.length" class="meeting__description">
                    <div v-if="meeting.manager" class="meeting__description-item meeting__manager">
                        <div class="meeting__description-media meeting__manager-photo">
                            <img :src="meeting.manager.image" :alt="meeting.manager.name">
                        </div>

                        <div class="meeting__description-detail meeting__manager-name">
                            <span>Ваш менеджер</span>
                            <p v-html="meeting.manager.name"></p>
                        </div>
                    </div>

                    <div v-if="meeting.length" class="meeting__description-item meeting__length">
                        <div class="meeting__description-media meeting__length-icon">
                            <img src="/webicons/meetings/clock.svg" :alt="meeting.length">
                        </div>

                        <div class="meeting__description-detail meeting__length-content">
                            <span>Продолжительность</span>
                            <p v-html="meeting.length"></p>
                        </div>
                    </div>

                    <div v-if="meeting.place" class="meeting__description-item meeting__place">
                        <div class="meeting__description-media meeting__place-icon">
                            <img src="/webicons/meetings/pin.svg" :alt="meeting.place">
                        </div>

                        <div class="meeting__description-detail meeting__place-address">
                            <span>Место встречи</span>
                            <p v-html="meeting.place"></p>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else>
                <div class="meeting__empty">
                    <p class="meeting__empty-title">Вы создали заявку на встречу</p>
                    <p class="meeting__empty-text">Мы с вами свяжемся для уточнения даты и времени</p>
                </div>
            </template>

            <div class="meeting__buttons">
                <ButtonComponent
                    modify="meeting__buttons-button meeting__buttons-callback"
                    text="Задать вопрос"
                    @click="callbackPopup" />

                <ButtonComponent
                    modify="button_theme_gray meeting__buttons-button meeting__buttons-cancel"
                    text="Отменить встречу"
                    @click="_clearSwitch" />
            </div>
        </template>
        <template v-else>
            <h4 class="meeting__title">{{ previewTitle }}</h4>
        </template>
    </div>
</template>

<script>
import axios from 'axios';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import ClearComponent from '@/components/ClearComponent/ClearComponent';
import {ERequests} from '@/requests';
import {mapGetters} from 'vuex';

export default {
    name: 'MeetingPage',

    components: {
        ButtonComponent,
        ClearComponent
    },

    data() {
        return {
            meeting      : {},
            type         : null,
            isProcessing : true,
            clearShow    : false,
            clearComplete: false,
            previewTitle : 'Загружаем данные о встрече'
        };
    },

    computed: {
        ...mapGetters({
            meetingData: 'GET_MEETING',
            tourData   : 'GET_TOUR'
        }),

        computedTitle() {
            return this.type === 'manager' ?
                'Встреча с менеджером' :
                `Экскурсия ${this.meeting.project ? 'по ЖК ' : ''}${this.meeting.project ? this.meeting.project : ''}`;
        },

        computedDescription() {
            return this.type === 'manager' ?
                'Задайте все вопросы менеджеру напрямую, встретившись с ним в одном из наших офисов продаж.' :
                // eslint-disable-next-line
                `Экскурсия ${this.meeting.project ? 'по ЖК ' : ''}${this.meeting.project ? this.meeting.project : '' } в сопровождении менеджера, на которой вы сможете ознакомиться с жизнью в проекте и задать все интересующие вас вопросы.`
        }
    },

    watch: {
        meetingData() {
            if (this.type === 'manager') {
                this.meeting = this.meetingData;
                this.isProcessing = false;
            }
        },

        tourData() {
            if (this.type !== 'manager') {
                this.meeting = this.tourData;
                this.isProcessing = false;
            }
        },

        isProcessing() {
            this.$emit('processing', this.isProcessing);
        }
    },

    mounted() {
        this.type = this.$route.meta.type ? this.$route.meta.type : 'manager';
        this.isProcessing = true;
        this.getMeetings(this.type);
    },

    methods: {
        getMeetings(type) {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            if (type === 'manager' && this.meetingData && Object.keys(this.meetingData).length) {
                this.meeting = this.meetingData;
                this.isProcessing = false;
            } else if (this.tourData && Object.keys(this.tourData)?.length) {
                this.meeting = this.tourData;
                this.isProcessing = false;
            }

            this.$emit('processing', this.isProcessing);
        },

        callbackPopup() {
            this.$root.$emit('showPopup', 'callback', 'Уточнение по встречи с менеджером');
        },

        async _cancelMeeting() {
            this.isProcessing = true;
            this.$emit('processing', this.isProcessing);

            const requestAddress = this.type === 'manager' ?
                `${ERequests.meeting}/cancel` :
                `${ERequests.tour}/cancel`;

            await axios.post(requestAddress)
                .then(() => {
                    this.$router.go(-1);
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(() => {
                    // В axios работает как finally при использовании fetch
                    this.isProcessing = false;
                    this.$emit('processing', this.isProcessing);
                    this.clearShow = false;
                    this.clearComplete = true;
                });
        },

        computedPreviewTitle() {
            if (!this.isProcessing && this.meeting && !Object.keys(this.meeting)?.length) {
                this.previewTitle = 'Данные о встрече не найдены';
            }
        },

        _clearSwitch() {
            this.clearShow = !this.clearShow;
        }
    }
};
</script>

<style lang="scss">
@import './MeetingPage';
</style>
