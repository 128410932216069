import type {AxiosError, AxiosResponse} from 'axios';
import type {
    IContactsResponse,
    IContactsResult,
    IManagementInfoResponse,
    IManagementInfoResult,
    IManagementServicesResponse,
    IManagementServicesResult,
    INewsContentResponse,
    INewsContentResult,
    INewsRequestOptions,
    INewsRequestResult,
    IPaymentMethodItem,
    IPaymentMethodsResponse,
    IPaymentMethodsResult
} from './type';
import axios from 'axios';
import {ERequests} from '../../requests';
import ManagementData from './managementData';
import {Utils} from '../../scripts/utils';

const ManagementDataStore = new ManagementData();

export default class ManagementDataController {
    async getInfo(): Promise<IManagementInfoResult> {
        const result: IManagementInfoResult = {
            success: true
        };

        await axios.get(ERequests.managementInfo)
            .then((response: AxiosResponse<IManagementInfoResponse>) => {
                result.banner = response.data.data?.banner;
                result.text = response.data.data?.text;
            })
            .catch((error: AxiosError) => {
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }

    async getServices(type: string): Promise<IManagementServicesResult> {
        const result: IManagementServicesResult = {
            success: true
        };

        await axios.post(ERequests.services, {type})
            .then((response: AxiosResponse<IManagementServicesResponse>) => {
                result.tabs = response.data.data.tabs;
                result.services = response.data.data.services;
            })
            .catch((error: AxiosError) => {
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }

    async getNews(options: INewsRequestOptions): Promise<INewsRequestResult> {
        const data = {
            type : options.type,
            page : options.page,
            limit: 12
        };

        const result: INewsRequestResult = {
            list     : [],
            noResults: false,
            more     : null,
            success  : true
        };


        await axios.post(ERequests.news, data)
            .then((response: AxiosResponse) => {
                // Записываем данные в хранилище
                ManagementDataStore.setRequestResponse(response.data.data, data.type);

                // Получаем данные из хранилища
                // Такая схема работы используется для конкатенации подгружаемых акций
                const storeData = ManagementDataStore.getStoreData();

                result.list = storeData.list;
                result.noResults = storeData.noResults;
                result.more = storeData.more;
            })
            .catch((error: AxiosError) => {
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }

    public async getNewsContent(id: string | number): Promise<INewsContentResult> {
        const result = {
            date        : '',
            title       : '',
            text        : '',
            showProjects: true
        };

        await axios.get(ERequests.newsItem)
            .then((response: AxiosResponse<INewsContentResponse>) => {
                result.date = response.data.data.date;
                result.title = response.data.data.title;
                result.text = response.data.data.text;
                result.showProjects = response.data.data.showProjects;
            });

        return result;
    }

    public async getPaymentMethods(): Promise<IPaymentMethodsResult> {
        const result: IPaymentMethodsResult = {
            success: true,
            tabs   : [],
            content: []
        };

        await axios.get(ERequests.paymentMethods)
            .then((response: AxiosResponse<IPaymentMethodsResponse>) => {
                const raw = response.data;

                if (raw.data?.lk?.length) {
                    raw.data.lk.forEach((item: IPaymentMethodItem) => {
                        result.tabs.push({
                            id  : item.id,
                            name: item.title,
                            slug: item.id
                        });
                    });
                }

                result.content = raw.data.lk;
            })
            .catch((error: AxiosError) => {
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }

    public async getContacts(): Promise<IContactsResult> {
        const result: IContactsResult = {
            list   : [],
            socials: [],
            success: true
        };

        await axios.get(ERequests.managementContacts)
            .then((response: AxiosResponse<IContactsResponse>) => {
                const data = response.data.data?.content?.online;

                result.list = data.list;
                result.socials = data.socials;
            })
            .catch((error: AxiosError) => {
                result.success = false;
                result.errorData = Utils.makeError(error);
            });

        return result;
    }
}
