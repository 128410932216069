<template>
    <div class="managers-plate">
        <template v-if="managers?.length">
            <div v-if="managers.length > 1" class="managers-plate__tabs">
                <TabsComponent
                    :tabsData="managersTabs"
                    :activeTab="activeManager"
                    @changeTab="_changeActiveManager" />
            </div>
            <div class="managers-plate__list">
                <template v-for="manager in managers">
                    <div
                        :key="manager.id"
                        v-if="manager?.id === activeManager?.id || managers?.length === 1"
                        class="managers-plate__item"
                    >
                        <div class="managers-plate__image">
                            <img :src="manager.image" :alt="manager.name">
                        </div>
                        <div class="managers-plate__name">{{ manager.name }}</div>
                        <div class="managers-plate__role">{{ manager.role }}</div>
                        <LinkComponent
                            :href="`tel:${manager.phone}`"
                            modify="sidebar__managers-phone"
                            :text="manager.phone" />

                        <div class="managers-plate__socials">
                            <ButtonComponent
                                text="Заказать звонок"
                                modify="button_theme_thin button_theme_gray"
                                @click="_callbackHandler"/>

                            <LinkComponent
                                v-if="manager.email"
                                type="button"
                                :href="`mailto:${manager.email}`"
                                modify="button_theme_circle-small button_theme_gray"
                                icon="EmailIcon" />


                            <a
                                v-for="(social, key) in manager.socials"
                                :key="social"
                                :href="social"
                                class="button button_theme_circle-small button_theme_gray"
                                target="_blank"
                            >
                                <span class="button__icon">
                                    <TelegramIcon v-if="key === 'tg'" />
                                    <WhatsappIcon v-if="key === 'whatsapp'" />
                                </span>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <div class="managers-plate__default">
                <div class="managers-plate__default-content">
                    <div class="managers-plate__default-title">Есть вопросы?</div>
                    <LinkComponent
                        :href="`tel:${settings?.phone}`"
                        modify="managers-plate__default-phone"
                        :text="settings?.phone" />
                </div>

                <div class="managers-plate__default-buttons">
                    <ButtonComponent
                        text="Заказать звонок"
                        modify="button_theme_gray"
                        @click="_callbackHandler"/>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import LinkComponent from '@/components/LinkComponent/LinkComponent';
import {mapGetters} from 'vuex';
import TabsComponent from '@/components/TabsComponent/TabsComponent';
import TelegramIcon from '@/icons/TelegramIcon';
import WhatsappIcon from '@/icons/WhatsappIcon';

export default {
    name: 'ManagerPlateComponent',

    components: {
        ButtonComponent,
        LinkComponent,
        TabsComponent,
        TelegramIcon,
        WhatsappIcon
    },

    props: {
        managers: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    data() {
        return {
            activeManager: null,
            managersTabs : []
        };
    },

    computed: {
        ...mapGetters({
            settings: 'GET_SETTINGS'
        })
    },

    watch: {
        managersTabs() {
            if (this.managersTabs?.length > 1) {
                this.activeManager = this.managersTabs[0];
            }
        },

        managers() {
            if (this.managers?.length > 1) {
                this.createManagersTabs();
            }
        }
    },

    methods: {
        _changeActiveManager(id) {
            this.activeManager = this.managersTabs.find((manager) => {
                return manager.id === id;
            });
        },

        _callbackHandler() {
            this.$root.$emit('showPopup', 'callback');
        },

        // Собираем данные для табов переключения менеджеров если их больше 1
        createManagersTabs() {
            this.managersTabs = [];

            this.managers.forEach((manager) => {
                this.managersTabs.push({
                    id  : manager.id,
                    slug: manager.type,
                    name: manager.typeName
                });
            });
        }
    }
};
</script>

<style lang="scss">
@import './ManagerPlateComponent';
</style>
