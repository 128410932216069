<template>
    <div :class="['preloader', modify]">
        <div class="preloader__spinner"></div>
    </div>
</template>

<script>
export default {
    name: 'PreloaderComponent',

    props: {
        modify: {
            default: null,
            type   : String
        }
    }
};
</script>

<style lang="scss">
@import './PreloaderComponent';
</style>
