<template>
    <div class="booking-list">
        <BookingCard
            v-for="item in listData"
            :key="item.index"
            :annotation="annotation"
            :activeTab="activeTab"
            :content="item"
            :bookingStatus="bookingStatus"
            @deleteBooking="_deleteHandler"
        />
    </div>
</template>

<script>
import BookingCard from '../BookingCard/BookingCard';

export default {
    name: 'BookingList',

    components: {
        BookingCard
    },

    props: {
        annotation: {
            default: null,
            type   : String
        },
        listData: {
            default: () => {
                return [];
            },
            type: Array
        },
        bookingStatus: {
            default: () => {
                return [];
            },
            type: Array
        },
        activeTab: {
            default: 'all',
            type   : String
        }
    },

    methods: {
        /**
         * Получаем из дочернего компонента и передаем в родительский компонент
         * запрос на удаление из списка бронирования текущее помещение
         * @param {string} id - ид удаляемого элемента
         */
        _deleteHandler(id) {
            this.$emit('deleteBooking', id);
        }
    }
};
</script>
