import OwnerAboutPage from '../../pages/Owner/OwnerAboutPage.vue';
import OwnerKeysPage from '../../pages/Owner/OwnerKeysPage.vue';

const ownerChildren = [{
    path     : 'about',
    name     : 'about',
    component: OwnerAboutPage,
    meta     : {
        name: 'О передаче объектов'
    },
    props: {
        section: 'owner'
    }
}, {
    path     : 'keys',
    name     : 'keys',
    component: OwnerKeysPage,
    meta     : {
        name: 'Запись на приемку'
    },
    props: {
        section: 'owner'
    }
}];

export default ownerChildren;
