var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-first order-body"},[_c('h5',{staticClass:"order-first__title"},[_vm._v(_vm._s(_vm.computedTitle))]),_vm._v(" "),(_vm.phoneStep === 2)?_c('p',{staticClass:"order-first__title-description"},[_vm._v("\n        Введите данные для регистрации\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"order-first__wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.phoneStep === 0),expression:"phoneStep === 0"}],staticClass:"order-first__phone order-first__content"},[_c('InputComponent',{attrs:{"name":"smsphone","label":"Ваш номер телефона","placeholder":"+7 (___) ___-__-__","mask":"+7 (###) ###-##-##","inputModify":"input_theme_order","validation":{
                    type: 'phone',
                    msg: 'Введите корректный номер телефона'
                },"data-store-instance":_vm.AuthStore},on:{"inputChanged":_vm._inputChanged}}),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"button_theme_yuntolovo","text":"Получить СМС-код","disabled":_vm.isProcessing || _vm.isInputError},on:{"click":_vm.getSMS}}),_vm._v(" "),_vm._m(0)],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.phoneStep === 1),expression:"phoneStep === 1"}],staticClass:"order-first__code order-first__content"},[_c('p',{staticClass:"order-first__code-info"},[_vm._v("Код отправлен на номер "+_vm._s(_vm.phoneNum))]),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"button_theme_flat order-first__code-change","text":"Изменить номер"},on:{"click":_vm.changeNum}}),_vm._v(" "),_c('InputComponent',{attrs:{"name":"smscode","inputModify":"input_theme_order","label":"Введите код из смс","placeholder":"Введите код из смс","maxlength":"6","validation":{
                    type: 'numberLength',
                    length: 6,
                    msg: 'Код должен состоять из 6 цифр'
                },"parent-error":_vm.parentError,"data-store-instance":_vm.AuthStore},on:{"inputChanged":_vm._inputChanged}}),_vm._v(" "),_c('div',{staticClass:"order-first__code-buttons"},[_c('ButtonComponent',{attrs:{"modify":"button_theme_yuntolovo","text":"Далее","disabled":_vm.isProcessing || _vm.isInputError},on:{"click":_vm.checkCode}}),_vm._v(" "),_c('ButtonComponent',{attrs:{"modify":"button_theme_yuntolovo button_theme_gray","disabled":_vm.resendDisabled,"text":_vm.resendText},on:{"click":_vm._resendCode}})],1)],1),_vm._v(" "),(_vm.phoneStep === 2)?_c('div',{staticClass:"order-first__registration order-first__content"},[_c('InputComponent',{ref:"surname",attrs:{"name":"surname","label":"Фамилия","placeholder":"Фамилия","inputModify":"input_theme_order","type":"text","validation":{
                    type: 'letters',
                    msg: 'Введите только буквы'
                },"data-store-instance":_vm.AuthStore,"parent-error":_vm.parentError},on:{"inputChanged":_vm._inputChanged}}),_vm._v(" "),_c('InputComponent',{ref:"name",attrs:{"name":"name","label":"Имя","placeholder":"Имя","inputModify":"input_theme_order","type":"text","validation":{
                    type: 'letters',
                    msg: 'Введите только буквы'
                },"data-store-instance":_vm.AuthStore,"parent-error":_vm.parentError},on:{"inputChanged":_vm._inputChanged}}),_vm._v(" "),_c('InputComponent',{attrs:{"name":"phone","label":"Ваш номер телефона","inputModify":"input_theme_order","disabled":"disabled","value":_vm.phoneNum}}),_vm._v(" "),_c('ButtonComponent',{attrs:{"text":"Изменить номер","modify":"button_theme_flat form__change-num"},on:{"click":_vm.changeNum}}),_vm._v(" "),_c('InputComponent',{attrs:{"name":"email","label":"Email","placeholder":"Email","inputModify":"input_theme_order","type":"text","validation":{
                    type: 'email',
                    msg: 'Введите корректный email адрес'
                },"data-store-instance":_vm.AuthStore,"parent-error":_vm.parentError},on:{"inputChanged":_vm._inputChanged}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"order-first__cancel"},[_c('ButtonComponent',{attrs:{"modify":"button_theme_yuntolovo button_theme_gray","text":"Отмена бронирование","leftIcon":"CloseIcon","fill":"#8d9aa8"},on:{"click":_vm.cancelBooking}}),_vm._v(" "),(_vm.phoneStep === 2)?_c('ButtonComponent',{attrs:{"modify":"button_theme_yuntolovo order-first__button-next","text":"Продолжить","rightIcon":"ArrowIcon","fill":_vm.isProcessing || !_vm.isRegistrationOpen ? '#8d9aa8' : '#ffffff',"disabled":_vm.isProcessing || !_vm.isRegistrationOpen},on:{"click":_vm.getRegistration}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-first__agreement"},[_c('p',[_vm._v("Нажимая кнопку «Получить СМС-код», вы подтверждаете своё согласие на\n                    "),_c('a',{attrs:{"href":"#"}},[_vm._v("обработку персональных данных")])])])
}]

export { render, staticRenderFns }