<template>
    <div class="treatment-list">
        <h5 v-if="finished" class="treatment-list__title">История обращений:</h5>
        <div
            v-for="item in list"
            :key="item.id"
            class="treatment-list__card">
            <div :class="['treatment-list__card-status', {'is-opened': !finished}]">
                <img :src="_calcImageSource" :alt="item.status?.text">
                {{ item.status?.text }}
            </div>
            <DetailsComponent
                modify="treatment-list__card-details"
                :title="item.title ? item.title : 'Обращение'"
                :text="item.text"
                :date="`${finished ? 'Закрыто' : 'Создано'} в ${item.date}`" />
        </div>
    </div>
</template>

<script>
import DetailsComponent from '@/components/DetailsComponent/DetailsComponent';

export default {
    name: 'TreatmentListSection',

    components: {
        DetailsComponent
    },

    props: {
        list: {
            default: () => {
                return [];
            },
            type: Array
        },
        finished: {
            default: false,
            type   : Boolean
        }
    },

    computed: {
        _calcImageSource() {
            return `/webicons/treatment/treatment-${this.finished ? 'finished' : 'opened'}.svg`;
        }
    }
};
</script>

<style lang="scss">
@import './TreatmentListSection';
</style>
