import {EGetters, ESetters} from './store/types';
import type {NavigationGuardNext, Route} from 'vue-router';
import App from './App.vue';
import type {CreateElement} from 'vue';
import PortalVue from 'portal-vue';
import router from './router';
import {setupCalendar} from 'v-calendar';
import store from './store/index';
import Vue from 'vue';
import VueMask from 'v-mask';


const id = '#lk-app';
const lkElement: HTMLElement | null = document.querySelector(id);

/**
 * Получаем страницу перехода на страницу ЛК
 */
const checkReferrer = (): void => {
    const activeReferrer = store.getters[EGetters.GET_REFERRER];

    if (!activeReferrer && document.referrer) {
        store.commit(ESetters.SET_REFERRER, document.referrer);
    }
};

/**
 * Проверяем и обновляем данные меню и сайдбара
 * @param {object} to - данные страницы куда переходим
 */
const checkSidebar = (to: Route): void => {
    const sidebarData = store.getters[EGetters.GET_SIDEBAR];

    if (Object.keys(sidebarData)?.length && to.matched[0]?.meta?.haveSidebar) {
        const sectionName = to.matched[0]?.props?.['default']?.section ?
            to.matched[0]?.props?.['default']?.section :
            null;

        const params = new URLSearchParams([['section', sectionName]]);

        store.dispatch('updateSidebar', params);
    } else {
        store.dispatch('getMenu');
    }
};

const redirect = (to: Route): void => {
    router.push('/content/favorites');

    checkSidebar(to);
};

/**
 * Обновляем данные пользователя и получаем стандартные формы если их нет
 */
const updateDefaultData = (): void => {
    store.dispatch('getUserData');

    const defaultForms = store.getters[EGetters.GET_DEFAULT_FORMS];

    if (!defaultForms?.length) {
        store.dispatch('getDefaultForms');
    }
};

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    checkReferrer();

    const requireAuth = to.matched.some((record: any) => {
        return record.meta.requiresAuth;
    });

    const haveAuth = document.cookie.split('; ').reduce((acc: boolean, value: string) => {
        const parts = value.split('=');

        return parts[0] === 'glavstroy-lk-auth' ? decodeURIComponent(parts[1]) : acc;
    }, '');

    if (to.path === '/') {
        if (haveAuth) {
            redirect(to);
        } else {
            router.push('/auth').catch(() => {
                return true;
            });
        }
    } else if (to.path === '/auth' && haveAuth) {
        redirect(to);
    }

    if (requireAuth) {
        if (haveAuth) {
            updateDefaultData();

            checkSidebar(to);
            // eslint-disable-next-line callback-return
            next();
        } else {
            router.push('/auth').catch(() => {
                return true;
            });
        }
    } else {
        // eslint-disable-next-line callback-return
        next();
    }
});

if (lkElement) {
    Vue.use(VueMask);
    Vue.use(setupCalendar, {});
    Vue.use(PortalVue);

    new Vue({
        router,
        store,
        render: (h: CreateElement) => {
            return h(App);
        }
    }).$mount(id);
}
