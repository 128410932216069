<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.94262 1.75H10.0575C10.527 1.74999 10.9146 1.74999 11.2303 1.77578C11.5582 1.80257 11.8596 1.86007 12.1427 2.00432C12.5817 2.22802 12.9387 2.58498 13.1624 3.02402C13.2059 3.10933 13.2414 3.1963 13.2707 3.28516L7.97076 6.81845C7.38294 7.21033 6.61713 7.21033 6.02931 6.81845L0.729387 3.28517C0.758634 3.19631 0.794224 3.10933 0.837693 3.02402C1.0614 2.58498 1.41835 2.22802 1.8574 2.00432C2.14051 1.86007 2.44192 1.80257 2.76981 1.77578C3.0855 1.74999 3.47306 1.74999 3.94262 1.75Z" fill="#455569"/>
        <path d="M0.584952 4.59104C0.583369 4.75141 0.583371 4.92393 0.583374 5.10923V8.89076C0.583367 9.36032 0.583361 9.74787 0.609154 10.0636C0.635944 10.3915 0.69344 10.6929 0.837693 10.976C1.0614 11.415 1.41835 11.772 1.8574 11.9957C2.14051 12.1399 2.44192 12.1974 2.76981 12.2242C3.0855 12.25 3.47304 12.25 3.9426 12.25H10.0575C10.527 12.25 10.9146 12.25 11.2303 12.2242C11.5582 12.1974 11.8596 12.1399 12.1427 11.9957C12.5817 11.772 12.9387 11.415 13.1624 10.976C13.3066 10.6929 13.3641 10.3915 13.3909 10.0636C13.4167 9.74787 13.4167 9.36033 13.4167 8.89077V5.10923C13.4167 4.92393 13.4167 4.75141 13.4151 4.59103L8.61791 7.78917C7.6382 8.44231 6.36187 8.44231 5.38216 7.78917L0.584952 4.59104Z" fill="#455569"/>
    </svg>
</template>

<script>
export default {
    name: 'EmailIcon'
};
</script>
