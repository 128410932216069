<template>
    <svg :class="modify" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.9165 7H11.0832" :stroke="fill" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'MinusMoreIcon',

    props: {
        modify: {
            default: '',
            type   : String
        },
        fill: {
            default: '#ffffff',
            type   : String
        }
    }
};
</script>
