<template>
    <div class="order-first order-body">
        <h5 class="order-first__title">{{ computedTitle }}</h5>
        <p
            v-if="phoneStep === 2"
            class="order-first__title-description">
            Введите данные для регистрации
        </p>


        <div class="order-first__wrapper">
            <div v-show="phoneStep === 0" class="order-first__phone order-first__content">
                <InputComponent
                    name="smsphone"
                    label="Ваш номер телефона"
                    placeholder="+7 (___) ___-__-__"
                    mask="+7 (###) ###-##-##"
                    inputModify="input_theme_order"
                    :validation="{
                        type: 'phone',
                        msg: 'Введите корректный номер телефона'
                    }"
                    :data-store-instance="AuthStore"
                    @inputChanged="_inputChanged"
                />

                <ButtonComponent
                    modify="button_theme_yuntolovo"
                    text="Получить СМС-код"
                    :disabled="isProcessing || isInputError"
                    @click="getSMS"/>

                <div class="order-first__agreement">
                    <p>Нажимая кнопку «Получить СМС-код», вы подтверждаете своё согласие на
                        <a href="#">обработку персональных данных</a></p>
                </div>
            </div>

            <div v-show="phoneStep === 1" class="order-first__code order-first__content">
                <p class="order-first__code-info">Код отправлен на номер {{ phoneNum }}</p>
                <ButtonComponent
                    modify="button_theme_flat order-first__code-change"
                    text="Изменить номер"
                    @click="changeNum" />

                <InputComponent
                    name="smscode"
                    inputModify="input_theme_order"
                    label="Введите код из смс"
                    placeholder="Введите код из смс"
                    maxlength="6"
                    :validation="{
                        type: 'numberLength',
                        length: 6,
                        msg: 'Код должен состоять из 6 цифр'
                    }"
                    :parent-error="parentError"
                    :data-store-instance="AuthStore"
                    @inputChanged="_inputChanged"
                />

                <div class="order-first__code-buttons">
                    <ButtonComponent
                        modify="button_theme_yuntolovo"
                        text="Далее"
                        :disabled="isProcessing || isInputError"
                        @click="checkCode" />
                    <ButtonComponent
                        modify="button_theme_yuntolovo button_theme_gray"
                        :disabled="resendDisabled"
                        :text="resendText"
                        @click="_resendCode"/>
                </div>
            </div>

            <div v-if="phoneStep === 2" class="order-first__registration order-first__content">
                <InputComponent
                    ref="surname"
                    name="surname"
                    label="Фамилия"
                    placeholder="Фамилия"
                    inputModify="input_theme_order"
                    type="text"
                    :validation="{
                        type: 'letters',
                        msg: 'Введите только буквы'
                    }"
                    :data-store-instance="AuthStore"
                    :parent-error="parentError"
                    @inputChanged="_inputChanged"
                />

                <InputComponent
                    ref="name"
                    name="name"
                    label="Имя"
                    placeholder="Имя"
                    inputModify="input_theme_order"
                    type="text"
                    :validation="{
                        type: 'letters',
                        msg: 'Введите только буквы'
                    }"
                    :data-store-instance="AuthStore"
                    :parent-error="parentError"
                    @inputChanged="_inputChanged"
                />

                <InputComponent
                    name="phone"
                    label="Ваш номер телефона"
                    inputModify="input_theme_order"
                    disabled="disabled"
                    :value="phoneNum"
                />

                <ButtonComponent
                    text="Изменить номер"
                    modify="button_theme_flat form__change-num"
                    @click="changeNum" />

                <InputComponent
                    name="email"
                    label="Email"
                    placeholder="Email"
                    inputModify="input_theme_order"
                    type="text"
                    :validation="{
                        type: 'email',
                        msg: 'Введите корректный email адрес'
                    }"
                    :data-store-instance="AuthStore"
                    :parent-error="parentError"
                    @inputChanged="_inputChanged"
                />
            </div>
        </div>


        <div class="order-first__cancel">
            <ButtonComponent
                modify="button_theme_yuntolovo button_theme_gray"
                text="Отмена бронирование"
                leftIcon="CloseIcon"
                fill="#8d9aa8"
                @click="cancelBooking"/>

            <ButtonComponent
                v-if="phoneStep === 2"
                modify="button_theme_yuntolovo order-first__button-next"
                text="Продолжить"
                rightIcon="ArrowIcon"
                :fill="isProcessing || !isRegistrationOpen ? '#8d9aa8' : '#ffffff'"
                :disabled="isProcessing || !isRegistrationOpen"
                @click="getRegistration" />
        </div>
    </div>
</template>

<script>
import AuthDataController from '@/dataControllers/auth';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import InputComponent from '@/components/InputComponent/InputComponent';
import OrderDataController from '@/dataControllers/order';

const AuthController = new AuthDataController();

const OrderController = new OrderDataController();

export default {
    name: 'OrderFirstStepComponent',

    components: {
        ButtonComponent,
        InputComponent
    },

    computed: {
        OrderStore() {
            return OrderController.orderDataStore;
        },

        AuthStore() {
            return AuthController.authDataStore;
        },

        computedTitle() {
            return this.phoneStep < 2 ?
                'Подтвердите ваш номер телефона' :
                'Регистрация';
        }
    },

    data() {
        return {
            phoneStep         : 0,
            phoneNum          : null,
            parentError       : null,
            isProcessing      : false,
            isInputError      : true,
            isRegistrationOpen: false,
            resendDisabled    : true,
            resendText        : null,
            resendInterval    : null
        };
    },

    watch: {
        /**
         * Следим за изменением шага - чтобы понимать когда запускать / обнулять
         * таймер повторной отправки кода
         */
        phoneStep() {
            if (this.phoneStep === 1) {
                this._startTimer();
            } else {
                this._clearTimer();
            }
        }
    },

    methods: {
        _inputChanged(inputScope) {
            if (this.phoneStep === 2) {
                this.isRegistrationOpen = AuthController.isRegistrationPossible(['name', 'surname', 'email']);
            } else {
                this.isInputError = inputScope?.error;
                this.parentError = null;
            }
        },

        /**
         * Отправляем запрос на получение смс кода
         */
        async getSMS() {
            this.isProcessing = true;
            this.parentError = null;

            const smsResult = await AuthController.getSMS('smsphone', true);

            if (smsResult.error) {
                this.parentError = smsResult.parentError;
            }

            if (this.phoneStep !== smsResult.step) {
                this.phoneStep = 1;
                this.phoneNum = smsResult.phoneNum;
            }

            this.isProcessing = false;
            this.isInputError = true;
        },

        changeNum() {
            this.phoneStep = 0;
        },

        /**
         * Проверяем смс код
         */
        async checkCode() {
            this.isProcessing = true;
            this.parentError = null;

            const checkResult = await AuthController.checkCode('smscode');

            if (checkResult.errorText.length) {
                this.parentError = checkResult.errorText;
            } else if (checkResult.success === true) {
                if (checkResult.auth === true) {
                    this.$emit('clickNextStep');
                } else {
                    this.phoneStep = 2;
                }
            }

            this.isProcessing = false;
        },

        async getRegistration() {
            this.isProcessing = true;
            const registrationResult = await AuthController.getRegistration(['surname', 'name', 'email']);

            registrationResult ?
                this.$emit('clickNextStep') :
                this.showError = true;

            this.isProcessing = false;
        },

        /**
         * Запускаем интервал таймера
         */
        _startTimer() {
            this.resendInterval = setInterval(this._setTime, 1000);
            AuthController.initTimer();
            this.resendText = AuthController.getTimerText();
        },

        /**
         * Очищаем интервал таймера
         */
        _clearTimer() {
            clearInterval(this.resendInterval, 1000);
            AuthController.clearTimer();
        },

        /**
         * Таймер обратного отсчета в кнопке повторной отправки смс
         */
        _setTime() {
            this.resendText = AuthController.getTimerText();

            if (!AuthController.getResendDisabledState()) {
                this.resendDisabled = false;
                this._clearTimer();
            }
        },

        /**
         * Повторная отправка кода
         */
        async _resendCode() {
            const resentResult = await AuthController.resendCode();

            if (resentResult.error) {
                this.showError = true;
            } else {
                this.resendDisabled = true;
                this._startTimer();
            }
        },

        cancelBooking() {
            this.$emit('cancelBooking');
        }
    }
};
</script>

<style lang="scss">
@import './OrderFirstStepComponent';
</style>
