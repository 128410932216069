<template>
    <div :class="['sort', {'is-active': sortOpened}]">
        <ButtonComponent
            ref="sortButton"
            :modify="computedModifySortButton"
            icon="SortIcon"
            rightIcon="ArrowDownIcon"
            :fill="isMobile ? '#455569' : '#62725b'"
            :text="activeSort?.text"
            @click="_switchSort" />
        <transition name="fade">
            <FiltersSelectListComponent
                v-if="sortOpened"
                :list="sortList"
                :isActive="sortOpened"
                label="Сортировать"
                :activeItem="activeSort"
                @closeList="_switchSort"
                @clickRadio="_clickRadioHandler"
            />
        </transition>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent.vue';
import FiltersSelectListComponent from '@/components/FiltersSelectListComponent/FiltersSelectListComponent';
import {mapGetters} from 'vuex';
import {Utils} from '@/scripts/utils';

export default {
    name: 'SortComponent',

    components: {
        ButtonComponent,
        FiltersSelectListComponent
    },

    props: ['activeSort', 'sortList'],

    data() {
        return {
            sortOpened: false
        };
    },

    computed: {
        ...mapGetters({
            isMobile: 'GET_IS_MOBILE'
        }),

        computedModifySortButton() {
            const modifyArray = ['button_theme_flat', 'sort__button'];

            if (this.sortOpened) {
                modifyArray.push('is-active');
            }

            return modifyArray.join(' ');
        }
    },

    mounted() {
        document.addEventListener('click', this._onClickOutside);
    },

    beforeDestroy() {
        document.removeEventListener('click', this.onClickOutside);
    },

    methods: {
        _switchSort() {
            this.sortOpened = !this.sortOpened;

            if (this.isMobile) {
                this.sortOpened ? Utils.bodyFixed(this.$el) : Utils.bodyStatic();
                this.$root.$emit('selectOpened', this.sortOpened);
            }
        },

        _clickRadioHandler(id) {
            this.$emit('sorted', id);

            this._switchSort();
        },

        _onClickOutside(event) {
            const {sortButton} = this.$refs;

            if (this.sortOpened && !sortButton?.$el.contains(event.target)) {
                this._switchSort();
            }
        }
    }
};
</script>

<style lang="scss">
@import './SortComponent';
</style>
