<template>
    <section class="mortgage-list">
        <MortgageCardComponent
            v-for="item in programs"
            :key="item.id"
            :content="item"
            :flatPrice="flatPrice"
            @popupShow="popupHandler" />
    </section>
</template>

<script>
import MortgageCardComponent from '@/components/Mortgage/MortgageCardComponent/MortgageCardComponent';

export default {
    name: 'MortgageListSection',

    components: {
        MortgageCardComponent
    },

    props: {
        flatPrice: {
            default: '0',
            type   : String
        },
        programs: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    methods: {
        popupHandler(text) {
            this.$emit('popupShow', text);
        }
    }
};
</script>

<style lang="scss">
@import './MortgageListSection';
</style>
