<template>
    <div class="agency-projects">
        <div class="agency-projects__list">
            <div
                v-for="complex in complexes"
                :key="complex.id"
                class="agency-projects__list-item"
            >
                <a :href="complex.href" target="_blank">
                    <div class="agency-projects__item-img">
                        <img :src="complex.image" :alt="complex.name">
                    </div>

                    <div class="agency-projects__item-name">{{ complex.name }}</div>

                    <ButtonComponent
                        modify="button_theme_circle agency-projects__list-button"
                        icon="ExternalIcon"
                        fill="#ffffff" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';

export default {
    name: 'AgencyProjectListSection',

    components: {
        ButtonComponent
    },

    props: {
        complexes: {
            default: () => {
                return [];
            },
            type: Array
        }
    }
};
</script>

<style lang="scss">
@import './AgencyProjectListSection';
</style>
