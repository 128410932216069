<template>
    <div class="popup popup-owners">
        <div
            class="popup__overlay"
            @click="_closeClick"
            ></div>
        <div class="popup__content popup-owners__content">
            <div class="popup__content-top">
                <ButtonComponent
                    modify="button_theme_thin button_theme_transparent popup__content-close"
                    icon="CloseIcon"
                    fill="#8b96a3"
                    @click="_closeClick" />
            </div>

            <div class="popup__content-body popup-owners__content-body">
                <h3 class="popup-owners__content-title">{{ popupData.title }}</h3>
                <div
                    v-if="popupData.name || popupData.price"
                    class="popup-owners__content-descr">
                    <span
                        v-if="popupData.name"
                        class="popup-owners__descr-item popup-owners__descr-name">{{ popupData.name }}</span>
                    <span
                        v-if="popupData.price"
                        class="popup-owners__descr-item popup-owners__descr-price">{{ popupData.price }} ₽</span>
                </div>
                <OwnerListTemplate
                    v-if="popupData.owners"
                    :owners=popupData.owners />
                <OwnerTextTemplate
                    v-if="popupData.list"
                    :list="popupData.list" />
                <OwnerWorkTimeTemplate
                    v-if="popupData.worktime"
                    :data="popupData.worktime" />
            </div>
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import OwnerListTemplate from './template/OwnerListTemplate';
import OwnerTextTemplate from './template/OwnerTextTemplate';
import OwnerWorkTimeTemplate from './template/OwnerWorkTimeTemplate';

export default {
    name: 'PopupOwnersComponent',

    components: {
        ButtonComponent,
        OwnerListTemplate,
        OwnerTextTemplate,
        OwnerWorkTimeTemplate
    },

    props: {
        popupData: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    methods: {
        _closeClick() {
            this.$emit('closePopup');
        }
    }
};
</script>

<style lang="scss">
@import './PopupOwnersComponent';
</style>
