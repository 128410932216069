<template>
    <div class="privacy-page">
        <HeaderComponent :name="content?.title" />

        <div class="privacy-page__content" v-html="content?.text"></div>

        <FooterComponent />

        <MobileBarComponent v-if="isMobile" />

        <PopupsSection />
    </div>
</template>

<script>
import axios from 'axios';
import FooterComponent from '@/components/FooterComponent/FooterComponent';
import HeaderComponent from '@/components/HeaderComponent/HeaderComponent';
import PopupsSection from '@/sections/PopupsSection/PopupsSection';

export default {
    name: 'PrivacyPage',

    components: {
        FooterComponent,
        HeaderComponent,
        PopupsSection
    },

    data() {
        return {
            content: null
        };
    },

    mounted() {
        this.getRequest();
    },

    methods: {
        getRequest() {
            axios.get('/tests/lk/privacy.php')
                .then((response) => {
                    this.content = response.data.data;
                });
        }
    }
};
</script>

<style lang="scss">
@import './PrivacyPage';
</style>
