<template>
    <button
        :class="['button', computedModify, {'is-disabled': disabled}]"
        :disabled="disabled"
        :type="type"
        @click="click($event)">
        <span v-if="leftIcon" class="button__icon button__icon_theme_left">
            <component :is="leftIcon" :fill="fill" />
        </span>

        <span v-if="text" class="button__text">{{ text }}</span>

        <span v-if="icon" class="button__icon">
            <component :is="icon" :fill="fill" />
        </span>

        <span v-if="rightIcon" class="button__icon button__icon_theme_right">
            <component :is="rightIcon" :fill="fill" />
        </span>
    </button>
</template>

<script>
import ArrowBack from '@/icons/ArrowBack';
import ArrowDownIcon from '@/icons/ArrowDownIcon';
import ArrowIcon from '@/icons/ArrowIcon';
import CloseIcon from '@/icons/CloseIcon';
import CompareIcon from '@/icons/CompareIcon';
import ExternalIcon from '@/icons/ExternalIcon';
import FavoritesFilledIcon from '@/icons/FavoritesFilledIcon';
import FavoritesIcon from '@/icons/FavoritesIcon';
import HeartIcon from '@/icons/HeartIcon';
import InfoIcon from '@/icons/InfoIcon';
import LogoutIcon from '@/icons/LogoutIcon';
import MenuIcon from '@/icons/MenuIcon';
import PlusIcon from '@/icons/PlusIcon';
import SortIcon from '@/icons/SortIcon';
import TrashIcon from '@/icons/TrashIcon';
import UserIcon from '@/icons/UserIcon';

export default {
    name: 'ButtonComponent',

    components: {
        ArrowBack,
        ArrowDownIcon,
        ArrowIcon,
        CloseIcon,
        CompareIcon,
        ExternalIcon,
        FavoritesIcon,
        FavoritesFilledIcon,
        HeartIcon,
        InfoIcon,
        LogoutIcon,
        MenuIcon,
        PlusIcon,
        SortIcon,
        TrashIcon,
        UserIcon
    },

    props: {
        text    : String,
        disabled: {
            default: false,
            type   : Boolean
        },
        type: {
            default: 'button',
            type   : String
        },
        modify: {
            default: null,
            type   : [String, Array]
        },
        leftIcon: {
            default: null,
            type   : String
        },
        rightIcon: {
            default: null,
            type   : String
        },
        icon: {
            default: null,
            type   : String
        },
        fill: {
            default: null,
            type   : String
        }
    },

    computed: {
        computedModify() {
            return Array.isArray(this.modify) ?
                this.modify.join(' ') :
                this.modify;
        }
    },

    methods: {
        click(event) {
            this.$emit('click', event);
        }
    }
};
</script>

<style lang="scss">
@import 'ButtonComponent';
</style>
