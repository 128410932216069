<template>
    <div class="agency-commerce__card">
        <div class="agency-commerce__card-head">
            <div v-if="cardContent.project" class="agency-commerce__card-project">{{ cardContent.project }}</div>
            <div class="agency-commerce__card-place">
                <span v-if="cardContent.corpus">Корп. {{ cardContent.corpus }},</span>
                <span v-if="cardContent.section">секц. {{ cardContent.section }}</span>
            </div>
        </div>

        <div class="agency-commerce__card-manage">
            <div v-if="cardContent.offersTag" class="agency-commerce__card-tag">
                {{ cardContent.offersTag }}
            </div>
        </div>

        <div class="agency-commerce__card-image">
            <img v-if="cardContent.img" :src="cardContent.img" :alt="cardContent.title" />
        </div>

        <div class="agency-commerce__card-content">
            <h5 v-if="cardContent.price" class="agency-commerce__card-price">
                {{ _convertToDigit(cardContent.price) }} ₽/мес
            </h5>
            <div v-if="cardContent.pricePerMeter" class="agency-commerce__card-meter">
                {{ _convertToDigit(cardContent.pricePerMeter) }} ₽/мес за м²
            </div>
            <div class="agency-commerce__card-descr">
                <span v-if="cardContent.title">{{ cardContent.title }}</span>
                <span v-if="cardContent.floor">{{ cardContent.floor }}</span>
                <span v-if="cardContent.area">{{ cardContent.area }} м²</span>
            </div>
            <div v-if="cardContent.tags?.length" class="agency-commerce__card-tags">
                <div class="agency-commerce__card-tag">
                    {{ cardContent.tags[0] }}
                </div>
                <div
                    v-if="cardContent.tags.length > 1"
                    class="agency-commerce__card-tag"
                    @mouseover="_switchOffersList(true)"
                    @mouseout="_switchOffersList(false)"
                    @click="_clickOffer">
                    + {{ cardContent.tags.length - 1 }}
                </div>
                <transition name="fade">
                    <div v-if="cardContent.tags?.length > 1 && offersListShow" class="agency-commerce__card-tag-list">
                        <div
                            v-for="tag in cardContent.tags"
                            :key="tag"
                            class="agency-commerce__card-tag">{{ tag }}</div>
                    </div>
                </transition>
            </div>
        </div>

        <div class="agency-commerce__card-buttons">
            <LinkComponent
                v-if="cardContent.link"
                :href="cardContent.link"
                type="button"
                modify="button_theme_gray agency-commerce__card-button"
                text="Подробнее" />

            <ButtonComponent
                modify="agency-commerce__card-button"
                text="Оставить заявку"
                @click="_orderHandler" />
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import LinkComponent from '@/components/LinkComponent/LinkComponent';
import {mapGetters} from 'vuex';
import {Utils} from '@/scripts/utils';

export default {
    name: 'AgencyCommerceCardComponent',

    components: {
        ButtonComponent,
        LinkComponent
    },

    props: {
        cardContent: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    computed: {
        ...mapGetters({
            isMobile: 'GET_IS_MOBILE'
        })
    },

    data() {
        return {
            offersListShow: false
        };
    },

    methods: {
        _convertToDigit(num) {
            return Utils.convertToDigit(num);
        },

        _orderHandler() {
            this.$root.$emit('showPopup', 'commerce',
                this.cardContent.guid ? this.cardContent.guid : this.cardContent.title);
        },

        /**
         * Показываем или скрываем список с тегами
         * @param {boolean} state - состояние переключения
         */
        _switchOffersList(state) {
            if (this.isMobile) {
                return;
            }

            this.offersListShow = state;
        },

        /**
         * Обработка клика по кнопке тегов на мобильных
         */
        _clickOffer() {
            if (!this.isMobile) {
                return;
            }

            this.offersListShow = !this.offersListShow;
        }
    }
};
</script>

<style lang="scss">
@import './AgencyCommerceCardComponent';
</style>
