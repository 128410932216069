<template>
    <div :class="['project-selector', {'is-active': projectsListOpen}]">
        <ButtonComponent
            ref="projectSelector"
            :modify="computedModifyFavButton"
            rightIcon="ArrowDownIcon"
            :fill="fillColor"
            :text="activeProject?.text"
            @click="_projectListSwitch" />
        <transition name="fade">
            <FiltersSelectListComponent
                v-if="projectsListOpen"
                :list="projectsList"
                :isActive="projectsListOpen"
                :label="projectLabel"
                :activeItem="activeProject"
                @closeList="_projectListSwitch"
                @clickRadio="_clickRadioHandler"
            />
        </transition>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import FiltersSelectListComponent from '@/components/FiltersSelectListComponent/FiltersSelectListComponent';
import {mapGetters} from 'vuex';
import {Utils} from '@/scripts/utils';

export default {
    name: 'ProjectSelectorComponent',

    components: {
        ButtonComponent,
        FiltersSelectListComponent
    },

    props: {
        projectsList: {
            default() {
                return [];
            },
            type: Array
        },
        projectLabel: {
            default: 'Выберите проект',
            type   : String
        },
        activeProject: {
            default: null,
            type   : Object
        }
    },

    data() {
        return {
            projectsListOpen: false
        };
    },

    computed: {
        ...mapGetters({
            isMobile: 'GET_IS_MOBILE'
        }),

        fillColor() {
            return this.isMobile ? '#8b96a3' : '#62725b';
        },

        /**
         * Рассчитываем модификатор для кнопки
         * @return {string} - возвращает строку модификатор
         */
        computedModifyFavButton() {
            const modifyArray = ['project-selector__button'];

            if (this.projectsListOpen) {
                modifyArray.push('is-active');
            }

            return modifyArray.join(' ');
        }
    },

    mounted() {
        document.addEventListener('click', this._onClickOutside);
    },

    beforeDestroy() {
        document.removeEventListener('click', this._onClickOutside);
    },

    methods: {
        _projectListSwitch() {
            this.projectsListOpen = !this.projectsListOpen;

            if (this.isMobile) {
                this.projectsListOpen ? Utils.bodyFixed(this.$el) : Utils.bodyStatic();
                this.$root.$emit('selectOpened', this.projectsListOpen);
            }
        },

        _clickRadioHandler(id) {
            this.$emit('changeValue', id);
            this._projectListSwitch();
        },

        _onClickOutside(event) {
            const {projectSelector} = this.$refs;

            if (this.projectsListOpen && !projectSelector?.$el.contains(event.target)) {
                this._projectListSwitch();
            }
        }
    }
};
</script>

<style lang="scss">
@import './ProjectSelectorComponent';
</style>
