import type {
    IMeetingRequestOptions,
    IMeetingRequestResult,
    IMeetingResponse
} from './types';
import axios from 'axios';
import type {AxiosResponse} from 'axios';
import {ERequests} from '../../requests';

export default class MeetingFormController {
    public async sendRequest(type: string, options: IMeetingRequestOptions): Promise<IMeetingRequestResult> {
        const result = {
            success: false
        };

        await axios.post(ERequests[type], options)
            .then((response: AxiosResponse<IMeetingResponse>) => {
                result.success = response.data.success;
            })
            .catch(() => {
                result.success = false;
            });

        return result;
    }
}
