<template>
    <div class="objects-list">
        <ObjectsCard
            v-for="card in listData"
            :key="card.id"
            :card="card"
            :activeTab="activeTab"
            @showOwners="_showOwners" />
    </div>
</template>

<script>
import ObjectsCard from '../ObjectsCard/ObjectsCard';

export default {
    name: 'ObjectsListComponent',

    components: {
        ObjectsCard
    },

    props: {
        listData: {
            default: () => {
                return [];
            },
            type: Array
        },
        activeTab: {
            default: 'all',
            type   : String
        }
    },

    methods: {
        _showOwners(data) {
            this.$emit('showOwners', data);
        }
    }
};
</script>

