<template>
    <CommonCard
        v-if="activeTab === 'all' || content.estateType === activeTab"
        :annotation="annotation"
        :content="content"
        :bookingStatus="bookingStatus"
        :cardOptions="cardOptions"
        :isBooking=true
        :steps="steps"
        modify="booking-card"
        @deleteBooking="_deleteCard" />
</template>

<script>
import BookingDataController from '../../../dataControllers/booking';
import CommonCard from '../../CommonCard/CommonCard';

const BookingController = new BookingDataController();

export default {
    name: 'BookingCard',

    components: {
        CommonCard
    },

    props: {
        annotation: {
            default: null,
            type   : String
        },
        content: {
            default: () => {
                return {};
            },
            type: Object
        },
        bookingStatus: {
            default: () => {
                return [];
            },
            type: Array
        },
        modify: {
            default: '',
            type   : String
        },
        activeTab: {
            default: 'all',
            type   : String
        }
    },

    computed: {
        cardOptions() {
            return BookingController.getCardOptions(this.content);
        },

        steps() {
            return BookingController.getBookingSteps();
        }
    },

    methods: {
        /**
         * Передаем в родительский компонент запрос на удаление из списка
         * бронирования текущее помещение
         * @param {string} id - id удаляемого элемента
         */
        _deleteCard(id) {
            this.$emit('deleteBooking', id);
        }
    }
};
</script>
