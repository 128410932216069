import type {
    IDataStore,
    IItemObject,
    IUserFormData
} from './types';
import {Utils} from '../../scripts/utils';

let formDataInstance: IUserFormData | null = null;

export class UserFormData implements IUserFormData {
    public dataStore: IDataStore;

    constructor() {
        if (!formDataInstance) {
            // eslint-disable-next-line
            formDataInstance = this;
        }

        this.dataStore = {
            items: {}
        };

        return formDataInstance;
    }

    public setData(itemName: string, itemState: IItemObject): object {
        if (!this.dataStore.items[itemName]) {
            this.dataStore.items[itemName] = {
                validation: {
                    type  : null,
                    length: null,
                    msg   : null
                },
                value   : null,
                required: false,
                error   : false
            };
        }

        this.dataStore.items[itemName] = itemState;

        this.validation(itemName);

        return this;
    }

    public getData(itemName: string): boolean | IItemObject {
        if (!this.dataStore.items[itemName]) {
            return null;
        }

        return this.dataStore.items[itemName];
    }

    public validation(itemName: string): boolean {
        const item = this.dataStore.items[itemName];

        if (!item?.validation?.type) {
            return;
        }

        if (item.validation.type === 'numberLength' || item.validation.type === 'number') {
            item.value = item?.value?.replace(/[^0-9]/g, '');
        } else if (item.validation.type === 'contract') {
            item.value = item?.value?.replace(/[^0-9, -]/g, '');
        }

        item.error = Utils.validation(item.validation.type, item?.value, item?.validation?.length);
    }

    public clearData(): void {
        this.dataStore.items = {};
    }
}
