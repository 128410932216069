<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2.5C5.41014 2.5 2.5 5.41014 2.5 9C2.5 12.5898 5.41015 15.5 9 15.5C12.5898 15.5 15.5 12.5898 15.5 9C15.5 5.41015 12.5898 2.5 9 2.5ZM0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30557 17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30557 17.5 0.5 13.6944 0.5 9ZM8 6C8 5.44772 8.44772 5 9 5H9.0075C9.55978 5 10.0075 5.44772 10.0075 6C10.0075 6.55228 9.55978 7 9.0075 7H9C8.44772 7 8 6.55228 8 6ZM9 8C9.55229 8 10 8.44772 10 9V12C10 12.5523 9.55229 13 9 13C8.44772 13 8 12.5523 8 12V9C8 8.44772 8.44772 8 9 8Z" :fill="fill"/>
    </svg>

</template>

<script>
export default {
    name: 'InfoIcon',

    props: {
        fill: {
            default: '#455569',
            type   : String
        }
    }
};
</script>
