import ActionsPage from '../../pages/ActionsPage/ActionsPage.vue';
import BookingPage from '../../pages/BookingPage/BookingPage.vue';
import ComparePage from '../../pages/ComparePage/ComparePage.vue';
import FavoritesPage from '../../pages/FavoritesPage/FavoritesPage.vue';
import MeetingPage from '../../pages/MeetingPage/MeetingPage.vue';
import MortgagePage from '../../pages/MortgagePage/MortgagePage.vue';
import ObjectPage from '../../pages/ObjectPage/ObjectPage.vue';
import TreatmentPage from '../../pages/TreatmentPage/TreatmentPage.vue';

const buyerChildren = [{
    path     : 'favorites',
    name     : 'favorites',
    component: FavoritesPage,
    meta     : {
        name: 'Избранное'
    }
}, {
    path     : 'compare',
    name     : 'compare',
    component: ComparePage,
    meta     : {
        name: 'Сравнение'
    }
}, {
    path     : 'booking',
    name     : 'booking',
    component: BookingPage,
    meta     : {
        name: 'Бронирование'
    }
}, {
    path     : 'mortgage',
    name     : 'mortgage',
    component: MortgagePage,
    meta     : {
        name: 'Ипотечный калькулятор'
    }
}, {
    path     : 'objects',
    name     : 'objects',
    component: ObjectPage,
    meta     : {
        name: 'Мои объекты'
    }
}, {
    path     : 'actions',
    name     : 'actions',
    component: ActionsPage,
    meta     : {
        name: 'Акции'
    }
}, {
    path     : 'meetings',
    name     : 'meetings',
    component: MeetingPage,
    meta     : {
        name: 'Встречи',
        type: 'manager'
    }
}, {
    path     : 'excursion',
    name     : 'excursion',
    component: MeetingPage,
    meta     : {
        name: 'Экскурсии',
        type: 'excursion'
    }
}, {
    path     : 'treatment',
    name     : 'treatment',
    component: TreatmentPage,
    meta     : {
        name: 'Мои обращения'
    }
}];

export default buyerChildren;
