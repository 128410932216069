import type {
    IObjectsData,
    IObjectsDataStore,
    IObjectsResponseResult
} from './types';

let objectsDataInstance: IObjectsData | null = null;

export class ObjectsData implements IObjectsData {
    public objectsDataStore: IObjectsDataStore;

    constructor() {
        if (!objectsDataInstance) {
            // eslint-disable-next-line
            objectsDataInstance = this;
        }

        this.objectsDataStore = {
            list       : [],
            optionsKeys: {
                address    : 'Адрес',
                deadline   : 'Срок сдачи',
                queue      : 'Очередь',
                corpus     : 'Корпус',
                floor      : 'Этаж',
                num        : 'Номер',
                type       : 'Тип',
                finishing  : 'Отделка',
                paymentType: 'Способ оплаты'
            }
        };

        return objectsDataInstance;
    }

    setStoreData(response: IObjectsResponseResult): void {
        this.objectsDataStore.list = response.data.objects;
    }

    getStoreData(): IObjectsDataStore {
        return this.objectsDataStore;
    }
}
