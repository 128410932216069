<template>
    <div class="popup-owners__list">
        <div
            v-for="(owner, index) in owners"
            :key="owner.id"
            :class="[
                'popup-owners__list-item',
                {'popup-owners__list-item_theme_main': owner.main}]"
        >
            <div class="popup-owners__list-num">{{ index + 1 }}</div>
            <div class="popup-owners__list-content">
                <p class="popup-owners__list-role">{{ owner.role }}</p>
                <h5 class="popup-owners__list-name">{{ owner.name }}</h5>
                <p class="popup-owners__list-birthday">Дата рождения: {{ owner.birth }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OwnerListTemplate',

    props: {
        owners: {
            default: () => {
                return [];
            },
            type: Array
        }
    }
};
</script>
