<template>
    <div class="evaluate">
        <div class="evaluate__title">
            <h4 v-if="content?.title" v-html="content.title"></h4>
            <p v-if="content?.text" v-html="content.text"></p>
        </div>

        <div v-if="tabs?.length" class="evaluate__tabs">
            <TabsComponent
                modify="evaluate__tabs-list tabs_theme_button-gray"
                type="button"
                :activeTab="activeTab"
                :tabsData="tabs"
                @changeTab="_changeActiveTab" />

            <div class="evaluate__tabs-content">
                <AddressForm
                    v-if="activeTab?.slug === 'address'"
                    :complexes="complexes"
                    @sendData="_sendDataHandler" />
                <ContractForm
                    v-if="activeTab?.slug === 'contract'"
                    @sendData="_sendDataHandler" />
            </div>
        </div>

        <transition name="fade">
            <EvaluateResultComponent
                v-if="resultInfo || errorText"
                :room="resultInfo?.title"
                :queue="resultInfo?.queue"
                :square="resultInfo?.area"
                :floor="resultInfo?.floor"
                :price="resultInfo?.price"
                :error="errorText" />
        </transition>
    </div>
</template>

<script>
import AddressForm from './templates/AddressForm';
import ContractForm from './templates/ContractForm';
import EvaluateResultComponent from '@/components/EvaluateResultComponent/EvaluateResultComponent';
import TabsComponent from '@/components/TabsComponent/TabsComponent';
import TradeInDataController from '@/dataControllers/tradein';

const TradeInController = new TradeInDataController();

export default {
    name: 'EvaluateSection',

    components: {
        AddressForm,
        ContractForm,
        EvaluateResultComponent,
        TabsComponent
    },

    props: {
        content: {
            default: () => {
                return {};
            },
            type: Object
        },
        addresses: {
            default: () => {
                return [];
            },
            type: Array
        }
    },

    data() {
        return {
            activeTab: {
                id  : 0,
                slug: 'address',
                name: 'Найти по адресу'
            },
            // Переделать под запрос
            tabs: [{
                id  : 0,
                slug: 'address',
                name: 'Найти по адресу'
            }, {
                id  : 1,
                slug: 'contract',
                name: 'Найти по номеру договора'
            }],
            resultInfo: null,
            errorText : null,
            complexes : []
        };
    },

    watch: {
        addresses: {
            deep     : true,
            immediate: true,
            handler() {
                this._preparedComplexes();
            }
        }
    },

    methods: {
        /**
         * Переключаем табы и скрываем результат предыдущего поиска
         * @param {string} id - ид нового активного таба
         */
        _changeActiveTab(id) {
            this.activeTab = this.tabs[id];
            this.resultInfo = null;
        },

        _sendDataHandler(page, data) {
            this.$emit('processing', true);

            TradeInController.postTradeInRequest(page, data)
                .then((response) => {
                    if (response.success) {
                        this.resultInfo = response.info;
                    } else if (!response.success && response.errorData?.status === 404) {
                        this.errorText = 'Квартира не найдена';
                    } else {
                        this.$emit('errorShow', true, {
                            statusCode: response.errorData?.status,
                            url       : response.errorData?.url
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);

                    this.$emit('errorShow', true, {
                        statusCode: 500,
                        url       : 'Неизвестная ошибка на странице Trade-in'
                    });
                })
                .then(() => {
                    this.$emit('processing', false);
                });
        },

        /**
         * Подготавливаем комплексы для корректного отображения в селектах
         * @return {array} массив с данными адресов по комплексам
         */
        _preparedComplexes() {
            this.addresses.forEach((complex) => {
                if (complex?.addresses) {
                    complex.text = complex.title;

                    complex.addresses.forEach((address) => {
                        address.text = address.title;
                    });
                }

                this.complexes.push(complex);
            });

            return this.complexes;
        }
    }

};
</script>

<style lang="scss">
@import './EvaluateSection';
</style>
