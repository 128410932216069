<template>
    <div class="user" ref="user">
        <ButtonComponent
            v-if="isDesktop"
            modify="button_theme_transparent button_theme_thin user__close"
            icon="CloseIcon"
            fill="#8b96a3"
            @click="_closeUserTooltip" />

        <template v-if="Object.keys(user)?.length">
            <div
                v-if="!isDesktop"
                class="user__ico"
            >
                <img :src="userPhoto" :alt="user?.name">
            </div>

            <h4 v-if="!isDesktop" class="user__name">{{ user?.name }} {{ user?.surname }}</h4>
            <h5 v-else class="user__name">{{ user?.name }} {{ user?.surname }}</h5>

            <div class="user__contacts">
                <div
                    v-if="user?.phone"
                    :class="[
                        'user__contacts-item',
                        'user__phone',
                        {'is-desktop': isDesktop}
                    ]"
                >
                    <span v-if="isDesktop">Номер телефона</span>

                    <LinkComponent
                        :href="`tel:+7${user.phone}`"
                        :text="`+7${user.phone}`" />
                </div>

                <div
                    v-if="user?.email"
                    :class="[
                        'user__contacts-item',
                        'user__email',
                        {'is-desktop': isDesktop}
                    ]"
                >
                    <span v-if="isDesktop">E-mail</span>

                    <LinkComponent
                        :href="`mailto:${user.email}`"
                        :text="user.email" />
                </div>
            </div>
            <div class="user__buttons">
                <ButtonComponent
                    modify="user__buttons-button"
                    text="Изменить"
                    :disabled="isProcessing"
                    @click="_userChange" />

                <ButtonComponent
                    modify="button_theme_gray user__buttons-button"
                    text="Выйти"
                    :disabled="isProcessing"
                    @click="_userExit" />
            </div>
        </template>

        <template v-else>
            <div class="user__empty">
                <div class="user__name"></div>

                <div class="user__contacts">
                    <div
                        v-for="i in emptyElement"
                        :key="i"
                        class="user__contacts-item">
                        <span></span>
                        <p></p>
                    </div>
                </div>
                <div class="user__buttons">
                    <div
                        v-for="i in emptyElement"
                        :key="i"
                        class="user__buttons-button"></div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import AuthDataController from '../../dataControllers/auth/index';
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import LinkComponent from '@/components/LinkComponent/LinkComponent.vue';

const AuthController = new AuthDataController();

export default {
    name: 'UserComponent',

    data() {
        return {
            userPhoto   : null,
            isProcessing: false,
            isOpened    : false,
            emptyElement: [1, 2]
        };
    },

    components: {
        ButtonComponent,
        LinkComponent
    },

    computed: {
        ...mapGetters({
            isDesktop: 'GET_IS_DESKTOP',
            isMobile : 'GET_IS_MOBILE',
            user     : 'GET_USER'
        })
    },

    created() {
        this.userPhoto = this.user?.photo && this.user?.photo.length ?
            this.user?.photo :
            '/webicons/user/empty.svg';
    },

    mounted() {
        setTimeout(() => {
            document.addEventListener('click', this._onClickOutside);
        }, 500);

        this.isOpened = true;
    },

    beforeDestroy() {
        document.removeEventListener('click', this._onClickOutside);
    },

    methods: {
        ...mapActions(['clearSidebar']),

        _userChange() {
            this.$router.push({name: 'profile'});
            this.$emit('closeMenu');
        },

        async _userExit() {
            this.isProcessing = true;
            const logoutResult = await AuthController.logout();

            this.clearSidebar();

            if (logoutResult) {
                this.$router.push('/auth');
            }

            this.isProcessing = false;
        },

        _closeUserTooltip() {
            this.$emit('closeUserTooltip');
            this.isOpened = false;
        },

        _onClickOutside(event) {
            const {user} = this.$refs;

            if (this.isOpened && !user?.contains(event.target)) {
                this._closeUserTooltip();
            }
        }
    }
};
</script>

<style lang="scss">
@import './UserComponent';
</style>
