<template>
    <div class="order-preview order-body">
        <div class="order-preview__content"></div>
        <div v-if="content?.note" class="order-preview__note">
            <div v-if="content.note.title" class="order-preview__note-title">{{ content.note.title }}</div>
            <div v-if="content.note.list" class="order-preview__note-list">
                <div
                    v-for="note in content.note.list"
                    :key="note.id"
                    class="order-preview__note-item"
                    >
                    {{ note.text }}
                </div>
            </div>
        </div>

        <OrderObjectCard
            v-if="content?.object"
            title="Характеристики помещения"
            :objectData="content.object" />

        <OrderConditionalsComponent
            v-if="content?.conditionals && Object.keys(content.conditionals).length"
            :content="content.conditionals"/>

        <div v-if="content?.booking && Object.keys(content.booking).length" class="order-preview__booking">
            <h5 class="order-preview__booking-title">Условия бронирования</h5>
            <div class="order-preview__booking-content order-universal__block">
                <div
                    v-if="content.booking.time"
                    class="order-preview__booking-item order-universal__prop-item order-preview__booking-developer">
                    <div class="order-preview__booking-name order-universal__prop-name">Срок бронирования</div>
                    <div class="order-preview__booking-value order-universal__prop-value">
                        {{ content.booking.time }}
                    </div>
                </div>
                <div
                    v-if="content.booking.price"
                    class="order-preview__booking-item order-universal__prop-item order-preview__booking-developer">
                    <div class="order-preview__booking-name order-universal__prop-name">Стоимость</div>
                    <div class="order-preview__booking-value order-universal__prop-value">
                        {{ content.booking.price }}
                    </div>
                </div>

                <div class="order-preview__booking-note">
                    <InfoIcon fill="#62725b" />
                    <span>Допускается не&nbsp;более одного бронирования одновременно
                        для каждого вида недвижимости</span>
                </div>
            </div>
        </div>

        <div class="order-preview__buttons">
            <LinkComponent
                type="button"
                modify="button_theme_yuntolovo button_theme_gray order-preview__button order-preview__button-back"
                :href="content?.link"
                leftIcon="ArrowIcon"
                fill="#8d9aa8"
                text="Вернуться назад" />

            <ButtonComponent
                rightIcon="ArrowIcon"
                modify="button_theme_yuntolovo order-preview__button order-preview__button-next"
                text="Начать бронирование"
                fill="#ffffff"
                @click="nextStep" />
        </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent/ButtonComponent';
import InfoIcon from '@/icons/InfoIcon';
import LinkComponent from '@/components/LinkComponent/LinkComponent';
import OrderConditionalsComponent from '../OrderConditionalsComponent/OrderConditionalsComponent';
import OrderObjectCard from '../OrderObjectCard/OrderObjectCard';

export default {
    name: 'OrderPreviewStepComponent',

    components: {
        ButtonComponent,
        InfoIcon,
        LinkComponent,
        OrderConditionalsComponent,
        OrderObjectCard
    },

    props: {
        content: {
            default: () => {
                return {};
            },
            type: Object
        }
    },

    methods: {
        nextStep() {
            this.$emit('clickNextStep');
        }
    }
};
</script>

<style lang="scss">
@import './OrderPreviewStepComponent';
</style>
