<template>
    <div class="popups">
        <transition-group name="popup-question-fade">
            <PopupQuestionComponent
                v-if="popupQuestionShow"
                key="popupQuestion"
                :title="questionTitle"
                :descr="questionDescr"
                :select="questionSelect"
                :textarea="questionTextarea"
                :buttonText="questionButton"
                :result="questionResult"
                @closePopup="_popupSwitch('popupQuestionShow')"
                :callbackData="callbackData" />

            <PopupMeetingComponent
                v-if="popupMeetingShow"
                key="popupMeeting"
                @closePopup="_popupSwitch('popupMeetingShow')"
                :type="optionData" />

            <PopupMultiStepsComponent
                v-if="popupMultiStepsShow"
                key="popupMultiSteps"
                :contracts="optionData"
                @closePopup="_popupSwitch('popupMultiStepsShow')" />

            <PopupTextContentComponent
                v-if="popupActionShow"
                key="popupAction"
                :popupData="optionData"
                @closePopup="_popupSwitch('popupActionShow')" />


            <PopupBookingStepsComponent
                v-if="popupBookingStepsShow"
                key="popupBookingSteps"
                :steps="optionData"
                @closePopup="_popupSwitch('popupBookingStepsShow')" />

            <PopupConstructorComponent
                v-if="popupConstructorShow"
                key="popupConstructor"
                :popupData="popupFieldsData"
                @closePopup="_popupSwitch('popupConstructorShow')" />

            <PopupOwnersComponent
                v-if="popupOwnersShow"
                key="popupOwners"
                :popupData="optionData"
                @closePopup="_popupSwitch('popupOwnersShow')" />
        </transition-group>

        <portal-target name="popups"></portal-target>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PopupBookingStepsComponent from '@/components/Popups/PopupBookingStepsComponent/PopupBookingStepsComponent';
import PopupConstructorComponent from '@/components/Popups/PopupConstructorComponent/PopupConstructorComponent';
import PopupMeetingComponent from '@/components/Popups/PopupMeetingComponent/PopupMeetingComponent';
import PopupMultiStepsComponent from '@/components/Popups/PopupMultiStepsComponent/PopupMultiStepsComponent';
import PopupOwnersComponent from '@/components/Popups/PopupOwnersComponent/PopupOwnersComponent';
import PopupQuestionComponent from '@/components/Popups/PopupQuestionComponent/PopupQuestionComponent';
import PopupTextContentComponent from '@/components/Popups/PopupTextContentComponent/PopupTextContentComponent';
import {Utils} from '@/scripts/utils';

export default {
    name: 'PopupsSection',

    components: {
        PopupBookingStepsComponent,
        PopupConstructorComponent,
        PopupMeetingComponent,
        PopupMultiStepsComponent,
        PopupOwnersComponent,
        PopupQuestionComponent,
        PopupTextContentComponent
    },

    data() {
        return {
            popupActionShow      : false,
            popupBookingStepsShow: false,
            popupConstructorShow : false,
            popupMeetingShow     : false,
            popupMultiStepsShow  : false,
            popupOwnersShow      : false,
            popupQuestionShow    : false,
            popupFieldsData      : null,
            optionData           : null
        };
    },

    computed: {
        ...mapGetters({
            forms: 'GET_DEFAULT_FORMS'
        })
    },

    mounted() {
        this.$root.$on('showPopup', this._callbackPopupHandler);
    },

    methods: {
        /**
         * Отображение требуемого попапа - функция написана на будущее - если
         * вдруг понадобится открывать разные попапы
         * @param {string} popupItem - идентификатор для отображения попапа
         */
        _popupSwitch(popupItem) {
            this[popupItem] = !this[popupItem];

            this[popupItem] ? Utils.bodyFixed(this.$el) : Utils.bodyStatic();
        },

        /**
         * Обрабатываем сигнал на показ попапа из дочернего компонента
         * @param {string} popupType - набор полей для формы
         * @param {any} optionData - Дополнительные данные
         */
        _callbackPopupHandler(popupType = 'callback', optionData = null) {
            let currentPopup = null;

            this.popupFieldsData = this._calcPopupFields(popupType);
            this.optionData = optionData;

            switch (popupType) {
                case 'actions':
                    currentPopup = 'popupActionShow';
                    break;
                case 'bookingSteps':
                    currentPopup = 'popupBookingStepsShow';
                    break;
                case 'meeting':
                    currentPopup = 'popupMeetingShow';
                    break;
                case 'multi':
                    currentPopup = 'popupMultiStepsShow';
                    break;
                case 'owners':
                    currentPopup = 'popupOwnersShow';
                    break;
                case 'service':
                    this.popupFieldsData.service = optionData ? optionData : null;
                    this.popupFieldsData.description = this.popupFieldsData.service;
                    currentPopup = 'popupConstructorShow';
                    break;
                case 'additional-service':
                    this.popupFieldsData.service = optionData ? optionData : null;
                    currentPopup = 'popupConstructorShow';
                    break;
                case 'commerce':
                case 'mortgage':
                case 'booking-question':
                    this.popupFieldsData.params = optionData ? optionData : null;
                    currentPopup = 'popupConstructorShow';
                    break;
                case 'feedback':
                case 'callback':
                case 'partner':
                default:
                    currentPopup = 'popupConstructorShow';
                    break;
            }

            this._popupSwitch(currentPopup);
        },

        /**
         * Ищем поле для подстановки данных
         * @param {string} type - имя поля
         * @return {Element} - инпут для подстановки
         */
        _calcPopupFields(type) {
            return this.forms.find((item) => {
                return item.code === type;
            });
        },

        /**
         * Обрабатываем сигнал на показ попапа записи на экскурсию или к
         * менеджеру
         * @param {string} type - тип записи
         */
        _meetingPopupHandler(type) {
            this.meetingPopup = type;
            this._popupSwitch('popupMeetingShow');
        }
    }
};
</script>
