<template>
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="56" height="56" rx="28" fill="#62725b"/>
        <path d="M24.126 30C24.5701 31.7252 26.1362 33 28 33C29.8638 33 31.4299 31.7252 31.874 30M27.0177 18.764L20.2354 24.0391C19.782 24.3917 19.5553 24.5681 19.392 24.7889C19.2474 24.9844 19.1396 25.2048 19.074 25.4391C19 25.7035 19 25.9907 19 26.5651V33.8C19 34.9201 19 35.4801 19.218 35.908C19.4097 36.2843 19.7157 36.5903 20.092 36.782C20.5198 37 21.0799 37 22.2 37H33.8C34.9201 37 35.4802 37 35.908 36.782C36.2843 36.5903 36.5903 36.2843 36.782 35.908C37 35.4801 37 34.9201 37 33.8V26.5651C37 25.9907 37 25.7035 36.926 25.4391C36.8604 25.2048 36.7526 24.9844 36.608 24.7889C36.4447 24.5681 36.218 24.3917 35.7646 24.0391L28.9823 18.764C28.631 18.4907 28.4553 18.3541 28.2613 18.3016C28.0902 18.2553 27.9098 18.2553 27.7387 18.3016C27.5447 18.3541 27.369 18.4907 27.0177 18.764Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    name: 'HouseIcon'
};
</script>
